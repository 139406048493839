import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from '../../layouts/cssstyled';
import FormField from '../FormField';
import { fieldsValidator } from '../../utils/helper';

import { normalizes } from '../../utils/normalizes';

const fields = {
  emailvcode: {
    validators: ['required', 'minLength:6', 'maxLength:6'],
  },
};

const validate = (values) => fieldsValidator(fields, values);

const Form = (props) => {
  const {
    error,
    handleSubmit,
    pristine,
    invalid,
    reset,
    submitting,
    t,
    changeLanguage,
    i18n,
    browserCompatible,
    browserVersionCompatible,
    parentState,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ width: '300px', margin: 'auto' }}>
        <Field
          inputName="emailvcode"
          name="emailvcode"
          formProps={props}
          label="form.codeverify"
          nameForm="singup2"
          component={FormField}
          type="text"
          normalize={normalizes.LowerCase}
          typeInput="text"
          formRowExpand="1"
        />

        {browserCompatible && browserVersionCompatible && (
          <div style={{ position: 'relative', margin: 'auto' }}>
            <Button type="submit" disabled={submitting}>
              {t('info.vVerify')}
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'singup2',
  validate,
})(Form);
