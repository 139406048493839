import ReadOnlyRichText from '../slatejs/ReadOnlyRichText';
import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from '../../layouts/cssstyled';
import FormField from '../FormField';
import FieldReadOnly from '../FieldReadOnly';
import FormArrayField from '../FormArrayField';

import {
  fieldsValidator,
  getOptions,
  processNewValues,
} from '../../utils/helper';
import {
  resolvePathObj,
  processValues,
  realTypeOf,
  tryParseJSON,
  getInputValue,
  toPrice,
} from '../../utils/commonutils';

import { Tables } from '../../defTables';
import { normalizes } from '../../utils/normalizes';

import RegistrationGroupping from './RegistrationGroupping';
import PriceFormula from './PriceFormula';

const tableCrud = 'regonline';
let nameForm = `${tableCrud}Form`;
const validate = (values, props) => {
  const { toursettings, aTour, aHotels, paymentOnline } = props;
  // validate conditional if field is added: room_type /  stopspoint_id
  let result = fieldsValidator('regonline', values, props, nameForm);
  // console.log('error result1', result1);
  // on the case field is invisible, dont test stoppoint
  if (
    toursettings.regonline_stopspoint &&
    props.aStopsPoints.length > 0 &&
    !values.stopspoint_id
  ) {
    result.stopspoint_id = 'validator.required';
  }

  if (
    toursettings.regonline_room_type &&
    aHotels.length > 0 && // twin AH1134
    !values.tourroom_id
  ) {
    result.tourroom_id = 'validator.required';
  }

  if (toursettings.regonline_status && !values.customerstatus_id) {
    result.customerstatus_id = 'validator.required';
  }

  // TWIN VLL122
  const customerstatus_id = getInputValue(props, 'customerstatus_id', {
    nameForm,
  });

  const customerStatus = props.allCustomerStatus.find(
    (customerstatus) => customerstatus.id === customerstatus_id
  );

  if (customerStatus) {
    // console.log("customerStatus found", customerStatus);
    customerStatus.availableservices.map((service) => {
      if (
        service.typeEntry === 'OL' &&
        service.validators &&
        service.validators.includes('required')
      ) {
        const nameInput = 'service_' + service.id;
        let serviceValue = getInputValue(props, nameInput, {
          nameForm,
        });
        if (!serviceValue) {
          result[nameInput] = 'validator.required';
        }
      }
    });
  }

  if (Object.keys(paymentOnline).length > 0) {
    if (!values.typecard) {
      result.typecard = 'validator.required';
    }
  }

  if (!values.gcu) {
    result.gcu = 'validator.required';
  }

  return result;
};
class Form extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.timerHandle = setTimeout(() => {
      this.props.dispatch(change(nameForm, '_fakefield', Math.random()));
      this.props.executeCode('onChangeInput', {
        nameForm,
        action: 'initForm',
        props: this.props,
        formProps: this.props,
        formState: this.props.formState,
      });
    }, 1000);
    window.addEventListener('message', this.readMessage, true);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
    window.removeEventListener('message', this.readMessage, true);
  }

  readMessage = (event) => {
    if (!event.origin.includes(window.location.hostname)) return;
    if (event && event.data) {
      const params = tryParseJSON(event.data, {});
      const readMess = tryParseJSON(event.data);
      if (params.popup) {
        params.inputFullName = params.popup;
        if (!params.formProps) params.formProps = this.props;
        this.props.executeCode('onChangeInput', params);
      }
    }
  };

  hocdebounced = debounce((methodCode, params) => {
    this.props.executeCode(methodCode, params);
  }, 1500);

  render() {
    let typecard = [
      { id: 'CB', name: 'CB' },
      { id: 'VISA', name: 'VISA' },
      { id: 'MASTERCARD', name: 'MASTERCARD' },
    ];

    const {
      error,
      handleSubmit,
      invalid,
      pristine,
      change,
      submitFailed,
      t,
      toursettings,
      aTour,
      paymentOnline,
      retryPayment,
      page,
      handleClickSubmit,
      submitting,
      changePage,
      containerPropsForm,
      allCustomerStatus,
      aServices,
      aHotels,
      ...otherProps
    } = this.props;

    let price = toPrice(
      getInputValue(this.props, 'price', {
        nameForm,
      })
    );

    let _customerSubStatuss = [];
    if (this.props.formState._customerSubStatuss) {
      _customerSubStatuss = this.props.formState._customerSubStatuss;
    }

    // console.log("aHotelsBrute", aHotelsBrute, this.props.containerPropsForm);
    const totalPages = 1; // suspendes pagination // toursettings.regonline_status ? 2 : 1;

    const LinesPaymentOnline = [];
    if (Object.keys(paymentOnline).length > 0) {
      LinesPaymentOnline.push(
        <Field
          key="typecard"
          inputName="typecard"
          name="typecard"
          component={FormField}
          formProps={this.props}
          formState={this.props.formState}
          type="selectBox"
          typeInput="selectBox"
          width="300px"
          label="Type de carte"
          listOptions={typecard}
        />
      );
    }

    const aLineServices = [];
    let aServicesInputs = [];
    aServicesInputs = aServices;
    aServicesInputs.map((service) => {
      const nameInput = 'service_' + service.id;

      if (service.typeEntry === '') {
        // Yes/no or Checkbox
        aLineServices.push(
          <Field
            key={service.id}
            nameForm={nameForm}
            inputName={nameInput}
            name={nameInput}
            labelNoTranslate={true}
            component={FormField}
            type="checkbox"
            typeInput="checkbox"
            width="300px"
            label={service.name}
            formProps={this.props}
            onChange={(event, newValue, previousValue) => {
              this.props.executeCode('onChangeInput', {
                nameForm,
                inputFullName: nameInput,
                formProps: this.props,
                formState: this.props.formState,
                event,
                newValue,
                previousValue,
              });
            }}
          />
        );
      }
      if (service.typeEntry === 'OL') {
        const listOptions = tryParseJSON(service.listOptions);
        aLineServices.push(
          <Field
            nameForm={nameForm}
            key={service.id}
            inputName={nameInput}
            name={nameInput}
            labelNoTranslate={true}
            component={FormField}
            validatorRequired={
              service.validators && service.validators.includes('required')
                ? '1'
                : null
            }
            type="selectBox"
            typeInput="selectBox"
            listOptions={listOptions}
            width="300px"
            label={service.nameOnly}
            formProps={this.props}
            onChange={(event, newValue, previousValue) => {
              this.props.executeCode('onChangeInput', {
                nameForm,
                inputFullName: nameInput,
                formProps: this.props,
                formState: this.props.formState,
                event,
                newValue,
                previousValue,
              });
            }}
          />
        );
      }
    });

    return (
      <form onSubmit={handleSubmit}>
        <div className="appBodyTitleSeparator" />
        {!retryPayment && toursettings.regonline_status && (
          <div className="formSection">
            <Field
              key="customerstatus_id"
              inputName="customerstatus_id"
              name="customerstatus_id"
              formProps={this.props}
              pathInTables="regonline.fields.customerstatus_id"
              formState={this.props.formState}
              listOptions={allCustomerStatus}
              nameForm={nameForm}
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="selectBox"
              typeInput="selectBox"
              onChange={(event, newValue, previousValue) => {
                this.props.executeCode('onChangeInput', {
                  nameForm,
                  inputFullName: 'customerstatus_id',
                  formProps: this.props,
                  formState: this.props.formState,
                  event,
                  newValue,
                  previousValue,
                });
              }}
            />
            <Field
              key="customersubstatus_id"
              inputName="customersubstatus_id"
              name="customersubstatus_id"
              formProps={this.props}
              pathInTables="regonline.fields.customersubstatus_id"
              formState={this.props.formState}
              listOptions={_customerSubStatuss}
              nameForm={nameForm}
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="selectBox"
              typeInput="selectBox"
              onChange={(event, newValue, previousValue) => {
                this.props.executeCode('onChangeInput', {
                  nameForm,
                  inputFullName: 'customersubstatus_id',
                  formProps: this.props,
                  formState: this.props.formState,
                  event,
                  newValue,
                  previousValue,
                });
              }}
            />
          </div>
        )}

        {
          // twin AH1134
          !retryPayment &&
            toursettings.regonline_room_type &&
            aHotels.length > 0 && (
              <div className="formSection">
                <Field
                  inputName="tourroom_id"
                  name="tourroom_id"
                  formProps={this.props}
                  pathInTables="regonline.fields.tourroom_id"
                  disableValidatorRequired={aHotels.length > 0 ? false : true}
                  formState={this.props.formState}
                  nameForm={nameForm}
                  component={FormField}
                  executeCode={this.props.executeCode}
                  syncCode={this.props.syncCode}
                  type="selectBox"
                  typeInput="selectBox"
                  listOptions={aHotels}
                  onChange={(event, newValue, previousValue) => {
                    this.props.executeCode('onChangeInput', {
                      nameForm,
                      inputFullName: 'tourroom_id',
                      formProps: this.props,
                      formState: this.props.formState,
                      event,
                      newValue,
                      previousValue,
                    });
                  }}
                />
              </div>
            )
        }
        {!retryPayment &&
          toursettings.regonline_room_type &&
          this.props.containerPropsForm.crud_view_tour.tour.tourorganizer_id.name.includes(
            'discontinued this if on the future%%%'
          ) && (
            <div className="formSection">
              <Field
                key="room_type"
                inputName="room_type"
                name="room_type"
                formProps={this.props}
                pathInTables="regonline.fields.room_type"
                formState={this.props.formState}
                nameForm={nameForm}
                component={FormField}
                executeCode={this.props.executeCode}
                syncCode={this.props.syncCode}
                type="selectBox"
                typeInput="selectBox"
                onChange={(event, newValue, previousValue) => {
                  this.props.executeCode('onChangeInput', {
                    nameForm,
                    inputFullName: 'room_type',
                    formProps: this.props,
                    formState: this.props.formState,
                    event,
                    newValue,
                    previousValue,
                  });
                }}
                listSource="roomBase"
              />
            </div>
          )}
        {!retryPayment && toursettings.regonline_room_share && (
          <div className="formSection">
            <Field
              key="room_shared"
              inputName="room_shared"
              name="room_shared"
              formProps={this.props}
              pathInTables="regonline.fields.room_shared"
              formState={this.props.formState}
              nameForm={nameForm}
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              type="text"
              typeInput="text"
            />
          </div>
        )}
        {!retryPayment &&
          toursettings.regonline_stopspoint &&
          this.props.aStopsPoints.length > 0 && (
            <div className="formSection">
              <Field
                key="stopspoint_id"
                inputName="stopspoint_id"
                name="stopspoint_id"
                formProps={this.props}
                pathInTables="regonline.fields.stopspoint_id"
                formState={this.props.formState}
                nameForm={nameForm}
                component={FormField}
                executeCode={this.props.executeCode}
                syncCode={this.props.syncCode}
                type="selectAutocomplete"
                tableCrud={tableCrud}
                typeInput="selectAutocomplete"
              />
            </div>
          )}
        {/*page === totalPages && <div>{aLineServices}</div>*/}
        <div>{aLineServices}</div>
        <div className="docked">
          {t('form.price')}: {price}
        </div>
        <div className="formSection">
          <FieldReadOnly value={price} label={t('form.price')} />
          <Field
            key="price"
            inputName="price"
            name="price"
            formProps={this.props}
            pathInTables="registration.fields.price"
            formState={this.props.formState}
            nameForm={nameForm}
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="hidden"
            normalize={normalizes.Price}
            typeInput="hidden"
            disabled={true}
          />

          <Field
            key="partialpayment"
            inputName="partialpayment"
            name="partialpayment"
            formProps={this.props}
            pathInTables="regonline.fields.partialpayment"
            sublabel="info.partialpayment"
            formState={this.props.formState}
            nameForm={nameForm}
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
            onChange={(event, newValue, previousValue) => {
              this.props.executeCode('onChangeInput', {
                nameForm,
                inputFullName: 'partialpayment',
                formProps: this.props,
                formState: this.props.formState,
                event,
                newValue,
                previousValue,
              });
            }}
          />
        </div>
        <Field
          inputName="items"
          name="items"
          formProps={this.props}
          pathInTables="regonline.fields.items"
          formState={this.props.formState}
          nameForm={nameForm}
          component={FormField}
          executeCode={this.props.executeCode}
          syncCode={this.props.syncCode}
          type="hidden"
          typeInput="hidden"
          width="500px"
        />
        <Field
          inputName="notes"
          name="notes"
          formProps={this.props}
          pathInTables="regonline.fields.notes"
          formState={this.props.formState}
          nameForm={nameForm}
          component={FormField}
          executeCode={this.props.executeCode}
          syncCode={this.props.syncCode}
          type="textarea"
          typeInput="textarea"
          width="500px"
        />
        <Field
          inputName="person_id"
          name="person_id"
          typeInput="hidden"
          component={FormField}
          label=""
        />
        <Field
          inputName="modetest"
          name="modetest"
          typeInput="hidden"
          component={FormField}
          label=""
        />
        {/*page < totalPages && (
          <div>
            <div>
              {error && <strong>{error}</strong>}
              <div className="formError">{invalid && t("form.haserrors")}</div>
            </div>
            <div>
              <Button margin="1" key="next" onClick={() => changePage()}>
                {t("form.continue")}
              </Button>
            </div>
          </div>
        )*/}
        {page === totalPages && (
          <div>
            <div>{LinesPaymentOnline}</div>
            <Field
              inputName="gcu"
              name="gcu"
              typeInput="checkbox"
              component={FormField}
              label="form.acceptcgu1"
            />
            <Field
              inputName="_formstate"
              name="_formstate"
              typeInput="hidden"
              component={FormField}
              label=""
            />
            <div className="formSection" style={{ flexDirection: 'column' }}>
              <div style={{ paddingBottom: '5px' }}>
                {this.props.urlgcu && (
                  <a target="_blank" rel="noopener" href={this.props.urlgcu}>
                    {' '}
                    ({t('info.vRead')})
                  </a>
                )}
              </div>

              {this.props.textgcu && (
                <div
                  style={{
                    padding: '20px',
                    height: '220px',
                    maxHeight: '200px',
                    overflowY: 'scroll',
                  }}
                >
                  <ReadOnlyRichText textInitValue={this.props.textgcu} />
                </div>
              )}
            </div>
            <div>
              {error && <strong>{error}</strong>}

              <div className="formError">{invalid && t('form.haserrors')}</div>
            </div>

            <div key="submit" style={{ paddingTop: '15px', margin: 'auto' }}>
              <Button
                type="submit"
                disabled={submitting}
                onClick={(e) => handleClickSubmit(e)}
              >
                {t('info.vRegistrateAndPay')}
              </Button>
            </div>
          </div>
        )}
      </form>
    );
  }
}

const ComponentWithData = reduxForm({
  form: nameForm,
  touchOnChange: true,

  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  const statesReturn = { myState: state };
  let initialValues;

  if (ownProps.retryPayment) {
    initialValues = processValues(
      ownProps,
      tableCrud,
      ownProps.retryPayment,
      'toClient',
      'view'
    );

    if (
      ownProps.formState.defaultValues &&
      ownProps.formState.defaultValues.id
    ) {
      initialValues.id = ownProps.formState.defaultValues.id;
    }
  } else {
    initialValues = processNewValues(
      ownProps,
      tableCrud,
      ownProps.data,
      'toClient',
      'new'
    );
    // initValues comes with default value for state = 1 (not processes state)
    initialValues.person_id = ownProps.person_id; // retrive person_id to set default
    initialValues.price = ownProps.data.price; // retrive tour price to set default
    initialValues.tour_id = ownProps.aTour.id; // important, %%%? tour_id is required on validation
  }
  statesReturn.initialValues = initialValues;

  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null
)(ComponentWithData);

export default ComponentWithDataAndState;
