const showNotification = (id, text, isError) => ({
  type: "SHOW_NOTIFICATION",
  id,
  text,
  isError,
});

const hideNotification = (id) => {
  localStorage.removeItem("Error"); // clean error when was able to show it
  return { type: "HIDE_NOTIFICATION", id };
};

// id dont needed , It's generated on notifSaga.js
const showNotificationWithTimeout = (text, isError, delay: null) => ({
  type: "SHOW_NOTIFICATION_WITH_TIMEOUT",
  text,
  isError,
  delay,
});

export { showNotification, hideNotification, showNotificationWithTimeout };
