import React from 'react';

const Cgupelemobil = (props) => {
  const { t } = props;

  return (
    <div
      style={{
        fontSize: '12px',
        color: '#ccc',
        padding: '10px 0px 10px 20px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          padding: '2px',
          fontSize: '17px',
        }}
      >
        {' '}
        >
        <a
          target="_blank"
          href="https://www.pelemobil.com/cgupelemobil.html"
          style={{
            color: '#000',
            margin: '0 60px 0 10px',
          }}
        >
          {t('form.onlineterms')}
        </a>
      </div>
    </div>
  );
};

export default Cgupelemobil;
