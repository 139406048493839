import React, { useEffect, useState } from 'react';
import { Tables } from '../../defTables';
import PersonView from './PersonView';
const tableCrud = 'person';
const solveRequiredFields = (props) => {
  // console.log('window.location.search;', window.location.search);
  const urlSearch = window.location.search; // could be '?foo=bar'
  const urlParams = new URLSearchParams(urlSearch);
  const fields = Tables[tableCrud].fields;

  const requiredFields = {};
  for (const [fieldKey, fieldMembers] of Object.entries(fields)) {
    const requiredSet = urlParams.get('required_' + fieldKey);
    if (requiredSet) {
      requiredFields[fieldKey] = requiredSet;
    }
  }

  if (props.session.iu !== props.match.params.id) {
    //when it's not the main  Person,  ask for associate person fields
    requiredFields.grouped_customer_id = 1;
    requiredFields.grouped_filiation = 1;
  }
  // console.log('requiredFields', requiredFields);
  return requiredFields;
};

const ContainerPersonView = (props) => {
  const [requiredFields, setRequiredFields] = useState(
    solveRequiredFields(props)
  );

  useEffect(() => {
    const required = solveRequiredFields(props);
    setRequiredFields(required);
  }, [props.match.params.id]);

  return <PersonView {...props} requiredFields={requiredFields} />;
};

export default ContainerPersonView;
