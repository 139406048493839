/*
WARNING:
  formProps is artificial when is launched from containerForm, in the case initForm or manual change
    from container.
    this send his props himself and not containerForm
  so don't use formProps to use special props, form myState and tt is ok, container and Form have these same props
  getInputValue use myState, myState is present in container so is ok to use it,
    but send always the name of form { nameForm }, because if formProps is artificil will fail
  use tt , for translate, have dependency with props
 */

import {
  toPrice,
  getInputValue,
  getToday,
  resolvePathObj,
  getObjFromListById,
  realTypeOf,
  tt,
  getPriceService,
} from '../../utils/commonutils';
import { fetchQl } from '../../apolloClient';
import { getRecordFromOptionsIndirectOnFilter } from '../../utils/helper';

const crudCode = {};

crudCode.onChangeInput = async (params) => {
  //const log = true;
  const log = false;
  if (log) console.log('onChangeInput params:', params);
  const {
    nameForm,
    popup,
    tableCrud,
    inputFullName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
    fns,
  } = params;

  /*
       popup denote that value is sent through window, and is here not because there is change, but
       must execute change manually and follow the same logic that onChange
       */
  if (popup) {
    if (inputFullName === 'customer_id') {
      formProps.change(inputFullName, newValue);
    }
  }

  const result = {};
  let newStates = {
    disabledFields: {},
    hiddenFields: {},
    warningFields: {},
    errors: {},
  };

  //  if (inputName === 'tour_id' || inputName === 'customerstatus_id' || inputName === 'registrationstatus_id') {
  let customer_id;
  let tourroom_id;
  let tour_id;
  let registrationstatus_id;
  let registationStatusRecord;
  // let customerStatusRecord;

  let customerStatus;
  let customerSubStatus;
  let realcustomerStatusId;
  let realcustomerSubStatusId;

  let _resTourCustomerStatusAndServices;

  if (action === 'initForm' || inputFullName !== 'customerstatus_id') {
    customerStatus = getInputValue(formProps, 'customerstatus_id', {
      nameForm,
    });
    realcustomerStatusId =
      customerStatus && customerStatus.id ? customerStatus.id : customerStatus;
  }
  if (action === 'initForm' || inputFullName !== 'customersubstatus_id') {
    customerSubStatus = getInputValue(formProps, 'customersubstatus_id', {
      nameForm,
    });
    realcustomerSubStatusId =
      customerSubStatus && customerSubStatus.id
        ? customerSubStatus.id
        : customerSubStatus;
  }

  let realTourId = getInputValue(formProps, 'tour_id', {
    nameForm,
    log: log,
  });

  let resTourRooms = formProps.containerPropsForm.list_tourroom.tourrooms;
  let resTourServices =
    formProps.containerPropsForm.getTourServices.getTourServices;

  if (action === 'initForm') {
    _resTourCustomerStatusAndServices = await fetchQl(
      `
            query getTourCustomerStatusAndServices($tour_id: ID, , $_qlType: String) {
              getTourCustomerStatusAndServices(tour_id: $tour_id,  _qlType: $_qlType) {
                id
                name
                nameOnly
                tourprice_id
                tourprice_price
                substatuss {
                  id
                  name
                  price
                  inputprice
                  availablehotels {
                    id
                    name
                    priceroom
                  }
                  availableservices {
                    id
                    idOnly
                    name
                    nameOnly
                    typeEntry
                    tour_id
                    operator
                    amount
                    listOptions {
                      id
                      name
                      amount
                    }
                    cancellednotzero
                    service_id
                  }
                }
                filterbyservice
                filteredservices
                availablehotels {
                  id
                  name
                  priceroom
                }
                availableservices {
                  id
                  idOnly
                  name
                  nameOnly
                  typeEntry
                  tour_id
                  operator
                  amount
                  listOptions {
                    id
                    name
                    amount
                  }
                  cancellednotzero
                  service_id
                }
              }
            }
          `,
      [
        { name: 'tour_id', type: 'ID', value: realTourId },
        { name: '_qlType', type: 'String', value: 'availability' },
      ],
      {
        dataName: 'getTourCustomerStatusAndServices',
        props: formProps, // important object that contains myState , for pelemaster resolver org
      }
    );
    // use fetchql manually because
    newStates._resTourCustomerStatusAndServices =
      _resTourCustomerStatusAndServices;
  } else {
    _resTourCustomerStatusAndServices =
      formProps.formState._resTourCustomerStatusAndServices;
  }

  if (action === 'initForm' && formProps.containerPropsForm) {
    // TWIN 8190A
    let querystring = window.location.search.substring(1);
    let params = new URLSearchParams(querystring);
    let mode = params.get('mode');
    if (mode === 'test') {
      formProps.change('modetest', true);
    }
  }

  if (inputFullName === 'customer_id') {
    //customer_id = newValue;
    //console.log('newValue/ event', newValue, event);
    // it's better take event, because newValue is only: id (string) we need { id, name, customerstatus_id}
    // event is forward from autocomplete from native event js
    customer_id = event;
    // make sure that customer_id is not undefined, when select is cleaned with 'x' can happen
    if (customer_id && customer_id.id) {
      formProps.change('customerstatus_id', customer_id.customerstatus_id);
      formProps.change(
        'customersubstatus_id',
        customer_id.customersubstatus_id
      );
      // customerStatusRecord = getRecordFromOptionsIndirectOnFilter(formProps, 'customerstatus', customer_id.customerstatus_id);
      // console.log('customerStatusRecord', customerStatusRecord);
    }
  } else {
    customer_id = getInputValue(formProps, 'customer_id', {
      nameForm,
      log: log,
    });
    if (log)
      console.log('crudCode.js() customer_id from getInputValue:', customer_id);
  }

  if (inputFullName === 'tourroom_id') {
    tourroom_id = newValue;
  } else {
    tourroom_id = getInputValue(formProps, 'tourroom_id', { nameForm });
  }
  if (log) console.log('tourroom_id ', tourroom_id);

  if (inputFullName === 'registrationstatus_id') {
    registrationstatus_id = newValue;
  } else {
    registrationstatus_id = getInputValue(formProps, 'registrationstatus_id', {
      nameForm,
    });
  }

  if (registrationstatus_id) {
    registationStatusRecord = getRecordFromOptionsIndirectOnFilter(
      formProps,
      'registrationstatus',
      registrationstatus_id
    );
  }

  // fill registration date to today, when registrationstatus is registration confirmed
  // future, restore accord initvalues on edition , if registrationstatus change to state no registrated
  if (inputFullName === 'registrationstatus_id' && registationStatusRecord) {
    const dateregistration = getInputValue(formProps, 'dateregistration', {
      nameForm,
    });
    if (registationStatusRecord.statusbase === 4) {
      const registrationstatusInitial = getInputValue(
        formProps,
        'registrationstatus_id',
        { nameForm, initial: true }
      );
      let registationStatusRecordInitial;
      if (registrationstatusInitial) {
        registationStatusRecordInitial = getRecordFromOptionsIndirectOnFilter(
          formProps,
          'registrationstatus',
          registrationstatusInitial
        );
      } else {
        registationStatusRecordInitial = null;
      }
      // if status was different to 4, or date is emtpy always change to today
      if (
        !dateregistration ||
        !registationStatusRecordInitial ||
        registationStatusRecordInitial.statusbase !== 4
      ) {
        formProps.change('dateregistration', getToday());
      }
    } else {
      const dateregistrationInitial = getInputValue(
        formProps,
        'dateregistration',
        { nameForm, initial: true }
      );
      // very confuse to user, to force to restore date if he is playing wiht date manually
      //if (!dateregistration && dateregistrationInitial) {
      //  formProps.change('dateregistration', dateregistrationInitial);
      //}
    }
  }

  // variables to resolve price by registation status TWIN Server CST119
  let hasRegistrationStatusCanceledPrice = false;
  let registrationStatusPrice = 0; // on the future, price will come not only from cancelled status
  //  prix room is not added when inscription is cancelled
  let isCancelled = false;

  // here price is resolved for  onchange on some inputs: tollfree, registration status
  let resolvedMethodPrice = [];

  let resCustomerStatusFound;
  let resCustomerSubStatusFound;

  if (inputFullName === 'customersubstatus_id') {
    customerSubStatus = newValue;
    realcustomerSubStatusId =
      customerSubStatus && customerSubStatus.id
        ? customerSubStatus.id
        : customerSubStatus;
  }
  if (inputFullName === 'customerstatus_id') {
    customerStatus = newValue;
    realcustomerStatusId =
      customerStatus && customerStatus.id ? customerStatus.id : customerStatus;

    // dont delete this: repetead logic but important to load substatus  for selectbox
    // after substatus changes
    resCustomerStatusFound = _resTourCustomerStatusAndServices.find(
      (resTourStat) => resTourStat.id === realcustomerStatusId
    );
    if (resCustomerStatusFound) {
      newStates._customerSubStatuss = resCustomerStatusFound.substatuss;
    }
  }

  //  redefine values for  resTourRooms, resTourServices, or keep them
  //  accord to status/ substatus
  if (realcustomerStatusId) {
    resCustomerStatusFound = _resTourCustomerStatusAndServices.find(
      (resTourStat) => resTourStat.id === realcustomerStatusId
    );
    // check value for substatus found, pareil en initform section code
    if (
      resCustomerStatusFound &&
      resCustomerStatusFound.availableservices.length > 0
    ) {
      resTourServices = resCustomerStatusFound.availableservices;
    }
    if (
      resCustomerStatusFound &&
      resCustomerStatusFound.availablehotels.length > 0
    ) {
      resTourRooms = resCustomerStatusFound.availablehotels;
    }

    //------ analyze substatus

    if (realcustomerSubStatusId && resCustomerStatusFound) {
      resCustomerSubStatusFound = resCustomerStatusFound.substatuss.find(
        (subs) => subs.id === realcustomerSubStatusId
      );

      if (
        resCustomerSubStatusFound &&
        resCustomerSubStatusFound.availablehotels.length > 0
      ) {
        resTourRooms = resCustomerSubStatusFound.availablehotels;
      }
      if (
        resCustomerSubStatusFound &&
        resCustomerSubStatusFound.availableservices.length > 0
      ) {
        resTourServices = resCustomerSubStatusFound.availableservices;
      }
    }

    //------ end analyze substatus
  }

  if (action !== 'initForm') {
    // initForm dispatched on form creation, have no .fns, so logic can't be executed

    let tollfree;
    if (inputFullName === 'tollfree') {
      tollfree = newValue || false;
    } else {
      tollfree = getInputValue(formProps, 'tollfree', { nameForm });
    }

    const tourRecord = formProps.aTour;

    let price;

    //console.log('tollfree',tollfree);
    if (tollfree) {
      // checkbox  valiue is priority to know if is toll free
      price = '0';
    } else if (!tourRecord) {
      // tourNotFound ( imposible case, but anyway I control) or tour has no price defined, then no price
      // if there are customerstatus_id, without tour price, by the moment price can't be defined
      if (log) console.log(' tour Record not found', tourRecord);
      price = '0';
    } else {
      if (registrationstatus_id) {
        if (registationStatusRecord) {
          if (parseInt(registationStatusRecord.statusbase) === 14) {
            isCancelled = true;
            if (log)
              console.log(
                'typeof tourRecord.cancelledprice',
                realTypeOf(tourRecord.cancelledprice)
              );
          }
          if (realTypeOf(tourRecord.cancelledprice) === '[object Number]') {
            // has price
            hasRegistrationStatusCanceledPrice = true;
            registrationStatusPrice = tourRecord.cancelledprice;
          }
        }
      }

      if (isCancelled && hasRegistrationStatusCanceledPrice) {
        price = registrationStatusPrice;
        resolvedMethodPrice.push({
          source:
            tt(formProps.t, 'table.registrationstatus') +
            ': ' +
            tt(formProps.t, 'registrationBaseStatus.cancelled'),
          price,
        });
      } else if (!customerStatus) {
        // not customer status defined, then price come from 'tour'
        price = tourRecord.price;
        resolvedMethodPrice.push({
          source: tt(formProps.t, 'table.tour'),
          price,
        });
      } else {
        if (
          resCustomerStatusFound &&
          (resCustomerStatusFound.tourprice_price ||
            resCustomerStatusFound.tourprice_price === 0 ||
            (resCustomerSubStatusFound && resCustomerSubStatusFound.inputprice))
        ) {
          // important check if is cero or not
          //console.log('take price from  , price customer status:',resCustomerStatusFound.price)
          price = resCustomerStatusFound.tourprice_price;
          // check if substatus has price
          if (resCustomerSubStatusFound) {
            price = resCustomerSubStatusFound.price;
          }
          resolvedMethodPrice.push({
            source: tt(formProps.t, 'table.customerstatus'),
            price,
          });
        } else {
          price = tourRecord.price;
          resolvedMethodPrice.push({
            source: tt(formProps.t, 'table.tour'),
            price,
          });
        }
        if (log) console.log(' price after tourprice = ', price);
      }

      // TWIN Server PRO032, for cancelled dont add room price
      if (!tollfree && !isCancelled) {
        // add price room
        let tourroomRecord = resTourRooms.find(
          (ahotel) => ahotel.id === tourroom_id
        );

        if (tourroomRecord && tourroomRecord.priceroom) {
          // important check if is cero or not
          if (log)
            console.log(
              'add price from  , tourroom:',
              tourroomRecord.priceroom
            );
          price += tourroomRecord.priceroom;
          resolvedMethodPrice.push({
            source: tt(formProps.t, 'table.tourroom'),
            price: tourroomRecord.priceroom,
          });
        }
      }
    }

    let totalServices = 0;

    /*
        START calc services
         */

    if (resTourServices) {
      // only if there are items
      for (let a = 0; a < resTourServices.length; a++) {
        const servicesObj = resTourServices[a];
        const inputNameService = 'service_' + servicesObj.id;
        let amountService = 0;
        let valueObject;
        if (inputFullName === inputNameService) {
          if (newValue) {
            // boolea is true, but take the .amount
            valueObject = newValue;
          }
        } else {
          valueObject = getInputValue(formProps, inputNameService, {
            nameForm,
          });
        }
        if (valueObject) {
          amountService = getPriceService({ value: valueObject }, servicesObj);
          totalServices += amountService;
        }
      }
    }
    /*
        END calc services
         */

    /*
        Check changes on transport:
         */

    if (inputFullName === 'transportationtransportation_id') {
      let changeRow = false;

      let resetStopPoint = true;
      const fullTransportation = getInputValue(formProps, 'transportation', {
        nameForm,
      });
      let currentTransportationLine = getInputValue(
        formProps,
        'transportation',
        { nameForm, line, notFound: undefined, log: false }
      );

      // console.log('newValue', newValue);
      // console.log('currentTransportationLine ', currentTransportationLine);

      // important this block avant the block study the changemente transport id, to keep the same stoppoint,
      // because the way to change the stoppoint is updating 'currentTransportationLine'  and not 'resetStopPoint'
      currentTransportationLine = {
        ...currentTransportationLine,
        transportation_id: newValue,
      };
      /*if (resetStopPoint && resolvePathObj(formProps, 'myState.app.appSettings.settingsforms.registration_stopspointfree', { compare: true, notFound: false})) {
            // for free stop point dont reset
            resetStopPoint = false;
          }*/

      if (!newValue) {
        // if idNew is null, then clean related field
        currentTransportationLine.stopspoint_id = null;
        changeRow = true;
      }

      /* need a lot of search to get the real stop id from the table records loaded, because the main ids are only representative for every record
          newValue  is just the real id, but after is selected, the id is generated aleatory, the newValue is part of .transportation_id.id
          the way to change the stoppoint is updating 'currentTransportationLine'  and not 'resetStopPoint'
          */
      if (
        newValue &&
        newValue.id &&
        previousValue &&
        previousValue.id &&
        currentTransportationLine
      ) {
        // console.log('previousValue', previousValue);
        // dont use: currentTransportationLine = getInputValue(... / but previousValue, in select autocomplete, all the value is changed
        // there is not way to get the old value using getInputValue, always will be the current value updated
        // use previousValue
        const oldTransportationId = previousValue.id;
        const oldStopPoint =
          currentTransportationLine.stopspoint_id &&
          currentTransportationLine.stopspoint_id
            ? currentTransportationLine.stopspoint_id.id
            : null;
        // console.log('oldTransportationId ', oldTransportationId );
        // console.log('oldStopPoint ', oldStopPoint );
        const newTransportationId = newValue.id;
        // console.log('newTransportationId', newTransportationId);
        let oldRecordTransport = getRecordFromOptionsIndirectOnFilter(
          formProps,
          'transportation',
          { id: oldTransportationId },
          true,
          log
        );
        // console.log('oldRecordTransport', oldRecordTransport);
        let newRecordTransport = getRecordFromOptionsIndirectOnFilter(
          formProps,
          'transportation',
          { id: newTransportationId },
          true,
          log
        );
        // console.log('newRecordTransport', newRecordTransport);

        if (
          oldStopPoint &&
          oldRecordTransport &&
          oldRecordTransport.routeplanner &&
          newRecordTransport &&
          newRecordTransport.routeplanner
        ) {
          const newRouterPlanner = JSON.parse(newRecordTransport.routeplanner);
          const oldRouterPlanner = JSON.parse(oldRecordTransport.routeplanner);
          // console.log('newRouterPlanner', newRouterPlanner);
          // console.log('oldRouterPlanner', oldRouterPlanner);
          // search if old stop id is foound in the routerplanner for the new transportid selected
          let objRealOldStop = getObjFromListById(oldRouterPlanner, {
            id: oldStopPoint,
          });
          // console.log('objRealOldStop', objRealOldStop);
          if (objRealOldStop && objRealOldStop.from_stopspoint_id) {
            const realOldStopId = objRealOldStop.from_stopspoint_id.id;
            // console.log('realOldStopId ', realOldStopId );
            // search realOldStopId in New route planner
            for (const [key, value] of Object.entries(newRouterPlanner)) {
              // console.log('value', value.from_stopspoint_id);
              // important de hacer ensemble transportid and stopid, to let a old transport, danger don't find stopid
              if (value.from_stopspoint_id.id === realOldStopId) {
                // console.log('value *************',value);
                // dont use 'from_stopspoint_id.id' because is from table 'stopspoints'.id, but the id from the multiline record  table "tourid" field: transports {id }
                currentTransportationLine.stopspoint_id = {
                  id: value.id,
                  name: value.from_stopspoint_id.name,
                }; // {...value};
                // console.log('value ************* newcurrentTransportationLine', currentTransportationLine);
                resetStopPoint = false;
                break;
              }
            }
          }
        }
      }

      if (resetStopPoint) {
        currentTransportationLine.stopspoint_id = null;
      }
      //console.log('currentAddressLine', currentAddressLine);
      fullTransportation[line] = currentTransportationLine;
      changeRow = true;
      if (changeRow) {
        result.changeFieldsLater = { transportation: fullTransportation }; // best modern way to change fields, it's more sure
        // formProps.change('transportation', fullTransportation);
      }
    }

    // transport is not take when has registration cancelled, but when has canceled priced
    // to keep compatibility with old registrations , take in count price transport for cancelled
    if (!(isCancelled && hasRegistrationStatusCanceledPrice)) {
      /*
          START calc transport (add to variables services
           */

      // guess the name form automatically, the event is produced on the same input
      const transportationsValue = getInputValue(formProps, 'transportation', {
        nameForm,
      });
      if (log)
        console.log('crudCode() transportationsValue ', transportationsValue);
      let totalTransportations = 0;
      if (transportationsValue) {
        // only if there are items
        for (let a = 0; a < transportationsValue.length; a++) {
          let transportation_id;
          let transportationObj;
          if (log)
            console.log(
              'crudCode()  transport line  ORIGINAL # ' + a + ') :',
              transportationsValue[a]
            );
          if (
            inputFullName === 'transportationtransportation_id' &&
            line === a
          ) {
            if (log)
              console.log(
                'crudCode() line just be typed , newValue:',
                newValue
              );
            // new value Is an object { id, name}
            // or is null when clic on 'x' to delete value
            if (newValue) transportation_id = newValue.id;
          } else {
            if (log) console.log('crudCode()  line  existed before ');
            // important: id is just the id of the row, the real id of the services is inside: .services_id.id
            // control  empty line, if not return false
            transportation_id = transportationsValue[a].transportation_id
              ? transportationsValue[a].transportation_id.id
              : false;
          }
          if (
            !(
              action === 'delete' &&
              line === a &&
              parentField === 'transportation'
            ) &&
            transportation_id
          ) {
            // check if this line is not deleting
            transportationObj = getRecordFromOptionsIndirectOnFilter(
              formProps,
              'transportation',
              { id: transportation_id },
              true,
              log
            );
            if (log)
              console.log(
                'crudCode()  transport line  RESULT # ' + a + ') :',
                transportationObj
              );
            if (transportationObj) {
              if (log)
                console.log(
                  'crudCode() transport price:' +
                    transportationObj.transportation_id +
                    ' price:' +
                    transportationObj.price
                );
              const transportationAmount =
                parseFloat(transportationObj.price) || 0; //isolation before to sum, so invalid amounts don't set total like NAN
              totalServices += transportationAmount;
              resolvedMethodPrice.push({
                source: tt(formProps.t, 'table.transportation'),
                price: transportationAmount,
              });
            }
          }
        }
      }
      /*
          END calc transport
           */
    }

    // formProps.change('_priceformula', JSON.stringify(resolvedMethodPrice));
    // make sure valid type numeric
    totalServices = parseFloat(totalServices);
    if (totalServices) price += totalServices;
    if (log)
      console.log(
        'crudCode() totalServices:' + totalServices + ' pricetotal:' + price
      );

    formProps.change('price', toPrice(price));
    let paid = getInputValue(formProps, 'paid', { nameForm });
    if (paid && typeof paid === 'string') {
      paid = paid.replace(' ', '');
    }
    paid = parseFloat(paid) || 0; // %% harcode, but replair
    if (price && typeof price === 'string') {
      price = price.replace(' ', '');
    }

    price = parseFloat(price) || 0;
    const balance = price - paid;
    /* console.log('price',price);
        console.log('paid',paid);
        console.log('balance',balance); */
    // formProps.change('balance', toPrice(balance));

    // partial payment, only validation inside 'initForm' if
    if (inputFullName === 'partialpayment') {
      // autocomplete  get only pure id, before compare changes with old (not really old, but previous value)
      if (
        newValue &&
        (parseFloat(newValue) > price || parseFloat(newValue) === 0)
      ) {
        newStates.errors.partialpayment =
          'info.partialpaymentCantBeGreaterPrice';
      }
    }
  }

  if (inputFullName === 'customerstatus_id') {
    // TWIN SU10902
    // autocomplete  get only pure id, before compare changes with old (not really old, but previous value)
    const idNew = newValue && newValue.id ? newValue.id : newValue;
    let idOld = getInputValue(formProps, inputFullName, { nameForm });
    if (idOld && idOld.id) idOld = idOld.id;
    // change tour id then reset room, transport, and services
    if (idNew !== idOld || !idNew) {
      // if idNew is null, then clean too
      // result.changeFieldsLater = { customersubstatus_id: null };
    }
  }

  if (log)
    console.log(
      'crudCode() customer_id typeof:' + typeof customer_id,
      customer_id
    );
  if (log) console.log('crudCode() tour_id typeof:' + typeof tour_id, tour_id);

  /*if (typeof tour_id === "undefined" || !tour_id) {
      newStates.disabledFields["tourroom_id"] = true;
    } else {
      newStates.disabledFields["tourroom_id"] = false;
    }*/
  if (log) console.log('crudCode() newStates', newStates);
  result.newStates = newStates;

  return result;
};

export default crudCode;
