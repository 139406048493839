import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from '../../layouts/cssstyled';
import FormField from '../FormField';
import { fieldsValidator } from '../../utils/helper';
import ShowIcon from '../icons/ShowIcon';
import { normalizes } from '../../utils/normalizes';
import { validUUID } from '../../utils/validators';

const fields = {
  email: {
    validators: ['required', 'email'],
  },
};

const fieldsWithTour = {
  email: {
    validators: ['required', 'email'],
  },
  tourId: {
    validators: ['required'],
  },
};

const tableCrud = 'regonline';
let nameForm = `${tableCrud}Form`;
const validate = (values, props) => {
  let result;
  if (!props.tourid) {
    // tour is not on the url
    result = fieldsValidator(fieldsWithTour, values);
    if (!result.tourId) {
      const urlSplit = values.tourId.trim().split('/');
      const tour = urlSplit[urlSplit.length - 1];

      // have value, no errors required, then valid uuid
      result.tourId = validUUID(tour);
    }

    // 'validUUID'
  } else {
    result = fieldsValidator(fields, values);
  }
  return result;
};

const Form = (props) => {
  const {
    error,
    handleSubmit,
    pristine,
    invalid,
    submitFailed,
    reset,
    submitting,
    t,
    changeLanguage,
    i18n,
    browserCompatible,
    browserVersionCompatible,
    tourid,
  } = props;

  const [check, setCheck] = useState(false);

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ width: '300px', margin: 'auto' }}>
        {!tourid && (
          <div key="War" style={{ fontSize: '18px' }}>
            <div style={{ paddingBottom: '10px' }}>
              <Field
                inputName="tourId"
                name="tourId"
                formProps={props}
                label="form.codetour"
                nameForm="singup1"
                component={FormField}
                type="text"
                typeInput="text"
                formRowExpand="1"
                showError="submitFailed"
              />
            </div>
          </div>
        )}

        <Field
          inputName="email"
          name="email"
          formProps={props}
          label="form.email"
          nameForm="singup1"
          component={FormField}
          type="text"
          normalize={normalizes.LowerCase}
          typeInput="text"
          formRowExpand="1"
          showError="submitFailed"
        />
        <div style={{ paddingBottom: '3px', color: '#555555' }}>
          <input
            name="accept"
            style={{ fontSize: '16px' }}
            type="checkbox"
            value="1"
            onChange={() => {
              setCheck(!check);
            }}
            checked={check}
          />
          {t('form.acceptcgu1')}

          <a
            target="_blank"
            rel="noopener"
            href="https://www.pelemobil.com/cgupelemobil.html"
          >
            {' '}
            ({t('info.vRead')})
          </a>
        </div>
        {!check && (
          <div className="formError" style={{ color: '#be0000' }}>
            {t('validator.required')}
          </div>
        )}

        {browserCompatible && browserVersionCompatible && (
          <div
            style={{ position: 'relative', paddingTop: '15px', margin: 'auto' }}
          >
            <Button type="submit" disabled={submitting || !check}>
              {t('login.signup')}
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'singup1',
  validate,
})(Form);
