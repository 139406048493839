import React from 'react';

const Comp = (props) => {
  return (
    <React.Fragment>
      {props.text.split('\n').map((text, key) => {
        return <div key={key}>{text}</div>;
      })}
    </React.Fragment>
  );
};
export default Comp;
