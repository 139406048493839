import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../FormField';
import FormArrayField from '../FormArrayField';

import {
  fieldsValidator,
  getOptions,
  processNewValues,
  uuid,
} from '../../utils/helper';
import {
  resolvePathObj,
  getInputValue,
  processValues,
  realTypeOf,
  sourcesOptions,
  getObjFromListById,
  tryParseJSON,
} from '../../utils/commonutils';

import { Tables } from '../../defTables';
import { normalizes } from '../../utils/normalizes';

const tableCrud = 'person';
let nameForm = `${tableCrud}Form`;
const validate = (values, props) => {
  const resultValidate = fieldsValidator('person', values, props, nameForm);
  return resultValidate;
};

const uuid1 = uuid();

class Form extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.timerHandle = setTimeout(() => {
      this.props.dispatch(change(nameForm, '_fakefield', Math.random()));
      this.props.executeCode('onChangeInput', {
        nameForm,
        action: 'initForm',
        props: this.props,
        formProps: this.props,
        formState: this.props.formState,
      });
    }, 1000);
    window.addEventListener('message', this.readMessage, true);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
    window.removeEventListener('message', this.readMessage, true);
  }

  readMessage = (event) => {
    if (!event.origin.includes(window.location.hostname)) return;
    if (event && event.data) {
      const params = tryParseJSON(event.data, {});
      if (params.popup) {
        params.inputFullName = params.popup;
        if (!params.formProps) params.formProps = this.props;
        this.props.executeCode('onChangeInput', params);
      }
    }
  };

  hocdebounced = debounce((methodCode, params) => {
    this.props.executeCode(methodCode, params);
  }, 1500);

  render() {
    const {
      error,
      handleSubmit,
      invalid,
      pristine,
      change,
      submitting,
      submitFailed,
      t,
      ...otherProps
    } = this.props;

    // master adress person_id
    const groupedaddress_customer_id = getInputValue(
      this.props,
      'groupedaddress_customer_id',
      { nameForm }
    );
    // list complet same master adress, included master
    let customeraddress_customers = tryParseJSON(
      getInputValue(this.props, 'customeraddress_customers', { nameForm }),
      []
    );
    const aAddresses = [];
    if (customeraddress_customers.length > 0) {
      // take the first to get the addresses:
      const mainAddresses = tryParseJSON(
        customeraddress_customers[0].addresses,
        []
      );

      mainAddresses.map((address) => {
        aAddresses.push(
          <div key={address.id}>
            {address.addressline1} {address.addressline2} {address.addressline3}{' '}
            <strong>{t('form.pc')}</strong> {address.cp}{' '}
            <strong>{t('form.city')}</strong> {address.city_id}{' '}
            <strong>{t('form.country')}</strong> {address.country.name}
          </div>
        );
      });
    }

    const aGroupedAddress = [
      { id: '', name: 'Sans Adress Associé' },
      ...this.props.groupedaddress_customer_id_listOptions,
    ];
    return (
      <form onSubmit={handleSubmit}>
        <div className="appBodyTitleSeparator" />

        <div className="formSection">
          <Field
            inputName="lastname"
            name="lastname"
            formProps={this.props}
            pathInTables="person.fields.lastname"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.UpperCase}
            typeInput="text"
            onChange={(event, newValue, previousValue) => {
              this.hocdebounced('onChangeInput', {
                nameForm,
                inputFullName: 'lastname',
                formProps: this.props,
                formState: this.props.formState,
                event,
                newValue,
                previousValue,
              });
            }}
          />
          <Field
            inputName="firstname"
            name="firstname"
            formProps={this.props}
            pathInTables="person.fields.firstname"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.StartCase}
            typeInput="text"
            onChange={(event, newValue, previousValue) => {
              this.hocdebounced('onChangeInput', {
                nameForm,
                inputFullName: 'firstname',
                formProps: this.props,
                formState: this.props.formState,
                event,
                newValue,
                previousValue,
              });
            }}
          />
          <Field
            inputName="email"
            name="email"
            formProps={this.props}
            pathInTables="person.fields.email"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            disabled={true}
            normalize={normalizes.LowerCase}
            typeInput="text"
          />
          <Field
            inputName="occupation"
            name="occupation"
            formProps={this.props}
            pathInTables="person.fields.occupation"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
          <Field
            inputName="imageright"
            name="imageright"
            formProps={this.props}
            pathInTables="person.fields.imageright"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectBox"
            typeInput="selectBox"
            listSource="yesNoEmpty"
          />
        </div>

        <div className="formSection">
          <Field
            inputName="grouped_customer_id"
            name="grouped_customer_id"
            formProps={this.props}
            pathInTables="person.fields.grouped_customer_id"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            listOptions={this.props.grouped_customer_id_listOptions}
            syncCode={this.props.syncCode}
            type="selectBox"
            tableCrud={tableCrud}
            typeInput="selectBox"
          />
          <Field
            inputName="grouped_filiation"
            name="grouped_filiation"
            formProps={this.props}
            pathInTables="person.fields.grouped_filiation"
            formState={this.props.formState}
            nameForm="customerForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectBox"
            typeInput="selectBox"
            listSource="filiation"
          />
        </div>
        <div className="formSection">
          <Field
            inputName="nationality"
            name="nationality"
            formProps={this.props}
            pathInTables="person.fields.nationality"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
            listSource="countries"
          />
          <Field
            inputName="residence"
            name="residence"
            formProps={this.props}
            pathInTables="person.fields.residence"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
            listSource="countries"
          />

          <Field
            inputName="birthdate"
            name="birthdate"
            formProps={this.props}
            pathInTables="person.fields.birthdate"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
          <Field
            inputName="birthcountry"
            name="birthcountry"
            formProps={this.props}
            pathInTables="person.fields.birthcountry"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
            listSource="countries"
          />
          <Field
            inputName="birthplace"
            name="birthplace"
            formProps={this.props}
            pathInTables="person.fields.birthplace"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
          <Field
            inputName="nameunmarried"
            name="nameunmarried"
            formProps={this.props}
            pathInTables="person.fields.nameunmarried"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
        </div>
        <div className="formSection">
          <FieldArray
            inputName="contacts"
            name="contacts"
            formProps={this.props}
            pathInTables="person.fields.contacts"
            formState={this.props.formState}
            nameForm="personForm"
            fieldParentKey="contacts"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            t={t}
            mode={{}}
          />
        </div>
        <div className="formSection">
          <FieldArray
            inputName="phones"
            name="phones"
            formProps={this.props}
            pathInTables="person.fields.phones"
            formState={this.props.formState}
            nameForm="personForm"
            fieldParentKey="phones"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            t={t}
            mode={{}}
          />
        </div>
        <div className="formSection" style={{ flexDirection: 'column' }}>
          {!(
            this.props.formState.actionsave === 'update' &&
            this.props.session &&
            this.props.session.iu ===
              this.props.containerPropsForm.match.params.id
          ) && (
            <Field
              inputName="groupedaddress_customer_id"
              name="groupedaddress_customer_id"
              formProps={this.props}
              formState={this.props.formState}
              nameForm="personForm"
              component={FormField}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              pathInTables="person.fields.groupedaddress_customer_id"
              listOptions={aGroupedAddress}
              syncCode={this.props.syncCode}
              type="selectBox"
              tableCrud={tableCrud}
              typeInput="selectBox"
              onChange={(event, newValue, previousValue) => {
                this.props.executeCode('onChangeInput', {
                  nameForm,
                  inputFullName: 'groupedaddress_customer_id',
                  formProps: this.props,
                  formState: this.props.formState,
                  event,
                  newValue,
                  previousValue,
                });
              }}
            />
          )}
          {groupedaddress_customer_id && (
            <div>
              <Field
                inputName="customeraddress_customers"
                name="customeraddress_customers"
                formProps={this.props}
                pathInTables="customer.fields.customeraddress_customers"
                formState={this.props.formState}
                nameForm="customerForm"
                component={FormField}
                executeCode={this.props.executeCode}
                syncCode={this.props.syncCode}
                type="hidden"
                typeInput="hidden"
              />
              {[aAddresses]}
            </div>
          )}
          {!groupedaddress_customer_id && (
            <FieldArray
              inputName="addresses"
              name="addresses"
              formProps={this.props}
              pathInTables="person.fields.addresses"
              formState={this.props.formState}
              nameForm="personForm"
              fieldParentKey="addresses"
              component={FormArrayField}
              tableCrud={tableCrud}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              t={t}
              mode={{}}
              labelChildsShow={true}
            />
          )}
        </div>
        <div className="formSection">
          <FieldArray
            inputName="identitydocs"
            name="identitydocs"
            formProps={this.props}
            pathInTables="person.fields.identitydocs"
            formState={this.props.formState}
            nameForm="personForm"
            fieldParentKey="identitydocs"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            t={t}
            mode={{}}
            labelChildsShow={true}
          />
        </div>
        <Field
          inputName="notes"
          name="notes"
          formProps={this.props}
          pathInTables="person.fields.notes"
          formState={this.props.formState}
          nameForm="personForm"
          component={FormField}
          executeCode={this.props.executeCode}
          syncCode={this.props.syncCode}
          type="textarea"
          typeInput="textarea"
        />
        <Field
          inputName="_formstate"
          name="_formstate"
          typeInput="hidden"
          component={FormField}
          label=""
        />

        <div>
          {error && <strong>{error}</strong>}

          <div className="formError">{invalid && t('form.haserrors')}</div>
        </div>
      </form>
    );
  }
}

const ComponentWithData = reduxForm({
  form: nameForm,
  touchOnChange: true,

  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  const log = false;
  const statesReturn = { myState: state };
  let initialValues;
  const t = ownProps.t;

  if (
    ownProps.formState.actionsave_origen === 'update' ||
    ownProps.formState.actionsave_origen === 'duplicate'
  ) {
    if (ownProps.data) {
      initialValues = processValues(
        ownProps,
        tableCrud,
        ownProps.data,
        'toClient',
        'view'
      );

      if (
        ownProps.formState.defaultValues &&
        ownProps.formState.defaultValues.id
      ) {
        initialValues.id = ownProps.formState.defaultValues.id;
      }
      let required_identitydocs = resolvePathObj(
        ownProps,
        'containerPropsForm.requiredFields.identitydocs',
        { notFound: null }
      );
      if (required_identitydocs) {
        required_identitydocs = parseInt(required_identitydocs);
        if (!initialValues.identitydocs) initialValues.identitydocs = [];
        const idFound = initialValues.identitydocs.find((ident) => {
          return ident.type === required_identitydocs;
        });
        if (!idFound) {
          // dont add id, because produce infinit render loop, the id for the row will be added on the server processvalues when is not found
          initialValues.identitydocs.push({
            type: {
              id: required_identitydocs,
              name: t(
                'identitydocsType.' +
                  getObjFromListById(
                    sourcesOptions.identitydocsType,
                    required_identitydocs
                  ).name
              ),
            },
          });
        }
      }

      let required_phones = resolvePathObj(
        ownProps,
        'containerPropsForm.requiredFields.phones',
        { notFound: null }
      );
      if (required_phones) {
        required_phones = parseInt(required_phones);
        if (!initialValues.phones) initialValues.phones = [];
        const idFound = initialValues.phones.find((ident) => {
          return ident.type === required_phones;
        });
        if (!idFound) {
          // dont add id, because produce infinit render loop, the id for the row will be added on the server processvalues when is not found
          initialValues.phones.push({
            type: {
              id: required_phones,
              name: t(
                'phonesType.' +
                  getObjFromListById(sourcesOptions.phonesType, required_phones)
                    .name
              ),
            },
          });
        }
      }

      let required_contacts = resolvePathObj(
        ownProps,
        'containerPropsForm.requiredFields.contacts',
        { notFound: null }
      );
      if (required_contacts) {
        required_contacts = parseInt(required_contacts);
        if (!initialValues.contacts) initialValues.contacts = []; // if value is null
        const idFound = initialValues.contacts.find((ident) => {
          return ident.type.toString() === required_contacts;
        });
        if (!idFound) {
          // dont add id, because produce infinit render loop, the id for the row will be added on the server processvalues when is not found
          initialValues.contacts.push({
            type: {
              id: required_contacts,
              name: t(
                'contactType.' +
                  getObjFromListById(
                    sourcesOptions.contactType,
                    required_contacts
                  ).name
              ),
            },
          });
        }
      }

      let required_addresses = resolvePathObj(
        ownProps,
        'containerPropsForm.requiredFields.addresses',
        { notFound: null }
      );
      if (required_addresses) {
        required_addresses = parseInt(required_addresses);
        if (!initialValues.addresses) initialValues.addresses = [];
        const idFound = initialValues.addresses.find((ident) => {
          return ident.type === required_addresses;
        });
        if (!idFound) {
          // dont add id, because produce infinit render loop, the id for the row will be added on the server processvalues when is not found
          initialValues.addresses.push({
            type: {
              id: parseInt(required_addresses),
              name: t(
                'addressesType.' +
                  getObjFromListById(
                    sourcesOptions.addressesType,
                    required_addresses
                  ).name
              ),
            },
          });
        }
      }
    }
  } else {
    initialValues = processNewValues(
      ownProps,
      tableCrud,
      ownProps.data,
      'toClient',
      'new'
    );
    if (
      ownProps.containerPropsForm.history &&
      Object.keys(Tables[tableCrud].listFilters)
    ) {
      const search = ownProps.containerPropsForm.history.location.search;
      const params = new URLSearchParams(search);

      Object.keys(Tables[tableCrud].listFilters.fields).map((fieldKey) => {
        if (log) console.log('fieldKey', fieldKey);
        let fieldFilter = Tables[tableCrud].listFilters.fields[fieldKey];
        let defaultValue, defaultValueName;
        if (
          ownProps.formState.defaultValues &&
          ownProps.formState.defaultValues[fieldKey]
        ) {
          defaultValue = ownProps.formState.defaultValues[fieldKey];
        } else {
          defaultValue = params.get(fieldKey);
          defaultValueName = params.get(fieldKey.replace('_id', '_name'));
        }
        if (defaultValue) {
          let initialVal;
          const isFieldArray =
            fieldFilter.fieldContainer &&
            Tables[tableCrud].fields[fieldFilter.fieldContainer].subfields;
          if (
            fieldFilter.fieldSource &&
            fieldFilter.fieldSource.typeInput === 'selectAutocomplete' &&
            fieldFilter.fieldSource.saveonly &&
            !defaultValue.id
          ) {
            initialVal = { id: defaultValue, name: defaultValueName };
          } else {
            initialVal = defaultValue;
          }
          if (isFieldArray) {
            fieldKey = fieldFilter.fieldContainer;
            if (!initialValues[fieldKey])
              initialValues[fieldKey] = [{ id: '' }];
            initialValues[fieldKey][0][fieldFilter.subfield] = initialVal;
          } else {
            initialValues[fieldKey] = initialVal;
          }
          if (log)
            console.log(
              'initialValues[' + fieldKey + '] ',
              initialValues[fieldKey]
            );
        }
      });
    }
  }
  statesReturn.initialValues = initialValues;

  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null
)(ComponentWithData);

export default ComponentWithDataAndState;
