import React from 'react';
import { withRouter } from 'react-router';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import ShowIcon from '../icons/ShowIcon';
import IconLoading from '../../components/icons/IconLoading';
import bowser from 'bowser';
import { connect } from 'react-redux';
import SignInForm from './SignInForm';
import SignUpAskEmailForm from './SignUpAskEmailForm';
import SignUpAskVerifyCodeForm from './SignUpAskVerifyCodeForm';
import SignUpAskAllForm from './SignUpAskAllForm';
import { getInputValue, resolvePathObj } from '../../utils/commonutils';
import { signIn } from '../../actions/authActions';
import { processError } from '../../utils/helper';
import { Button } from '../../layouts/cssstyled';
import CookiesAlert from '../CookiesAlert';
import Cgupelemobil from '../Cgupelemobil';
import { fetchQl } from '../../apolloClient';

const heightBlock = '345px';
class SignInFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      screensignup: 'ask-email',
      // screensignup: 'ask-all',
      SignUpAskEmailFormProcessing: false,
      SignUpAskVerifyCodeFormProcessing: false,
      SignUpAskAllFormProcessing: false,
      email: '',
      personid: '',
      tourId: props.tourId || '',
      inputLink: '',
    };
  }

  handleSignUpAskAllForm = (values) => {
    this.setState({
      SignUpAskAllFormProcessing: true,
    });

    this.props
      .mutationsignUp3({
        // nationality and residence , need to send 'id' value, and not   {id, value } from object autoselect
        variables: {
          ...values,
          email: this.state.email,
          id: this.state.personid,
          nationality: values.nationality.id,
          residence: values.residence.id,
          imageright: parseFloat(values.imageright) || 0,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        this.setState({
          SignUpAskAllFormProcessing: false,
        });
        //console.log('handlesubmit then');
        if (response.data.signUp3.errors.length <= 0) {
          this.setState({
            screensignup: 'ask-finished',
          });
        } else {
          /*
          really is useful to match errors from server with fields?  or global errors %%% test it 
          */
          this.setState({
            errors: response.data.signUp3.errors,
          });
        }
      })
      .catch((err) => {
        this.setState({
          SignUpAskAllFormProcessing: false,
        });
        processError(err, this.props);
      });
  };

  fixEmail = () => {
    this.setState({
      screensignup: 'ask-email',
    });
  };

  handleSignUpAskVerifyCodeForm = (values) => {
    this.setState({
      SignUpAskVerifyCodeFormProcessing: true,
    });
    this.props
      .mutationsignUp2({
        variables: { ...values, email: this.state.email },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        this.setState({
          SignUpAskVerifyCodeFormProcessing: false,
        });
        //console.log('handlesubmit then');
        if (
          response.data.signUp2.errors.length <= 0 &&
          response.data.signUp2 &&
          response.data.signUp2.answer
        ) {
          this.setState({
            screensignup: 'ask-all',
            personid: response.data.signUp2.answer,
          });
        } else {
          this.setState({
            errors: response.data.signUp2.errors,
          });
        }
      })
      .catch((err) => {
        this.setState({
          SignUpAskVerifyCodeFormProcessing: false,
        });
        processError(err, this.props);
      });
  };

  handleSignUpAskEmailForm = async (values) => {
    // whe input tourId exists, his value overwrittes tourId (that comes from state, that comes from url)
    if (values.tourId) {
      const urlSplit = values.tourId.trim().split('/');
      values.tourId = urlSplit[urlSplit.length - 1];
    }

    const variables = { tourId: this.state.tourId, ...values };

    // check if tour exist
    const resTours = await fetchQl(
      `
      query TourView ($id: ID!) {
        tour (id: $id) {
          id
          name
        }
      }`,
      [{ name: 'id', type: 'ID', value: variables.tourId }],
      {
        props: this.props,
      }
    );
    if (!resTours.data || !resTours.data.tour || !resTours.data.tour.id) {
      this.props.showNotificationWithTimeout(
        this.props.t('mobilsemError.tourNotFound'),
        3
      );
      return false;
    }

    this.setState({
      SignUpAskEmailFormProcessing: true,
      email: values.email,
    });

    this.props
      .mutationsignUp1({
        variables,
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        this.setState({
          SignUpAskEmailFormProcessing: false,
        });
        //console.log('handlesubmit then');
        if (response.data.signUp1.errors.length <= 0) {
          this.setState({
            screensignup: 'ask-verifycode',
          });
        } else {
          this.setState({
            errors: response.data.signUp1.errors,
          });
        }
      })
      .catch((err) => {
        this.setState({
          SignUpAskEmailFormProcessing: false,
        });
        processError(err, this.props);
      });
  };

  executeCode = async (methodCode = '', params = {}) => {
    if (
      methodCode === 'onChangeInput' &&
      params.inputFullName === 'nationality'
    ) {
      const residence = getInputValue(this.props, 'residence', {
        nameForm: 'signup3',
      });
      if (!residence) {
        params.formProps.change('residence', params.newValue);
      }
    }
  };

  handleLinkChange = (event) => {
    const newvalue = event.target.value;
    this.setState({
      inputLink: newvalue,
    });
  };

  handleLinkRegistration = () => {
    const linkReg = this.state.inputLink.trim();
    if (linkReg) {
      const urlsParts = linkReg.split('/');
      if (urlsParts.length > 0) {
        window.location.href =
          'tour/' + urlsParts[urlsParts.length - 1] + '?origin=homesign';
      }
    }
  };

  handleSubmit = async (values, dispatch, props) => {
    try {
      const response = await this.props.mutationsignIn({
        variables: values,
        fetchPolicy: 'no-cache',
      });
      // console.log('response', response);
      if (response.data.signIn.errors.length <= 0) {
        // console.log(response.data.signIn);
        this.props.signInDispatcher(response.data.signIn);
        if (this.state.tourId) {
          setTimeout(() => {
            // window.location.href = '/tour/'+this.state.tourId;
            // redirect to  tour, with the person id
            this.props.history.push(
              '/tour/' + this.state.tourId + '?p=' + response.data.signIn.iu
            );
          }, 1000);
        } else {
          this.props.history.push('/dashboard');
        }
        // dont needed reload params in voyage app
        // window.location.href = '/dashboard'; // patch to recharge patch to settingsload , but in the future see how to call a ql and recharge from there , so don' recharge all the whole page
      } else {
        this.setState({
          errors: response.data.signIn.errors,
        });
      }
    } catch (err) {
      processError(err, this.props);
    }
  };

  render() {
    const { t } = this.props;
    let browserCompatible = true;
    let browserVersionCompatible = true;
    let browserMsgVersionOk = <ShowIcon size="18" color="green" icon="done" />;
    let browserMsgOk = <ShowIcon size="18" color="green" icon="done" />;
    let browserVersion = (
      <span style={{ color: '#666666' }}>
        {t('info.version')}: {bowser.version}
      </span>
    );
    let browserMsgWarning = <span></span>;
    if (
      bowser.name === 'Chrome' ||
      bowser.name === 'Firefox' ||
      bowser.name === 'Safari'
    ) {
      browserCompatible = true;
    } else if (bowser.name === 'Internet Explorer') {
      browserCompatible = false;
    } else {
      browserCompatible = false;
    }

    if (!browserCompatible) {
      browserMsgOk = (
        <span>
          <ShowIcon size="18" color="darkred" icon="clear" />
        </span>
      );
      browserMsgWarning = <span> {t('info.browserError')}</span>;
    }

    if (bowser.name === 'Firefox') {
      if (bowser.version < 57) {
        browserVersionCompatible = false;
        browserMsgVersionOk = (
          <span>
            <ShowIcon size="18" color="darkred" icon="clear" />
            <br />
            {t('info.browserVersionError')} 57
          </span>
        );
      }
    } else if (bowser.name === 'Chrome') {
      if (bowser.version < 62) {
        browserMsgVersionOk = (
          <span>
            <ShowIcon size="18" color="darkred" icon="clear" />
            <br />
            {t('info.browserVersionError')} 62
          </span>
        );
        browserVersionCompatible = false;
      }
    } else if (bowser.name === 'Safari') {
      if (bowser.version < 11) {
        browserMsgVersionOk = (
          <span>
            <ShowIcon size="18" color="darkred" icon="clear" />
            <br />
            {t('info.browserVersionError')} 11
          </span>
        );
        browserVersionCompatible = false;
      }
    }

    //console.log('form container props');
    //console.log(this.props);

    const SignUpBlock = [];
    if (this.state.screensignup === 'ask-email') {
      if (this.state.SignUpAskEmailFormProcessing) {
        SignUpBlock.push(<div key="ask1email">{this.state.email}</div>);
        SignUpBlock.push(
          <div key="iconAE">
            <IconLoading key="icon" />
          </div>
        );
      } else {
        SignUpBlock.push(
          <SignUpAskEmailForm
            key="SignUpAskEmailForm"
            tourid={this.state.tourId}
            browserCompatible={browserCompatible}
            browserVersionCompatible={browserVersionCompatible}
            onSubmit={this.handleSignUpAskEmailForm}
            errors={this.state.errors}
            t={this.props.t}
            changeLanguage={this.props.t}
          />
        );
      }
    }
    if (this.state.screensignup === 'ask-verifycode') {
      if (this.state.SignUpAskVerifyCodeFormProcessing) {
        SignUpBlock.push(<div key="ask2email">{this.state.email}</div>);
        SignUpBlock.push(
          <div key="iconAV">
            <IconLoading key="icon" />
          </div>
        );
      } else {
        SignUpBlock.push(
          <div key="verifmsgm" style={{ marginBottom: '15px' }}>
            {t('info.verificationSent')}
          </div>
        );

        SignUpBlock.push(
          <div
            key="ask3email"
            style={{ marginBottom: '18px', color: '#555555' }}
          >
            {this.state.email}
            &nbsp;&nbsp;
            <a onClick={() => this.fixEmail()} style={{ color: 'blue' }}>
              &#60;- {t('info.vCorrect')}
            </a>
          </div>
        );

        SignUpBlock.push(
          <SignUpAskVerifyCodeForm
            key="SignUpAskVerifyCodeForm"
            browserCompatible={browserCompatible}
            browserVersionCompatible={browserVersionCompatible}
            onSubmit={this.handleSignUpAskVerifyCodeForm}
            errors={this.state.errors}
            t={this.props.t}
            changeLanguage={this.props.t}
          />
        );
      }
    }

    if (this.state.screensignup === 'ask-all') {
      if (this.state.SignUpAskAllFormProcessing) {
        SignUpBlock.push(
          <div key="iconAA">
            <IconLoading key="icon" />
          </div>
        );
      }

      SignUpBlock.push(
        <SignUpAskAllForm
          key="SignUpAskAllForm"
          executeCode={this.executeCode}
          browserCompatible={browserCompatible}
          browserVersionCompatible={browserVersionCompatible}
          onSubmit={this.handleSignUpAskAllForm}
          formState={this.state}
          errors={this.state.errors}
          processing={this.state.SignUpAskAllFormProcessing}
          t={this.props.t}
          changeLanguage={this.props.t}
        />
      );
    }

    if (this.state.screensignup === 'ask-finished') {
      SignUpBlock.push(
        <div
          key="signupE1"
          style={{
            height: '200px',
            color: 'darkred',
            fontSize: '21px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>{t('info.signupEnd')}</div>
        </div>
      );
    }

    return (
      <div>
        <div style={{ height: '20px' }}>&nbsp;</div>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              maxWidth: '950px',
            }}
          >
            <div
              style={{
                padding: '20px',
                width: this.state.screensignup !== 'ask-all' ? '435px' : '',
                minWidth: this.state.screensignup !== 'ask-all' ? '' : '750px',

                alignItems:
                  this.state.screensignup !== 'ask-all' ? 'center' : '',
                textAlign: this.state.screensignup !== 'ask-all' ? '' : 'left',
              }}
            >
              <div
                style={{
                  padding: '20px',
                  height:
                    this.state.screensignup !== 'ask-all' ? heightBlock : '',
                  alignItems:
                    this.state.screensignup !== 'ask-all' ? 'center' : '',
                  textAlign:
                    this.state.screensignup !== 'ask-all' ? '' : 'left',
                  border: 'solid 1px #CCCCCC',
                }}
              >
                <div
                  style={{
                    padding: '7px',
                    color: '#333333',
                    fontSize: '22px',
                    marginBotom: '5px',
                  }}
                >
                  {this.state.screensignup !== 'ask-all' &&
                  this.state.screensignup !== 'ask-finished'
                    ? t('info.signup')
                    : t('login.signup')}
                </div>
                {this.state.screensignup !== 'ask-all' &&
                  this.state.screensignup !== 'ask-finished' && (
                    <div
                      style={{
                        padding: '7px',
                        height: '25px',
                        color: '#aaaaaa',
                        fontSize: '16px',
                        marginBotom: '20px',
                      }}
                    >
                      {t('info.signupsub')}
                    </div>
                  )}

                {SignUpBlock}
              </div>
            </div>

            {this.state.screensignup !== 'ask-all' && (
              <div
                style={{
                  padding: '20px',
                  width: '435px',
                  height: '310px',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    padding: '20px',
                    alignItems:
                      this.state.screensignup !== 'ask-all' ? 'center' : '',
                    textAlign:
                      this.state.screensignup !== 'ask-all' ? '' : 'left',
                    border: 'solid 1px #CCCCCC',
                    height: heightBlock,
                  }}
                >
                  <div
                    style={{
                      padding: '7px',
                      height: '25px',
                      color: '#777777',
                      fontSize: '22px',
                      marginBotom: '5px',
                    }}
                  >
                    {t('info.signin')}
                  </div>
                  <div
                    style={{
                      padding: '7px',
                      height: '25px',
                      color: '#aaaaaa',
                      fontSize: '16px',
                      marginBotom: '20px',
                    }}
                  >
                    {t('info.signinsub')}
                  </div>
                  <SignInForm
                    containerPropsForm={this.props}
                    myState={this.props.myState}
                    browserCompatible={browserCompatible}
                    browserVersionCompatible={browserVersionCompatible}
                    onSubmit={this.handleSubmit}
                    errors={this.state.errors}
                    t={this.props.t}
                    changeLanguage={this.props.t}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <div>
            {t('info.browser')}:{' '}
            <span style={{ color: '#666666' }}>{bowser.name}</span>
            {browserMsgOk}
          </div>
          {browserCompatible && (
            <div>
              {t('info.version')}:{' '}
              <span style={{ color: '#666666' }}>{bowser.version}</span>
              {browserMsgVersionOk}
            </div>
          )}
          <div>{!browserCompatible && t('info.browsersMsgCompatible')}</div>
        </div>

        <div>{browserMsgWarning}</div>

        {!this.props.tourId && <CookiesAlert t={t} />}
        {(!this.props.tourId || window.location.href.includes('/signin')) && (
          <div
            style={{
              fontSize: '12px',
              color: '#ccc',
              padding: '10px 0px 10px 20px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                padding: '2px',
                fontSize: '17px',
              }}
            >
              {' '}
              >
              <a
                target="_blank"
                href="https://www.pelemobil.com/legal.html"
                style={{
                  color: '#000',
                  margin: '0 60px 0 10px',
                }}
              >
                {t('form.onlineterms')}
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const signInMutation = gql`
  mutation signIn($nick: String!, $password: String!) {
    signIn(nick: $nick, password: $password) {
      token
      company
      nick
      is
      ai
      pe
      ar
      gu
      iu
      errors {
        key
        value
      }
    }
  }
`;

const signUp1Mutation = gql`
  mutation signUp1($email: String!, $tourId: ID) {
    signUp1(email: $email, tourId: $tourId) {
      answer
      errors {
        key
        value
      }
    }
  }
`;

const signUp2Mutation = gql`
  mutation signUp2($email: String!, $emailvcode: String!) {
    signUp2(email: $email, emailvcode: $emailvcode) {
      answer
      errors {
        key
        value
      }
    }
  }
`;

const signUp3Mutation = gql`
  mutation signUp3(
    $email: String!
    $id: ID!
    $password: String!
    $nick: String!
    $firstname: String!
    $lastname: String!
    $birthdate: String!
    $nationality: String!
    $residence: String!
    $imageright: Int!
  ) {
    signUp3(
      id: $id
      nick: $nick
      email: $email
      firstname: $firstname
      lastname: $lastname
      password: $password
      birthdate: $birthdate
      nationality: $nationality
      residence: $residence
      imageright: $imageright
    ) {
      answer
      errors {
        key
        value
      }
    }
  }
`;

const withGraphqlandRouter = compose(
  graphql(signInMutation, {
    name: 'mutationsignIn',
    options: (props) => {
      const optionsValues = {}; // variables: {id: props.match.params.id, _qlType: 'View' }
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
  }),
  graphql(signUp1Mutation, {
    name: 'mutationsignUp1',
    options: (props) => {
      const optionsValues = {}; // variables: {id: props.match.params.id, _qlType: 'View' }
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
  }),
  graphql(signUp2Mutation, {
    name: 'mutationsignUp2',
    options: (props) => {
      const optionsValues = {}; // variables: {id: props.match.params.id, _qlType: 'View' }
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
  }),
  graphql(signUp3Mutation, {
    name: 'mutationsignUp3',
    options: (props) => {
      const optionsValues = {}; // variables: {id: props.match.params.id, _qlType: 'View' }
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
  })
)(withRouter(SignInFormContainer));

const mapDispatchToProps = (dispatch) => ({
  signInDispatcher(response) {
    dispatch(signIn(response));
  },
});

const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};

const SignInWithDataAndState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

export default SignInWithDataAndState;
