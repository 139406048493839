import gql from 'graphql-tag';
import strQls from './defStrQls.js';
import strFragments from './defStrFragmentsQls.js';
const defQls = {};

defQls['changePass'] = gql`
  mutation changePass($password: String!, $newpass: String!) {
    changePass(password: $password, newpass: $newpass) {
      answer
    }
  }
`;

// www.mobilsem.com  - Copyright .src/components/settings/Settings.qls
defQls['setOrgSettings'] = gql`
  mutation setOrgSettings(
    $settingsforms: String
    $accounting: String
    $othersettings: String
    $esettings: String
  ) {
    setOrgSettings(
      settingsforms: $settingsforms
      accounting: $accounting
      othersettings: $othersettings
      esettings: $esettings
    ) {
      answer
      errors {
        key
        value
      }
    }
  }
`;

defQls['getOrgSettings'] = gql`
  query getOrgSettings {
    getOrgSettings {
      record
      errors {
        key
        value
      }
    }
  }
`;

defQls['customeraddress'] = {};

defQls['customeraddress']['ListMini'] = gql`
  query CustomeraddressList($id: ID, $_qlType: String, $_filter: String) {
    customeraddresss(id: $id, _qlType: $_qlType, _filter: $_filter) {
      id
      customers
      addresses
    }
  }
`;
defQls['customeraddress']['List'] = gql`
        query CustomeraddressList ($id: ID, $_qlType: String, $_filter: String) {
          customeraddresss  (id: $id, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.customeraddress}
          }    
        }
        `;
defQls['customeraddress']['ListPage'] = gql`
  ${strQls.customeraddress.ListPage}
`;

defQls['customeraddress']['View'] = gql`
  query CustomeraddressView($id: ID!) {
    customeraddress(id: $id) {
      id
      customers
      addresses
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      customeraddressupdated_user_id {
        name
      }
      customeraddresscreated_user_id {
        name
      }
    }
  }
`;
defQls['customeraddress']['Update'] = gql`
  mutation customeraddressUpdate(
    $id: ID!
    $customers: String
    $addresses: String
  ) {
    customeraddressUpdate(
      id: $id
      customers: $customers
      addresses: $addresses
    ) {
      id
      customers
      addresses
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['customeraddress']['Delete'] = gql`
  mutation customeraddressDelete($id: ID!) {
    customeraddressDelete(id: $id) {
      id
      customers
      addresses
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['customeraddress']['Add'] = gql`
  mutation customeraddressAdd(
    $id: ID!
    $customers: String
    $addresses: String
  ) {
    customeraddressAdd(id: $id, customers: $customers, addresses: $addresses) {
      id
      customers
      addresses
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['customer'] = {};

defQls['customer']['ListMini'] = gql`
  query CustomerList(
    $lastname: String
    $firstname: String
    $customerstatus_id: String
    $addresses_city_id: ID
    $inactive: Boolean
    $id: ID
    $name: String
    $_qlType: String
    $_filter: String
    $_orders: String
  ) {
    customers(
      lastname: $lastname
      firstname: $firstname
      customerstatus_id: $customerstatus_id
      addresses_city_id: $addresses_city_id
      inactive: $inactive
      id: $id
      name: $name
      _qlType: $_qlType
      _filter: $_filter
      _orders: $_orders
    ) {
      id
      name
      email
      customerstatus_id
      customersubstatus_id
      mobile
      customergendertype_id {
        name
      }
    }
  }
`;
defQls['customer']['List'] = gql`
        query CustomerList ($lastname: String, $firstname: String, $customerstatus_id: String, $addresses_city_id: ID, $inactive: Boolean, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          customers  (lastname: $lastname, firstname: $firstname, customerstatus_id: $customerstatus_id, addresses_city_id: $addresses_city_id, inactive: $inactive, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.customer}
          }    
        }
        `;
defQls['customer']['ListPage'] = gql`
  ${strQls.customer.ListPage}
`;

defQls['customer']['View'] = gql`
  query CustomerView($id: ID!) {
    customer(id: $id) {
      id
      gendertype_id
      name
      lastname
      firstname
      email
      socialsn
      imageright
      inactive
      deceased
      customerstatus_id
      customersubstatus_id
      grouped_customer_id
      grouped_filiation
      birthdate
      info
      nationality
      contacts
      mobile
      phones
      customeraddress_id
      customeraddress_customers
      addresses
      identitydocs
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      customercustomeraddress_id {
        customers
        addresses
      }
      customergrouped_customer_id {
        name
      }
      customergendertype_id {
        name
      }
      customercustomerstatus_id {
        name
      }
      customerupdated_user_id {
        name
      }
      customercreated_user_id {
        name
      }
    }
  }
`;
defQls['customer']['Update'] = gql`
  mutation customerUpdate(
    $id: ID!
    $gendertype_id: String
    $lastname: String
    $firstname: String
    $email: String
    $socialsn: String
    $imageright: Int
    $inactive: Boolean
    $deceased: Boolean
    $customerstatus_id: String
    $customersubstatus_id: String
    $grouped_customer_id: String
    $grouped_filiation: Int
    $birthdate: String
    $info: String
    $nationality: String
    $contacts: String
    $mobile: String
    $phones: String
    $customeraddress_id: String
    $customeraddress_customers: String
    $addresses: String
    $identitydocs: String
  ) {
    customerUpdate(
      id: $id
      gendertype_id: $gendertype_id
      lastname: $lastname
      firstname: $firstname
      email: $email
      socialsn: $socialsn
      imageright: $imageright
      inactive: $inactive
      deceased: $deceased
      customerstatus_id: $customerstatus_id
      customersubstatus_id: $customersubstatus_id
      grouped_customer_id: $grouped_customer_id
      grouped_filiation: $grouped_filiation
      birthdate: $birthdate
      info: $info
      nationality: $nationality
      contacts: $contacts
      mobile: $mobile
      phones: $phones
      customeraddress_id: $customeraddress_id
      customeraddress_customers: $customeraddress_customers
      addresses: $addresses
      identitydocs: $identitydocs
    ) {
      id
      gendertype_id
      name
      lastname
      firstname
      email
      socialsn
      imageright
      inactive
      deceased
      customerstatus_id
      customersubstatus_id
      grouped_customer_id
      grouped_filiation
      birthdate
      info
      nationality
      contacts
      mobile
      phones
      customeraddress_id
      customeraddress_customers
      addresses
      identitydocs
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['customer']['Delete'] = gql`
  mutation customerDelete($id: ID!) {
    customerDelete(id: $id) {
      id
      gendertype_id
      name
      lastname
      firstname
      email
      socialsn
      imageright
      inactive
      deceased
      customerstatus_id
      customersubstatus_id
      grouped_customer_id
      grouped_filiation
      birthdate
      info
      nationality
      contacts
      mobile
      phones
      customeraddress_id
      customeraddress_customers
      addresses
      identitydocs
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['customer']['Add'] = gql`
  mutation customerAdd(
    $id: ID!
    $gendertype_id: String
    $lastname: String
    $firstname: String
    $email: String
    $socialsn: String
    $imageright: Int
    $inactive: Boolean
    $deceased: Boolean
    $customerstatus_id: String
    $customersubstatus_id: String
    $grouped_customer_id: String
    $grouped_filiation: Int
    $birthdate: String
    $info: String
    $nationality: String
    $contacts: String
    $mobile: String
    $phones: String
    $customeraddress_id: String
    $customeraddress_customers: String
    $addresses: String
    $identitydocs: String
  ) {
    customerAdd(
      id: $id
      gendertype_id: $gendertype_id
      lastname: $lastname
      firstname: $firstname
      email: $email
      socialsn: $socialsn
      imageright: $imageright
      inactive: $inactive
      deceased: $deceased
      customerstatus_id: $customerstatus_id
      customersubstatus_id: $customersubstatus_id
      grouped_customer_id: $grouped_customer_id
      grouped_filiation: $grouped_filiation
      birthdate: $birthdate
      info: $info
      nationality: $nationality
      contacts: $contacts
      mobile: $mobile
      phones: $phones
      customeraddress_id: $customeraddress_id
      customeraddress_customers: $customeraddress_customers
      addresses: $addresses
      identitydocs: $identitydocs
    ) {
      id
      gendertype_id
      name
      lastname
      firstname
      email
      socialsn
      imageright
      inactive
      deceased
      customerstatus_id
      customersubstatus_id
      grouped_customer_id
      grouped_filiation
      birthdate
      info
      nationality
      contacts
      mobile
      phones
      customeraddress_id
      customeraddress_customers
      addresses
      identitydocs
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
/*
don't use 'gql', because it will be used directly by fetch(), that use direct string, and no graphql
defQls['getCitiesByCp'] = gql` query getCitiesByCp( $cp: String )  {
*/
/*defQls['getCitiesByCp'] = ` query getCitiesByCp( $cp: String )  {
            getCitiesByCp ( cp: $cp )   {
                id
                name
                city_id
            }
          }
    `;
*/
// With autocomplete, fetch works only with .name variable to look data, does not support another names
defQls['getCitiesByCp'] = ` query getCitiesByCp( $name: String )  {
            getCitiesByCp ( name: $name )   {
                id
                name
                city_name
                city_id
            }    
          }
    `;

defQls['tour'] = {};

defQls['tour']['ListMini'] = gql`
  query TourList(
    $name: String
    $inactive: Boolean
    $id: ID
    $_qlType: String
    $_filter: String
  ) {
    tours(
      name: $name
      inactive: $inactive
      id: $id
      _qlType: $_qlType
      _filter: $_filter
    ) {
      id
      name
      tourcategory_id
      price
      cancelledprice
    }
  }
`;
defQls['tour']['List'] = gql`
        query TourList ($name: String, $inactive: Boolean, $id: ID, $_qlType: String, $_filter: String) {
          tours  (name: $name, inactive: $inactive, id: $id, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.tour}
          }    
        }
        `;
defQls['tour']['ListPage'] = gql`
  ${strQls.tour.ListPage}
`;

defQls['tour']['View'] = gql`
  query TourView($id: ID!) {
    tour(id: $id) {
      id
      name
      longname
      country
      description
      seatsmax
      seatsmin
      datestart
      dateend
      inactive
      registrationstart
      registrationend
      tourcategory_id
      onlineregtype
      gcu
      urlgcu
      textgcu
      itinerary
      price
      cancelledprice
      additionalservices
      updatedAt
      createdAt
      deletedAt
      deleted
      tourtourcategory_id {
        name
        settings
      }
      tourorganizer_id {
        name
        settingsforms
        textgcu
        modules
      }
    }
  }
`;
defQls['tourcategory'] = {};

defQls['tourcategory']['ListMini'] = gql`
  query TourcategoryList(
    $id: ID
    $name: String
    $_qlType: String
    $_filter: String
  ) {
    tourcategorys(id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
      id
      name
    }
  }
`;
defQls['tourcategory']['List'] = gql`
        query TourcategoryList ($id: ID, $name: String, $_qlType: String, $_filter: String) {
          tourcategorys  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.tourcategory}
          }    
        }
        `;
defQls['tourcategory']['ListPage'] = gql`
  ${strQls.tourcategory.ListPage}
`;

defQls['tourcategory']['View'] = gql`
  query TourcategoryView($id: ID!) {
    tourcategory(id: $id) {
      id
      name
      settings
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      tourcategoryupdated_user_id {
        name
      }
      tourcategorycreated_user_id {
        name
      }
    }
  }
`;
defQls['tourcategory']['Update'] = gql`
  mutation tourcategoryUpdate($id: ID!, $name: String!, $settings: String) {
    tourcategoryUpdate(id: $id, name: $name, settings: $settings) {
      id
      name
      settings
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['tourcategory']['Delete'] = gql`
  mutation tourcategoryDelete($id: ID!) {
    tourcategoryDelete(id: $id) {
      id
      name
      settings
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['tourcategory']['Add'] = gql`
  mutation tourcategoryAdd($id: ID!, $name: String!, $settings: String) {
    tourcategoryAdd(id: $id, name: $name, settings: $settings) {
      id
      name
      settings
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['transportation'] = {};

defQls['transportation']['ListMini'] = gql`
  query TransportationList(
    $tour_id: String
    $id: ID
    $name: String
    $_qlType: String
    $_filter: String
  ) {
    transportations(
      tour_id: $tour_id
      id: $id
      name: $name
      _qlType: $_qlType
      _filter: $_filter
    ) {
      id
      tour_id
      name
      price
      routeplanner
    }
  }
`;
defQls['transportation']['List'] = gql`
        query TransportationList ($tour_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          transportations  (tour_id: $tour_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.transportation}
          }    
        }
        `;
defQls['transportation']['ListPage'] = gql`
  ${strQls.transportation.ListPage}
`;

defQls['transportation']['View'] = gql`
  query TransportationView($id: ID!) {
    transportation(id: $id) {
      id
      tour_id
      name
      seats
      price
      driverinfo
      responsibleinfo
      routeplanner
      bookings
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      transportationtour_id {
        name
        seatsmax
      }
      transportationupdated_user_id {
        name
      }
      transportationcreated_user_id {
        name
      }
    }
  }
`;
defQls['transportation']['Update'] = gql`
  mutation transportationUpdate(
    $id: ID!
    $tour_id: String
    $name: String
    $seats: Int
    $price: Float
    $driverinfo: String
    $responsibleinfo: String
    $routeplanner: String
    $bookings: String
  ) {
    transportationUpdate(
      id: $id
      tour_id: $tour_id
      name: $name
      seats: $seats
      price: $price
      driverinfo: $driverinfo
      responsibleinfo: $responsibleinfo
      routeplanner: $routeplanner
      bookings: $bookings
    ) {
      id
      tour_id
      name
      seats
      price
      driverinfo
      responsibleinfo
      routeplanner
      bookings
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['transportation']['Delete'] = gql`
  mutation transportationDelete($id: ID!) {
    transportationDelete(id: $id) {
      id
      tour_id
      name
      seats
      price
      driverinfo
      responsibleinfo
      routeplanner
      bookings
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['transportation']['Add'] = gql`
  mutation transportationAdd(
    $id: ID!
    $tour_id: String
    $name: String
    $seats: Int
    $price: Float
    $driverinfo: String
    $responsibleinfo: String
    $routeplanner: String
    $bookings: String
  ) {
    transportationAdd(
      id: $id
      tour_id: $tour_id
      name: $name
      seats: $seats
      price: $price
      driverinfo: $driverinfo
      responsibleinfo: $responsibleinfo
      routeplanner: $routeplanner
      bookings: $bookings
    ) {
      id
      tour_id
      name
      seats
      price
      driverinfo
      responsibleinfo
      routeplanner
      bookings
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['getTransportationCustomers'] = gql`
  query getTransportationCustomers(
    $transportation_id: String
    $tour_id: String
  ) {
    getTransportationCustomers(
      transportation_id: $transportation_id
      tour_id: $tour_id
    ) {
      transportation_id
      name
      customer_id
      registration_id
      transportation
      errorT
      realStopId
      services
      __typename
    }
  }
`;
defQls['tourprice'] = {};

defQls['tourprice']['ListMini'] = gql`
  query TourpriceList(
    $tour_id: String
    $id: ID
    $_qlType: String
    $_filter: String
  ) {
    tourprices(
      tour_id: $tour_id
      id: $id
      _qlType: $_qlType
      _filter: $_filter
    ) {
      id
      customerstatus_id
      tour_id
      price
    }
  }
`;
defQls['tourprice']['List'] = gql`
        query TourpriceList ($tour_id: String, $id: ID, $_qlType: String, $_filter: String) {
          tourprices  (tour_id: $tour_id, id: $id, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.tourprice}
          }    
        }
        `;
defQls['tourprice']['ListPage'] = gql`
  ${strQls.tourprice.ListPage}
`;

defQls['tourprice']['View'] = gql`
  query TourpriceView($id: ID!) {
    tourprice(id: $id) {
      id
      customerstatus_id
      tour_id
      price
      notes
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      tourpricecustomerstatus_id {
        name
      }
      tourpricetour_id {
        name
        seatsmax
      }
      tourpriceupdated_user_id {
        name
      }
      tourpricecreated_user_id {
        name
      }
    }
  }
`;
defQls['tourprice']['Update'] = gql`
  mutation tourpriceUpdate(
    $id: ID!
    $customerstatus_id: String
    $tour_id: String
    $price: Float
    $notes: String
  ) {
    tourpriceUpdate(
      id: $id
      customerstatus_id: $customerstatus_id
      tour_id: $tour_id
      price: $price
      notes: $notes
    ) {
      id
      customerstatus_id
      tour_id
      price
      notes
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['tourprice']['Delete'] = gql`
  mutation tourpriceDelete($id: ID!) {
    tourpriceDelete(id: $id) {
      id
      customerstatus_id
      tour_id
      price
      notes
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['tourprice']['Add'] = gql`
  mutation tourpriceAdd(
    $id: ID!
    $customerstatus_id: String
    $tour_id: String
    $price: Float
    $notes: String
  ) {
    tourpriceAdd(
      id: $id
      customerstatus_id: $customerstatus_id
      tour_id: $tour_id
      price: $price
      notes: $notes
    ) {
      id
      customerstatus_id
      tour_id
      price
      notes
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['tourroom'] = {};

defQls['tourroom']['ListMini'] = gql`
  query TourroomList(
    $tour_id: String
    $id: ID
    $name: String
    $_qlType: String
    $_filter: String
    $_orders: String
  ) {
    tourrooms(
      tour_id: $tour_id
      id: $id
      name: $name
      _qlType: $_qlType
      _filter: $_filter
      _orders: $_orders
    ) {
      id
      hotel_id
      room_type
      tour_id
      priceroom
      people_available
      name
      isfullname
      tourroomtour_id {
        name
      }
      tourroomhotel_id {
        name
      }
    }
  }
`;
defQls['tourroom']['List'] = gql`
        query TourroomList ($tour_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          tourrooms  (tour_id: $tour_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.tourroom}
          }    
        }
        `;
defQls['tourroom']['ListPage'] = gql`
  ${strQls.tourroom.ListPage}
`;

defQls['tourroom']['View'] = gql`
  query TourroomView($id: ID!) {
    tourroom(id: $id) {
      id
      hotel_id
      room_type
      tour_id
      room_quantity
      priceroom
      name
      info
      bookings
      customers
      room_numbers
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      tourroomtour_id {
        name
      }
      tourroomhotel_id {
        name
      }
      tourroomupdated_user_id {
        name
      }
      tourroomcreated_user_id {
        name
      }
    }
  }
`;
defQls['tourroom']['Update'] = gql`
  mutation tourroomUpdate(
    $id: ID!
    $hotel_id: String
    $room_type: Int
    $tour_id: String
    $room_quantity: Int
    $priceroom: Float
    $name: String
    $info: String
    $bookings: String
    $customers: String
    $room_numbers: String
  ) {
    tourroomUpdate(
      id: $id
      hotel_id: $hotel_id
      room_type: $room_type
      tour_id: $tour_id
      room_quantity: $room_quantity
      priceroom: $priceroom
      name: $name
      info: $info
      bookings: $bookings
      customers: $customers
      room_numbers: $room_numbers
    ) {
      id
      hotel_id
      room_type
      tour_id
      room_quantity
      priceroom
      name
      info
      bookings
      customers
      room_numbers
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['tourroom']['Delete'] = gql`
  mutation tourroomDelete($id: ID!) {
    tourroomDelete(id: $id) {
      id
      hotel_id
      room_type
      tour_id
      room_quantity
      priceroom
      name
      info
      bookings
      customers
      room_numbers
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['tourroom']['Add'] = gql`
  mutation tourroomAdd(
    $id: ID!
    $hotel_id: String
    $room_type: Int
    $tour_id: String
    $room_quantity: Int
    $priceroom: Float
    $name: String
    $info: String
    $bookings: String
    $customers: String
    $room_numbers: String
  ) {
    tourroomAdd(
      id: $id
      hotel_id: $hotel_id
      room_type: $room_type
      tour_id: $tour_id
      room_quantity: $room_quantity
      priceroom: $priceroom
      name: $name
      info: $info
      bookings: $bookings
      customers: $customers
      room_numbers: $room_numbers
    ) {
      id
      hotel_id
      room_type
      tour_id
      room_quantity
      priceroom
      name
      info
      bookings
      customers
      room_numbers
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['getTourRoomCustomers'] = gql`
  query getTourRoomCustomers($tourroom_id: String!) {
    getTourRoomCustomers(tourroom_id: $tourroom_id) {
      tourroom_id
      name
      customer_id
      registration_id
      services
      __typename
    }
  }
`;

defQls['registration'] = {};

defQls['registration']['ListMini'] = gql`
  query RegistrationList(
    $numbering: String
    $customer_id: ID
    $tour_id: ID
    $person_id: ID
    $transportation_transportation_id: String
    $tourroom_id: String
    $registrationstatus_id_statusbase: Int
    $customerstatus_id: String
    $customersubstatus_id: String
    $confirmationstatus_id_statusbase: Int
    $_balance: String
    $assignedroom: Boolean
    $_channel: String
    $_withmobile: String
    $inactive: Boolean
    $id: ID
    $_qlType: String
    $_filter: String
    $_orders: String
    $_process: String
  ) {
    registrations(
      numbering: $numbering
      customer_id: $customer_id
      tour_id: $tour_id
      person_id: $person_id
      transportation_transportation_id: $transportation_transportation_id
      tourroom_id: $tourroom_id
      registrationstatus_id_statusbase: $registrationstatus_id_statusbase
      customerstatus_id: $customerstatus_id
      customersubstatus_id: $customersubstatus_id
      confirmationstatus_id_statusbase: $confirmationstatus_id_statusbase
      _balance: $_balance
      assignedroom: $assignedroom
      _channel: $_channel
      _withmobile: $_withmobile
      inactive: $inactive
      id: $id
      _qlType: $_qlType
      _filter: $_filter
      _orders: $_orders
      _process: $_process
    ) {
      id
      person_id
      tour_id
      registrationcustomer_id {
        name
        email
      }
      registrationtour_id {
        name
        seatsmax
      }
    }
  }
`;
defQls['registration']['List'] = gql`
        query RegistrationList ($numbering: String, $customer_id: ID, $tour_id: ID, $transportation_transportation_id: String, $tourroom_id: String, $registrationstatus_id_statusbase: Int, $customerstatus_id: String, $customersubstatus_id: String, $confirmationstatus_id_statusbase: Int, $_balance: String, $assignedroom: Boolean, $_channel: String, $_withmobile: String, $inactive: Boolean, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          registrations  (numbering: $numbering, customer_id: $customer_id, tour_id: $tour_id, transportation_transportation_id: $transportation_transportation_id, tourroom_id: $tourroom_id, registrationstatus_id_statusbase: $registrationstatus_id_statusbase, customerstatus_id: $customerstatus_id, customersubstatus_id: $customersubstatus_id, confirmationstatus_id_statusbase: $confirmationstatus_id_statusbase, _balance: $_balance, assignedroom: $assignedroom, _channel: $_channel, _withmobile: $_withmobile, inactive: $inactive, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.registration}
          }    
        }
        `;
defQls['registration']['ListPage'] = gql`
  ${strQls.registration.ListPage}
`;

defQls['registration']['View'] = gql`
  query RegistrationView($id: ID!) {
    registration(id: $id) {
      id
      customer_id
      registrationgroupping_customers
      tour_id
      numbering
      registrationstatus_id
      dateregistration
      confirmationstatus_id
      inactive
      customerstatus_id
      customersubstatus_id
      tourroom_id
      assignedroom
      tollfree
      price
      paid
      balance
      _priceformula
      transportation
      objects
      notes
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      registrationcustomer_id {
        name
        email
      }
      registrationtour_id {
        name
        seatsmax
      }
      registrationtourroom_id {
        room_type
        hotel_id
        tourroomhotel_id {
          name
        }
      }
      registrationregistrationstatus_id {
        name
        color
        statusbase
      }
      registrationconfirmationstatus_id {
        name
        color
      }
      registrationcustomerstatus_id {
        name
      }
      registrationupdated_user_id {
        name
      }
      registrationcreated_user_id {
        name
      }
    }
  }
`;
defQls['registration']['Update'] = gql`
  mutation registrationUpdate(
    $id: ID!
    $customer_id: ID
    $registrationgroupping_customers: String
    $tour_id: ID
    $numbering: String
    $registrationstatus_id: String
    $dateregistration: String
    $confirmationstatus_id: String
    $inactive: Boolean
    $customerstatus_id: String
    $customersubstatus_id: String
    $tourroom_id: String
    $tollfree: Boolean
    $price: Float
    $paid: Float
    $balance: Float
    $_priceformula: String
    $transportation: String
    $objects: String
    $notes: String
  ) {
    registrationUpdate(
      id: $id
      customer_id: $customer_id
      registrationgroupping_customers: $registrationgroupping_customers
      tour_id: $tour_id
      numbering: $numbering
      registrationstatus_id: $registrationstatus_id
      dateregistration: $dateregistration
      confirmationstatus_id: $confirmationstatus_id
      inactive: $inactive
      customerstatus_id: $customerstatus_id
      customersubstatus_id: $customersubstatus_id
      tourroom_id: $tourroom_id
      tollfree: $tollfree
      price: $price
      paid: $paid
      balance: $balance
      _priceformula: $_priceformula
      transportation: $transportation
      objects: $objects
      notes: $notes
    ) {
      id
      customer_id
      registrationgroupping_customers
      tour_id
      numbering
      registrationstatus_id
      dateregistration
      confirmationstatus_id
      inactive
      customerstatus_id
      customersubstatus_id
      tourroom_id
      assignedroom
      tollfree
      price
      paid
      balance
      _priceformula
      transportation
      objects
      notes
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['registration']['Delete'] = gql`
  mutation registrationDelete($id: ID!) {
    registrationDelete(id: $id) {
      id
      customer_id
      registrationgroupping_customers
      tour_id
      numbering
      registrationstatus_id
      dateregistration
      confirmationstatus_id
      inactive
      customerstatus_id
      customersubstatus_id
      tourroom_id
      assignedroom
      tollfree
      price
      paid
      balance
      _priceformula
      transportation
      objects
      notes
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['registration']['Add'] = gql`
  mutation registrationAdd(
    $id: ID!
    $customer_id: ID
    $registrationgroupping_customers: String
    $tour_id: ID
    $numbering: String
    $registrationstatus_id: String
    $dateregistration: String
    $confirmationstatus_id: String
    $inactive: Boolean
    $customerstatus_id: String
    $customersubstatus_id: String
    $tourroom_id: String
    $tollfree: Boolean
    $price: Float
    $paid: Float
    $balance: Float
    $_priceformula: String
    $transportation: String
    $services: String
    $notes: String
  ) {
    registrationAdd(
      id: $id
      customer_id: $customer_id
      registrationgroupping_customers: $registrationgroupping_customers
      tour_id: $tour_id
      numbering: $numbering
      registrationstatus_id: $registrationstatus_id
      dateregistration: $dateregistration
      confirmationstatus_id: $confirmationstatus_id
      inactive: $inactive
      customerstatus_id: $customerstatus_id
      customersubstatus_id: $customersubstatus_id
      tourroom_id: $tourroom_id
      tollfree: $tollfree
      price: $price
      paid: $paid
      balance: $balance
      _priceformula: $_priceformula
      transportation: $transportation
      services: $services
      notes: $notes
    ) {
      id
      customer_id
      registrationgroupping_customers
      tour_id
      numbering
      registrationstatus_id
      dateregistration
      confirmationstatus_id
      inactive
      customerstatus_id
      customersubstatus_id
      tourroom_id
      assignedroom
      tollfree
      price
      paid
      balance
      _priceformula
      transportation
      services
      notes
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
// these def go to global defQls , and disponible for all cruds forms or components
defQls['getTourServices'] = gql`
  query getTourServices($tour_id: ID!, $onlineregtype: Boolean) {
    getTourServices(tour_id: $tour_id, onlineregtype: $onlineregtype) {
      id
      name
      nameOnly
      typeEntry
      tour_id
      operator
      amount
      listOptions {
        id
        name
        amount
        nameOnly
      }
      cancellednotzero
      type
      globalname
      globaltype
    }
  }
`;

defQls['tourcategory'] = {};

defQls['tourcategory']['ListMini'] = gql`
  query TourcategoryList(
    $id: ID
    $name: String
    $_qlType: String
    $_filter: String
  ) {
    tourcategorys(id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
      id
      name
    }
  }
`;
defQls['tourcategory']['List'] = gql`
        query TourcategoryList ($id: ID, $name: String, $_qlType: String, $_filter: String) {
          tourcategorys  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.tourcategory}
          }    
        }
        `;

defQls['getTourCustomerStatusAndServices'] = gql`
  query getTourCustomerStatusAndServices($tour_id: ID, $_qlType: String) {
    getTourCustomerStatusAndServices(tour_id: $tour_id, _qlType: $_qlType) {
      id
      name
      nameOnly
      tourprice_id
      tourprice_price
      substatuss {
        id
        name
        nameOnly
        price
        inputprice
        availableservices {
          id
          name
          nameOnly
          amount
          typeEntry
          validators
          listOptions {
            id
            name
            amount
          }
        }
        availablehotels {
          id
          name
          priceroom
        }
      }
      availableservices {
        id
        name
        nameOnly
        amount
        typeEntry
        validators
        listOptions {
          id
          name
          amount
        }
      }
      availablehotels {
        id
        name
        priceroom
      }
    }
  }
`;
defQls['getTourStopsPoints'] = gql`
  query getTourStopsPoints {
    getTourStopsPoints {
      id
      realstopid
      name
      tour_id
      transportation_id
      price
    }
  }
`;

defQls['regonline'] = {};

defQls['regonline']['ListMini'] = gql`
  query RegonlineList(
    $tour_id: ID
    $person_id: ID
    $id: ID
    $_qlType: String
    $_filter: String
    $_orders: String
    $_process: String
  ) {
    regonlines(
      tour_id: $tour_id
      person_id: $person_id
      id: $id
      _qlType: $_qlType
      _filter: $_filter
      _orders: $_orders
      _process: $_process
    ) {
      id
      room_type
      person_id
      tour_id
      partialpayment
      price
      notes
      state
      regonlineperson_id {
        id
        name
        email
      }
      regonlinecustomer_id {
        id
        name
        email
      }
      regonlinetour_id {
        id
        name
        seatsmax
        longname
      }
    }
  }
`;
defQls['regonline']['List'] = gql`
        query RegonlineList ($tour_id: ID, $person_id: ID, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          regonlines  (tour_id: $tour_id, person_id: $person_id, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.regonline}
          }    
        }
        `;
defQls['regonline']['ListPage'] = gql`
  ${strQls.regonline.ListPage}
`;

defQls['regonline']['View'] = gql`
  query RegonlineView($id: ID!) {
    regonline(id: $id) {
      id
      person_id
      tour_id
      dateregistration
      customer_id
      tourroom_id
      customerstatus_id
      customersubstatus_id
      room_type
      room_shared
      stopspoint_id
      items
      objects
      price
      partialpayment
      state
      notes
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      regonlineperson_id {
        id
        name
        email
      }
      regonlinecustomer_id {
        id
        name
        email
      }
      regonlinetour_id {
        id
        name
        longname
        seatsmax
      }
      regonlinestopspoint_id {
        id
        name
      }
      regonlinecustomerstatus_id {
        name
      }
      regonlinetourroom_id {
        name
        isfullname
        room_type
        hotel_id
        priceroom
        tourroomhotel_id {
          name
        }
      }
    }
  }
`;
defQls['regonline']['Add'] = gql`
  mutation regonlineAdd(
    $id: ID!
    $person_id: ID
    $tour_id: ID
    $payinput: String
    $modetest: Boolean
    $dateregistration: String
    $customer_id: ID
    $tourroom_id: String
    $customerstatus_id: String
    $customersubstatus_id: String
    $room_type: Int
    $room_shared: String
    $stopspoint_id: String
    $items: String
    $objects: String
    $price: Float
    $partialpayment: Float
    $state: Int
    $notes: String
  ) {
    regonlineAdd(
      id: $id
      person_id: $person_id
      tour_id: $tour_id
      payinput: $payinput
      modetest: $modetest
      dateregistration: $dateregistration
      customer_id: $customer_id
      tourroom_id: $tourroom_id
      customerstatus_id: $customerstatus_id
      customersubstatus_id: $customersubstatus_id
      room_type: $room_type
      room_shared: $room_shared
      stopspoint_id: $stopspoint_id
      price: $price
      partialpayment: $partialpayment
      items: $items
      objects: $objects
      state: $state
      notes: $notes
    ) {
      id
      signature
      payinput
      modetest
      person_id
      tour_id
      dateregistration
      customer_id
      tourroom_id
      customerstatus_id
      customersubstatus_id
      room_type
      room_shared
      stopspoint_id
      price
      partialpayment
      state
      objects
      items
      notes
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;

defQls['regonline']['Update'] = gql`
  mutation regonlineUpdate(
    $id: ID!
    $customer_id: ID
    $person_id: ID
    $tour_id: ID
    $payinput: String
    $dateregistration: String
    $tourroom_id: String
    $room_type: Int
    $room_shared: String
    $stopspoint_id: String
    $state: Int
    $notes: String
  ) {
    regonlineUpdate(
      id: $id
      customer_id: $customer_id
      person_id: $person_id
      tour_id: $tour_id
      payinput: $payinput
      dateregistration: $dateregistration
      tourroom_id: $tourroom_id
      room_type: $room_type
      room_shared: $room_shared
      stopspoint_id: $stopspoint_id
      state: $state
      notes: $notes
    ) {
      id
      customer_id
      person_id
      tour_id
      dateregistration
      tourroom_id
      room_type
      room_shared
      stopspoint_id
      payinput
      state
      notes
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;

defQls['customerhistory'] = {};

defQls['customerhistory']['ListMini'] = gql`
  query CustomerhistoryList(
    $customer_id: String
    $id: ID
    $_qlType: String
    $_filter: String
  ) {
    customerhistorys(
      customer_id: $customer_id
      id: $id
      _qlType: $_qlType
      _filter: $_filter
    ) {
      id
    }
  }
`;
defQls['customerhistory']['List'] = gql`
        query CustomerhistoryList ($customer_id: String, $id: ID, $_qlType: String, $_filter: String) {
          customerhistorys  (customer_id: $customer_id, id: $id, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.customerhistory}
          }    
        }
        `;
// defQls['customerhistory']['ListPage'] = gql`${strQls.customerhistory.ListPage}`;

defQls['customerhistory']['View'] = gql`
  query CustomerhistoryView($id: ID!) {
    customerhistory(id: $id) {
      id
      actiondate
      user_id
      customer_id
      changes
      oldchanges
      customerhistoryuser_id {
        name
      }
      customerhistorycustomer_id {
        name
      }
    }
  }
`;
defQls['payment'] = {};

defQls['payment']['ListMini'] = gql`
  query PaymentList(
    $check_number: String
    $application_customer_id: String
    $application_tour_id: String
    $paymentdocstatus_id: String
    $paymentmethod_id: String
    $_balanceinit: Float
    $name: String
    $amount: Float
    $_depositedcheck: Int
    $_multidate: Int
    $_multidate1: String
    $_multidate2: String
    $_multidateField: String
    $id: ID
    $_qlType: String
    $_filter: String
    $_orders: String
  ) {
    payments(
      check_number: $check_number
      application_customer_id: $application_customer_id
      application_tour_id: $application_tour_id
      paymentdocstatus_id: $paymentdocstatus_id
      paymentmethod_id: $paymentmethod_id
      _balanceinit: $_balanceinit
      name: $name
      amount: $amount
      _depositedcheck: $_depositedcheck
      _multidate: $_multidate
      _multidate1: $_multidate1
      _multidate2: $_multidate2
      _multidateField: $_multidateField
      id: $id
      _qlType: $_qlType
      _filter: $_filter
      _orders: $_orders
    ) {
      id
      name
      amount
      datereception
      datedeferred
      dateaccounting
      bank_id
      info
    }
  }
`;
defQls['payment']['List'] = gql`
        query PaymentList ($check_number: String, $application_customer_id: String, $application_tour_id: String, $paymentdocstatus_id: String, $paymentmethod_id: String, $_balanceinit: Float, $name: String, $amount: Float, $_depositedcheck: Int, $_multidate: Int, $_multidate1: String, $_multidate2: String, $_multidateField: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          payments  (check_number: $check_number, application_customer_id: $application_customer_id, application_tour_id: $application_tour_id, paymentdocstatus_id: $paymentdocstatus_id, paymentmethod_id: $paymentmethod_id, _balanceinit: $_balanceinit, name: $name, amount: $amount, _depositedcheck: $_depositedcheck, _multidate: $_multidate, _multidate1: $_multidate1, _multidate2: $_multidate2, _multidateField: $_multidateField, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.payment}
          }    
        }
        `;
defQls['payment']['ListPage'] = gql`
  ${strQls.payment.ListPage}
`;

defQls['payment']['View'] = gql`
  query PaymentView($id: ID!) {
    payment(id: $id) {
      id
      name
      paymentmethod_id
      amount
      datereception
      datedeferred
      dateaccounting
      bank_id
      paymentdocstatus_id
      application
      balance
      info
      checkdeposit_id
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
      paymentbank_id {
        name
      }
      paymentpaymentmethod_id {
        name
        color
        statusbase
        usedatedeferred
      }
      paymentcheckdeposit_id {
        name
      }
      paymentpaymentdocstatus_id {
        name
        color
        statusbase
      }
    }
  }
`;
defQls['payment']['Update'] = gql`
  mutation paymentUpdate(
    $id: ID!
    $name: String
    $paymentmethod_id: String
    $amount: Float
    $datereception: String
    $datedeferred: String
    $dateaccounting: String
    $bank_id: String
    $paymentdocstatus_id: String
    $application: String
    $aentry: String
    $info: String
  ) {
    paymentUpdate(
      id: $id
      name: $name
      paymentmethod_id: $paymentmethod_id
      amount: $amount
      datereception: $datereception
      datedeferred: $datedeferred
      dateaccounting: $dateaccounting
      bank_id: $bank_id
      paymentdocstatus_id: $paymentdocstatus_id
      application: $application
      aentry: $aentry
      info: $info
    ) {
      id
      name
      paymentmethod_id
      amount
      datereception
      datedeferred
      dateaccounting
      bank_id
      paymentdocstatus_id
      application
      aentry
      balance
      info
      checkdeposit_id
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
    }
  }
`;
defQls['payment']['Delete'] = gql`
  mutation paymentDelete($id: ID!) {
    paymentDelete(id: $id) {
      id
      name
      paymentmethod_id
      amount
      datereception
      datedeferred
      dateaccounting
      bank_id
      paymentdocstatus_id
      application
      aentry
      balance
      info
      checkdeposit_id
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
    }
  }
`;
defQls['payment']['Add'] = gql`
  mutation paymentAdd(
    $id: ID!
    $name: String
    $paymentmethod_id: String
    $amount: Float
    $datereception: String
    $datedeferred: String
    $dateaccounting: String
    $bank_id: String
    $paymentdocstatus_id: String
    $application: String
    $aentry: String
    $info: String
  ) {
    paymentAdd(
      id: $id
      name: $name
      paymentmethod_id: $paymentmethod_id
      amount: $amount
      datereception: $datereception
      datedeferred: $datedeferred
      dateaccounting: $dateaccounting
      bank_id: $bank_id
      paymentdocstatus_id: $paymentdocstatus_id
      application: $application
      aentry: $aentry
      info: $info
    ) {
      id
      name
      paymentmethod_id
      amount
      datereception
      datedeferred
      dateaccounting
      bank_id
      paymentdocstatus_id
      application
      aentry
      balance
      info
      checkdeposit_id
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
    }
  }
`;
// only defined one time, is going to insert globallyl in defQks.js
// then don't need for checkdepositdetList that use
defQls['setCheckDepositedDet'] = gql`
  mutation setCheckDepositedDet(
    $payments: String
    $source: String
    $checkdeposithead_id: String
  ) {
    setCheckDepositedDet(
      payments: $payments
      source: $source
      checkdeposithead_id: $checkdeposithead_id
    ) {
      answer
      errors {
        key
        value
      }
    }
  }
`;

defQls['checkdeposithead'] = {};

defQls['checkdeposithead']['ListMini'] = gql`
  query CheckdepositheadList(
    $name: String
    $id: ID
    $_qlType: String
    $_filter: String
  ) {
    checkdepositheads(
      name: $name
      id: $id
      _qlType: $_qlType
      _filter: $_filter
    ) {
      id
      name
      statusbase
    }
  }
`;
defQls['checkdeposithead']['List'] = gql`
        query CheckdepositheadList ($name: String, $id: ID, $_qlType: String, $_filter: String) {
          checkdepositheads  (name: $name, id: $id, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.checkdeposithead}
          }    
        }
        `;
defQls['checkdeposithead']['ListPage'] = gql`
  ${strQls.checkdeposithead.ListPage}
`;

defQls['checkdeposithead']['View'] = gql`
  query CheckdepositheadView($id: ID!) {
    checkdeposithead(id: $id) {
      id
      name
      statusbase
      date
      dateaccounting
      info
      lastcheckdepositopen
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      checkdepositheadupdated_user_id {
        name
      }
      checkdepositheadcreated_user_id {
        name
      }
    }
  }
`;
defQls['checkdeposithead']['Update'] = gql`
  mutation checkdepositheadUpdate(
    $id: ID!
    $name: String
    $statusbase: Int
    $date: String
    $dateaccounting: String
    $info: String
  ) {
    checkdepositheadUpdate(
      id: $id
      name: $name
      statusbase: $statusbase
      date: $date
      dateaccounting: $dateaccounting
      info: $info
    ) {
      id
      name
      statusbase
      date
      dateaccounting
      info
      lastcheckdepositopen
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['checkdeposithead']['Delete'] = gql`
  mutation checkdepositheadDelete($id: ID!) {
    checkdepositheadDelete(id: $id) {
      id
      name
      statusbase
      date
      dateaccounting
      info
      lastcheckdepositopen
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['checkdeposithead']['Add'] = gql`
  mutation checkdepositheadAdd(
    $id: ID!
    $name: String
    $statusbase: Int
    $date: String
    $dateaccounting: String
    $info: String
  ) {
    checkdepositheadAdd(
      id: $id
      name: $name
      statusbase: $statusbase
      date: $date
      dateaccounting: $dateaccounting
      info: $info
    ) {
      id
      name
      statusbase
      date
      dateaccounting
      info
      lastcheckdepositopen
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['checkdepositdet'] = {};

defQls['checkdepositdet']['ListMini'] = gql`
  query CheckdepositdetList(
    $checkdeposithead_id: String
    $id: ID
    $_qlType: String
    $_filter: String
    $_orders: String
  ) {
    checkdepositdets(
      checkdeposithead_id: $checkdeposithead_id
      id: $id
      _qlType: $_qlType
      _filter: $_filter
      _orders: $_orders
    ) {
      id
      checkdepositdetpayment_id {
        name
        datereception
        datedeferred
        dateaccounting
        bank_id
        info
        amount
      }
    }
  }
`;
defQls['checkdepositdet']['List'] = gql`
        query CheckdepositdetList ($checkdeposithead_id: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          checkdepositdets  (checkdeposithead_id: $checkdeposithead_id, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.checkdepositdet}
          }    
        }
        `;
defQls['checkdepositdet']['ListPage'] = gql`
  ${strQls.checkdepositdet.ListPage}
`;

defQls['checkdepositdet']['View'] = gql`
  query CheckdepositdetView($id: ID!) {
    checkdepositdet(id: $id) {
      id
      payment_id
      checkdeposithead_id
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      checkdepositdetpayment_id {
        name
        datereception
        datedeferred
        dateaccounting
        bank_id
        paymentbank_id {
          name
        }
        info
        amount
      }
      checkdepositdetcheckdeposithead_id {
        name
      }
      checkdepositdetupdated_user_id {
        name
      }
      checkdepositdetcreated_user_id {
        name
      }
    }
  }
`;
defQls['checkdepositdet']['Delete'] = gql`
  mutation checkdepositdetDelete($id: ID!) {
    checkdepositdetDelete(id: $id) {
      id
      payment_id
      checkdeposithead_id
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['export'] = {};

defQls['export']['ListMini'] = gql`
  query ExportList($id: ID, $name: String, $_qlType: String, $_filter: String) {
    exports(id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
      id
      name
      state
    }
  }
`;
defQls['export']['List'] = gql`
        query ExportList ($id: ID, $name: String, $_qlType: String, $_filter: String) {
          exports  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.export}
          }    
        }
        `;
defQls['export']['ListPage'] = gql`
  ${strQls.export.ListPage}
`;

defQls['export']['View'] = gql`
  query ExportView($id: ID!) {
    export(id: $id) {
      id
      name
      state
      exportdate
      files
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      exportupdated_user_id {
        name
      }
      exportcreated_user_id {
        name
      }
    }
  }
`;
defQls['export']['Update'] = gql`
  mutation exportUpdate(
    $id: ID!
    $name: String!
    $state: Int
    $exportdate: String
    $files: String
  ) {
    exportUpdate(
      id: $id
      name: $name
      state: $state
      exportdate: $exportdate
      files: $files
    ) {
      id
      name
      state
      exportdate
      files
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['export']['Delete'] = gql`
  mutation exportDelete($id: ID!) {
    exportDelete(id: $id) {
      id
      name
      state
      exportdate
      files
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['export']['Add'] = gql`
  mutation exportAdd(
    $id: ID!
    $name: String!
    $state: Int
    $exportdate: String
    $files: String
  ) {
    exportAdd(
      id: $id
      name: $name
      state: $state
      exportdate: $exportdate
      files: $files
    ) {
      id
      name
      state
      exportdate
      files
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['hotel'] = {};

defQls['hotel']['ListMini'] = gql`
  query HotelList($id: ID, $name: String, $_qlType: String, $_filter: String) {
    hotels(id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
      id
      name
    }
  }
`;
defQls['hotel']['List'] = gql`
        query HotelList ($id: ID, $name: String, $_qlType: String, $_filter: String) {
          hotels  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.hotel}
          }    
        }
        `;
defQls['hotel']['ListPage'] = gql`
  ${strQls.hotel.ListPage}
`;

defQls['hotel']['View'] = gql`
  query HotelView($id: ID!) {
    hotel(id: $id) {
      id
      name
      longname
      email
      phone
      tour_id
      info
      contacts
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      hoteltour_id {
        name
      }
      hotelupdated_user_id {
        name
      }
      hotelcreated_user_id {
        name
      }
    }
  }
`;
defQls['hotel']['Update'] = gql`
  mutation hotelUpdate(
    $id: ID!
    $name: String
    $longname: String
    $email: String
    $phone: String
    $tour_id: String
    $info: String
    $contacts: String
  ) {
    hotelUpdate(
      id: $id
      name: $name
      longname: $longname
      email: $email
      phone: $phone
      tour_id: $tour_id
      info: $info
      contacts: $contacts
    ) {
      id
      name
      longname
      email
      phone
      tour_id
      info
      contacts
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['hotel']['Delete'] = gql`
  mutation hotelDelete($id: ID!) {
    hotelDelete(id: $id) {
      id
      name
      longname
      email
      phone
      tour_id
      info
      contacts
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['hotel']['Add'] = gql`
  mutation hotelAdd(
    $id: ID!
    $name: String
    $longname: String
    $email: String
    $phone: String
    $tour_id: String
    $info: String
    $contacts: String
  ) {
    hotelAdd(
      id: $id
      name: $name
      longname: $longname
      email: $email
      phone: $phone
      tour_id: $tour_id
      info: $info
      contacts: $contacts
    ) {
      id
      name
      longname
      email
      phone
      tour_id
      info
      contacts
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['stopspoint'] = {};

/// -- this is used by  PreRegistrattion.js
defQls['stopspoint']['ListMini'] = gql`
  query StopspointList(
    $id: ID
    $organizer_id: String
    $name: String
    $_qlType: String
    $_filter: String
  ) {
    stopspoints(
      id: $id
      organizer_id: $organizer_id
      name: $name
      _qlType: $_qlType
      _filter: $_filter
    ) {
      id
      name
    }
  }
`;
defQls['stopspoint']['List'] = gql`
        query StopspointList ($id: ID, $organizer_id: String, $name: String, $_qlType: String, $_filter: String) {
          stopspoints  (id: $id, organizer_id: $organizer_id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.stopspoint}
          }    
        }
        `;
defQls['stopspoint']['ListPage'] = gql`
  ${strQls.stopspoint.ListPage}
`;

defQls['stopspoint']['View'] = gql`
  query StopspointView($id: ID!) {
    stopspoint(id: $id) {
      id
      name
      sinfo
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      stopspointupdated_user_id {
        name
      }
      stopspointcreated_user_id {
        name
      }
    }
  }
`;
defQls['stopspoint']['Update'] = gql`
  mutation stopspointUpdate($id: ID!, $name: String, $sinfo: String) {
    stopspointUpdate(id: $id, name: $name, sinfo: $sinfo) {
      id
      name
      sinfo
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['stopspoint']['Delete'] = gql`
  mutation stopspointDelete($id: ID!) {
    stopspointDelete(id: $id) {
      id
      name
      sinfo
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['stopspoint']['Add'] = gql`
  mutation stopspointAdd($id: ID!, $name: String, $sinfo: String) {
    stopspointAdd(id: $id, name: $name, sinfo: $sinfo) {
      id
      name
      sinfo
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['service'] = {};

defQls['service']['ListMini'] = gql`
  query ServiceList(
    $id: ID
    $name: String
    $_qlType: String
    $_filter: String
  ) {
    services(id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
      id
      name
    }
  }
`;
defQls['service']['List'] = gql`
        query ServiceList ($id: ID, $name: String, $_qlType: String, $_filter: String) {
          services  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.service}
          }    
        }
        `;
defQls['service']['ListPage'] = gql`
  ${strQls.service.ListPage}
`;

defQls['service']['View'] = gql`
  query ServiceView($id: ID!) {
    service(id: $id) {
      id
      name
      statusbase
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      serviceupdated_user_id {
        name
      }
      servicecreated_user_id {
        name
      }
    }
  }
`;
defQls['service']['Update'] = gql`
  mutation serviceUpdate($id: ID!, $name: String!, $statusbase: Int) {
    serviceUpdate(id: $id, name: $name, statusbase: $statusbase) {
      id
      name
      statusbase
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['service']['Delete'] = gql`
  mutation serviceDelete($id: ID!) {
    serviceDelete(id: $id) {
      id
      name
      statusbase
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['service']['Add'] = gql`
  mutation serviceAdd($id: ID!, $name: String!, $statusbase: Int) {
    serviceAdd(id: $id, name: $name, statusbase: $statusbase) {
      id
      name
      statusbase
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['city'] = {};

defQls['city']['ListMini'] = gql`
  query CityList(
    $name: String
    $id: ID
    $_qlType: String
    $_filter: String
    $_orders: String
  ) {
    citys(
      name: $name
      id: $id
      _qlType: $_qlType
      _filter: $_filter
      _orders: $_orders
    ) {
      id
      name
      pcs
    }
  }
`;
defQls['city']['List'] = gql`
        query CityList ($name: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          citys  (name: $name, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.city}
          }    
        }
        `;
defQls['city']['ListPage'] = gql`
  ${strQls.city.ListPage}
`;

defQls['city']['View'] = gql`
  query CityView($id: ID!) {
    city(id: $id) {
      id
      name
      pcs
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      cityupdated_user_id {
        name
      }
      citycreated_user_id {
        name
      }
    }
  }
`;
defQls['city']['Update'] = gql`
  mutation cityUpdate($id: ID!, $name: String, $pcs: String) {
    cityUpdate(id: $id, name: $name, pcs: $pcs) {
      id
      name
      pcs
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['city']['Delete'] = gql`
  mutation cityDelete($id: ID!) {
    cityDelete(id: $id) {
      id
      name
      pcs
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['city']['Add'] = gql`
  mutation cityAdd($id: ID!, $name: String, $pcs: String) {
    cityAdd(id: $id, name: $name, pcs: $pcs) {
      id
      name
      pcs
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['registrationstatus'] = {};

defQls['registrationstatus']['ListMini'] = gql`
  query RegistrationstatusList(
    $id: ID
    $name: String
    $_qlType: String
    $_filter: String
  ) {
    registrationstatuss(
      id: $id
      name: $name
      _qlType: $_qlType
      _filter: $_filter
    ) {
      id
      name
      statusbase
      color
    }
  }
`;
defQls['registrationstatus']['List'] = gql`
        query RegistrationstatusList ($id: ID, $name: String, $_qlType: String, $_filter: String) {
          registrationstatuss  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.registrationstatus}
          }    
        }
        `;
defQls['registrationstatus']['ListPage'] = gql`
  ${strQls.registrationstatus.ListPage}
`;

defQls['registrationstatus']['View'] = gql`
  query RegistrationstatusView($id: ID!) {
    registrationstatus(id: $id) {
      id
      name
      statusbase
      color
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      registrationstatusupdated_user_id {
        name
      }
      registrationstatuscreated_user_id {
        name
      }
    }
  }
`;
defQls['registrationstatus']['Update'] = gql`
  mutation registrationstatusUpdate(
    $id: ID!
    $name: String!
    $statusbase: Int
    $color: String
  ) {
    registrationstatusUpdate(
      id: $id
      name: $name
      statusbase: $statusbase
      color: $color
    ) {
      id
      name
      statusbase
      color
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['registrationstatus']['Delete'] = gql`
  mutation registrationstatusDelete($id: ID!) {
    registrationstatusDelete(id: $id) {
      id
      name
      statusbase
      color
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['registrationstatus']['Add'] = gql`
  mutation registrationstatusAdd(
    $id: ID!
    $name: String!
    $statusbase: Int
    $color: String
  ) {
    registrationstatusAdd(
      id: $id
      name: $name
      statusbase: $statusbase
      color: $color
    ) {
      id
      name
      statusbase
      color
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['confirmationstatus'] = {};

defQls['confirmationstatus']['ListMini'] = gql`
  query ConfirmationstatusList(
    $id: ID
    $name: String
    $_qlType: String
    $_filter: String
  ) {
    confirmationstatuss(
      id: $id
      name: $name
      _qlType: $_qlType
      _filter: $_filter
    ) {
      id
      name
      statusbase
      color
    }
  }
`;
defQls['confirmationstatus']['List'] = gql`
        query ConfirmationstatusList ($id: ID, $name: String, $_qlType: String, $_filter: String) {
          confirmationstatuss  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.confirmationstatus}
          }    
        }
        `;
defQls['confirmationstatus']['ListPage'] = gql`
  ${strQls.confirmationstatus.ListPage}
`;

defQls['confirmationstatus']['View'] = gql`
  query ConfirmationstatusView($id: ID!) {
    confirmationstatus(id: $id) {
      id
      name
      statusbase
      color
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      confirmationstatusupdated_user_id {
        name
      }
      confirmationstatuscreated_user_id {
        name
      }
    }
  }
`;
defQls['confirmationstatus']['Update'] = gql`
  mutation confirmationstatusUpdate(
    $id: ID!
    $name: String!
    $statusbase: Int
    $color: String
  ) {
    confirmationstatusUpdate(
      id: $id
      name: $name
      statusbase: $statusbase
      color: $color
    ) {
      id
      name
      statusbase
      color
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['confirmationstatus']['Delete'] = gql`
  mutation confirmationstatusDelete($id: ID!) {
    confirmationstatusDelete(id: $id) {
      id
      name
      statusbase
      color
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['confirmationstatus']['Add'] = gql`
  mutation confirmationstatusAdd(
    $id: ID!
    $name: String!
    $statusbase: Int
    $color: String
  ) {
    confirmationstatusAdd(
      id: $id
      name: $name
      statusbase: $statusbase
      color: $color
    ) {
      id
      name
      statusbase
      color
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['bank'] = {};

defQls['bank']['ListMini'] = gql`
  query BankList($id: ID, $name: String, $_qlType: String, $_filter: String) {
    banks(id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
      id
      name
    }
  }
`;
defQls['bank']['List'] = gql`
        query BankList ($id: ID, $name: String, $_qlType: String, $_filter: String) {
          banks  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.bank}
          }    
        }
        `;
defQls['bank']['ListPage'] = gql`
  ${strQls.bank.ListPage}
`;

defQls['bank']['View'] = gql`
  query BankView($id: ID!) {
    bank(id: $id) {
      id
      name
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      bankupdated_user_id {
        name
      }
      bankcreated_user_id {
        name
      }
    }
  }
`;
defQls['bank']['Update'] = gql`
  mutation bankUpdate($id: ID!, $name: String!) {
    bankUpdate(id: $id, name: $name) {
      id
      name
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['bank']['Delete'] = gql`
  mutation bankDelete($id: ID!) {
    bankDelete(id: $id) {
      id
      name
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['bank']['Add'] = gql`
  mutation bankAdd($id: ID!, $name: String!) {
    bankAdd(id: $id, name: $name) {
      id
      name
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['paymentmethod'] = {};

defQls['paymentmethod']['ListMini'] = gql`
  query PaymentmethodList(
    $id: ID
    $name: String
    $_qlType: String
    $_filter: String
  ) {
    paymentmethods(id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
      id
      name
      statusbase
      usedatedeferred
      anature
    }
  }
`;
defQls['paymentmethod']['List'] = gql`
        query PaymentmethodList ($id: ID, $name: String, $_qlType: String, $_filter: String) {
          paymentmethods  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.paymentmethod}
          }    
        }
        `;
defQls['paymentmethod']['ListPage'] = gql`
  ${strQls.paymentmethod.ListPage}
`;

defQls['paymentmethod']['View'] = gql`
  query PaymentmethodView($id: ID!) {
    paymentmethod(id: $id) {
      id
      name
      statusbase
      color
      usedatedeferred
      ajournalcode_id
      anature
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      paymentmethodajournalcode_id {
        name
        title
        counterpart_id
        ajournalcodecounterpart_id {
          name
        }
      }
      paymentmethodupdated_user_id {
        name
      }
      paymentmethodcreated_user_id {
        name
      }
    }
  }
`;
defQls['paymentmethod']['Update'] = gql`
  mutation paymentmethodUpdate(
    $id: ID!
    $name: String!
    $statusbase: Int
    $color: String
    $usedatedeferred: Boolean
    $ajournalcode_id: String
    $anature: String
  ) {
    paymentmethodUpdate(
      id: $id
      name: $name
      statusbase: $statusbase
      color: $color
      usedatedeferred: $usedatedeferred
      ajournalcode_id: $ajournalcode_id
      anature: $anature
    ) {
      id
      name
      statusbase
      color
      usedatedeferred
      ajournalcode_id
      anature
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['paymentmethod']['Delete'] = gql`
  mutation paymentmethodDelete($id: ID!) {
    paymentmethodDelete(id: $id) {
      id
      name
      statusbase
      color
      usedatedeferred
      ajournalcode_id
      anature
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['paymentmethod']['Add'] = gql`
  mutation paymentmethodAdd(
    $id: ID!
    $name: String!
    $statusbase: Int
    $color: String
    $usedatedeferred: Boolean
    $ajournalcode_id: String
    $anature: String
  ) {
    paymentmethodAdd(
      id: $id
      name: $name
      statusbase: $statusbase
      color: $color
      usedatedeferred: $usedatedeferred
      ajournalcode_id: $ajournalcode_id
      anature: $anature
    ) {
      id
      name
      statusbase
      color
      usedatedeferred
      ajournalcode_id
      anature
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['paymentdocstatus'] = {};

defQls['paymentdocstatus']['ListMini'] = gql`
  query PaymentdocstatusList(
    $id: ID
    $name: String
    $_qlType: String
    $_filter: String
  ) {
    paymentdocstatuss(
      id: $id
      name: $name
      _qlType: $_qlType
      _filter: $_filter
    ) {
      id
      name
      statusbase
      color
    }
  }
`;
defQls['paymentdocstatus']['List'] = gql`
        query PaymentdocstatusList ($id: ID, $name: String, $_qlType: String, $_filter: String) {
          paymentdocstatuss  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.paymentdocstatus}
          }    
        }
        `;
defQls['paymentdocstatus']['ListPage'] = gql`
  ${strQls.paymentdocstatus.ListPage}
`;

defQls['paymentdocstatus']['View'] = gql`
  query PaymentdocstatusView($id: ID!) {
    paymentdocstatus(id: $id) {
      id
      name
      statusbase
      color
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      paymentdocstatusupdated_user_id {
        name
      }
      paymentdocstatuscreated_user_id {
        name
      }
    }
  }
`;
defQls['paymentdocstatus']['Update'] = gql`
  mutation paymentdocstatusUpdate(
    $id: ID!
    $name: String!
    $statusbase: Int
    $color: String
  ) {
    paymentdocstatusUpdate(
      id: $id
      name: $name
      statusbase: $statusbase
      color: $color
    ) {
      id
      name
      statusbase
      color
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['paymentdocstatus']['Delete'] = gql`
  mutation paymentdocstatusDelete($id: ID!) {
    paymentdocstatusDelete(id: $id) {
      id
      name
      statusbase
      color
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['paymentdocstatus']['Add'] = gql`
  mutation paymentdocstatusAdd(
    $id: ID!
    $name: String!
    $statusbase: Int
    $color: String
  ) {
    paymentdocstatusAdd(
      id: $id
      name: $name
      statusbase: $statusbase
      color: $color
    ) {
      id
      name
      statusbase
      color
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['customerstatus'] = {};

defQls['customerstatus']['ListMini'] = gql`
  query CustomerstatusList(
    $id: ID
    $organizer_id: String
    $name: String
    $_qlType: String
    $_filter: String
    $_orders: String
  ) {
    customerstatuss(
      id: $id
      organizer_id: $organizer_id
      name: $name
      _qlType: $_qlType
      _filter: $_filter
      _orders: $_orders
    ) {
      id
      name
      substatus
    }
  }
`;
defQls['customerstatus']['List'] = gql`
        query CustomerstatusList ($id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          customerstatuss  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.customerstatus}
          }    
        }
        `;
defQls['customerstatus']['ListPage'] = gql`
  ${strQls.customerstatus.ListPage}
`;

defQls['customerstatus']['View'] = gql`
  query CustomerstatusView($id: ID!) {
    customerstatus(id: $id) {
      id
      name
      substatus
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      customerstatusupdated_user_id {
        name
      }
      customerstatuscreated_user_id {
        name
      }
    }
  }
`;
defQls['gendertype'] = {};

defQls['gendertype']['ListMini'] = gql`
  query GendertypeList(
    $id: ID
    $name: String
    $_qlType: String
    $_filter: String
  ) {
    gendertypes(id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
      id
      name
    }
  }
`;
defQls['gendertype']['List'] = gql`
        query GendertypeList ($id: ID, $name: String, $_qlType: String, $_filter: String) {
          gendertypes  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.gendertype}
          }    
        }
        `;
defQls['gendertype']['ListPage'] = gql`
  ${strQls.gendertype.ListPage}
`;

defQls['gendertype']['View'] = gql`
  query GendertypeView($id: ID!) {
    gendertype(id: $id) {
      id
      name
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      gendertypeupdated_user_id {
        name
      }
      gendertypecreated_user_id {
        name
      }
    }
  }
`;
defQls['gendertype']['Update'] = gql`
  mutation gendertypeUpdate($id: ID!, $name: String) {
    gendertypeUpdate(id: $id, name: $name) {
      id
      name
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['gendertype']['Delete'] = gql`
  mutation gendertypeDelete($id: ID!) {
    gendertypeDelete(id: $id) {
      id
      name
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['gendertype']['Add'] = gql`
  mutation gendertypeAdd($id: ID!, $name: String) {
    gendertypeAdd(id: $id, name: $name) {
      id
      name
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['userlog'] = {};

defQls['userlog']['ListMini'] = gql`
  query UserlogList(
    $user_id: String
    $action: String
    $id: ID
    $name: String
    $_qlType: String
    $_filter: String
  ) {
    userlogs(
      user_id: $user_id
      action: $action
      id: $id
      name: $name
      _qlType: $_qlType
      _filter: $_filter
    ) {
      id
      name
    }
  }
`;
defQls['userlog']['List'] = gql`
        query UserlogList ($user_id: String, $action: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          userlogs  (user_id: $user_id, action: $action, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.userlog}
          }    
        }
        `;
defQls['userlog']['ListPage'] = gql`
  ${strQls.userlog.ListPage}
`;

defQls['userlog']['View'] = gql`
  query UserlogView($id: ID!) {
    userlog(id: $id) {
      id
      name
      actiondate
      user_id
      record_id
      record_parentid
      record_table
      action
      ip
      userloguser_id {
        name
      }
    }
  }
`;
defQls['user'] = {};

defQls['user']['ListMini'] = gql`
  query UserList(
    $nick: String
    $longname: String
    $id: ID
    $name: String
    $_qlType: String
    $_filter: String
  ) {
    users(
      nick: $nick
      longname: $longname
      id: $id
      name: $name
      _qlType: $_qlType
      _filter: $_filter
    ) {
      id
      name
    }
  }
`;
defQls['user']['List'] = gql`
        query UserList ($nick: String, $longname: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          users  (nick: $nick, longname: $longname, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.user}
          }    
        }
        `;
defQls['user']['ListPage'] = gql`
  ${strQls.user.ListPage}
`;

defQls['user']['View'] = gql`
  query UserView($id: ID!) {
    user(id: $id) {
      id
      nick
      password
      name
      longname
      email
      isadmin
      usergroup_id
      lastaccessdate
      lastactiondate
      countactionsmonth
      phones
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      userusergroup_id {
        name
      }
    }
  }
`;
defQls['user']['Update'] = gql`
  mutation userUpdate(
    $id: ID!
    $nick: String
    $password: String
    $name: String
    $longname: String
    $email: String
    $usergroup_id: String
    $phones: String
  ) {
    userUpdate(
      id: $id
      nick: $nick
      password: $password
      name: $name
      longname: $longname
      email: $email
      usergroup_id: $usergroup_id
      phones: $phones
    ) {
      id
      nick
      password
      name
      longname
      email
      isadmin
      usergroup_id
      lastaccessdate
      lastactiondate
      countactionsmonth
      phones
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['user']['Add'] = gql`
  mutation userAdd(
    $id: ID!
    $nick: String
    $password: String
    $name: String
    $longname: String
    $email: String
    $usergroup_id: String
    $phones: String
  ) {
    userAdd(
      id: $id
      nick: $nick
      password: $password
      name: $name
      longname: $longname
      email: $email
      usergroup_id: $usergroup_id
      phones: $phones
    ) {
      id
      nick
      password
      name
      longname
      email
      isadmin
      usergroup_id
      lastaccessdate
      lastactiondate
      countactionsmonth
      phones
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['usergroup'] = {};

defQls['usergroup']['ListMini'] = gql`
  query UsergroupList(
    $id: ID
    $name: String
    $_qlType: String
    $_filter: String
  ) {
    usergroups(id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
      id
      name
    }
  }
`;
defQls['usergroup']['List'] = gql`
        query UsergroupList ($id: ID, $name: String, $_qlType: String, $_filter: String) {
          usergroups  (id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.usergroup}
          }    
        }
        `;
defQls['usergroup']['ListPage'] = gql`
  ${strQls.usergroup.ListPage}
`;

defQls['usergroup']['View'] = gql`
  query UsergroupView($id: ID!) {
    usergroup(id: $id) {
      id
      name
      accessrestrictedto
      permissions
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      usergroupupdated_user_id {
        name
      }
      usergroupcreated_user_id {
        name
      }
    }
  }
`;
defQls['usergroup']['Update'] = gql`
  mutation usergroupUpdate(
    $id: ID!
    $name: String
    $accessrestrictedto: String
    $permissions: String
  ) {
    usergroupUpdate(
      id: $id
      name: $name
      accessrestrictedto: $accessrestrictedto
      permissions: $permissions
    ) {
      id
      name
      accessrestrictedto
      permissions
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['usergroup']['Add'] = gql`
  mutation usergroupAdd(
    $id: ID!
    $name: String
    $accessrestrictedto: String
    $permissions: String
  ) {
    usergroupAdd(
      id: $id
      name: $name
      accessrestrictedto: $accessrestrictedto
      permissions: $permissions
    ) {
      id
      name
      accessrestrictedto
      permissions
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['ejobmail'] = {};

defQls['ejobmail']['ListMini'] = gql`
  query EjobmailList(
    $title: String
    $customer_id: String
    $tour_id: ID
    $id: ID
    $_qlType: String
    $_filter: String
    $_orders: String
  ) {
    ejobmails(
      title: $title
      customer_id: $customer_id
      tour_id: $tour_id
      id: $id
      _qlType: $_qlType
      _filter: $_filter
      _orders: $_orders
    ) {
      id
      state
      ejobmailcustomer_id {
        name
      }
      ejobmailtour_id {
        name
      }
      ejobmailetemplate_id {
        name
      }
    }
  }
`;
defQls['ejobmail']['List'] = gql`
        query EjobmailList ($title: String, $customer_id: String, $tour_id: ID, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          ejobmails  (title: $title, customer_id: $customer_id, tour_id: $tour_id, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.ejobmail}
          }    
        }
        `;
defQls['ejobmail']['ListPage'] = gql`
  ${strQls.ejobmail.ListPage}
`;

defQls['ejobmail']['View'] = gql`
  query EjobmailView($id: ID!) {
    ejobmail(id: $id) {
      id
      customer_id
      channels
      channelsfilters
      etemplate_id
      title
      tour_id
      limitexceeded
      totalMessaging
      confirmationstatus_id
      state
      customersrecipients
      templatedata
      channel
      sent
      templatesms
      sms
      template
      body
      files
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      ejobmailcustomer_id {
        name
      }
      ejobmailtour_id {
        name
      }
      ejobmailetemplate_id {
        name
      }
      ejobmailupdated_user_id {
        name
      }
      ejobmailcreated_user_id {
        name
      }
    }
  }
`;

defQls['person'] = {};

defQls['person']['ListMini'] = gql`
  query PersonList(
    $lastname: String
    $firstname: String
    $addresses_city_id: ID
    $inactive: Boolean
    $id: ID
    $name: String
    $_qlType: String
    $_filter: String
    $_orders: String
  ) {
    persons(
      lastname: $lastname
      firstname: $firstname
      addresses_city_id: $addresses_city_id
      inactive: $inactive
      id: $id
      name: $name
      _qlType: $_qlType
      _filter: $_filter
      _orders: $_orders
    ) {
      id
      name
      email
      groupedaddress_customer_id
      persongendertype_id {
        name
      }
    }
  }
`;
defQls['person']['List'] = gql`
        query PersonList ($lastname: String, $firstname: String, $addresses_city_id: ID, $inactive: Boolean, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          persons  (lastname: $lastname, firstname: $firstname, addresses_city_id: $addresses_city_id, inactive: $inactive, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.person}
          }    
        }
        `;
defQls['person']['ListPage'] = gql`
  ${strQls.person.ListPage}
`;

defQls['person']['View'] = gql`
  query PersonView($id: ID!) {
    person(id: $id) {
      id
      gendertype_id
      name
      lastname
      firstname
      email
      nick
      password
      socialsn
      imageright
      inactive
      deceased
      birthdate
      grouped_customer_id
      grouped_filiation
      info
      nationality
      residence
      contacts
      phones
      groupedaddress_customer_id
      customeraddress_customers
      addresses
      identitydocs
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
      persongrouped_customer_id {
        name
      }
      persongroupedaddress_customer_id {
        name
        addresses
      }
      persongendertype_id {
        name
      }
      personupdated_user_id {
        name
      }
      personcreated_user_id {
        name
      }
      personpending_tourid {
        id
        name
        longname
      }
    }
  }
`;
defQls['person']['Update'] = gql`
  mutation personUpdate(
    $id: ID!
    $gendertype_id: String
    $lastname: String
    $firstname: String
    $email: String
    $nick: String
    $password: String
    $socialsn: String
    $imageright: Int
    $inactive: Boolean
    $deceased: Boolean
    $birthdate: String
    $info: String
    $nationality: String
    $residence: String
    $grouped_customer_id: String
    $grouped_filiation: Int
    $contacts: String
    $phones: String
    $groupedaddress_customer_id: String
    $addresses: String
    $identitydocs: String
  ) {
    personUpdate(
      id: $id
      gendertype_id: $gendertype_id
      lastname: $lastname
      firstname: $firstname
      email: $email
      nick: $nick
      password: $password
      socialsn: $socialsn
      imageright: $imageright
      inactive: $inactive
      deceased: $deceased
      birthdate: $birthdate
      info: $info
      nationality: $nationality
      residence: $residence
      grouped_customer_id: $grouped_customer_id
      grouped_filiation: $grouped_filiation
      contacts: $contacts
      phones: $phones
      groupedaddress_customer_id: $groupedaddress_customer_id
      addresses: $addresses
      identitydocs: $identitydocs
    ) {
      id
      gendertype_id
      name
      lastname
      firstname
      email
      nick
      password
      socialsn
      imageright
      inactive
      deceased
      grouped_customer_id
      grouped_filiation
      birthdate
      info
      nationality
      residence
      contacts
      phones
      groupedaddress_customer_id
      customeraddress_customers
      addresses
      identitydocs
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['person']['Delete'] = gql`
  mutation personDelete($id: ID!) {
    personDelete(id: $id) {
      id
      gendertype_id
      name
      lastname
      firstname
      email
      nick
      password
      socialsn
      imageright
      inactive
      deceased
      birthdate
      info
      nationality
      contacts
      phones
      groupedaddress_customer_id
      customeraddress_customers
      addresses
      identitydocs
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
defQls['person']['Add'] = gql`
  mutation personAdd(
    $id: ID!
    $gendertype_id: String
    $lastname: String
    $firstname: String
    $email: String
    $nick: String
    $password: String
    $socialsn: String
    $imageright: Int
    $inactive: Boolean
    $deceased: Boolean
    $birthdate: String
    $info: String
    $nationality: String
    $residence: String
    $grouped_customer_id: String
    $grouped_filiation: Int
    $contacts: String
    $phones: String
    $groupedaddress_customer_id: String
    $addresses: String
    $identitydocs: String
  ) {
    personAdd(
      id: $id
      gendertype_id: $gendertype_id
      lastname: $lastname
      firstname: $firstname
      email: $email
      nick: $nick
      password: $password
      socialsn: $socialsn
      imageright: $imageright
      inactive: $inactive
      deceased: $deceased
      birthdate: $birthdate
      info: $info
      nationality: $nationality
      residence: $residence
      grouped_customer_id: $grouped_customer_id
      grouped_filiation: $grouped_filiation
      contacts: $contacts
      phones: $phones
      groupedaddress_customer_id: $groupedaddress_customer_id
      addresses: $addresses
      identitydocs: $identitydocs
    ) {
      id
      gendertype_id
      name
      lastname
      firstname
      email
      nick
      password
      socialsn
      imageright
      inactive
      deceased
      grouped_customer_id
      grouped_filiation
      birthdate
      info
      nationality
      contacts
      phones
      groupedaddress_customer_id
      customeraddress_customers
      addresses
      identitydocs
      counterhistory
      updatedAt
      createdAt
      deletedAt
      deleted
      updated_user_id
      created_user_id
      deleted_user_id
    }
  }
`;
export default defQls;
