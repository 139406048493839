import React, { useState, useMemo } from 'react';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';

const ReadOnly = (props) => {
  const [value, setValue] = useState(
    props.textInitValue || [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ]
  );
  const editor = useMemo(() => withReact(createEditor()), []);
  return (
    <Slate editor={editor} value={value} onChange={(value) => setValue(value)}>
      <Editable readOnly placeholder="..." />
    </Slate>
  );
};

export default ReadOnly;
