import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import ShowIcon from '../components/icons/ShowIcon';

const MenuItem = (props) => {
  const label = props.label || '';
  const icon = props.icon || '';
  const currentPath = props.currentPath || '';
  let menuItemTextStyle;
  let menuItem;
  menuItem = {
    display: 'flex',
    flexBasis: '35px',
    paddingLeft: '10px',
  };

  if (!props.menuMini) {
    menuItemTextStyle = {
      fontSize: '15px',
      alignSelf: 'center',
      textDecoration: 'none',
      position: 'relative',
      left: '5px',
      color: props.theme.TextOnBackgroundLight,
    };
  } else {
    menuItem.marginTop = '10px';
    menuItem.paddingLeft = '7px';
    menuItemTextStyle = {
      display: 'none',
    };
  }

  if (props.action) {
    return (
      <div style={menuItem}>
        <a
          onClick={() => props.action(props.actionValue)}
          className="menuItemContainer aExtend"
        >
          <MenuItemIcon {...props} currentPath={currentPath} />
          <div style={menuItemTextStyle}>{label}</div>
        </a>
      </div>
    );
  }
  /*return (
    <div style={menuItem}>
      <Link to={props.path} className="menuItemContainer aExtend">
        <MenuItemIcon {...props} currentPath={currentPath }/>
        <div style = {menuItemTextStyle}>{label}</div>
      </Link>
    </div>
  );*/
  return (
    <div style={menuItem}>
      <a
        onClick={() => props.goUrl(props.path)}
        className="menuItemContainer aExtend"
      >
        <MenuItemIcon {...props} currentPath={currentPath} />
        <div style={menuItemTextStyle}>{label}</div>
      </a>
    </div>
  );
};

const MenuItemIcon = (props) => {
  if (props.icon !== '') {
    let color = '';
    if (props.currentPath === `/person-view/${props.userid}`) {
      /*
      special logic to get color only for 'my profile' and not 'my group' at same time
      both need the parameter 'userid' to realize a what give the color
      */
      if (props.myProfile) color = '#cc5200'; // it's option 'my profile'
    } else {
      if (props.path && props.path === props.currentPath.split('-')[0])
        color = '#cc5200';
    }

    return (
      <div className="menuItemIcon">
        <ShowIcon {...props} color={color} />
      </div>
    );
  }
  return null;
};

export { MenuItem, MenuItemIcon };
