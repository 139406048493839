import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button } from '../../layouts/cssstyled';
import FormField from '../FormField';
import { fieldsValidator } from '../../utils/helper';
import ShowIcon from '../icons/ShowIcon';
import { normalizes } from '../../utils/normalizes';

const nameForm = 'signup3';
const fields = {
  nick: {
    validators: ['required', 'minLength:7', 'maxLength:15'],
  },
  password: {
    validators: ['required', 'password'],
  },
  /*newpass2: {
    validators: ['required', 'password'],
  },

            <Field
            name="newpass2"
            type="text"
            component={FormField}
            label="login.newpassword2"
            validatorRequired="1"
            width="300px"
          />
  */
  firstname: {
    validators: ['required', 'alphai18n', 'minLength:3'],
  },
  lastname: {
    validators: ['required', 'alphai18n', 'minLength:3'],
  },
  birthdate: {
    validators: ['required'],
  },
  nationality: {
    validators: ['required'],
  },
  residence: {
    validators: ['required'],
  },
  imageright: {
    validators: ['required'],
  },
};

const validate = (values, props) => {
  let result = fieldsValidator(fields, values);
  /*if (Object.keys(result).length === 0 ) {
    if (values.newpass !== values.newpass2 ) {
      result.newpass2 = props.t('mobilsemError.newpassDiff');
    }
  }*/
  return result;
};

/*
icon is not ok position left: style={{  position: 'relative', paddingLeft: '35px' }}
 */
class Form extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    /* this.timerHandle = setTimeout(() => {
      this.props.dispatch ( change (nameForm, '_fakefield',  Math.random() ));
      this.props.executeCode ( 'onChangeInput', { nameForm, action: 'initForm' , props: this.props, formProps: this.props, formState: this.props.formState  });
    }, 1000);
    window.addEventListener('message', this.readMessage, true); */
  }

  hocdebounced = debounce((methodCode, params) => {
    this.props.executeCode(methodCode, params);
  }, 1500);

  render() {
    // //console.log('sign in form props');
    // //console.log(props);

    const {
      processing,
      error,
      handleSubmit,
      pristine,
      invalid,
      reset,
      submitting,
      t,
      changeLanguage,
      i18n,
      browserCompatible,
      browserVersionCompatible,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div style={{}}>
          <div className="formSection">
            <Field
              inputName="nick"
              name="nick"
              formProps={this.props}
              pathInTables="person.fields.nick"
              label="login.usercode"
              nameForm="signup3"
              component={FormField}
              type="text"
              normalize={normalizes.LowerCaseTrim}
              typeInput="text"
            />
            <Field
              name="password"
              type="text"
              component={FormField}
              label="form.password"
              sublabel="info.password"
              validatorRequired="1"
              width="300px"
            />
          </div>
          <div className="formSection">
            <Field
              inputName="firstname"
              name="firstname"
              formProps={this.props}
              pathInTables="customer.fields.firstname"
              nameForm="signup3"
              component={FormField}
              type="text"
              normalize={normalizes.StartCase}
              typeInput="text"
            />
            <Field
              inputName="lastname"
              name="lastname"
              formProps={this.props}
              pathInTables="customer.fields.lastname"
              nameForm="signup3"
              component={FormField}
              type="text"
              normalize={normalizes.UpperCase}
              typeInput="text"
            />
          </div>

          <div className="formSection">
            <Field
              inputName="birthdate"
              name="birthdate"
              formProps={this.props}
              pathInTables="person.fields.birthdate"
              nameForm="signup3"
              component={FormField}
              type="text"
              typeInput="text"
            />
            <Field
              inputName="imageright"
              name="imageright"
              formProps={this.props}
              pathInTables="person.fields.imageright"
              nameForm="personForm"
              component={FormField}
              type="selectBox"
              typeInput="selectBox"
              listSource="yesNoEmpty"
            />
          </div>

          <div className="formSection">
            <Field
              inputName="nationality"
              name="nationality"
              formProps={this.props}
              pathInTables="person.fields.nationality"
              formState={this.props.formState}
              nameForm="signup3"
              component={FormField}
              type="selectAutocomplete"
              typeInput="selectAutocomplete"
              listSource="countries"
              executeCode={this.props.executeCode}
              onChange={(event, newValue, previousValue) => {
                //if (this.props.executeCode)
                this.props.executeCode('onChangeInput', {
                  inputFullName: 'nationality',
                  formProps: this.props,
                  formState: this.state,
                  event,
                  newValue,
                  previousValue,
                });
              }}
            />
            <Field
              inputName="residence"
              name="residence"
              formProps={this.props}
              pathInTables="person.fields.residence"
              formState={this.props.formState}
              nameForm="signup3"
              component={FormField}
              type="selectAutocomplete"
              typeInput="selectAutocomplete"
              listSource="countries"
            />
          </div>

          {!processing && browserCompatible && browserVersionCompatible && (
            <div style={{ position: 'relative', margin: 'auto' }}>
              <Button type="submit" disabled={submitting}>
                {t('login.signup')}
              </Button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

const ComponentWithData = reduxForm({
  form: nameForm,
  touchOnChange: true,

  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  const statesReturn = { myState: state };
  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null
)(ComponentWithData);

export default ComponentWithDataAndState;
