import React, { Component } from 'react';
import { Route, Redirect, withRouter, Prompt } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
//import { reset } from 'redux-form';
import { translate } from 'react-i18next';
//import moment from 'moment';
import { checkAuth } from '../utils/auth';
import { fetchQl } from '../apolloClient';
import { ISOtoDate, getTheme, tt } from '../utils/commonutils';
import { getFieldsChanged, uuid } from '../utils/helper';
//import { ISOtoDate, getTheme } from '../utils/commonutils';
import { getSession } from '../utils/auth';
import themes from './themes';
import Layout from './LayoutPage';
import PopupLayout from './LayoutPopup';
import Confirm from '../components/Confirm';
import {
  showNotificationWithTimeout,
  hideNotification,
} from '../actions/notifActions';
import { showConfirm, hideConfirm } from '../actions/confirmActions';
import ErrorBoundary from './ErrorBoundary';
import { Tables } from '../defTables';
//moment.locale('fr');

let theme = getTheme();
const errorDate = localStorage.getItem('ErrorDate');

class LayoutContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopup: this.props.location.search.includes('popup'),
      menuGrow: false,
    };
  }

  goUrl = (
    url = '',
    options = { justCheck: false, checkBoolean: false, callback: null }
  ) => {
    /*
    url can be empty, then just control changes, but not redirect to URL, useful for customs toolbars
    with special logic contrl
     */

    const log = false;
    if (log) console.log('goUrl url /options', url, options);
    if (log) console.log('this.props.myState', this.props.myState);
    if (this.props.myState.form) {
      let listFields = '';
      for (const [keyForm, objForm] of Object.entries(
        this.props.myState.form
      )) {
        if (log) console.log(keyForm, objForm);
        let tableCrud =
          keyForm.substr(-4) === 'Form'
            ? keyForm.substr(0, keyForm.length - 4)
            : '';
        //console.log('tableCrud/anyTouched',  tableCrud ,objForm.anyTouched);
        //Make sure (objForm.values && objForm.initial) are defined, sometimes maybe due redux or loading
        //  slow that variables did not yet texist ... or due type of form name (popup?)
        if (log)
          console.log(
            'objForm.values, objForm.initial',
            objForm.values,
            objForm.initial
          );
        if (
          tableCrud &&
          objForm.anyTouched &&
          objForm.values &&
          objForm.initial
        ) {
          // Due to fakefield is changed always is touched , see if it's need optimize and do'nt use fake values? i think is not really slow and it's more organzer let it like this
          listFields = getFieldsChanged(objForm, keyForm, this.props.t);
          if (log) console.log('objForm.anyTouched , changes:', listFields);
          if (listFields) break;
        }
      }
      if (options.justCheck) {
        // TWIN CF029
        return options.checkBoolean ? (listFields ? true : false) : listFields;
      }
      if (listFields) {
        /*if(!window.confirm( tt(this.props.t,'form.goWithoutSave::'+listFields  ))) {
          return false;
        }*/

        this.props.showConfirm({
          id: uuid(),
          type: 'confirmation',
          text: tt(this.props.t, 'form.goWithoutSave::' + listFields),
          buttons: [
            {
              label: this.props.t('info.vChangeWithoutSave'),
              onClick: () => {
                if (options.callback) {
                  options.callback();
                } else if (url) {
                  this.props.history.push(url);
                }
              },
            },
            {
              label: this.props.t('info.vComeBack'),
              onClick: () => null,
            },
          ],
        });
        return true;
      } else {
        // if there no changes, go direct to url
        if (options.callback) {
          options.callback();
        } else if (url) {
          if (url.includes('http')) {
            window.location.replace(url); // external site
          } else {
            this.props.history.push(url); // internal site
          }
        }
      }
    } else {
      if (options.justCheck) {
        // TWIN CF029
        return false;
      }
    }
    //if (url) this.props.history.push(url);
    //return true;
  };
  render() {
    //console.log('layout contianer props', this.props);
    // const mystore = store.getState();
    /* //console.log('---- redux notifications');
     //console.log(this.props.notifications); */
    const changeLanguage = (lng) => {
      this.props.i18n.changeLanguage(lng);
    };
    let session = getSession();

    const { t, i18n } = this.props;
    let action = this.props.action;
    // conver to real name in permission, E.g. 'list'(crud) and 'view'(crud) = 'view' (permision)
    if (action === 'list') {
      action = 'view';
    }
    if (this.props.table) {
      const tab = this.props.permissionTable
        ? this.props.permissionTable
        : this.props.table; // real table for permission
      let permissionOk = true;
      if (this.props.ai) {
        if (
          !(
            session.ai ||
            (session.pe && session.pe[tab] && session.pe[tab][action])
          )
        ) {
          permissionOk = false;
        }
      } else {
        if (
          !(
            session.ai ||
            !session.gu ||
            (session.pe[tab] && session.pe[tab][action])
          )
        ) {
          permissionOk = false;
        }
      }
      if (!permissionOk) {
        const nameAccess = t('table.' + tab + 's');
        return (
          <div>
            <div>
              {t(
                'mobilsemError.permission::' +
                  nameAccess +
                  ' > ' +
                  this.props.action
              )}
            </div>
            <div style={{ marginTop: '30px' }}>
              {t('info.vVisit')}:{' '}
              <a style={{ color: '#2269d9' }} href="/dashboard">
                {t('menu.dashboard')}
              </a>
            </div>
          </div>
        );
      }
    }
    const auth = checkAuth(); // need always checout; pour pages public, that needed show the left menu, like '/tour/id' (regonline)
    if (!this.props.public) {
      /* Check auth only for private pages */
      if (!auth) {
        setTimeout(
          () =>
            this.props.showNotificationWithTimeout(
              this.props.t('login.tokenInvalid'),
              3,
              7
            ),
          300
        );
        return <Redirect to="/signin" />;
      }
    }

    let nameApp = 'VOYAGES PELEMOBIL';

    let theme = this.props.themeName;
    const popup = this.props.location.search.includes('popup');

    return (
      <ErrorBoundary>
        <Route
          exact
          path={this.props.path}
          render={(matchProps) => (
            <div>
              <ThemeProvider theme={themes[theme]}>
                <div>
                  {/*
                  // need more sofistication to dont launch two times alert change page
                  // need to activate a global state (redux?) every time a confirm is open
                  // to save the response and read it from there ...
                  // and not possible easy customise .confirm browser from react-router
                   // <Prompt
                    when={ this.goUrl('',{justCheck: true, checkBoolean: true})}
                    message={location =>
                      tt(this.props.t,'form.goWithoutSaveSimple')
                    }
                  />*/}
                  {!(popup || this.state.isPopup) && (
                    <Layout
                      nameApp={nameApp}
                      i18n={i18n}
                      t={t}
                      auth={auth}
                      session={session}
                      goUrl={this.goUrl}
                      showNotificationWithTimeout={
                        this.props.showNotificationWithTimeout
                      }
                      hideNotification={this.props.hideNotification}
                      notifications={this.props.notifications}
                      public={this.props.public}
                      changeLanguage={changeLanguage}
                      title={this.props.title}
                      theme={themes[theme]}
                      component={this.props.component}
                      myState={this.props.myState}
                      {...matchProps}
                    />
                  )}
                  {(popup || this.state.isPopup) && (
                    <PopupLayout
                      nameApp="VOYAGES"
                      i18n={i18n}
                      t={t}
                      goUrl={this.goUrl}
                      showNotificationWithTimeout={
                        this.props.showNotificationWithTimeout
                      }
                      hideNotification={this.props.hideNotification}
                      notifications={this.props.notifications}
                      public={this.props.public}
                      changeLanguage={changeLanguage}
                      title={this.props.title}
                      theme={themes[theme]}
                      component={this.props.component}
                      myState={this.props.myState}
                      {...matchProps}
                    />
                  )}
                  <div
                    style={{
                      textAlign: 'right',
                      color: '#888888',
                      paddingRight: '20px',
                    }}
                  >
                    Version: <strong>{process.env.REACT_APP_VERSION}</strong>{' '}
                    {ISOtoDate(process.env.REACT_APP_DATE)}
                    <br />
                    <br />
                  </div>
                  {this.props.confirms.length > 0 && (
                    <div key="confirm " className="appHeaderNotif">
                      {this.props.confirms.map((confirm) => {
                        return (
                          <Confirm
                            hideConfirm={this.props.hideConfirm}
                            key={confirm.obj.id}
                            confirm={confirm}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </ThemeProvider>
            </div>
          )}
        />
      </ErrorBoundary>
    );
  }
}

const LayoutContainerWithRouter = withRouter(LayoutContainer);
const mapStateToProps = (state) => ({
  themeName: state.app.themeName,
  toasts: state.toasts,
  notifications: state.notifications,
  confirms: state.confirms,
  myState: state,
});

const App = connect(mapStateToProps, {
  showNotificationWithTimeout,
  hideNotification,
  showConfirm,
  hideConfirm,
})(LayoutContainerWithRouter);

export default translate('translations')(App);
