import { resolvePathObj } from '../../utils/commonutils';

export const listAssociatedOptions = (props) => {
  // block for dont include same person id on the related id field
  // TWIN PER2010
  let grouped_customer_id_listOptions = []; // list de person 'associed to'
  let groupedaddress_customer_id_listOptions = []; // list de person 'address associed to'

  const aPersons = resolvePathObj(props, 'list_person.persons', {
    notFound: [],
  });
  aPersons.map((person) => {
    if (person.id !== props.match.params.id) {
      // dont include same id

      grouped_customer_id_listOptions.push(person);
      if (!person.groupedaddress_customer_id) {
        // just include persons with no associated address
        groupedaddress_customer_id_listOptions.push(person);
      }
    }
  });
  return {
    grouped_customer_id_listOptions,
    groupedaddress_customer_id_listOptions,
  };
};
