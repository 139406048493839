import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { reducer as formReducer } from "redux-form";
import createSagaMiddleware from "redux-saga";
import { getSession } from "./utils/auth";

import { Provider } from "react-redux";
import { ApolloProvider } from "react-apollo";

import { resolvePathObj, processValues } from "./utils/commonutils";
import ErrorBoundary from "./layouts/ErrorBoundary";
import createClient from "./apolloClient";
import notifReducer from "./reducers/notifReducers";
import confirmReducer from "./reducers/confirmReducer";
import rootSaga from "./reducers/notifSagas";
import authReducer from "./reducers/authReducer";
import appReducer from "./reducers/appReducer";
import SettingsLoad from "./SettingsLoad";
import "./App.css";
import LayoutContainer from "./layouts/LayoutContainer";

import PersonAdd from "./components/person/PersonAdd";
import PersonList from "./components/person/PersonList";
import PersonView from "./components/person/ContainerPersonView";
// import PersonView from './components/person/PersonView';
import RegistrationAdd from "./components/registration/RegistrationAdd";
import RegistrationList from "./components/registration/RegistrationList";
import RegistrationView from "./components/registration/RegistrationView";
import PreRegistration from "./components/registration/PreRegistration";

import RegonlineAdd from "./components/regonline/RegonlineAdd";
import RegonlineList from "./components/regonline/RegonlineList";
import RegonlineView from "./components/regonline/RegonlineView";

import PaymentList from "./components/payment/PaymentList";
import PaymentView from "./components/payment/PaymentView";

import Homepage from "./components/Homepage";
import ChangePass from "./components/myaccount/ChangePass";
import Dashboard from "./components/dashboard/Dashboard";

import SignOut from "./components/session/SignOut";

import SignInPage from "./components/session/SignInPage";
import SignedOutPage from "./components/session/SignedOutPage";

import NotFound from "./components/NotFound";
import TestColors from "./components/test/TestColors";
import Constants from "./utils/environment";

/*
                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="payment"
                    action="list"
                    path="/payment"
                    component={PaymentList}
                    title="table.payment"
                  />
*/
window.onerror = (msg, url, lineNo, columnNo, error) => {
  console.log("hay error", error);
  const info = {
    href: window.location.href || "",
    url: url || "",
    lineNo: lineNo || "",
    columnNo: columnNo || "",
    error: error || "",
  };

  const session = getSession();
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    version: process.env.REACT_APP_VERSION,
    is: session.is,
  };
  let qlVars = { error: msg.toString(), info: JSON.stringify(info) };
  console.log("vars", qlVars);
  headers["Authorization"] = "Bearer " + session.token;
  let query = `mutation setError ( $error: String, $info: String )   {   
    setError ( error: $error, info: $info ){
       answer
       errors {
          key,
          value
       }        
    }
  }
  `;
  const qlBody = JSON.stringify({ query, variables: qlVars });
  try {
    fetch(Constants.URI, {
      headers,
      method: "POST",
      body: qlBody,
    });
  } catch (err) {
    console.error(err);
  }
};

const sagaMiddleware = createSagaMiddleware();

const client = createClient();

const store = createStore(
  combineReducers({
    form: formReducer,
    auth: authReducer,
    app: appReducer,
    notifications: notifReducer,
    confirms: confirmReducer,
  }),
  {},
  compose(
    applyMiddleware(sagaMiddleware),

    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);

sagaMiddleware.run(rootSaga);



class App extends Component{
  componentDidMount() {
    localStorage.removeItem("Error");
  }
  render() {
    const { t, i18n } = this.props;

    return (
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <SettingsLoad>
              <BrowserRouter>
                <Switch>
                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/changepass"
                    component={ChangePass}
                    title="form.changePass"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/dashboard"
                    component={Dashboard}
                    title="menu.dashboard"
                  />
                  <LayoutContainer
                    t={t}
                    public="yes"
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/signin"
                    component={SignInPage}
                    title="login.signin"
                  />

                  <LayoutContainer
                    t={t}
                    public="yes"
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/signedout"
                    component={SignedOutPage}
                    title="login.signedout"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="person"
                    action="add"
                    path="/person-add"
                    component={PersonAdd}
                    title="table.person"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="person"
                    action="list"
                    path="/person"
                    component={PersonList}
                    title="form.mygroup"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="person"
                    action="view"
                    path="/person-view/:id"
                    component={PersonView}
                    title="table.person"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="regonline"
                    action="add"
                    path="/regonline-add"
                    component={RegonlineAdd}
                    title="table.regonline"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="regonline"
                    action="list"
                    path="/regonline"
                    component={RegonlineList}
                    title="table.regonline"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="regonline"
                    action="view"
                    path="/regonline-view/:id"
                    component={RegonlineView}
                    title="table.regonline"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="registration"
                    action="add"
                    path="/registration-add"
                    component={RegistrationAdd}
                    title="table.registration"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="registration"
                    action="list"
                    path="/registration"
                    component={RegistrationList}
                    title="table.registration"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="registration"
                    action="view"
                    path="/registration-view/:id"
                    component={RegistrationView}
                    title="table.registration"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="tour"
                    action="list"
                    path="/tour/:id"
                    public="yes"
                    component={PreRegistration}
                    title="table.tour"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="payment"
                    action="view"
                    path="/payment-view/:id"
                    component={PaymentView}
                    title="table.payment"
                  />

                  <Route exact path="/" component={Homepage} />
                  <Route exact path="/signout" component={SignOut} />
                  <Route component={NotFound} />
                </Switch>
              </BrowserRouter>
            </SettingsLoad>
          </Provider>
        </ApolloProvider>
      </ErrorBoundary>
    );
  }
}

export default App;
