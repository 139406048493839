import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import { Button } from '../layouts/cssstyled';
import { signOut } from '../actions/authActions';
import { fetchQl } from '../apolloClient';
import { Tables } from '../defTables';
import ShowIcon from '../components/icons/ShowIcon';

import {
  ISOtoDate,
  toPrice,
  sourcesOptions,
  realTypeOf,
  processValues,
  resolvePathObj,
  intersection,
  tt,
} from '../utils/commonutils';

import { getSubFieldsToPrint } from '../utils/listCommon';
import {
  errorTranslate,
  resolveLink,
  Circle,
  processDisplayValue,
  processDisplayRelatedValue,
  formatCol,
} from '../utils/helper';
import IconLoading from './icons/IconLoading';

const refreshPage = () => {
  window.location.reload();
};

export const MsgError = (props) => {
  //<div style= {{marginTop: '10px', marginBottom: '10px' }} >{t('form.reload')}</div>
  return (
    <div>
      <div style={{ color: 'darkred', fontSize: '19px' }}>
        {errorTranslate(props.msg, props.t)}
      </div>

      <div>
        <Button onClick={refreshPage}>{props.t('form.retry')}</Button>
      </div>
    </div>
  );
};

export const resolveListLabel = (tableCrud, keyName) => {
  let log = false;
  let titleField;
  const table = Tables[tableCrud];
  //console.log(' table.listview for key:' + keyName, table.listview);
  const listViewField = table.listview.fields[keyName];
  if (log)
    console.log('listview: keyName: ' + keyName + ' , obj;', listViewField);

  /*
    Cases like checkdepositdet.listview.payment_id, are empty value = {} , because is based in source
      because is based in table.fields[fieldName].fieldDisplayedOptions
   */

  if (listViewField && listViewField.virtual) {
    // virtual don't throw error, must have always label
    titleField = listViewField.label || 'form.' + keyName;
  } else if (listViewField && listViewField.label) {
    titleField = listViewField.label; // it has label, does not need process it
    if (log) console.log(' label key found in listview field:' + titleField);
  } else {
    const realNameField =
      listViewField && listViewField.field ? listViewField.field : keyName;
    // if fieldSource give undefined and produce error, a field of list of fields is not in the list of table fields
    const fieldSource = table.fields[realNameField];

    if (!table.fields[keyName]) {
      throw 'listview field:' + keyName + ' not found in table:' + tableCrud;
    }
    if (table.fields[keyName].label) {
      titleField = table.fields[keyName].label;
      if (log) console.log(' label key found in field:' + titleField);
    } else {
      if (typeof fieldSource === 'undefined')
        throw (
          'ListData: field: ' +
          keyName +
          ' from table: ' +
          tableCrud +
          ' does not exist'
        );

      // if there define label in listview, this wins, second option table source name
      if (table.listview.fields[keyName].label) {
        titleField = table.listview.fields[keyName].label;
        if (log)
          console.log(' label key found in listview field:' + titleField);
      } else if (
        (table.related && table.related[realNameField]) ||
        (fieldSource.typeInput &&
          fieldSource.typeInput === 'selectAutocomplete' &&
          !fieldSource.listSource)
      ) {
        titleField = 'table.' + table.related[realNameField].table; // field belong to table related
        if (log)
          console.log(
            ' assign label accord table related found: table.related[' +
              realNameField +
              '].table ' +
              titleField
          );
      } else {
        titleField = 'form.' + keyName; //otherwise, default label source name
        if (log)
          console.log(' assign label default form.keyName :' + titleField);
      }
    }
  }
  return titleField;
};

export const PageSettings = (props) => {
  const {
    table,
    listviewFields,
    pagesSettings,
    t,
    tableCrud,
    updatePageSettingsFields,
    templates,
    isVisiblePageSettings,
    visibleFieldsToggle,
    pageSettingId,
    onChangePageSettingId,
    showConfirm,
    deletePagesetting,
    showNotificationWithTimeout,
    updatePageSetting,
  } = props;
  const pageCurrent = pageSettingId
    ? pagesSettings.find((pageS) => pageS.id === pageSettingId)
    : { name: '' };

  const [nameInputVue, setNameInputVue] = useState('');
  const [isAdding, setIsAdding] = useState(false);

  const handleCancelAdd = () => {
    // remove last item
    updatePageSetting(pageSettingId, 'cancelAdd');
    setNameInputVue('');
    setIsAdding(false);
  };

  const addPageSetting = async () => {
    setIsAdding(true);

    const listviewFieldsClone = JSON.parse(JSON.stringify(listviewFields));
    updatePageSetting(pageSettingId, 'addInit', {
      id: '',
      name: '',
      fields: listviewFieldsClone,
    });

    // onChangePageSettingId({ target: { value: pageSettingId } }); // change parent state combobox value
  };
  const onChangeLocalPageSettingId = async (e) => {
    const pageSettingId = e.target.value;
    onChangePageSettingId({ target: { value: pageSettingId } }); // change parent state combobox value

    try {
      // dont work if null or empty values is sent, just add when variables has values
      // dont add id for case for default pagesettings ( is not adding but have empty pageSettingId )
      let aParams = [
        { name: 'page_name', type: 'String!', value: tableCrud },
        {
          name: 'fields',
          type: 'String',
          // no change fields settings, just it's change default pageid,
          // can be pageid ='' when is default not saved with id
          value: '*justChangePageId',
        },
      ];
      if (pageSettingId) {
        aParams.push({
          name: 'id',
          type: 'ID',
          value: pageSettingId,
        });
      }

      await fetchQl(
        `
        mutation setPagesetting ($id: ID, $name: String, $page_name: String!, $fields: String) {
          setPagesetting  (id: $id, name: $name, page_name: $page_name, fields: $fields) {
            id
          }
        }`,
        aParams,
        {}
      );
    } catch (err) {
      console.log('err', err);
    }
  };

  const deletePageSettingsAction = async (e) => {
    const response = await deletePagesetting({
      variables: { id: pageSettingId },
      fetchPolicy: 'no-cache',
    });
    updatePageSetting(pageSettingId, 'delete');
    showNotificationWithTimeout(props.t('actionLOG.deleted'), false);
  };
  const handleSaveView = async (e) => {
    const aFieldsToSave = {};
    for (const [keyName, field] of Object.entries(listviewFields)) {
      aFieldsToSave[keyName] = { invisible: field.invisible ? true : false };
    }
    try {
      let aParams = [
        { name: 'page_name', type: 'String!', value: tableCrud },
        {
          name: 'fields',
          type: 'String',
          value: JSON.stringify(aFieldsToSave),
        },
      ];
      // dont work if null or empty values is sent, just add when variables has values
      // dont add id for case for default pagesettings ( is not adding but have empty pageSettingId )
      if (!isAdding && pageSettingId) {
        // asterix means new pagesettings, then pageSettingId is really empty
        aParams.push({
          name: 'id',
          type: 'ID',
          value: pageSettingId,
        });
      }
      if (nameInputVue) {
        aParams.push({
          name: 'name',
          type: 'String',
          value: nameInputVue,
        });
      }
      const resCheck = await fetchQl(
        `
      mutation setPagesetting ($id: ID, $name: String, $page_name: String!, $fields: String) {
        setPagesetting  (id: $id, name: $name, page_name: $page_name, fields: $fields) {
          id
        }
      }`,
        aParams,
        {}
      );

      /// when isAdding, id has values, when is not adding can be too 'default pagesettings' (id and name empty)
      updatePageSetting(
        pageSettingId,
        pageSettingId ? 'afterSaveUpdate' : 'addAfterSave',
        {
          id: resCheck.data ? resCheck.data.setPagesetting.id : '',
          name: nameInputVue,
          fields: aFieldsToSave,
        }
      );

      showNotificationWithTimeout(props.t('alert.datasaved'), false);
      setIsAdding(false);
      setNameInputVue('');
    } catch (err) {
      console.log('err', err);
    }

    return;

    // const result = await this.props.dispatch(submit(this.props.nameCrudForm)); // modern way to submit
  };

  let aFields = [];

  for (const [keyName, fieldSet] of Object.entries(listviewFields)) {
    const listViewField = table.listview.fields[keyName];
    if (listViewField) {
      // make sure field exist (it's old field and actually deleted) TWIN EF912

      let fieldSource;
      if (listViewField.virtual) {
        // virtual don't throw error, must have always label
        fieldSource = listViewField;
      } else {
        fieldSource = table.fields[keyName];
      }
      const field = table.fields[keyName];

      const { aColHeaderToAdd, titleFieldFull, printCell } = getHeaderToAdd(
        listViewField,
        keyName,
        fieldSource,
        table,
        props,
        t,
        templates,
        tableCrud,
        fieldSet,
        listviewFields,
        false
      );

      if (printCell) {
        aFields.push(
          <div
            key={keyName}
            style={{
              fontSize: '11px',
              minWidth: '100px',
              maxWidtht: '150px',
              margin: '2px',
              backgroundColor:
                listviewFields && listviewFields[keyName].invisible === true
                  ? null
                  : '#EFEFEF',
            }}
          >
            <input
              type="checkbox"
              name={keyName}
              id={keyName}
              style={{}}
              checked={
                !listviewFields[keyName].invisible ||
                listviewFields[keyName].invisible === false
                  ? true
                  : false
              }
              onChange={(e) => updatePageSettingsFields(e, isAdding)}
            />
            <label
              style={{
                fontSize: '12px',
                color: '#888888',
              }}
              htmlFor={keyName}
            >
              {titleFieldFull}
            </label>
          </div>
        );
      }
    }
  }

  const listProccesed = [];
  let firstDefaulViewAdded = false;
  // pagesSettings comes already prepared and filled, same for default pagesettings that not exist on db
  for (let r = 0; r < pagesSettings.length; r++) {
    let pageSetting = pagesSettings[r];
    if (!(r === 0 && firstDefaulViewAdded && pageSetting.name === '')) {
      listProccesed.push({
        id: pageSetting.id,
        name: pageSetting.name ? pageSetting.name : t('form.bydefault'),
      });
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#999999',
        paddingBottom: '10px',
        width: '100%',
      }}
    >
      <div>
        {!isVisiblePageSettings && (
          <a onClick={visibleFieldsToggle}>
            <ShowIcon icon="collage" color="#5f97c1" />
          </a>
        )}
      </div>
      {isVisiblePageSettings && (
        <div
          style={{
            display: 'flex',
            padding: '10px',
            border: 'solid 1px #cccccc',
          }}
        >
          <div>
            <div style={{ position: 'relative', top: '-10px', left: '-10px' }}>
              <a onClick={visibleFieldsToggle}>
                <ShowIcon
                  icon="arrowdowndropcircleoutline"
                  color="#555555"
                  size="30"
                />
              </a>
            </div>
          </div>
          <div style={{ padding: '10px' }}>
            <div>
              <div>
                {t(isAdding ? 'form.newviewlist' : 'form.viewlist')}
                {': '}
              </div>
              <div style={{ display: 'flex' }}>
                {!isAdding && (
                  <div>
                    <select
                      key="lvn"
                      autoComplete="new-password"
                      className="formSelectBox"
                      onChange={onChangeLocalPageSettingId}
                      value={pageSettingId ? pageSettingId : ''}
                    >
                      {listProccesed.map((list) => (
                        <option key={list.id} value={list.id}>
                          {list.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {isAdding && (
                  <div>
                    {' '}
                    <input
                      autoComplete="new-password"
                      className="formSelectBox"
                      onChange={(e) => setNameInputVue(e.target.value)}
                      value={nameInputVue}
                    />
                    <div
                      className="formError"
                      style={{ color: '#be0000', minHeight: '20px' }}
                    >
                      {nameInputVue.trim() === '' && (
                        <span>{t('validator.required')}</span>
                      )}
                    </div>
                  </div>
                )}

                <div style={{ marginTop: '7px', display: 'flex' }}>
                  <div style={{ margin: '0px 10px 0px 15px' }}>
                    <a
                      key="save3"
                      title={t('form.save')}
                      onClick={async (e) =>
                        isAdding &&
                        (!nameInputVue || nameInputVue.trim() === '')
                          ? () => {}
                          : await handleSaveView()
                      }
                    >
                      <ShowIcon
                        size="20"
                        color={
                          nameInputVue.trim() === '' && isAdding
                            ? '#cecece'
                            : 'limegreen'
                        }
                        icon="markercheck"
                      />
                    </a>
                  </div>

                  {isAdding && (
                    <div>
                      <a
                        key="save1"
                        title={t('info.vCancel')}
                        onClick={(e) => handleCancelAdd(e)}
                      >
                        <ShowIcon size="20" icon="closebox" color="darkred" />
                      </a>
                    </div>
                  )}
                </div>
                {!isAdding && (
                  <div style={{ display: 'flex' }}>
                    <div style={{ margin: '5px 10px 0px 0px' }}>
                      <a
                        key="save2"
                        title={t('info.vAdd')}
                        onClick={(e) => addPageSetting()}
                      >
                        <ShowIcon size="22" color="#555555" icon="plusbox" />
                      </a>
                    </div>
                    {!pageCurrent ||
                      (pageCurrent.name !== '' && (
                        <div style={{ margin: '3px 0px 0px 0px' }}>
                          {' '}
                          <a
                            key="delete1"
                            title={t('info.vDelete')}
                            onClick={() => {
                              //if(window.confirm( props.t('form.deleteconfirm'))) {this.props.handleDeleteRecord()};
                              showConfirm({
                                id: 'delpage',
                                type: 'confirmation',
                                text: props.t('form.deleteviewconfirm'),
                                buttons: [
                                  {
                                    label: props.t('info.vDelete'),
                                    onClick: () => deletePageSettingsAction(),
                                  },
                                  {
                                    label: props.t('form.no'),
                                    onClick: () => null,
                                  },
                                ],
                              });
                            }}
                          >
                            <ShowIcon
                              size="25"
                              icon="deleteforever"
                              color="darkred"
                            />
                          </a>
                        </div>
                      ))}
                  </div>
                )}
              </div>

              <div>
                <div
                  key="cv"
                  style={{
                    fontSize: '11x',
                    marginTop: '15px',
                  }}
                >
                  <div>
                    {t('info.visiblefields')}
                    {': '}
                  </div>
                </div>
                {aFields}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const getHeaderToAdd = (
  listViewField,
  keyName,
  fieldSource,
  table,
  props,
  t,
  templates,
  tableCrud,
  fieldSet,
  listviewFields,
  short = true
) => {
  let colsHeader = [];
  let printCell = true;
  // Logic Twin TT103
  let titleField;
  let titleFieldFull;
  // fieldSource can be not exist, by example , virtual field: customers.listview.addresses2
  if (
    fieldSource &&
    fieldSource.fieldDisplayedOptions &&
    fieldSource.fieldDisplayedOptions.separatorsubfield === 'col'
  ) {
    /* TWIN EXPCOL14
    expands cols from related table like main fields
  not call main field, but the subfields to get label
   */
    for (const [nameSubField, objField] of Object.entries(
      fieldSource.fieldDisplayedOptions.subfields
    )) {
      titleField = resolveListLabel(table.related[keyName].table, nameSubField); // look for related table
      titleFieldFull =
        titleFieldFull + (titleFieldFull ? ' ' : '') + titleField;
      colsHeader.push(
        <div className="cell" key={nameSubField + keyName}>
          {t(titleField)}
        </div>
      );
    }
  } else {
    if (listViewField.module) {
      if (
        !resolvePathObj(
          props,
          'myState.app.appSettings.modules.' + listViewField.module,
          true
        )
      ) {
        //if (keyName === 'dateaccounting') console.log('module not compat', props);
        printCell = false;
      }
    }
    if (listViewField.templates) {
      if (
        !intersection(
          listViewField.templates,
          templates,
          props.tableCrud,
          keyName
        )
      ) {
        //if (keyName === 'dateaccounting') console.log('no template');
        printCell = false;
      }
    }
  }
  /*if (keyName === 'dateaccounting') {
    console.log('module, propstemplates, templates', listViewField.module, templates, listViewField.templates);
  }*/

  if (printCell) {
    titleFieldFull = resolveListLabel(tableCrud, keyName);

    // try find short word, if not exist then get normal word
    let title = t(titleFieldFull + '_short');
    if (!short || title === titleFieldFull + '_short')
      title = t(titleFieldFull); // when label short does not existe take default
    titleFieldFull = title;

    colsHeader.push(
      <div className="cell" key={keyName}>
        {titleFieldFull}
      </div>
    );
  }
  return { titleFieldFull, printCell, colsHeader };
};
export const ListDataHeader = (props) => {
  const { tableCrud } = props;
  const table = Tables[tableCrud];
  const extraCols = props.extraCols || [];
  const refresh = props.refresh || false;
  const t = props.t;
  let templates = props.templates || [];
  let log = false;
  let colsData = [];
  let listviewFields;
  // tiene que cargarse en settings de la db, pero por ahora no es importante
  // una vez cargado del default, si modifca la lista de campos, este debe guardarse
  // en el state
  if (
    !props.settings ||
    !props.settings.listviewFields ||
    Object.keys(props.settings.listviewFields).length === 0
  ) {
    listviewFields = table.listview.fields;
  } else {
    listviewFields = props.settings.listviewFields;
  }

  // fieldSet, come from database list view settings
  // TWIN FS1132
  for (const [keyName, fieldSet] of Object.entries(listviewFields)) {
    const listViewField = table.listview.fields[keyName];

    if (listViewField) {
      // make sure field exist (it's old field and actually deleted) TWIN EF912
      let printCellpre = true;
      if (fieldSet.invisible === true) {
        // accord state - visible on grid
        printCellpre = false;
      }
      if (printCellpre) {
        let fieldSource;
        if (listViewField.virtual) {
          // virtual don't throw error, must have always label
          fieldSource = listViewField;
        } else {
          fieldSource = table.fields[keyName];
        }
        const { colsHeader, printCell } = getHeaderToAdd(
          listViewField,
          keyName,
          fieldSource,
          table,
          props,
          t,
          templates,
          tableCrud,
          fieldSet
        );
        if (printCell) {
          colsData.push(colsHeader);
        }
      }
    }
  }

  extraCols.map((colName) => {
    colsData.push(
      <div className="cell" key={colName}>
        {t(colName)}
      </div>
    );
  });

  return (
    <div
      className={
        props.settings.listMode === 'rows'
          ? 'cellhiddenxPhone row header'
          : `cellhiddenxPhone listMode${props.settings.listMode}`
      }
    >
      <div className="cell" key="toolbar" style={{ whiteSpace: 'nowrap' }}>
        {props.checkAll && (
          <input type="checkbox" value={true} onClick={props.checkAll} />
        )}
        {!props.checkAll && refresh && (
          <a
            title={t('form.refresh')}
            className="aNotHover"
            onClick={() => refresh()}
          >
            <ShowIcon icon="refresh" color="white" />
          </a>
        )}
      </div>
      {!props.hiddenFirstCol && (
        <div className="cell" key="item" style={{ whiteSpace: 'nowrap' }}>
          &nbsp;
        </div>
      )}
      {colsData}
    </div>
  );
};

export const ListDataRercord = (props) => {
  /*
ListDataRercord-> processDisplayRelatedValue (helper.js) -> getSubFieldsToPrint (listcommon.js) *for subfields
ListDataRercord-> getSubFieldsToPrint (listcommon.js) *for subfields
*/

  let log = false;
  let { tableCrud, record, t } = props;
  // important to unpack fields packed in fatherGroup
  let templates = props.templates || [];

  let recordParsed = processValues(
    props,
    tableCrud,
    record,
    'toClient',
    'list'
  );
  // JSON fake, for asyn autocomplete is converted automaticall from object
  // {id, name} to String:name in processDisplayRelatedValue()
  if (log) console.log('recordParsed', recordParsed);
  let table = Tables[tableCrud];
  let tableToolbar = table;
  let nameTableToolbar = '';
  if (table.toolbarTable) {
    tableToolbar = Tables[table.toolbarTable];
    // in format, string, important for resolveLink, so dont use logic
    // for parent but direct id logic to create the link
    nameTableToolbar = table.toolbarTable;
  }
  let aColData = [];
  let toolbars = [];

  let listviewFields;
  if (
    !props.settings.listviewFields ||
    Object.keys(props.settings.listviewFields).length === 0
  ) {
    listviewFields = table.listview.fields;
  } else {
    listviewFields = props.settings.listviewFields;
  }

  let toolsDefaultPrinted = false;
  toolsDefaultPrinted = true;
  let linkItem;
  if (props.link) {
    linkItem = props.link;
  } else {
    linkItem = resolveLink(
      tableToolbar,
      recordParsed,
      'view',
      nameTableToolbar
    );
  }
  if (!table.disabledView && !props.disabledView) {
    toolbars.push(
      <Link
        className="aToolBarExtend"
        title={t('form.watch') + '  ' + t('form.record')}
        key="eye"
        to={linkItem}
      >
        <ShowIcon size="20" color="gray" icon="eye" />
      </Link>
    );
  }

  if (table.toolbar) {
    // block twin L827

    Object.keys(table.toolbar.menus).map((keyName, keyIndex) => {
      let menu = table.toolbar.menus[keyName];
      let url = ''; // simple url
      let download = false;
      if (menu.inList) {
        let linkTool;
        if (menu.urlType === 'filter') {
          //linkTool = menu.url.replace('{id}', recordParsed.id);
        } else {
          if (menu.twinPath) {
            linkTool = resolveLink(table, recordParsed, menu.twinPath);
          } else if (menu.urlType === 'link') {
            //twin U3902
            url = menu.url;
            if (url.includes('{DOWN_URI}') || url.includes('http'))
              download = true;
            url = url.replace('{DOWN_URI}', process.env.REACT_APP_DOWN_URI);
            if (recordParsed.id) url = url.replace('{id}', recordParsed.id);
            //if (props.parentid) url = url.replace('{parentid}',recordParsed.parentid);
            //props.id
            if (log) console.log('type link, url:', url);
            linkTool = url;
          } else {
            linkTool = `/${tableCrud}-child/${recordParsed.id}/${keyName}`;
          }
        }

        /* moved outside, always print eye
        if (!toolsDefaultPrinted) {
          toolsDefaultPrinted = true;
          toolbars.push(
            <Link className="aToolBarExtend" title={t('form.watch')+ '  '+ t('form.record') } key="eye" to={resolveLink(table, recordParsed, 'view')}>
              <ShowIcon size="20" color="gray" icon="eye" />
            </Link>);
        }*/
        let title = menu.label ? t(menu.label) : '';

        if (menu.urlType === 'filter') {
          // TWIN FF384
          toolbars.push(
            <a
              key={keyName}
              onClick={(e) => props.toolbarFunctions(keyName, recordParsed)}
              title={title}
            >
              <ShowIcon size="22" color="gray" icon={menu.icon} />
            </a>
          );
        } else if (download) {
          toolbars.push(
            <a key={keyName} href={linkTool} title={title}>
              <ShowIcon size="22" color="gray" icon={menu.icon} />
            </a>
          );
        } else {
          toolbars.push(
            <Link
              className="aToolBarExtend "
              key={keyName}
              to={linkTool}
              title={title}
            >
              <ShowIcon size="22" color="gray" icon={menu.icon} />
            </Link>
          );
        }
      }
    });
  }
  if (props.showCheckbox) {
    aColData.push(
      <div key="check" className="cell cellhiddenxPhone">
        <Field
          name={'itemcheck' + props.line}
          id={'itemcheck' + props.line}
          component="input"
          type="checkbox"
        />
        <Field name={'itemid' + props.line} component="input" type="hidden" />
      </div>
    );
  } else {
    // not needed... implemnt logic in handleClick() on component
    // aColData.push(<Field name={'itemid'+props.line} component="input" type="hidden"/>);
    // if (toolbars.length > 0) {
    // always print a col, when there are icons or not, this else is printed when there is not checkbox
    // show checkbox, is not compatible to show besides the icons, see %% on the future
    aColData.push(
      <div
        key="toolbar"
        className="cell appCrudBar"
        style={{ whiteSpace: 'nowrap' }}
      >
        {toolbars}
      </div>
    );
    // }
  }

  // Item Number
  aColData.push(
    <div
      key="nitem"
      className="cell cellhiddenxPhone"
      style={{ paddingLeft: '0px', paddingRight: '0px', color: '#888888' }}
    >
      {props.item}
    </div>
  );
  //aColData.push(<div key="toolbar" className="cell appCrudBar"  style = { {  whiteSpace: 'nowrap' } }>{toolbars}</div>);

  // TWIN FS1132
  let keyIndex = -1;
  for (const [keyName, fieldSet] of Object.entries(listviewFields)) {
    keyIndex++;

    const listViewField = table.listview.fields[keyName];
    if (listViewField) {
      // make sure field exist (it's old field and actually deleted) TWIN EF912
      // Logic Twin TT103
      const realNameField = listViewField.field ? listViewField.field : keyName;

      let printCell = true;
      if (fieldSet.invisible === true) {
        printCell = false;
      }

      let colStyle = {};

      let fieldSource;
      if (listViewField.virtual) {
        // virtual don't throw error, must have always label
        fieldSource = listViewField;
      } else {
        fieldSource = table.fields[realNameField];
      }
      if (!fieldSource) {
        console.log('error fieldsource not found on table.fields:', keyName);
        continue;
      }
      if (recordParsed[keyName + 'Style']) {
        colStyle = recordParsed[keyName + 'Style'];
      } else if (listViewField.style) {
        colStyle = listViewField.style;
        //console.log('fieldsource :'+realNameField , fieldSource  );
      }
      let value = recordParsed[realNameField];
      const typofValue = Object.prototype.toString.call(value);
      const logDetail =
        'table: ' +
        table.name.singular +
        ', fieldview:' +
        keyName +
        ', realname: ' +
        realNameField +
        ', typeof:' +
        typofValue +
        ', value:';
      if (log) console.log(logDetail, value);
      if (log)
        console.log('fieldSource / listViewField', fieldSource, listViewField);
      let typeInput = fieldSource.typeInput;
      if (
        props.isLogHistory &&
        recordParsed.changes.hasOwnProperty(realNameField) &&
        !value
      ) {
        /*
       means the change was set to empty, it need to show something
       look if the fieldname is prosent in changes json
       */
        value = t('form.logcleanvalue');
        /*if (recordParsed['oldvalues'][realNameField]) {
        value = ' old:' + recordParsed['oldvalues'][realNameField];
      }*/
        typeInput = 'text'; // change to get not error with '-' value in possible data with source table
      }
      let classCell = 'cell';
      if (listViewField.hiddenPhone) classCell += ' cellhiddenxPhone';
      if (listViewField.hiddenTablet) classCell += ' cellhiddenxTablet';

      // not enter in this block, if have listViewField.subfields,
      // in next block when is table related try to ge the .name
      if (listViewField.module) {
        if (
          !resolvePathObj(
            props,
            'myState.app.appSettings.modules.' + listViewField.module,
            true
          )
        ) {
          printCell = false;
        }
      }
      if (listViewField.templates) {
        if (
          !intersection(
            listViewField.templates,
            templates,
            props.tableCrud,
            keyName
          )
        ) {
          printCell = false;
        }
      }

      // let enter type radio, because value can be null
      if (
        printCell &&
        (value ||
          fieldSource.typeInput === 'radio' ||
          (fieldSource.typeValue &&
            fieldSource.typeValue.substr(0, 5) === 'Price'))
      ) {
        // is not null or empty, price =0 must enter here to format and align
        /*
      Suspended: very complex... date is converted in server...

      if (fieldSource.dbtype === 'Date' || fieldSource.typeValue === 'Date') {
        if (fieldSource.formatListValue ) {
          value = ISOtoDate(value,fieldSource.formatValue);
        } else {
          value = ISOtoDate(value,'-nice-');
        }
      }*/
        if (realNameField === 'email' || fieldSource.typeInput === 'email') {
          value = <a href={`mailto:${value}`}>{value}</a>;
        }
        if (typeInput && typeInput === 'color') {
          value = <Circle color={value} />;
        }
        /*
      special , able to add columns from relative table,
      1. definition in deftables  ->  table -> related -> {table} -> fields
      2. definitiion fields in field: example: deftables -> checkdepositdet -> fields - payment_id -> fieldDisplayedOptions
      3. toolbarTable: 'payment'  // toolbar for row take this table (ej. not checkdepositdet)
       */
        const resValue = processDisplayRelatedValue(
          fieldSet,
          value,
          tableCrud,
          fieldSource,
          realNameField,
          recordParsed,
          listViewField
        );
        if (log)
          console.log(
            'value  : related value for :',
            realNameField,
            value,
            resValue
          );
        value = resValue.value;
        if (resValue.type === 'col') {
          // data that generate virtual cols
          resValue.value.map((value, index) => {
            aColData.push(value);
          });
          //value =  resValue.value;
          printCell = false;
        }
        value = processDisplayValue(value, fieldSource, t, listViewField);
        // console.log("value 2 : related value for :" + realNameField, resValue);
        // if (keyName === 'dateaccounting') {
        //  console.log('value', value, resValue);
        // }

        classCell += formatCol(fieldSource);
        /*if ( fieldSource.nextCompareText) {
        console.log('props.',props);
        if (props.nextrecord && props.nextrecord [keyName] ) {
          let cleanvalue =  value.replace(props.nextrecord[keyName],'');
          value = value.replace(cleanvalue , `<span style = {{color:'red'}}>${cleanvalue }</span>`);
        }
      }*/
      }
      if (value && fieldSource.listSource) {
        const listSource = fieldSource.listSource;
        value = sourcesOptions[listSource].find((item) => {
          // id can be string or int, from db need to convert a real int
          if (item.id === value || parseInt(item.id) === parseInt(value)) {
            return true;
          }
        });
        if (typeof value !== 'undefined' && value.name) {
          let optionObj = value;
          value = optionObj.name;
          if (!fieldSource.translateDisabledSource)
            value = t(listSource + '.' + value);
          if (fieldSource.icon && optionObj.icon) {
            value = [<IconLoading key="ico" size="24" />, value];
          }
        }
      }
      if (log) console.log('value', value, 'listViewField)?', listViewField);

      if (value && listViewField.subfields) {
        // Eg; payment.application

        value = getSubFieldsToPrint(
          null,
          '',
          '',
          '',
          keyName,
          listViewField,
          fieldSource,
          recordParsed,
          value
        );
      }

      if (printCell) {
        if (keyIndex === 0) {
          // %%% study hot to make really right for first col. e.g. for userlog
          let prePath = '';
          if (table.parentTable) {
            prePath =
              '/' +
              table.parentTable +
              '-child/' +
              recordParsed[table.parentTable + '_id'];
          }
          //<Link className="aExtend" key={keyIndex} to={recordParsed.id < 0 ? '/' : prePath +`/${tableCrud}-view/${recordParsed.id}`}>

          if (table.disabledView) {
            aColData.push(
              <div key={keyName} className="cell">
                {value}
              </div>
            );
          } else {
            // it's ok Link Container for the div, margins is ok
            aColData.push(
              <Link className="aExtendList" key={keyName} to={linkItem}>
                <div className="cell">{value}</div>
              </Link>
            );
          }
        } else if (listViewField.childTable) {
          // %% chequear, no funciona aparentement
          if (listViewField.filter === '!Empty' && !value) {
            aColData.push(<div className={classCell} key={keyName}></div>);
          } else {
            aColData.push(
              <div className={classCell} key={keyName}>
                <Link
                  className="aToolBarExtend"
                  to={`/${tableCrud}-child/${recordParsed.id}/${listViewField.childTable}`}
                >
                  <ShowIcon size="20" color="gray" icon={listViewField.icon} />
                  {value}
                </Link>
              </div>
            );
          }
        } else {
          aColData.push(
            <div className={classCell} style={colStyle} key={keyName}>
              {value}
            </div>
          );
        }
      }
    }
  }

  return (
    <React.Fragment>
      <div
        className={
          props.settings.listMode === 'rows'
            ? 'row'
            : `listModeRow${props.settings.listMode}`
        }
      >
        {aColData}
      </div>
      {props.secondLine && props.secondLine}
    </React.Fragment>
  );
};

/*
simil ListDataRercord() but simplified, just to show changes in record
 */
export const ListDataControlRercord = (props) => {
  let log = false;
  let { tableCrud, record, t } = props;
  // important to unpack fields packed in fatherGroup

  let recordParsed = processValues(
    props,
    tableCrud,
    record,
    'toClient',
    'view'
  );
  if (log) console.log('recordParsed', recordParsed);
  const table = Tables[tableCrud];

  let aColData = [];
  aColData.push(
    <div key="emptycol" className="cell cellhiddenxPhone">
      &nbsp;
    </div>
  );
  aColData.push(
    <div key="emptycol2" className="cell cellhiddenxPhone">
      &nbsp;
    </div>
  );

  Object.keys(table.listview.fields).map((keyName, keyIndex) => {
    //log =  (keyName === "addresses2" ? true : false);
    // Logic Twin TT103
    const listViewField = table.listview.fields[keyName];
    const realNameField = listViewField.field ? listViewField.field : keyName;
    let fieldSource = table.fields[realNameField];

    //console.log('fieldsource :'+realNameField , fieldSource  );

    let value = recordParsed[realNameField];
    const typofValue = Object.prototype.toString.call(value);
    const logDetail =
      'table: ' +
      table.name.singular +
      ', fieldview:' +
      keyName +
      ', realname: ' +
      realNameField +
      ', typeof:' +
      typofValue +
      ', value:';
    if (log) console.log(logDetail, value);

    let typeInput = fieldSource.typeInput;
    if (
      props.isLogHistory &&
      recordParsed.changes &&
      recordParsed.changes.hasOwnProperty(realNameField) &&
      !value
    ) {
      /*
       means the change was set to empty, it need to show something
       look if the fieldname is prosent in changes json
       */
      value = t('form.logcleanvalue');
      /*if (recordParsed['oldvalues'][realNameField]) {
        value = ' old:' + recordParsed['oldvalues'][realNameField];
      }*/
      typeInput = 'text'; // change to get not error with '-' value in possible data with source table
    }
    let classCell = 'cell';
    if (listViewField.hiddenPhone) classCell += ' cellhiddenxPhone';
    if (listViewField.hiddenTablet) classCell += ' cellhiddenxTablet';

    if (
      value ||
      (fieldSource.typeValue && fieldSource.typeValue.substr(0, 5) === 'Price')
    ) {
      // is not null or empty, price =0 must enter here to format and align
      let resValue = processDisplayRelatedValue(
        listViewField, // really fieldSet:
        value,
        tableCrud,
        fieldSource,
        realNameField,
        recordParsed
      );
      value = resValue.value;
      value = processDisplayValue(value, fieldSource, t);
      classCell += formatCol(fieldSource);
    }
    aColData.push(
      <div className={classCell} key={keyIndex}>
        {value}
      </div>
    );
  });

  let classCell = 'cell';
  let changes = JSON.parse(record.changes);

  let values = [];

  let aCtrlFields = [...props.aCtrlFields, '_info']; // add fake field _info, general info
  aCtrlFields.map((keyField, indexK) => {
    if (changes && typeof changes[keyField] !== 'undefined') {
      // be sure that changes

      if (keyField === '_info') {
        let detailInfoName = changes[keyField];
        let detailUrl = <span></span>;
        if (realTypeOf(changes[keyField]) === '[object Object]') {
          let detailInfo = changes[keyField];
          detailInfoName = detailInfo.name;
          if (detailInfo.table && detailInfo.id) {
            /*
            send manually link .... twin DELK038
             */
            let fakeRecord = { id: detailInfo.id };
            // is not prepared to show organizer log, filtered on server to don't send those records
            if (Tables[detailInfo.table].parentRelationField) {
              // %%% not implemented , without use
              fakeRecord[Tables[detailInfo.table].parentRelationField] =
                detailInfo.parentid;
            }
            let link = resolveLink(detailInfo.table, fakeRecord, 'view');
            detailUrl = (
              <a
                onClick={() => props.containerPropsForm.goUrl(link)}
                className="aToolBarExtend"
                key="eye"
                title={props.t('form.watch') + '  ' + props.t('form.record')}
              >
                <ShowIcon size="35" color="gray" icon="eye" />
              </a>
            );
          }
        }
        values.push(
          <div
            key={keyField + indexK}
            style={{ marginBottom: '5px', color: '#555555' }}
          >
            <span style={{ color: '#999999' }}>
              {tt(t, 'info.' + detailInfoName)}
            </span>
            {detailUrl}
          </div>
        );
      } else {
        const fieldSource = Tables[props.tableCtrl].fields[keyField]; // target table to control
        let label = fieldSource.label ? t(fieldSource.label) : keyField;
        let value = changes[keyField];
        let realType = realTypeOf(value);
        //console.log('field:'+keyField+ ', label:' + label + ', type: '+ realTypeOf (value) + ',value:', value);
        let cleanValue = false;
        // add 'undefined' because server give 'undefined' to subkeys in fieldarray, it will be unified to 'null'
        if (realType === '[object Null]' || value === 'undefined') {
          value = t('form.logcleanvalue');
          cleanValue = true;
        } else {
          let printField = true;

          if (fieldSource.subfields) {
            //realType  === '[object Array]') {
            value = value !== 'null' ? JSON.parse(value) : [];
            let valuesArray = []; //all lines

            printField = false;
            cleanValue = true;
            value.map((subValue, indexA) => {
              //console.log('subValue', subValue);
              let valueArray = []; // one line
              Object.keys(fieldSource.subfields).forEach(
                (keySubField, indexS) => {
                  let subfieldSource = fieldSource.subfields[keySubField];
                  //console.log('subValue[' + keySubField + ']',subValue[keySubField]);
                  if (
                    keySubField !== 'id' &&
                    typeof subValue[keySubField] !== 'undefined'
                  ) {
                    // changes in subfields structure can give undefined values
                    // deleted because by example addresse have no .name subfield>&& subValue[keySubField].name)
                    printField = true;
                    // check if suvalue, is a object, in this case take only .name
                    // make sure is not null before ask for .name
                    let valueSubField =
                      subValue[keySubField] && subValue[keySubField].name
                        ? subValue[keySubField].name
                        : subValue[keySubField];
                    //valueArray.push( <div key= {keyField + keySubField + indexS} style = { { marginLeft: '20px' } }><span style = { { color: '#999999' } }>{t(subfieldSource.label)}:</span> {valueSubField} </div>);
                    //console.log('subfieldSource.label', subfieldSource.label);
                    valueArray.push(
                      <span key={keyField + keySubField + indexS}>
                        <span style={{ color: '#999999' }}>
                          {subfieldSource.label
                            ? t(subfieldSource.label) + ':'
                            : ' '}
                        </span>{' '}
                        {valueSubField}{' '}
                      </span>
                    );
                  }
                }
              );
              valuesArray.push(
                <div key={keyField + indexA} style={{ marginLeft: '25px' }}>
                  {valueArray}
                </div>
              );
              cleanValue = false;
            });
            value = <div>{valuesArray}</div>;
          } else if (realType === '[object Object]') {
            if (value.name) {
              value = value.name;
            } else {
              value = ''; //unkown description field
            }
          } else {
            value = processDisplayValue(value, fieldSource, t);
          }

          //aColData.push (<div className={classCell} key={keyField}>{label}:{value}</div>);
        }
        if (!(cleanValue && props.lastRecord)) {
          values.push(
            <div
              key={keyField + indexK}
              style={{ marginBottom: '5px', color: '#444444' }}
            >
              <span style={{ color: '#777777' }}>{label}</span> : {value}
            </div>
          );
        }
      }
    }
  });
  aColData.push(
    <div className={classCell} key="changes">
      {values}
    </div>
  );

  return (
    <div
      className={
        props.settings.listMode === 'rows'
          ? 'row'
          : `listModeRow${props.settings.listMode}`
      }
    >
      {aColData}
    </div>
  );
};
