import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { graphql, compose } from 'react-apollo';
import debounce from 'lodash/debounce';

import { Field, reduxForm, change } from 'redux-form';
import { Link } from 'react-router-dom';

import { Tables } from '../../defTables';
import { fetchQl } from '../../apolloClient';
import defQls from '../../defQls';
import {
  processError,
  errorTranslate,
  checkLoadCrud,
  cleanFilters,
  getFilters,
  preSubmitValidation,
  submitValues,
  deleteRecord,
  uuid,
  getRecordFromOptionsIndirectOnFilter,
  resolveLink,
} from '../../utils/helper';

import ShowIcon from '../icons/ShowIcon';
import PageHeader from '../PageHeader';
import CrudToolBar from '../CrudToolBar';
import { CrudNav } from '../CrudNav';
import CrudFilterOrderBar from '../CrudFilterOrderBar';
import IconLoading from '../icons/IconLoading';
import { MsgError, ListDataRercord, ListDataHeader } from '../ListData';
import { getInputValue, resolvePathObj } from '../../utils/commonutils';
import AddPreregistration from './AddPreregistration';

const crudCode = {};

const templates = [];

const tableCrud = 'regonline';
const action = 'list';
const nameForm = '';
const qlName = 'crud_list_' + tableCrud;
const formFilterName = 'listFilterregonline';
const formPagerName = 'regonlinePager';
let table = Tables[tableCrud];

const cardTitle = {
  color: 'darkblue',
  fontSize: '1.25rem',
  fontWeight: '300',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  paddingBottom: '20px',
};

const cardText = {
  color: '#222222',
  flex: '1 1 auto',
  fontSize: '17px',
  lineHeight: '1.5',
  marginBottom: '1.25rem',
};

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crudTable: tableCrud,
      mainForm: formFilterName,
      listMode: 'rows',
      counter: 0,
      dialogOpened: {},
      date: Date.now,
      actionsave: 'list',
      parentid:
        this.props.match.params && this.props.match.params.parentid
          ? this.props.match.params.parentid
          : '',
      id: '',
    };
  }

  componentDidMount() {
    const intervalId = setInterval(this.timer, 4000);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  timer = () => {
    if (localStorage.getItem('Error') !== null) {
      this.setState({ date: Date.now() });
      clearInterval(this.state.intervalId);
    }
    if (Tables[tableCrud].pollInterval) {
      this.props[qlName].refetch();
    }
  };

  handleAddPreregistration = async (values) => {
    // whe input tourId exists, his value overwrittes tourId (that comes from state, that comes from url)
    if (values.tourId) {
      const urlSplit = values.tourId.trim().split('/');
      values.tourId = urlSplit[urlSplit.length - 1];
    }

    const variables = { ...values };

    // check if tour exist
    const resTours = await fetchQl(
      `
      query TourView ($id: ID!) {
        tour (id: $id) {
          id
          name
        }
      }`,
      [{ name: 'id', type: 'ID', value: variables.tourId }],
      {
        props: this.props,
      }
    );

    if (!resTours.data || !resTours.data.tour || !resTours.data.tour.id) {
      this.props.showNotificationWithTimeout(
        this.props.t('mobilsemError.tourNotFound'),
        3
      );
      return false;
    }
    this.props.history.push('tour/' + values.tourId + '?p=' + values.person_id);
  };

  hocdebounced = debounce((methodCode, params) => {
    this.executeCode(methodCode, params);
  }, 1500);

  executeCode = async (methodCode = '', params = {}) => {
    params.tableCrud = tableCrud;
    params.formState = this.state;

    if (methodCode === 'onChangeInput' && params.action !== 'initForm') {
      const { inputFullName, line, action, event, newValue } = params;
      const oldValue = getInputValue(this.props, inputFullName, {
        nameForm: formFilterName,
      });
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        this.props.dispatch(change(formPagerName, '_page', 1));
      }
    }

    if (!crudCode[methodCode]) {
      return;
    }

    const result = await crudCode[methodCode](params);

    if (result && result.changeFieldsLater) {
      Object.keys(result.changeFieldsLater).map((keyName, keyIndex) => {
        let valueField = result.changeFieldsLater[keyName];
        params.formProps.change(keyName, valueField);
      });
    }

    if (result && result.newStates) {
      let formStateChanged = false;
      let currentState = this.state;
      Object.keys(result.newStates).map((keyName, keyIndex) => {
        if (
          typeof this.state[keyName] === 'undefined' ||
          JSON.stringify(this.state[keyName]) !==
            JSON.stringify(result.newStates[keyName])
        ) {
          let keyNewState = result.newStates[keyName];

          if (keyName === 'warningFields') {
            keyNewState = {
              ...this.state[keyName],
              ...result.newStates[keyName],
            };
          }
          this.setState({ [keyName]: keyNewState });
          currentState[keyName] = result.newStates[keyName];
          formStateChanged = true;
        }
      });
      if (formStateChanged) {
        this.props.dispatch(
          change(nameForm, '_formstate', JSON.stringify(currentState))
        );
      }
    }

    if (result && typeof result.valueToReturn !== 'undefined') {
      return result.valueToReturn;
    }
  };

  toolbarFunctions = (toolbar, params = {}) => {};

  render() {
    const { t, sesssion, ...otherProps } = this.props;
    let aElements = [];
    let aQlFiltered = {
      crud_view_person: { table: 'person' },
      list_person: { table: 'person' },
    };
    // let aQlFiltered = {"crud_view_person":{"table":"person"}} ;

    let persons = resolvePathObj(this.props, 'list_person.persons', {
      notFound: null,
    });

    const resultCheck = checkLoadCrud(aQlFiltered, this.props);
    if (resultCheck.messageError) {
      return <MsgError msg={resultCheck.messageError} t={this.props.t} />;
    }
    let result;
    let recordsLoaded;

    if (!resultCheck.globalLoading) {
      recordsLoaded = true;
    }
    aElements.push(
      <PageHeader title="menu.home" icon="home" key="pageheader" t={t} />
    );

    const aPreRegistrationElements = [];
    if (recordsLoaded) {
      // need this control when use old session not exist, or change login session, person can be undefined temporary
      if (
        this.props.crud_view_person &&
        this.props.crud_view_person.person &&
        this.props.crud_view_person.person.personpending_tourid
      ) {
        aPreRegistrationElements.push(
          <div
            key="pendingtour"
            style={{ marginTop: '23px', marginBottom: '27px' }}
          >
            <div
              style={{
                fontSize: '23px',
                color: '#8c2828',
                backgroundColor: '#efd367',
                padding: '10px',
              }}
            >
              <Link
                to={`/tour/${this.props.crud_view_person.person.personpending_tourid.id}`}
              >
                <div style={{ display: 'flex' }}>
                  <div>
                    <ShowIcon icon="navright" />
                  </div>
                  <div>
                    <div>{t('info.continueRegThe')}:</div>
                    <div style={{ color: '#9F6000' }}>
                      {
                        this.props.crud_view_person.person.personpending_tourid
                          .longname
                      }
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        );
      } else {
        aPreRegistrationElements.push(
          <AddPreregistration
            key="AddPreregistration"
            onSubmit={this.handleAddPreregistration}
            persons={persons}
            errors={this.state.errors}
            t={this.props.t}
            changeLanguage={this.props.t}
          />
        );
      }
    }

    if (resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(<IconLoading key="load" />);
    }

    if (recordsLoaded) {
    }

    return (
      <div>
        {aElements}
        <div>
          <div
            style={{
              padding: '15px',
              marginTop: '20px',
              backgroundSize: 'cover',
            }}
          >
            <div style={cardTitle} className="aPointer">
              ><Link to="/regonline">{t('table.regonlines')}</Link>
            </div>
            <p style={cardText}>{t('info.dashboardPreReg')}</p>
            <div>{aPreRegistrationElements}</div>
          </div>
        </div>
        <div>
          <div style={{ padding: '15px', backgroundSize: 'cover' }}>
            <div style={cardTitle} className="aPointer">
              ><Link to="/registration">{t('table.registrations')}</Link>
            </div>
            <p style={cardText}>{t('info.dashboardReg')}</p>
          </div>
          <div className="card__content"></div>
        </div>
        -
      </div>
    );
  }
}

const ComponentWithData = reduxForm({
  form: formPagerName,
  enableReinitialize: true,
})(List);

const withRouterList = withRouter(ComponentWithData);
const withGraphqlandRouter = compose(
  // dont' use ListPage , only list , because page need that a form filters exists
  // if not, does not work
  // graphql(defQls.regonline.ListPage, {

  graphql(defQls.person.View, {
    name: 'crud_view_person',
    options: (props) => {
      // just to know for new person registration, if follow pre-registration
      const optionsValues = {
        variables: { id: props.session.iu, _qlType: 'View' },
      };
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
  }),

  graphql(defQls.person.ListMini, {
    name: 'list_person',
    options: (props) => {
      const optionsValues = { variables: { _qlType: 'ListMini' } };
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
  })
)(withRouterList);

const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change, dispatch }, dispatch);
};

const ComponentWithDataAndState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

export default ComponentWithDataAndState;
