import React, { useState } from 'react';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import { ISOtoDate } from '../../utils/commonutils';
import ShowIcon from '../icons/ShowIcon';
import RenderText from '../RenderText';
import datefnsLocale from 'date-fns/locale/fr';

const Comp = (tour) => {
  const { t } = tour; // t , is integrated on tour

  const [itineraryShow, setItineraryShow] = useState(false);

  let itinerary = [];

  if (tour.itinerary) {
    const dataItinerary = JSON.parse(tour.itinerary);
    dataItinerary.map((programm, daynumber) => {
      const date = format(addDays(tour.datestart, daynumber), 'dddd DD MMMM', {
        locale: datefnsLocale,
      });
      itinerary.push(
        <div key="dd">
          <div
            style={{
              marginTop: '30px',
              marginBottom: '15px',
              fontSize: '22px',
              color: '#444444',
            }}
          >
            <span style={{ color: '#986e6e' }}>
              {t('form.day')} {daynumber + 1}
            </span>
            <span style={{ color: '#888888', fontSize: '20px' }}> {date} </span>
            <br />
            <strong>{programm.title}</strong> {programm.where}
          </div>
        </div>
      );
      if (programm.timetable) {
        programm.timetable.map((event, index) => {
          const time = [];
          if (event.when) {
            if (/\d/.test(event.when)) {
              time.push(event.when);
              if (event.whenminute) {
                time.push(<span key="whenm">:{event.whenminute}</span>);
              } else {
                time.push(<span key="whenm">:00</span>);
              }
            } else {
              time.push(t('form.' + event.when));
            }
          }
          if (event.duration) {
            time.push(
              <span key="dur" style={{ color: '#777777' }}>
                {' '}
                {t('form.duration')}: {event.duration}'
              </span>
            );
          }

          const where = [];
          if (event.where) {
            where.push(<span key="w1">{event.where} </span>);
          }
          if (event.gps) {
            const gps = event.gps.replace(/\s/g, ''); // delete blank space to work map zoom
            where.push(
              <span key="wg">
                <a
                  target="_blank"
                  rel="noopener"
                  href={
                    'http://www.google.com/maps/place/' +
                    gps +
                    '/@' +
                    gps +
                    ',13z'
                  }
                >
                  <ShowIcon
                    icon="gps"
                    size="23"
                    color="#2f8dd6"
                    viewBox="1 1 20 20"
                  />
                </a>{' '}
              </span>
            );
          }
          itinerary.push(
            <div
              key={'iw' + index}
              style={{
                display: 'flex',
                alignItems: 'baseline',
                padding: '7px 0px 5px 0px',
              }}
            >
              <div>{time}</div>
              <div style={{ paddingLeft: '7px', color: '#555555' }}>
                {where}
              </div>
            </div>
          );
          if (event.description) {
            itinerary.push(
              <div key={'id' + index} style={{ color: '#777777' }}>
                <RenderText text={event.description} />
              </div>
            );
          }
          itinerary.push(
            <div key={'bs' + index} style={{ height: '7px' }}></div>
          );
        });
      }
    });
  }
  return (
    <React.Fragment>
      <div
        style={{
          width: '100%',
          textAlign: 'left',
          marginBottom: '15px',
          backgroundColor: '#fbf9f0',
          padding: '15px 5px 15px 5px',
        }}
      >
        <div
          style={{
            display: 'flex',
            paddingBottom: '10px',
            fontSize: '25px',
            color: 'darkred',
            width: '500px',
          }}
        >
          <div>{tour.t('table.regonline')}</div>
        </div>

        <div
          style={{
            display: 'flex',
            paddingBottom: '10px',
            fontSize: '18px',
            width: '500px',
          }}
        >
          <div style={{ color: '#777777', width: '120px' }}>
            {tour.t('table.tour')}
          </div>
          <div style={{ color: '#07438E' }}>{tour.longname}</div>
        </div>
        <div
          style={{ display: 'flex', paddingBottom: '10px', fontSize: '18px' }}
        >
          <div style={{ color: '#777777', width: '120px' }}>
            {tour.t('table.organizer')}
          </div>
          <div style={{ color: '#07438E' }}>{tour.tourorganizer_id.name}</div>
        </div>
        <div
          style={{ display: 'flex', paddingBottom: '10px', fontSize: '18px' }}
        >
          <div style={{ color: '#777777', width: '120px' }}>
            {tour.t('form.datestart')}
          </div>
          <div style={{ color: '#07438E' }}>
            {ISOtoDate(tour.datestart)} au {ISOtoDate(tour.dateend)}
          </div>
        </div>
        {tour.description && (
          <div
            style={{ display: 'flex', paddingBottom: '10px', fontSize: '18px' }}
          >
            <div style={{ color: '#777777', minWidth: '120px' }}>
              {tour.t('form.description')}
            </div>
            <div style={{ color: '#555555', paddingRight: '20px' }}>
              <RenderText text={tour.description} />
            </div>
          </div>
        )}
      </div>
      {itinerary.length > 0 && (
        <div style={{ textAlign: 'left' }}>
          <div
            style={{ display: 'flex', paddingBottom: '10px', fontSize: '18px' }}
          >
            <div style={{ color: '#555555' }}>
              <span style={{ color: 'darkred' }}>
                {tour.t('form.itinerary')}
              </span>
              {!itineraryShow && (
                <a className="aNotHover" onClick={() => setItineraryShow(true)}>
                  <ShowIcon icon="down" color="#4984ab" />
                  {t('info.vView')}
                </a>
              )}
              {itineraryShow && (
                <a
                  className="aNotHover"
                  onClick={() => setItineraryShow(false)}
                >
                  <ShowIcon icon="up" color="#4984ab" />
                  {t('info.vHide')}
                </a>
              )}
            </div>
          </div>
          {itineraryShow && (
            <div
              style={{
                display: 'flex',
                paddingBottom: '10px',
                fontSize: '18px',
              }}
            >
              <div style={{ backgroundColor: '#FFFFFF' }}>{itinerary}</div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
export default Comp;
