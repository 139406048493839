import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { graphql, compose } from 'react-apollo';
import debounce from 'lodash/debounce';

import { submit, change, touch, initialize } from 'redux-form';

import { Tables } from '../../defTables';
import { fetchQl } from '../../apolloClient';
import defQls from '../../defQls';
import {
  processError,
  errorTranslate,
  checkLoadCrud,
  cleanFilters,
  getFilters,
  preSubmitValidation,
  submitValues,
  deleteRecord,
  uuid,
  getRecordFromOptionsIndirectOnFilter,
  resolveLink,
} from '../../utils/helper';

import Form from './RegistrationForm';

import PageHeader from '../PageHeader';
import CrudToolBar from '../CrudToolBar';
import IconLoading from '../icons/IconLoading';
import { MsgError } from '../ListData';
import { getInputValue, tt, getObjFromListById } from '../../utils/commonutils';

import {
  appSubmitStart,
  appSubmitStop,
  appSettingsLoad,
} from '../../actions/appActions';
import { showConfirm, hideConfirm } from '../../actions/confirmActions';

import crudCode from './crudCode';

const templates = [];

const tableCrud = 'registration';
const action = 'add';
const nameForm = tableCrud + 'Form';
const formFilterName = 'listFilterregistration';

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionsave: 'add',
      actionsave_origen: 'add',

      defaultValues: { id: uuid() },
      crudAction: 'Add',
      crudTable: tableCrud,
      mainForm: nameForm,
      id: this.props.match.params.id || '',
      dialogOpened: {},
      parentid:
        this.props.match.params && this.props.match.params.parentid
          ? this.props.match.params.parentid
          : '',
      disabledFields: [],
      hiddenFields: [],
      warningFields: {},
    };
  }

  handleSubmit = async (values) => {
    try {
      if (
        this.state.warningFields &&
        this.state.warningFields._submit &&
        this.state.warningFields._submit.length
      ) {
        const validation = await preSubmitValidation(
          tableCrud,
          this.state.actionsave,
          this.props,
          this.syncCode
        );
        if (!validation) return false;
        this.props.showConfirm({
          id: uuid(),
          type: 'confirmation',
          text: [
            ...this.state.warningFields._submit,
            <div key="usure">{this.props.t('form.uSureSave')}</div>,
          ],
          buttons: [
            {
              label: this.props.t('form.yes'),
              onClick: async () => await this.handleSubmitOk(values),
            },
            {
              label: this.props.t('form.no'),
              onClick: () => null,
            },
          ],
        });
      } else {
        return await this.handleSubmitOk(values);
      }
    } catch (err) {
      throw err;
    }
  };

  handleSubmitOk = async (values) => {
    try {
      const res = await submitValues(
        tableCrud,
        this.state.actionsave,
        this.props,
        true,
        this.syncCode
      );
      if (res) {
        if (res !== 'dontreloaddata') {
          const newState = {
            actionsave: 'update',
            actionsave_origen: 'update',
          };
          if (res && res.id) newState.id = res.id;
          this.setState(newState, async () => {
            await this.executeCode('onChangeInput', {
              action: 'initForm',
              nameForm,

              formProps: { ...this.props, containerPropsForm: this.props },
              formState: this.state,
              event: null,
              newValue: null,
            });
          });
        }
      } else {
        this.props.dispatch(change(nameForm, '_fakefield', Math.random()));
      }
      return res;
    } catch (err) {}
  };

  toolbarFunctions = (toolbar, params = {}) => {};

  hocdebounced = debounce((methodCode, params) => {
    this.executeCode(methodCode, params);
  }, 1500);

  executeCode = async (methodCode = '', params = {}) => {
    params.tableCrud = tableCrud;
    params.formState = this.state;

    if (!crudCode[methodCode]) {
      return;
    }

    const result = await crudCode[methodCode](params);

    if (result && result.changeFieldsLater) {
      Object.keys(result.changeFieldsLater).map((keyName, keyIndex) => {
        let valueField = result.changeFieldsLater[keyName];
        params.formProps.change(keyName, valueField);
      });
    }

    if (result && result.newStates) {
      let formStateChanged = false;
      let currentState = this.state;
      Object.keys(result.newStates).map((keyName, keyIndex) => {
        if (
          typeof this.state[keyName] === 'undefined' ||
          JSON.stringify(this.state[keyName]) !==
            JSON.stringify(result.newStates[keyName])
        ) {
          let keyNewState = result.newStates[keyName];

          if (keyName === 'warningFields') {
            keyNewState = {
              ...this.state[keyName],
              ...result.newStates[keyName],
            };
          }
          this.setState({ [keyName]: keyNewState });
          currentState[keyName] = result.newStates[keyName];
          formStateChanged = true;
        }
      });
      if (formStateChanged) {
        this.props.dispatch(
          change(nameForm, '_formstate', JSON.stringify(currentState))
        );
      }
    }

    if (result && typeof result.valueToReturn !== 'undefined') {
      return result.valueToReturn;
    }
  };

  syncCode = (method, params = {}) => {
    let valueToReturn;
    if (method === '*isStopsPointFree') {
      let tour_id = getInputValue(params.formProps, 'tour_id', { nameForm });
      if (tour_id) {
        tour_id = tour_id.id || tour_id;

        const tourObj = getObjFromListById(this.props.list_tour.tours, tour_id);
        valueToReturn = tourObj.stopspointfree || false;
      }
    }

    if (typeof valueToReturn !== 'undefined') return valueToReturn;
  };

  render() {
    const { t, ...otherProps } = this.props;
    let aElements = [];
    let aQlFiltered = {
      crud_update_registration: { table: 'registration' },
      crud_add_registration: { table: 'registration' },
      crud_delete_registration: { table: 'registration' },
      list_transportation: { table: 'transportation' },
      list_stopspoint: { table: 'stopspoint' },
      list_tour: { table: 'tour' },
      list_tourroom: { table: 'tourroom' },
      list_registrationstatus: { table: 'registrationstatus' },
      list_confirmationstatus: { table: 'confirmationstatus' },
      list_customerstatus: { table: 'customerstatus' },
      list_tourprice: { table: 'tourprice' },
    };
    const resultCheck = checkLoadCrud(aQlFiltered, this.props);
    if (resultCheck.messageError) {
      return <MsgError msg={resultCheck.messageError} t={this.props.t} />;
    }
    aElements.push(
      <PageHeader
        key="pageheader"
        action={action}
        t={t}
        tableCrud={tableCrud}
        tables={Tables}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        id={this.props.match.params.id}
      />
    );
    aElements.push(
      <CrudToolBar
        containerPropsForm={this.props}
        toolbarFunctions={this.toolbarFunctions}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        t={this.props.t}
        tableCrud={tableCrud}
        containerState={this.state}
        nameCrudForm={nameForm}
        nameFilterForm={formFilterName}
        tables={Tables}
        key="crudToolBar1"
        executeCode={this.executeCode}
      />
    );
    if (resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(
        <div key="titleandloading">
          <div className="appBodyTitleSeparator" />
          <div
            className="animated-background"
            style={{ width: '100%', height: '500px' }}
          >
            <IconLoading key="icon" />
          </div>
        </div>
      );
    }
    if (this.state.actionsave === 'add') {
      aElements.push(
        <div key="addlegend" style={{ color: 'darkred' }}>
          {t('info.' + this.state.actionsave_origen)}
        </div>
      );
    }
    if (!resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(
        <Form
          executeCode={this.executeCode}
          syncCode={this.syncCode}
          toolbarFunctions={this.toolbarFunctions}
          crudAction="Add"
          key="mainform"
          id={''}
          onSubmit={this.handleSubmit}
          containerPropsForm={this.props}
          formState={this.state}
          t={this.props.t}
        />
      );
    }

    aElements.push(
      <CrudToolBar
        containerPropsForm={this.props}
        toolbarFunctions={this.toolbarFunctions}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        t={this.props.t}
        tableCrud={tableCrud}
        containerState={this.state}
        nameCrudForm={nameForm}
        nameFilterForm={formFilterName}
        tables={Tables}
        key="crudToolBar2"
        executeCode={this.executeCode}
      />
    );
    return <div>{aElements}</div>;
  }
}

const withGraphqlandRouter = compose(
  graphql(defQls.registration.Update, {
    name: 'crud_update_registration',
    options: (props) => {
      const optionsValues = {
        variables: { id: props.match.params.id, _qlType: 'Update' },
      };
      optionsValues.fetchPolicy = 'no-cache';
      return optionsValues;
    },
  }),
  graphql(defQls.registration.Add, {
    name: 'crud_add_registration',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = 'no-cache';
      optionsValues.variables = { _qlType: 'Add' };
      return optionsValues;
    },
  }),
  graphql(defQls.registration.Delete, {
    name: 'crud_delete_registration',
    options: (props) => {
      const optionsValues = {
        variables: { id: props.match.params.id, _qlType: 'Delete' },
      };
      optionsValues.fetchPolicy = 'no-cache';
      return optionsValues;
    },
  }),
  graphql(defQls.transportation.ListMini, {
    name: 'list_transportation',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.stopspoint.ListMini, {
    name: 'list_stopspoint',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.tour.ListMini, {
    name: 'list_tour',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini', inactive: false };
      return optionsValues;
    },
  }),
  graphql(defQls.tourroom.ListMini, {
    name: 'list_tourroom',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.registrationstatus.ListMini, {
    name: 'list_registrationstatus',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.confirmationstatus.ListMini, {
    name: 'list_confirmationstatus',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.customerstatus.ListMini, {
    name: 'list_customerstatus',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.tourprice.ListMini, {
    name: 'list_tourprice',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.getTourServices, {
    name: 'getTourServices',
    options: (props) => {
      let filters = {};
      const optionsValues = {};

      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
  }),

  graphql(defQls.getTourStopsPoints, {
    name: 'getTourStopsPoints',
    options: (props) => {
      let filters = {};
      const optionsValues = {};

      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
  })
)(withRouter(FormContainer));

const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      appSubmitStart,
      appSubmitStop,
      showConfirm,
      initialize,
      change,
      touch,
      dispatch,
    },
    dispatch
  );
};

const withState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

const ComponentFull = withState;

export default ComponentFull;
