import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { graphql, compose } from 'react-apollo';
import debounce from 'lodash/debounce';

import { Field, reduxForm, change } from 'redux-form';
import { Link } from 'react-router-dom';

import { Tables } from '../../defTables';
import { fetchQl } from '../../apolloClient';
import defQls from '../../defQls';
import {
  processError,
  errorTranslate,
  checkLoadCrud,
  cleanFilters,
  getFilters,
  preSubmitValidation,
  submitValues,
  deleteRecord,
  uuid,
  getRecordFromOptionsIndirectOnFilter,
  resolveLink,
} from '../../utils/helper';

import AddPreregistration from '../dashboard/AddPreregistration';
import ShowIcon from '../icons/ShowIcon';
import PageHeader from '../PageHeader';
import CrudToolBar from '../CrudToolBar';
import { CrudNav } from '../CrudNav';
import CrudFilterOrderBar from '../CrudFilterOrderBar';
import IconLoading from '../icons/IconLoading';
import { MsgError, ListDataRercord, ListDataHeader } from '../ListData';
import { getInputValue, resolvePathObj } from '../../utils/commonutils';

const crudCode = {};

const templates = [];

const tableCrud = 'regonline';
const action = 'list';
const nameForm = '';
const qlName = 'crud_list_' + tableCrud;
const formFilterName = 'listFilterregonline';
const formPagerName = 'regonlinePager';
let table = Tables[tableCrud];

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crudTable: tableCrud,
      mainForm: formFilterName,
      listMode: 'rows',
      counter: 0,
      dialogOpened: {},
      date: Date.now,
      actionsave: 'list',
      parentid:
        this.props.match.params && this.props.match.params.parentid
          ? this.props.match.params.parentid
          : '',
      id: '',
    };
  }

  componentDidMount() {
    const intervalId = setInterval(this.timer, 4000);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  timer = () => {
    if (localStorage.getItem('Error') !== null) {
      this.setState({ date: Date.now() });
      clearInterval(this.state.intervalId);
    }
    if (Tables[tableCrud].pollInterval) {
      this.props[qlName].refetch();
    }
  };

  handleAddPreregistration = async (values) => {
    // whe input tourId exists, his value overwrittes tourId (that comes from state, that comes from url)
    if (values.tourId) {
      const urlSplit = values.tourId.trim().split('/');
      values.tourId = urlSplit[urlSplit.length - 1];
    }

    const variables = { ...values };

    // check if tour exist
    const resTours = await fetchQl(
      `
      query TourView ($id: ID!) {
        tour (id: $id) {
          id
          name
        }
      }`,
      [{ name: 'id', type: 'ID', value: variables.tourId }],
      {
        props: this.props,
      }
    );

    if (!resTours.data || !resTours.data.tour || !resTours.data.tour.id) {
      this.props.showNotificationWithTimeout(
        this.props.t('mobilsemError.tourNotFound'),
        3
      );
      return false;
    }
    this.props.history.push('tour/' + values.tourId + '?p=' + values.person_id);
  };

  hocdebounced = debounce((methodCode, params) => {
    this.executeCode(methodCode, params);
  }, 1500);

  executeCode = async (methodCode = '', params = {}) => {
    params.tableCrud = tableCrud;
    params.formState = this.state;

    if (methodCode === 'onChangeInput' && params.action !== 'initForm') {
      const { inputFullName, line, action, event, newValue } = params;
      const oldValue = getInputValue(this.props, inputFullName, {
        nameForm: formFilterName,
      });
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        this.props.dispatch(change(formPagerName, '_page', 1));
      }
    }

    if (!crudCode[methodCode]) {
      return;
    }

    const result = await crudCode[methodCode](params);

    if (result && result.changeFieldsLater) {
      Object.keys(result.changeFieldsLater).map((keyName, keyIndex) => {
        let valueField = result.changeFieldsLater[keyName];
        params.formProps.change(keyName, valueField);
      });
    }

    if (result && result.newStates) {
      let formStateChanged = false;
      let currentState = this.state;
      Object.keys(result.newStates).map((keyName, keyIndex) => {
        if (
          typeof this.state[keyName] === 'undefined' ||
          JSON.stringify(this.state[keyName]) !==
            JSON.stringify(result.newStates[keyName])
        ) {
          let keyNewState = result.newStates[keyName];

          if (keyName === 'warningFields') {
            keyNewState = {
              ...this.state[keyName],
              ...result.newStates[keyName],
            };
          }
          this.setState({ [keyName]: keyNewState });
          currentState[keyName] = result.newStates[keyName];
          formStateChanged = true;
        }
      });
      if (formStateChanged) {
        this.props.dispatch(
          change(nameForm, '_formstate', JSON.stringify(currentState))
        );
      }
    }

    if (result && typeof result.valueToReturn !== 'undefined') {
      return result.valueToReturn;
    }
  };

  toolbarFunctions = (toolbar, params = {}) => {};

  navPage = (navTo, page) => {
    let newPage = parseInt('0' + page._page);
    if (navTo === 'right' && page._page < page._totalpages) {
      newPage++;
    }
    if (navTo === 'left' && page._page > 1) {
      newPage--;
    }
    if (navTo === 'first') {
      newPage = 1;
    }
    if (navTo === 'last') {
      newPage = page._totalpages;
    }
    let strNewpage = parseInt('0' + newPage.toString());
    this.props.change('_page', strNewpage);
  };

  render() {
    const { t, sesssion, ...otherProps } = this.props;
    let aElements = [];
    let aQlFiltered = {
      crud_view_person: { table: 'person' },
      crud_list_regonline: { table: 'regonline' },
      list_tour: { table: 'tour' },
      list_stopspoint: { table: 'stopspoint' },
      list_person: { table: 'person' },
    };
    let persons = resolvePathObj(this.props, 'list_person.persons', {
      notFound: null,
    });

    const resultCheck = checkLoadCrud(aQlFiltered, this.props, false); // last param debug

    if (resultCheck.messageError) {
      return <MsgError msg={resultCheck.messageError} t={this.props.t} />;
    }
    aElements.push(
      <PageHeader
        key="pageheader"
        action={action}
        t={t}
        tableCrud={tableCrud}
        tables={Tables}
      />
    );
    aElements.push(
      <CrudToolBar
        containerPropsForm={this.props}
        toolbarFunctions={this.toolbarFunctions}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        t={this.props.t}
        tableCrud={tableCrud}
        containerState={this.state}
        nameCrudForm={nameForm}
        nameFilterForm={formFilterName}
        tables={Tables}
        key="crudToolBar1"
        executeCode={this.executeCode}
      />
    );
    aElements.push(
      <CrudFilterOrderBar
        containerPropsForm={this.props}
        toolbarFunctions={this.toolbarFunctions}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        t={this.props.t}
        tableCrud={tableCrud}
        containerState={this.state}
        nameCrudForm={nameForm}
        nameFilterForm={formFilterName}
        tables={Tables}
        key="crudFilter"
        executeCode={this.executeCode}
        templates={templates}
      />
    );
    let result;
    let recordsLoaded;

    if (
      !resultCheck.globalLoading &&
      !resultCheck.messageError &&
      this.props[qlName] &&
      this.props[qlName].pageregonlines
    ) {
      recordsLoaded = true;
      result = this.props[qlName].pageregonlines;
    }

    if (recordsLoaded) {
      if (this.props.crud_view_person.person.personpending_tourid) {
        aElements.push(
          <div
            key="pendingtour"
            style={{ marginTop: '23px', marginBottom: '27px' }}
          >
            <div
              style={{
                fontSize: '23px',
                color: '#999999',
                backgroundColor: '#FEEFB3',
                padding: '10px',
              }}
            >
              <Link
                to={`/tour/${this.props.crud_view_person.person.personpending_tourid.id}`}
              >
                <div style={{ display: 'flex' }}>
                  <div>
                    <ShowIcon icon="navright" />
                  </div>
                  <div>{t('info.continueRegThe')}:&nbsp;</div>
                  <div style={{ color: '#9F6000' }}>
                    {
                      this.props.crud_view_person.person.personpending_tourid
                        .longname
                    }
                  </div>
                </div>
              </Link>
            </div>
          </div>
        );
      }
    }
    aElements.push(
      <form key="listForm">
        <Field key="_page" name="_page" component="input" type="hidden" />
      </form>
    );

    if (resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(<IconLoading key="load" />);
    }

    if (recordsLoaded) {
      let lastTours = [];
      if (result.nodes.length > 0) {
        lastTours = [result.nodes[0].regonlinetour_id];
        // console.log('lastTour', lastTours);
      }
      aElements.push(
        <AddPreregistration
          key="AddPreregistration"
          onSubmit={this.handleAddPreregistration}
          persons={persons}
          lastTours={lastTours}
          errors={this.state.errors}
          t={this.props.t}
          changeLanguage={this.props.t}
        />
      );

      aElements.push(
        <div
          key="listTitle"
          style={{ marginTop: '20px', color: 'darkred', fontSize: '16px' }}
        >
          Pre-inscriptions réalisées
        </div>
      );
      aElements.push(
        <CrudNav
          key="crudnav1"
          t={t}
          page={result.page}
          navPage={this.navPage}
        />
      );
      aElements.push(
        <ListDataHeader
          key="list"
          myState={this.props.myState}
          t={t}
          tableCrud={tableCrud}
          settings={this.state}
          refresh={
            !Tables[tableCrud].pollInterval && this.props[qlName]
              ? this.props[qlName].refetch
              : false
          }
        />
      );

      for (let r = 0; r < result.nodes.length; r++) {
        let record = result.nodes[r];
        let nextrecord =
          table.listSendNextRecord && r + 1 < result.nodes.length
            ? result.nodes[r + 1]
            : {};
        let item =
          (result.page._page - 1) * result.page._recordsperpage + r + 1;

        let secondLine = null;
        if (record.payinput && !record.paid && record.state === 1) {
          // only for regonline that had payonline
          secondLine = (
            <div style={{ color: 'darkred' }}>
              Votre paiement n'a pas abouti. Réessayez le paiement.
              <a href={`/tour/${record.tour_id}?trypay=${record.id}`}>
                Cliquez ici
              </a>
            </div>
          );
          /*secondLine = (
            <div style={{ color: "darkred" }}>
              Paiement en cours de validation...
            </div>
          );*/
        }
        aElements.push(
          <ListDataRercord
            key={record.id}
            myState={this.props.myState}
            t={t}
            item={item}
            secondLine={secondLine}
            toolbarFunctions={this.toolbarFunctions}
            tableCrud={tableCrud}
            record={record}
            nextrecord={nextrecord}
            settings={this.state}
          />
        );
      }

      if (result.page._totalpages > 1) {
        aElements.push(
          <CrudNav
            key="crudnav2"
            t={t}
            page={result.page}
            navPage={this.navPage}
          />
        );
      } else {
        aElements.push(
          <div className="xrow" key="rfound">
            <div className="cell"></div>
            <div
              className="cell"
              style={{ color: '#999999', paddingTop: '20px' }}
            >
              {result.page._totalrecords} {t('info.recordsFound')}
            </div>
          </div>
        );
      }
    }

    return <div>{aElements}</div>;
  }
}

const ComponentWithData = reduxForm({
  form: formPagerName,
  enableReinitialize: true,
})(List);

const withRouterList = withRouter(ComponentWithData);
const withGraphqlandRouter = compose(
  graphql(defQls.regonline.ListPage, {
    name: 'crud_list_regonline',
    options: (props) => {
      let filters = getFilters(tableCrud, props, formPagerName, formFilterName);
      const optionsValues = { variables: { ...filters, _qlType: 'ListPage' } };
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      return optionsValues;
    },
    skip: (props) => {
      return !(
        props.myState &&
        props.myState.form &&
        props.myState.form[formFilterName] &&
        props.myState.form[tableCrud + 'Pager'] &&
        props.myState.form[formFilterName].values &&
        props.myState.form[tableCrud + 'Pager'].values
      );
    },
  }),
  graphql(defQls.person.View, {
    name: 'crud_view_person',
    options: (props) => {
      const optionsValues = {
        variables: { id: props.session.iu, _qlType: 'View' },
      };
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      return optionsValues;
    },
  }),
  graphql(defQls.tour.ListMini, {
    name: 'list_tour',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.stopspoint.ListMini, {
    name: 'list_stopspoint',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.person.ListMini, {
    name: 'list_person',
    options: (props) => {
      const optionsValues = { variables: { _qlType: 'ListMini' } };
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
  })
)(withRouterList);

function mapStateToProps(state, ownProps) {
  let initialValues = { _page: 1 };
  const statesReturn = { myState: state };
  if (
    state &&
    state.form &&
    state.form[formPagerName] &&
    typeof state.form[formPagerName].values
  ) {
    statesReturn.initialValues = initialValues;
  }
  return statesReturn;
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change, dispatch }, dispatch);
};

const ComponentWithDataAndState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

export default ComponentWithDataAndState;
