const Tables = {
  organizer: {
    uniquefields: {
      1: ['name'],
    },
    apps: ['phone'],
    orders: {
      noOptionBlank: true,
      defaultValue: 'updatedAt',
      label: 'form.order',
      inLine: true,
      fields: {
        name: {},
        updatedAt: {
          label: 'form.updateAtDesc',
          order: 'DESC',
        },
        createdAt: {
          label: 'form.createAtDesc',
          order: 'DESC',
        },
      },
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String',
        validators: ['required'],
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      province: {
        type: 'String',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.province',
      },
      contact: {
        type: 'String',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.contact',
      },
      email: {
        type: 'String',
        validators: ['email'],
        normalize: 'LowerCase',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.email',
      },
      limitemail: {
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'text',
        label: 'form.limitemail',
      },
      limitsms: {
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'text',
        label: 'form.limitsms',
      },
      info: {
        type: 'String',
        dbtype: 'Json',
        fatherGroup: true,
        typeInput: 'text',
        label: 'form.info',
      },
      invoicename: {
        type: 'String',
        placeHolderShow: true,
        nameGroup: 'info',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.invoicename',
      },
      addressline1: {
        type: 'String',
        placeHolderShow: true,
        nameGroup: 'info',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.addressline1',
      },
      addressline2: {
        type: 'String',
        placeHolderShow: true,
        nameGroup: 'info',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.addressline2',
      },
      addressline3: {
        type: 'String',
        placeHolderShow: true,
        nameGroup: 'info',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.addressline3',
      },
      cp: {
        type: 'String',
        placeHolderShow: true,
        inputSize: 7,
        nameGroup: 'info',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.cp',
      },
      city: {
        type: 'String',
        placeHolderShow: true,
        nameGroup: 'info',
        sublabel: 'info.citywillnotbeadd',
        sublabeldown: true,
        validators: ['required'],
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.city',
      },
      cityid: {
        type: 'ID',
        placeHolderShow: true,
        typeInput: 'hidden',
        nameGroup: 'info',
        dbtype: 'ID',
        label: 'form.cityid',
      },
      country: {
        type: 'String',
        validators: ['required'],
        typeInput: 'selectAutocomplete',
        listSource: 'countries',
        nameGroup: 'info',
        saveIdOnly: true,
        dbtype: 'String',
        translateDisabledSource: true,
        label: 'form.country',
      },
      lang: {
        type: 'String',
        validators: ['required'],
        typeInput: 'selectAutocomplete',
        listSource: 'aLanguages',
        nameGroup: 'info',
        saveIdOnly: true,
        dbtype: 'String',
        label: 'form.lang',
      },
      status: {
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'selectBox',
        listSource: 'orgBase',
        label: 'form.status',
      },
      contacts: {
        type: 'String',
        dbtype: 'Json',
        placeHolderShowChildsShow: true,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          name: {
            type: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          phone: {
            type: 'String',
            validators: ['required'],
            icon: 'phoneblack',
            typeInput: 'text',
            label: 'form.phone',
          },
          address: {
            type: 'String',
            typeInput: 'text',
            label: 'form.address',
          },
          email: {
            type: 'String',
            validators: ['email'],
            normalize: 'LowerCase',
            typeInput: 'text',
            label: 'form.email',
          },
          otherinfo: {
            type: 'String',
            typeInput: 'text',
            label: 'form.otherinfo',
          },
        },
        typeInput: 'text',
        label: 'form.contacts',
        mode: {},
      },
      modules: {
        type: 'String',
        dbtype: 'Json',
        placeHolderShowChildsShow: true,
        saveformat: 'object',
        saveidinrow: true,
        subfields: {
          id: {
            label: 'form.module',
            type: 'String',
            typeInput: 'selectAutocomplete',
            listSource: 'orgModules',
            validators: ['required'],
          },
        },
        typeInput: 'text',
        label: 'form.modules',
        mode: {},
      },
      numberingformat: {
        type: 'String',
        formNewSection: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.numberingformat',
      },
      settingsforms: {
        type: 'String',
        dbtype: 'Json',
        excludedInForm: true,
      },
      textgcu: {
        type: 'String',
        dbtype: 'Json',
        excludedInForm: true,
      },
      notes: {
        type: 'String',
        typeInput: 'textarea',
        formNewSection: true,
        dbtype: 'String',
        label: 'form.notes',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
      updated_user_id: {
        type: 'String',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.updated_user_id',
      },
      created_user_id: {
        type: 'String',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.created_user_id',
      },
      deleted_user_id: {
        type: 'String',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.deleted_user_id',
      },
    },
    icon: 'assignment',
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    listview: {
      fields: {
        name: true,
        province: true,
        city: true,
        contact: true,
        email: true,
        contacts: {
          type: 'String',
          dbtype: 'Json',
          subfields: {
            name: true,
            phone: true,
            address: true,
            otherinfo: true,
          },
          limit: 2,
          joinsubfields: false,
        },
        createdAt: true,
        updatedAt: true,
      },
    },
    listFilters: {
      fields: {
        name: {
          target: ['maincrud', 'listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String',
            validators: ['required'],
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String',
          typeInput: 'text',
          listSource: '',
        },
        province: {
          tableRelatedAlias: '',
          table: '',
          fieldName: 'province',
          target: ['maincrud'],
          label: 'form.province',
          fieldSource: {
            type: 'String',
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.province',
          },
          tableRelated: '',
          type: 'String',
          typeInput: 'text',
          listSource: '',
        },
        city: {
          tableRelatedAlias: '',
          table: '',
          fieldName: 'city',
          target: ['maincrud'],
          label: 'form.city',
          fieldSource: {
            type: 'String',
            placeHolderShow: true,
            nameGroup: 'info',
            sublabel: 'info.citywillnotbeadd',
            sublabeldown: true,
            validators: ['required'],
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.city',
          },
          tableRelated: '',
          type: 'String',
          typeInput: 'text',
          listSource: '',
        },
        email: {
          tableRelatedAlias: '',
          table: '',
          fieldName: 'email',
          target: ['maincrud'],
          label: 'form.email',
          fieldSource: {
            type: 'String',
            validators: ['email'],
            normalize: 'LowerCase',
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.email',
          },
          tableRelated: '',
          type: 'String',
          typeInput: 'text',
          listSource: '',
        },
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    name: {
      singular: 'organizer',
      plural: 'organizers',
      singularCase: 'Organizer',
      pluralCase: 'Organizers',
    },
    mainfield: 'name',
    related: {
      updatedUser: {
        table: 'user',
        relation: 'oneChild',
        fields: ['name'],
        key: 'updated_user_id',
        qlDisable: true,
        alias: 'organizerupdated_user_id',
      },
      createdUser: {
        table: 'user',
        relation: 'oneChild',
        fields: ['name'],
        key: 'created_user_id',
        qlDisable: true,
        alias: 'organizercreated_user_id',
      },
    },
    miniFields: ['id', 'name'],
    componentName: 'OrganizerView',
    path: '/organizer-view/:id',
  },
  customeraddress: {
    destination: ['serverSchema', 'serverResolver', 'ormModels'],
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      customers: {
        type: 'String',
        dbtype: 'Json',
        labelChildsShow: true,
        listmini: true,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          name: {
            type: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
        },
        typeInput: 'text',
        label: 'form.customers',
        mode: {},
      },
      addresses: {
        type: 'String',
        dbtype: 'Json',
        labelChildsShow: true,
        listmini: true,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          type: {
            type: 'String',
            placeHolderShow: true,
            label: 'form.addresses_type',
            typeInput: 'selectAutocomplete',
            listSource: 'addressesType',
            validators: ['required'],
          },
          addressline1: {
            type: 'String',
            validators: ['required'],
            placeHolderShow: true,
            validateRequiredIfEmpty: 'addressline2',
            sublabel: 'info.addressline1',
            sublabeldown: true,
            typeInput: 'text',
            label: 'form.addressline1',
          },
          addressline2: {
            type: 'String',
            placeHolderShow: true,
            validators: ['required'],
            validateRequiredIfEmpty: 'addressline1',
            sublabel: 'info.addressline2',
            sublabeldown: true,
            typeInput: 'text',
            label: 'form.addressline2',
          },
          addressline3: {
            type: 'String',
            placeHolderShow: true,
            typeInput: 'text',
            label: 'form.addressline3',
          },
          city_id: {
            type: 'ID',
            typeInput: 'selectAutocomplete',
            table: 'city',
            add: true,
            validators: ['required'],
            onChange: true,
            loadAsync: true,
            saveonly: 'id',
            dbtype: 'Json',
            label: 'table.city',
          },
          cp: {
            type: 'String',
            placeHolderShow: true,
            inputSize: 7,
            loadAsync: true,
            typeInput: 'selectAutocomplete',
            ql: 'getCitiesByCp',
            validators: ['required'],
            onChange: true,
            nameisid: true,
            loadMin: 5,
            saveonly: 'id',
            dbtype: 'Json',
            label: 'form.cp',
          },
          country: {
            type: 'String',
            validators: ['required'],
            typeInput: 'selectAutocomplete',
            listSource: 'countries',
            saveIdOnly: true,
            translateDisabledSource: true,
            label: 'form.country',
          },
        },
        typeInput: 'text',
        label: 'form.addresses',
        mode: {},
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
      updated_user_id: {
        type: 'String',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.updated_user_id',
      },
      created_user_id: {
        type: 'String',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.created_user_id',
      },
      deleted_user_id: {
        type: 'String',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.deleted_user_id',
      },
    },
    listFilters: {
      fields: {
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    listview: {
      fields: {
        addresses: {
          subfields: {
            addressline1: true,
            addressline2: true,
            addressline3: true,
          },
          limit: 1,
          joinsubfields: true,
        },
        addresses2: {
          field: 'addresses',
          label: 'form.city',
          subfields: {
            city_id: true,
          },
          limit: 1,
          joinsubfields: true,
        },
      },
    },
    norelated: {
      customer: {
        norelated: true,
        table: 'customer',
        fields: ['name'],
      },
    },
    name: {
      singular: 'customeraddress',
      plural: 'customeraddresss',
      singularCase: 'Customeraddress',
      pluralCase: 'Customeraddresss',
    },
    mainfield: 'customers',
    related: {
      updatedUser: {
        table: 'user',
        relation: 'oneChild',
        fields: ['name'],
        key: 'updated_user_id',
        qlDisable: true,
        alias: 'customeraddressupdated_user_id',
      },
      createdUser: {
        table: 'user',
        relation: 'oneChild',
        fields: ['name'],
        key: 'created_user_id',
        qlDisable: true,
        alias: 'customeraddresscreated_user_id',
      },
    },
    miniFields: ['id', 'customers', 'addresses'],
  },
  customer: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.t0gc6h8dkoou',
    },
    triggers: true,
    orders: {
      noOptionBlank: true,
      defaultValue: 'updatedAt',
      label: 'form.order',
      inLine: true,
      fields: {
        name: {},
        updatedAt: {
          label: 'form.updateAtDesc',
          order: 'DESC',
        },
        createdAt: {},
      },
    },
    listOrder: '"customer"."updatedAt":DESC',
    afterAddGoView: true,
    icon: 'pele',
    preloadHeight: '700px',
    loadAsync: true,
    formToolBarBottom: true,
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      gendertype_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        listmini: false,
        dbtype: 'String',
        table: 'gendertype',
        label: 'table.gendertype',
      },
      name: {
        type: 'String',
        disabledMutation: true,
        excludedInForm: true,
        listmini: true,
        label: 'form.fullname',
        dbtype: 'String',
        typeInput: 'text',
      },
      lastname: {
        type: 'String',
        validators: ['required', 'alphai18n', 'minLength:3'],
        normalize: 'UpperCase',
        onDebounce: 1500,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.lastname',
      },
      firstname: {
        type: 'String',
        validators: ['required', 'alphai18n', 'minLength:3'],
        normalize: 'StartCase',
        onDebounce: 1500,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.firstname',
      },
      email: {
        type: 'String',
        validators: ['email'],
        normalize: 'LowerCase',
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.email',
      },
      socialsn: {
        type: 'String',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.socialsn',
      },
      occupation: {
        type: 'String',
        nameGroup: 'info',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.occupation',
      },
      imageright: {
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'selectBox',
        listSource: 'yesNoEmpty',
        label: 'form.imageright',
      },
      inactive: {
        type: 'Boolean',
        sublabel: 'info.inactive',
        sublabeldown: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.inactive',
      },
      deceased: {
        type: 'Boolean',
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deceased',
      },
      customerstatus_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        formNewSection: true,
        listmini: true,
        onChange: true,
        dbtype: 'String',
        table: 'customerstatus',
        label: 'table.customerstatus',
      },
      customersubstatus_id: {
        type: 'String',
        label: 'form.substatus',
        typeInput: 'selectAutocomplete',
        filterBy: ['customerstatus_id'],
        listmini: true,
        subfield: 'substatus',
        table: 'customerstatus',
        dbtype: 'String',
      },
      grouped_customer_id: {
        type: 'String',
        label: 'form.grouped_customer_id',
        typeInput: 'selectAutocomplete',
        formNewSection: true,
        dbtype: 'Json',
        table: 'customer',
        loadAsync: true,
        saveonly: 'id',
      },
      grouped_filiation: {
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'selectBox',
        label: 'form.grouped_filiation',
        listSource: 'filiation',
      },
      birthdate: {
        type: 'String',
        dbtype: 'Date',
        formNewSection: true,
        typeInput: 'text',
        validators: ['date'],
        label: 'form.birthdate',
      },
      info: {
        type: 'String',
        dbtype: 'Json',
        fatherGroup: true,
        typeInput: 'text',
        label: 'form.info',
      },
      birthplace: {
        type: 'String',
        nameGroup: 'info',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.birthplace',
      },
      birthcountry: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        listSource: 'countries',
        nameGroup: 'info',
        dbtype: 'String',
        translateDisabledSource: true,
        label: 'form.birthcountry',
      },
      nationality: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        listSource: 'countries',
        dbtype: 'String',
        translateDisabledSource: true,
        label: 'form.nationality',
      },
      residence: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        listSource: 'countries',
        dbtype: 'String',
        translateDisabledSource: true,
        label: 'form.nationality',
      },
      fullnameparent1: {
        type: 'String',
        nameGroup: 'info',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.fullnameparent1',
      },
      fullnameparent2: {
        type: 'String',
        nameGroup: 'info',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.fullnameparent2',
      },
      nameunmarried: {
        type: 'String',
        nameGroup: 'info',
        sublabel: 'info.nameunmarried',
        sublabeldown: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.nameunmarried',
      },
      contacts: {
        type: 'String',
        dbtype: 'Json',
        placeHolderShowChildsShow: true,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            noExport: true,
            label: 'form.id',
          },
          type: {
            type: 'String',
            typeInput: 'selectAutocomplete',
            listSource: 'contactType',
            validators: ['required'],
            label: 'form.type',
          },
          name: {
            type: 'String',
            validators: ['required'],
            typeInput: 'text',
            label: 'form.name',
          },
          phone: {
            type: 'String',
            validators: ['required'],
            icon: 'phoneblack',
            typeInput: 'text',
            label: 'form.phone',
          },
          otherinfo: {
            type: 'String',
            typeInput: 'text',
            label: 'form.otherinfo',
          },
        },
        typeInput: 'text',
        label: 'form.contacts',
        mode: {},
      },
      smsdisabled: {
        type: 'Boolean',
        typeInput: 'checkbox',
        inLine: true,
        noContainerInLine: true,
        formNewSection: true,
        nameGroup: 'info',
        width: '700px',
        dbtype: 'Boolean',
        label: 'form.smsdisabled',
      },
      mobile: {
        type: 'String',
        inLine: true,
        listmini: true,
        noContainerInLine: true,
        width: '700px',
        disabledAdd: true,
        disabledUpdate: true,
        typeInput: 'hidden',
        dbtype: 'String',
        label: 'form.mobile',
      },
      phones: {
        type: 'String',
        dbtype: 'Json',
        placeHolderShowChildsShow: true,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          type: {
            type: 'String',
            typeInput: 'selectAutocomplete',
            listSource: 'phonesType',
            validators: ['required'],
            sublabel: 'info.phonetype',
            label: 'form.type',
          },
          phone: {
            type: 'String',
            validators: ['required', 'valPhone'],
            normalize: 'Phone',
            icon: 'phoneblack',
            onChange: true,
            sublabel: 'info.phone',
            typeInput: 'text',
            label: 'form.phone',
          },
          ph: {
            type: 'String',
            label: '',
            typeInput: 'hidden',
            disabledOnAdd: true,
            disabledOnUpdate: true,
          },
          otherinfo: {
            type: 'String',
            sublabel: 'info.phoneotherinfo',
            typeInput: 'text',
            label: 'form.otherinfo',
          },
        },
        typeInput: 'text',
        label: 'form.phones',
        mode: {},
      },
      customeraddress_id: {
        type: 'String',
        typeInput: 'hidden',
        label: 'form.customeraddress_id',
        dbtype: 'String',
      },
      customeraddress_customers: {
        type: 'String',
        typeInput: 'hidden',
        virtual: true,
        preventExit: true,
        dbtype: 'String',
        label: 'form.customeraddress_customers',
      },
      addresses: {
        preComponents: {
          components: {
            MemberAddress: {
              position: 'insideAfterTitle',
            },
          },
        },
        type: 'String',
        dbtype: 'Json',
        labelChildsShow: true,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          type: {
            type: 'String',
            placeHolderShow: true,
            label: 'form.addresses_type',
            typeInput: 'selectAutocomplete',
            listSource: 'addressesType',
            validators: ['required'],
          },
          addressline1: {
            type: 'String',
            validators: ['required'],
            placeHolderShow: true,
            validateRequiredIfEmpty: 'addressline2',
            sublabel: 'info.addressline1',
            sublabeldown: true,
            typeInput: 'text',
            label: 'form.addressline1',
          },
          addressline2: {
            type: 'String',
            placeHolderShow: true,
            validators: ['required'],
            validateRequiredIfEmpty: 'addressline1',
            sublabel: 'info.addressline2',
            sublabeldown: true,
            typeInput: 'text',
            label: 'form.addressline2',
          },
          addressline3: {
            type: 'String',
            placeHolderShow: true,
            typeInput: 'text',
            label: 'form.addressline3',
          },
          city_id: {
            type: 'ID',
            typeInput: 'selectAutocomplete',
            table: 'city',
            add: true,
            validators: ['required'],
            onChange: true,
            loadAsync: true,
            saveonly: 'id',
            dbtype: 'Json',
            label: 'table.city',
          },
          cp: {
            type: 'String',
            placeHolderShow: true,
            inputSize: 7,
            loadAsync: true,
            typeInput: 'selectAutocomplete',
            ql: 'getCitiesByCp',
            validators: ['required'],
            onChange: true,
            nameisid: true,
            loadMin: 5,
            saveonly: 'id',
            dbtype: 'Json',
            label: 'form.cp',
          },
          country: {
            type: 'String',
            validators: ['required'],
            typeInput: 'selectAutocomplete',
            listSource: 'countries',
            saveIdOnly: true,
            translateDisabledSource: true,
            label: 'form.country',
          },
        },
        typeInput: 'text',
        label: 'form.addresses',
        mode: {},
      },
      identitydocs: {
        type: 'String',
        dbtype: 'Json',
        labelChildsShow: true,
        placeHolderShowChildsShow: false,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            noExport: true,
            label: 'form.id',
          },
          type: {
            type: 'String',
            label: 'form.id_doctype',
            typeInput: 'selectAutocomplete',
            listSource: 'identitydocsType',
            validators: ['required'],
          },
          country: {
            type: 'String',
            validators: ['required'],
            typeInput: 'selectAutocomplete',
            listSource: 'countries',
            saveIdOnly: true,
            translateDisabledSource: true,
            label: 'form.country',
          },
          number: {
            type: 'String',
            label: 'form.id_docnumber',
            validators: ['required'],
            typeInput: 'text',
          },
          idexpiration: {
            type: 'String',
            dbtype: 'Date',
            typeInput: 'text',
            label: 'form.idexpiration',
          },
          idiauthority: {
            type: 'String',
            typeInput: 'text',
            label: 'form.idiauthority',
          },
          ididate: {
            type: 'String',
            dbtype: 'Date',
            typeInput: 'text',
            label: 'form.ididate',
          },
          idnameisother: {
            type: 'Boolean',
            typeInput: 'checkbox',
            inLine: true,
            noContainerInLine: true,
            onChange: true,
            formNewSection: true,
            noExport: true,
            width: '700px',
            label: 'form.idnameisother',
          },
          lastname: {
            type: 'String',
            validators: ['alphai18n'],
            normalize: 'UpperCase',
            onHidden: true,
            formNewSection: true,
            typeInput: 'text',
            label: 'form.lastname',
          },
          firstname: {
            type: 'String',
            validators: ['alphai18n'],
            onHidden: true,
            typeInput: 'text',
            label: 'form.firstname',
          },
          idaddressisother: {
            type: 'Boolean',
            typeInput: 'checkbox',
            inLine: true,
            onChange: true,
            formNewSection: true,
            noContainerInLine: true,
            noExport: true,
            width: '700px',
            label: 'form.idaddressisother',
          },
          addressline1: {
            type: 'String',
            onHidden: true,
            formNewSection: true,
            typeInput: 'text',
            label: 'form.addressline1',
          },
          addressline2: {
            type: 'String',
            onHidden: true,
            typeInput: 'text',
            label: 'form.addressline2',
          },
          addressline3: {
            type: 'String',
            onHidden: true,
            typeInput: 'text',
            label: 'form.addressline3',
          },
          cp: {
            type: 'String',
            inputSize: 7,
            onHidden: true,
            typeInput: 'text',
            label: 'form.cp',
          },
          city: {
            type: 'String',
            placeHolderShow: true,
            sublabel: 'info.citywillnotbeadd',
            sublabeldown: true,
            onHidden: true,
            typeInput: 'text',
            label: 'form.city',
          },
          cityid: {
            type: 'ID',
            typeInput: 'hidden',
            noExport: true,
            label: 'form.cityid',
          },
        },
        typeInput: 'text',
        label: 'form.identitydocs',
        mode: {},
      },
      notes: {
        type: 'String',
        typeInput: 'textarea',
        nameGroup: 'info',
        formNewSection: true,
        dbtype: 'String',
        label: 'form.notes',
      },
      counterhistory: {
        type: 'Int',
        disabledMutation: true,
        excludedInForm: true,
        label: 'form.crm',
        dbtype: 'Int',
        typeInput: 'text',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
      updated_user_id: {
        type: 'String',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.updated_user_id',
      },
      created_user_id: {
        type: 'String',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.created_user_id',
      },
      deleted_user_id: {
        type: 'String',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.deleted_user_id',
      },
    },
    listFilters: {
      fields: {
        lastname: {
          preload: true,
          minLength: 3,
          normalize: 'UpperCase',
          sublabel: 'info.filterMinLength::3',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'lastname',
          target: ['maincrud'],
          label: 'form.lastname',
          fieldSource: {
            type: 'String',
            validators: ['required', 'alphai18n', 'minLength:3'],
            normalize: 'UpperCase',
            onDebounce: 1500,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.lastname',
          },
          tableRelated: '',
          type: 'String',
          typeInput: 'text',
          listSource: '',
        },
        firstname: {
          preload: true,
          minLength: 3,
          anyCase: true,
          normalize: 'StartCase',
          sublabel: 'info.filterMinLength::3',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'firstname',
          target: ['maincrud'],
          label: 'form.firstname',
          fieldSource: {
            type: 'String',
            validators: ['required', 'alphai18n', 'minLength:3'],
            normalize: 'StartCase',
            onDebounce: 1500,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.firstname',
          },
          tableRelated: '',
          type: 'String',
          typeInput: 'text',
          listSource: '',
        },
        customerstatus_id: {
          tableRelatedAlias: {
            table: 'customerstatus',
            relation: 'oneChild',
            fields: ['name'],
            key: 'customerstatus_id',
            alias: 'customercustomerstatus_id',
          },
          table: 'customerstatus',
          fieldName: 'customerstatus_id',
          target: ['maincrud', 'listmini'],
          label: 'table.customerstatus',
          fieldSource: {
            type: 'String',
            typeInput: 'selectAutocomplete',
            formNewSection: true,
            listmini: true,
            onChange: true,
            dbtype: 'String',
            table: 'customerstatus',
            label: 'table.customerstatus',
          },
          tableRelated: {
            table: 'customerstatus',
            relation: 'oneChild',
            fields: ['name'],
            key: 'customerstatus_id',
            alias: 'customercustomerstatus_id',
          },
          type: 'String',
          typeInput: 'selectAutocomplete',
          listSource: '',
        },
        addresses_city_id: {
          fieldContainer: 'addresses',
          subfield: 'city_id',
          table: 'city',
          typeInput: 'selectAutocomplete',
          tableRelatedAlias: 'city',
          fieldName: 'addresses.city_id',
          target: ['maincrud'],
          label: 'table.city',
          fieldSource: {
            type: 'ID',
            typeInput: 'selectAutocomplete',
            table: 'city',
            add: true,
            validators: ['required'],
            onChange: true,
            loadAsync: true,
            saveonly: 'id',
            dbtype: 'Json',
            label: 'table.city',
          },
          tableRelated: 'city',
          type: 'ID',
          listSource: '',
        },
        inactive: {
          typeInput: 'radio',
          listOptions: [
            {
              id: 'false',
              name: 'form.no',
            },
            {
              id: 'true',
              name: 'form.yes',
            },
            {
              id: 'null',
              name: 'form.all',
            },
          ],
          showLabel: true,
          inLine: true,
          defaultValue: 'false',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'inactive',
          target: ['maincrud'],
          label: 'form.inactive',
          fieldSource: {
            type: 'Boolean',
            sublabel: 'info.inactive',
            sublabeldown: true,
            dbtype: 'Boolean',
            typeInput: 'checkbox',
            label: 'form.inactive',
          },
          tableRelated: '',
          type: 'Boolean',
          listSource: '',
        },
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
        name: {
          target: ['listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.fullname',
          fieldSource: {
            type: 'String',
            disabledMutation: true,
            excludedInForm: true,
            listmini: true,
            label: 'form.fullname',
            dbtype: 'String',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'String',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    listview: {
      fields: {
        name: true,
        email: true,
        addresses: {
          subfields: {
            addressline1: true,
            addressline2: true,
            addressline3: true,
          },
          limit: 1,
          joinsubfields: true,
        },
        addresses2: {
          field: 'addresses',
          label: 'form.city',
          subfields: {
            city_id: true,
          },
          limit: 1,
          joinsubfields: true,
        },
        phones: {
          type: 'String',
          dbtype: 'Json',
          subfields: {
            phone: true,
          },
          limit: 2,
          separator: '',
          joinsubfields: true,
        },
      },
    },
    norelated: {
      city: {
        norelated: true,
        table: 'city',
        fields: ['name'],
      },
    },
    related: {
      customeraddress_id: {
        table: 'customeraddress',
        relation: 'oneChild',
        fields: ['customers', 'addresses'],
        key: 'customeraddress_id',
        alias: 'customercustomeraddress_id',
      },
      grouped_customer_id: {
        table: 'customer',
        label: 'form.grouped_customer_id',
        relation: 'oneChild',
        fields: ['name'],
        key: 'grouped_customer_id',
        alias: 'customergrouped_customer_id',
      },
      gendertype_id: {
        table: 'gendertype',
        relation: 'oneChild',
        fields: ['name'],
        key: 'gendertype_id',
        listmini: true,
        alias: 'customergendertype_id',
      },
      customerstatus_id: {
        table: 'customerstatus',
        relation: 'oneChild',
        fields: ['name'],
        key: 'customerstatus_id',
        alias: 'customercustomerstatus_id',
      },
      updatedUser: {
        table: 'user',
        relation: 'oneChild',
        fields: ['name'],
        key: 'updated_user_id',
        qlDisable: true,
        alias: 'customerupdated_user_id',
      },
      createdUser: {
        table: 'user',
        relation: 'oneChild',
        fields: ['name'],
        key: 'created_user_id',
        qlDisable: true,
        alias: 'customercreated_user_id',
      },
    },
    name: {
      singular: 'customer',
      plural: 'customers',
      singularCase: 'Customer',
      pluralCase: 'Customers',
    },
    mainfield: 'name',
    miniFields: [
      'id',
      'name',
      'email',
      'customerstatus_id',
      'customersubstatus_id',
      'mobile',
    ],
    componentName: 'CustomerView',
    path: '/customer-view/:id',
  },
  tour: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.t0gc6h8dkoou',
    },
    apps: ['phone', 'admin'],
    triggers: true,
    listOrder: '"tour"."datestart":ASC',
    listFilters: {
      fields: {
        name: {
          target: ['maincrud', 'listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String!',
            validators: ['required', 'minLength:3', 'maxLength:22'],
            onDisabled: true,
            sublabel: 'info.tourshortname',
            sublabeldown: true,
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String!',
          typeInput: 'text',
          listSource: '',
        },
        inactive: {
          typeInput: 'radio',
          listOptions: [
            {
              id: 'false',
              name: 'form.no',
            },
            {
              id: 'true',
              name: 'form.yes',
            },
            {
              id: 'null',
              name: 'form.all',
            },
          ],
          showLabel: true,
          inLine: true,
          defaultValue: 'false',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'inactive',
          target: ['maincrud'],
          label: 'form.inactive',
          fieldSource: {
            type: 'Boolean',
            sublabel: 'info.inactive',
            sublabeldown: true,
            dbtype: 'Boolean',
            typeInput: 'checkbox',
            label: 'form.inactive',
          },
          tableRelated: '',
          type: 'Boolean',
          listSource: '',
        },
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String!',
        validators: ['required', 'minLength:3', 'maxLength:22'],
        onDisabled: true,
        sublabel: 'info.tourshortname',
        sublabeldown: true,
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      longname: {
        type: 'String',
        validators: ['required', 'minLength:3'],
        listmini: true,
        onDisabled: true,
        sublabel: 'info.longname',
        sublabeldown: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.longname',
      },
      country: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        listSource: 'countries',
        validators: [],
        saveIdOnly: true,
        dbtype: 'String',
        translateDisabledSource: true,
        label: 'form.country',
      },
      description: {
        type: 'String',
        typeInput: 'textarea',
        formNewSection: true,
        sublabel: 'info.tourdescription',
        dbtype: 'String',
        label: 'form.description',
      },
      seatsmax: {
        type: 'Int',
        normalize: 'IntPositive',
        onDisabled: true,
        validators: ['required', 'IntRange:1:2500'],
        sublabel: 'info.seatsmax',
        sublabeldown: true,
        formNewSection: true,
        dbtype: 'Int',
        typeInput: 'text',
        label: 'form.seatsmax',
      },
      seatsmin: {
        type: 'Int',
        normalize: 'IntPositive',
        onDisabled: true,
        sublabel: 'info.seatsmin',
        sublabeldown: true,
        dbtype: 'Int',
        typeInput: 'text',
        label: 'form.seatsmin',
      },
      datestart: {
        type: 'String',
        validators: ['date'],
        dbtype: 'Date',
        onChange: true,
        formNewSection: true,
        typeInput: 'text',
        label: 'form.datestart',
      },
      dateend: {
        type: 'String',
        validators: ['date'],
        dbtype: 'Date',
        onChange: true,
        typeInput: 'text',
        label: 'form.dateend',
      },
      inactive: {
        type: 'Boolean',
        sublabel: 'info.inactive',
        sublabeldown: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        listmini: true,
        label: 'form.inactive',
      },
      registrationstart: {
        type: 'String',
        validators: ['date'],
        dbtype: 'Date',
        onChange: true,
        formNewSection: true,
        typeInput: 'text',
        label: 'form.registrationstart',
      },
      registrationend: {
        type: 'String',
        validators: ['date'],
        dbtype: 'Date',
        onChange: true,
        typeInput: 'text',
        label: 'form.registrationend',
      },
      tourcategory_id: {
        type: 'String',
        label: 'table.tourcategory_long',
        typeInput: 'selectAutocomplete',
        listmini: true,
        onChange: true,
        dbtype: 'String',
        table: 'tourcategory',
      },
      onlineregtype: {
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'selectBox',
        listSource: 'onlineRegType',
        onChange: true,
        validators: [],
        label: 'form.onlineregtype',
      },
      gcu: {
        type: 'Int',
        dbtype: 'Int',
        label: 'form.gcu',
      },
      urlgcu: {
        type: 'String',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.urlgcu',
      },
      textgcu: {
        type: 'String',
        dbtype: 'Json',
        typeInput: 'hidden',
        excludedInForm: true,
        label: 'form.textgcu',
      },
      itinerary: {
        type: 'String',
        dbtype: 'Json',
        indexLabel: 'form.day',
        excludedInForm: true,
        typeInput: 'text',
        label: 'form.itinerary',
      },
      total: {
        type: 'Float',
        typeValue: 'Price',
        disabledMutation: true,
        formNewSection: true,
        dbtype: 'Float',
        typeInput: 'text',
        normalize: 'Price',
        label: 'form.total',
      },
      paid: {
        type: 'Float',
        typeValue: 'Price',
        disabledMutation: true,
        defaultValue: 0,
        dbtype: 'Float',
        typeInput: 'text',
        normalize: 'Price',
        label: 'form.paid',
      },
      balance: {
        type: 'Float',
        typeValue: 'Price',
        disabledMutation: true,
        dbtype: 'Float',
        typeInput: 'text',
        normalize: 'Price',
        label: 'form.balance',
      },
      price: {
        type: 'Float',
        typeValue: 'Price',
        validators: [],
        listmini: true,
        onDisabled: true,
        formNewSection: true,
        label: 'form.baseprice',
        sublabel: 'info.baseprice',
        sublabeldown: true,
        formRowExpand: true,
        dbtype: 'Float',
        typeInput: 'text',
        normalize: 'Price',
      },
      cancelledprice: {
        type: 'Float',
        typeValue: 'Price',
        defaultValue: 0,
        validators: [],
        listmini: true,
        onDisabled: true,
        formNewSection: true,
        label: 'form.cancelledprice',
        sublabel: 'info.cancelledprice',
        sublabeldown: true,
        formRowExpand: true,
        dbtype: 'Float',
        typeInput: 'text',
        normalize: 'Price',
      },
      additionalservices: {
        type: 'String',
        dbtype: 'Json',
        sublabel: 'info.additionalServices',
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          amount: {
            type: 'Float',
            typeValue: 'Price',
            onChange: true,
            validators: ['required'],
            width: '200px',
            typeInput: 'text',
            normalize: 'Price',
            label: 'form.amount',
          },
          sinfo: {
            type: 'String',
            label: 'form.servicetourname',
            sublabel: 'info.servicetourname',
            sublabeldown: true,
            validators: ['required', 'minLength:3', 'maxLength:32'],
            validateRequiredIfEmpty: 'service_id',
            typeInput: 'text',
          },
          service_id: {
            type: 'String',
            typeInput: 'selectAutocomplete',
            table: 'service',
            label: 'form.typeservice',
            sublabel: 'info.optionalstats',
            sublabeldown: true,
            validators: ['required'],
            validateIfEmpty: 'sinfo',
          },
          visibility: {
            type: 'String',
            typeInput: 'selectBox',
            listSource: 'serviceVisibility',
            label: 'form.visibility',
          },
          cancellednotzero: {
            type: 'Boolean',
            typeInput: 'checkbox',
            sublabel: 'info.cancellednotzero',
            label: 'form.cancellednotzero',
          },
        },
        typeInput: 'text',
        label: 'form.additionalservices',
        mode: {},
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
      updated_user_id: {
        type: 'String',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.updated_user_id',
      },
      created_user_id: {
        type: 'String',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.created_user_id',
      },
      deleted_user_id: {
        type: 'String',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.deleted_user_id',
      },
      organizer_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        disabledUpdate: true,
        validators: ['required'],
        formNewSection: true,
        dbtype: 'String',
        table: 'organizer',
        label: 'table.organizer',
      },
    },
    listview: {
      fields: {
        name: true,
        country: {
          hiddenPhone: true,
        },
        seatsmax: true,
        datestart: {
          hiddenPhone: true,
        },
        price: true,
        total: true,
        paid: true,
        balance: true,
      },
    },
    related: {
      tourcategory_id: {
        table: 'tourcategory',
        relation: 'oneChild',
        fields: ['name'],
        key: 'tourcategory_id',
        alias: 'tourtourcategory_id',
      },
      organizer_id: {
        table: 'organizer',
        relation: 'oneChild',
        fields: ['name'],
        key: 'organizer_id',
        alias: 'tourorganizer_id',
      },
    },
    toolbar: {
      menus: {
        filterregistration: {
          icon: 'pele',
          label: 'table.customers',
          urlType: 'filter',
          inView: false,
          inList: true,
        },
        filterpayment: {
          icon: 'monetization',
          label: 'table.payments',
          urlType: 'filter',
          inView: false,
          inList: true,
        },
        tourroom: {
          icon: 'room',
          label: 'table.tourrooms',
          component: 'TourArrangementRoom',
          urlType: 'child',
          inView: true,
          inList: true,
        },
        tourprice: {
          icon: 'pricelist',
          label: 'table.tourprices',
          component: 'TourPrice',
          urlType: 'child',
          inList: true,
          inView: true,
        },
        transportation: {
          icon: 'transportation',
          label: 'table.transportations',
          component: 'Transportation',
          urlType: 'child',
          inList: true,
          inView: true,
        },
      },
    },
    icon: 'flight',
    formToolBarBottom: true,
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    name: {
      singular: 'tour',
      plural: 'tours',
      singularCase: 'Tour',
      pluralCase: 'Tours',
    },
    mainfield: 'name',
    miniFields: [
      'id',
      'name',
      'tourcategory_id',
      'price',
      'cancelledprice',
      'acode_id',
      'acostcode_id',
      'inactive',
    ],
    componentName: 'TourView',
    path: '/tour-view/:id',
  },
  tourcategory: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.t0gc6h8dkoou',
    },
    apps: ['phone', 'admin'],
    triggers: true,
    listOrder: '"tourcategory"."name":ASC',
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String!',
        validators: ['required', 'minLength:3', 'maxLength:22'],
        onDisabled: true,
        preComponents: {
          components: {
            TitleRegistrationOnline: {
              position: 'outsideAfterCloseFieldsContainer',
            },
          },
        },
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      regonline_occupation: {
        type: 'Boolean',
        label: 'form.occupation',
        typeInput: 'checkbox',
        nameGroup: 'settings',
        formNewSection: true,
        dbtype: 'Boolean',
      },
      regonline_birthdata: {
        type: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.birthdata',
        nameGroup: 'settings',
        dbtype: 'Boolean',
      },
      regonline_contactEmergency: {
        type: 'Boolean',
        typeInput: 'checkbox',
        label: 'contactType.Emergency',
        nameGroup: 'settings',
        dbtype: 'Boolean',
      },
      regonline_nationality: {
        type: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.nationality',
        nameGroup: 'settings',
        dbtype: 'Boolean',
      },
      regonline_nameunmarried: {
        type: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.nameunmarried',
        nameGroup: 'settings',
        dbtype: 'Boolean',
      },
      regonline_phones: {
        type: 'Boolean',
        label: 'form.phones',
        typeInput: 'checkbox',
        nameGroup: 'settings',
        dbtype: 'Boolean',
      },
      regonline_address: {
        type: 'Boolean',
        label: 'form.address',
        typeInput: 'checkbox',
        nameGroup: 'settings',
        dbtype: 'Boolean',
      },
      regonline_NationalIdentity: {
        type: 'Boolean',
        label: 'identitydocsType.NationalIdentity',
        typeInput: 'checkbox',
        nameGroup: 'settings',
        dbtype: 'Boolean',
      },
      regonline_passport: {
        type: 'Boolean',
        label: 'identitydocsType.Passport',
        typeInput: 'checkbox',
        nameGroup: 'settings',
        dbtype: 'Boolean',
      },
      regonline_imageright: {
        type: 'Boolean',
        label: 'form.imageright',
        typeInput: 'checkbox',
        nameGroup: 'settings',
        preComponents: {
          components: {
            TitleRegistrationOnlineTransport: {
              position: 'outsideAfterCloseFieldsContainer',
            },
          },
        },
        dbtype: 'Boolean',
      },
      regonline_status: {
        formNewSection: true,
        type: 'Boolean',
        label: 'table.customerstatus',
        typeInput: 'checkbox',
        nameGroup: 'settings',
        dbtype: 'Boolean',
      },
      regonline_stopspoint: {
        formNewSection: true,
        type: 'Boolean',
        label: 'table.stopspoint',
        typeInput: 'checkbox',
        nameGroup: 'settings',
        dbtype: 'Boolean',
      },
      regonline_hotel: {
        formNewSection: true,
        type: 'Boolean',
        label: 'table.hotel',
        typeInput: 'checkbox',
        nameGroup: 'settings',
        preComponents: {
          components: {
            TitleRegistrationOnlineHotel: {
              position: 'outsideAfterCloseFieldsContainer',
            },
          },
        },
        dbtype: 'Boolean',
      },
      regonline_room_type: {
        type: 'Boolean',
        label: 'form.room_type',
        typeInput: 'checkbox',
        nameGroup: 'settings',
        dbtype: 'Boolean',
      },
      regonline_room_share: {
        type: 'Boolean',
        label: 'form.roomshare',
        typeInput: 'checkbox',
        nameGroup: 'settings',
        dbtype: 'Boolean',
      },
      settings: {
        type: 'String',
        dbtype: 'Json',
        fatherGroup: true,
        typeInput: 'text',
        label: 'form.settings',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    listview: {
      fields: {
        name: true,
      },
    },
    icon: 'tourcategory',
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    name: {
      singular: 'tourcategory',
      plural: 'tourcategorys',
      singularCase: 'Tourcategory',
      pluralCase: 'Tourcategorys',
    },
    mainfield: 'name',
    miniFields: ['id', 'name'],
    listFilters: {
      fields: {
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
        name: {
          target: ['listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String!',
            validators: ['required', 'minLength:3', 'maxLength:22'],
            onDisabled: true,
            preComponents: {
              components: {
                TitleRegistrationOnline: {
                  position: 'outsideAfterCloseFieldsContainer',
                },
              },
            },
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'TourcategoryView',
    path: '/tourcategory-view/:id',
  },
  transportation: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.u8eg39hqkk6r',
    },
    triggers: true,
    icon: 'transportation',
    parentTable: 'tour',
    parentRelationField: 'tour_id',
    formToolBarBottom: true,
    toolbar: {
      menus: {
        roombooking: {
          icon: 'seat',
          label: 'table.transportation',
          component: 'TransportationBooking',
          twinPath: 'booking',
          inListHeader: false,
          inList: true,
          inView: false,
        },
      },
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      tour_id: {
        type: 'String',
        typeInput: 'hidden',
        listmini: true,
        dbtype: 'String',
        label: 'form.tour_id',
      },
      name: {
        type: 'String',
        validators: ['required', 'minLength:3', 'maxLength:40'],
        sublabel: 'info.transportationname',
        sublabeldown: true,
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      seats: {
        type: 'Int',
        validators: [],
        normalize: 'IntPositive',
        onDisabled: true,
        dbtype: 'Int',
        typeInput: 'text',
        label: 'form.seats',
      },
      price: {
        type: 'Float',
        typeValue: 'Price',
        listmini: true,
        sublabel: 'info.priceaddbase',
        sublabeldown: true,
        dbtype: 'Float',
        typeInput: 'text',
        normalize: 'Price',
        label: 'form.price',
      },
      driverinfo: {
        type: 'String',
        typeInput: 'textarea',
        formNewSection: true,
        dbtype: 'String',
        label: 'form.driverinfo',
      },
      responsibleinfo: {
        type: 'String',
        typeInput: 'textarea',
        formNewSection: true,
        dbtype: 'String',
        label: 'form.responsibleinfo',
      },
      routeplanner: {
        type: 'String',
        dbtype: 'Json',
        sublabel: 'info.routeplanner',
        listmini: true,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          from_stopspoint_id: {
            type: 'String',
            typeInput: 'selectAutocomplete',
            table: 'stopspoint',
            sublabel: '',
            sublabeldown: true,
            validators: ['required'],
            label: 'table.stopspoint',
          },
          sinfo: {
            type: 'String',
            sublabel: 'info.stoppoint',
            sublabeldown: true,
            typeInput: 'text',
            label: 'form.sinfo',
          },
        },
        typeInput: 'text',
        label: 'form.routeplanner',
        mode: {},
      },
      bookings: {
        type: 'String',
        dbtype: 'Json',
        typeInput: 'hidden',
        label: 'table.roombooking',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    listview: {
      fields: {
        name: true,
        seats: true,
        price: true,
      },
    },
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
    ],
    related: {
      tour_id: {
        table: 'tour',
        relation: 'oneChild',
        fields: ['name', 'seatsmax'],
        key: 'tour_id',
        isParent: true,
        alias: 'transportationtour_id',
      },
    },
    name: {
      singular: 'transportation',
      plural: 'transportations',
      singularCase: 'Transportation',
      pluralCase: 'Transportations',
    },
    mainfield: 'name',
    miniFields: ['id', 'tour_id', 'name', 'price', 'routeplanner'],
    listFilters: {
      fields: {
        tour_id: {
          tableRelatedAlias: {
            table: 'tour',
            relation: 'oneChild',
            fields: ['name', 'seatsmax'],
            key: 'tour_id',
            isParent: true,
            alias: 'transportationtour_id',
          },
          table: 'tour',
          fieldName: 'tour_id',
          target: ['maincrud', 'listmini'],
          label: 'form.tour_id',
          fieldSource: {
            type: 'String',
            typeInput: 'hidden',
            listmini: true,
            dbtype: 'String',
            label: 'form.tour_id',
          },
          tableRelated: {
            table: 'tour',
            relation: 'oneChild',
            fields: ['name', 'seatsmax'],
            key: 'tour_id',
            isParent: true,
            alias: 'transportationtour_id',
          },
          type: 'String',
          typeInput: 'hidden',
          listSource: '',
        },
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
        name: {
          target: ['listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String',
            validators: ['required', 'minLength:3', 'maxLength:40'],
            sublabel: 'info.transportationname',
            sublabeldown: true,
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'TransportationView',
    path: '/tour-child/:parentid/transportation-view/:id',
  },
  tourprice: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.4qemdobz21io',
    },
    icon: 'pricelist',
    parentTable: 'tour',
    parentRelationField: 'tour_id',
    uniquefields: {
      1: ['customerstatus_id', 'tour_id'],
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      customerstatus_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        formNewSection: true,
        disabledUpdate: true,
        listmini: true,
        dbtype: 'String',
        table: 'customerstatus',
        label: 'table.customerstatus',
      },
      tour_id: {
        type: 'String',
        typeInput: 'hidden',
        listmini: true,
        dbtype: 'String',
        label: 'form.tour_id',
      },
      price: {
        type: 'Float',
        typeValue: 'Price',
        listmini: true,
        label: 'form.baseprice',
        sublabel: 'info.tourpricebaseprice',
        sublabeldown: true,
        validators: ['required'],
        dbtype: 'Float',
        typeInput: 'text',
        normalize: 'Price',
      },
      notes: {
        type: 'String',
        typeInput: 'textarea',
        formNewSection: true,
        dbtype: 'String',
        label: 'form.notes',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    listview: {
      fields: {
        customerstatus_id: true,
        price: true,
      },
    },
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
    ],
    related: {
      customerstatus_id: {
        table: 'customerstatus',
        relation: 'oneChild',
        fields: ['name'],
        key: 'customerstatus_id',
        alias: 'tourpricecustomerstatus_id',
      },
      tour_id: {
        table: 'tour',
        relation: 'oneChild',
        fields: ['name', 'seatsmax'],
        key: 'tour_id',
        isParent: true,
        alias: 'tourpricetour_id',
      },
    },
    name: {
      singular: 'tourprice',
      plural: 'tourprices',
      singularCase: 'Tourprice',
      pluralCase: 'Tourprices',
    },
    mainfield: 'customerstatus_id',
    miniFields: ['id', 'customerstatus_id', 'tour_id', 'price'],
    listFilters: {
      fields: {
        tour_id: {
          tableRelatedAlias: {
            table: 'tour',
            relation: 'oneChild',
            fields: ['name', 'seatsmax'],
            key: 'tour_id',
            isParent: true,
            alias: 'tourpricetour_id',
          },
          table: 'tour',
          fieldName: 'tour_id',
          target: ['maincrud', 'listmini'],
          label: 'form.tour_id',
          fieldSource: {
            type: 'String',
            typeInput: 'hidden',
            listmini: true,
            dbtype: 'String',
            label: 'form.tour_id',
          },
          tableRelated: {
            table: 'tour',
            relation: 'oneChild',
            fields: ['name', 'seatsmax'],
            key: 'tour_id',
            isParent: true,
            alias: 'tourpricetour_id',
          },
          type: 'String',
          typeInput: 'hidden',
          listSource: '',
        },
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'TourpriceView',
    path: '/tour-child/:parentid/tourprice-view/:id',
  },
  tourroom: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.i3jzvapxpdot',
    },
    logFields: {
      hotel_id: true,
      room_type: true,
    },
    orders: {
      noOptionBlank: true,
      defaultValue: 'hotel_id',
      label: 'form.order',
      inLine: true,
      fields: {
        hotel_id: {
          label: 'table.hotel',
        },
        room_type: {},
        updatedAt: {
          label: 'form.updateAtDesc',
          order: 'DESC',
        },
        createdAt: {},
      },
    },
    triggers: true,
    icon: 'room',
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
    ],
    parentTable: 'tour',
    parentRelationField: 'tour_id',
    disableRecreateTemplateView: true,
    toolbar: {
      menus: {
        roombooking: {
          icon: 'bed',
          label: 'table.roombookings',
          component: 'TourroomBooking',
          twinPath: 'booking',
          inList: true,
          inView: true,
        },
      },
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      hotel_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        validators: ['required'],
        listmini: true,
        dbtype: 'String',
        table: 'hotel',
        label: 'table.hotel',
      },
      room_type: {
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'selectBox',
        listSource: 'roomBase',
        validators: ['required'],
        listmini: true,
        onChange: true,
        translateDisabledSource: true,
        label: 'form.room_type',
      },
      tour_id: {
        type: 'String',
        typeInput: 'hidden',
        listmini: true,
        dbtype: 'String',
        label: 'form.tour_id',
      },
      room_quantity: {
        type: 'Int',
        validators: ['IntRange:0:100'],
        normalize: 'IntPositive',
        onChange: true,
        dbtype: 'Int',
        typeInput: 'text',
        label: 'form.room_quantity',
      },
      people_capacity: {
        type: 'Int',
        disabledAdd: true,
        disabledUpdate: true,
        formNewSection: true,
        dbtype: 'Int',
        typeInput: 'text',
        label: 'form.people_capacity',
      },
      people_allocated: {
        type: 'Int',
        disabledAdd: true,
        disabledUpdate: true,
        dbtype: 'Int',
        typeInput: 'text',
        label: 'form.people_allocated',
      },
      people_available: {
        type: 'Int',
        disabledAdd: true,
        disabledUpdate: true,
        dbtype: 'Int',
        typeInput: 'text',
        label: 'form.people_available',
        listmini: true,
      },
      priceroom: {
        type: 'Float',
        typeValue: 'Price',
        sublabel: 'info.priceaddbase',
        sublabeldown: true,
        listmini: true,
        formNewSection: true,
        dbtype: 'Float',
        typeInput: 'text',
        normalize: 'Price',
        label: 'form.priceroom',
      },
      name: {
        type: 'String',
        label: 'form.sinfo',
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
      },
      isfullname: {
        type: 'Boolean',
        label: 'form.roomisfullname',
        listmini: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
      },
      bookings: {
        type: 'String',
        dbtype: 'Json',
        typeInput: 'hidden',
        label: 'table.seatbooking',
      },
      customers: {
        type: 'String',
        dbtype: 'Json',
        typeInput: 'hidden',
        label: 'form.customers',
      },
      room_numbers: {
        type: 'String',
        dbtype: 'Json',
        typeInput: 'hidden',
        label: 'form.room_numbers',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    listview: {
      fields: {
        room_type: true,
        hotel_id: true,
        priceroom: true,
        room_quantity: true,
        people_capacity: true,
        people_allocated: true,
        people_available: true,
        name: true,
        isfullname: true,
      },
    },
    related: {
      tour_id: {
        table: 'tour',
        relation: 'oneChild',
        listmini: true,
        fields: ['name'],
        key: 'tour_id',
        isParent: true,
        alias: 'tourroomtour_id',
      },
      hotel_id: {
        table: 'hotel',
        relation: 'oneChild',
        listmini: true,
        fields: ['name'],
        key: 'hotel_id',
        alias: 'tourroomhotel_id',
      },
    },
    name: {
      singular: 'tourroom',
      plural: 'tourrooms',
      singularCase: 'Tourroom',
      pluralCase: 'Tourrooms',
    },
    mainfield: 'name',
    miniFields: ['id', 'hotel_id', 'room_type', 'tour_id', 'priceroom', 'name'],
    listFilters: {
      fields: {
        tour_id: {
          tableRelatedAlias: {
            table: 'tour',
            relation: 'oneChild',
            listmini: true,
            fields: ['name'],
            key: 'tour_id',
            isParent: true,
            alias: 'tourroomtour_id',
          },
          table: 'tour',
          fieldName: 'tour_id',
          target: ['maincrud', 'listmini'],
          label: 'form.tour_id',
          fieldSource: {
            type: 'String',
            typeInput: 'hidden',
            listmini: true,
            dbtype: 'String',
            label: 'form.tour_id',
          },
          tableRelated: {
            table: 'tour',
            relation: 'oneChild',
            listmini: true,
            fields: ['name'],
            key: 'tour_id',
            isParent: true,
            alias: 'tourroomtour_id',
          },
          type: 'String',
          typeInput: 'hidden',
          listSource: '',
        },
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
        name: {
          target: ['listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.sinfo',
          fieldSource: {
            type: 'String',
            label: 'form.sinfo',
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'String',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'TourroomView',
    path: '/tour-child/:parentid/tourroom-view/:id',
  },
  registration: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.f1i2xryuseac',
    },
    apps: ['phone', 'admin'],
    formToolBarBottom: true,
    icon: 'registration',
    triggers: true,
    disabledAdd: true,
    disabledUpdate: true,
    disabledDelete: true,
    disabledView: false,
    readOnly: true,
    disableRecreateTemplateList: true,
    orders: {
      noOptionBlank: true,
      label: 'form.order',
      inLine: true,
      defaultValue: 'updatedAt',
      fields: {
        name: {
          mainRelated: 'registration',
          subfields: [
            {
              related: 'customer_id',
              field: 'name',
            },
          ],
        },
        numbering: {
          label: 'form.numbering',
          order: 'ASC',
          module: 'DOSSIER',
        },
        updatedAt: {
          label: 'form.updateAtDesc',
          order: 'DESC',
        },
        createdAt: {},
        balanceAsc: {
          label: 'form.balance',
          order: 'ASC',
          labelorder: true,
          fieldName: 'balance',
        },
        balanceDesc: {
          label: 'form.balance',
          order: 'DESC',
          labelorder: true,
          fieldName: 'balance',
        },
      },
    },
    afterAddGoView: true,
    preloadHeight: '500px',
    detail: {
      customer_id: true,
      tour_id: true,
    },
    logFields: {
      customer_id: true,
      tour_id: true,
    },
    uniquefields: {
      1: ['customer_id', 'tour_id'],
    },
    listFilters: {
      fields: {
        tour_id: {
          invisible: true, // temporally %%% until to fix the filter on voyages, but needed on server
          preload: true,
          tableRelatedAlias: {
            table: 'tour',
            relation: 'oneChild',
            fields: ['name', 'seatsmax'],
            key: 'tour_id',
            listmini: true,
            alias: 'regonlinetour_id',
          },
          table: 'tour',
          fieldName: 'tour_id',
          target: ['maincrud'],
          label: 'table.tour',
          fieldSource: {
            type: 'ID',
            typeInput: 'selectAutocomplete',
            disabledUpdate: true,
            onChange: true,
            validators: ['required'],
            inViewLink: '',
            dbtype: 'ID',
            table: 'tour',
            label: 'table.tour',
          },
          tableRelated: {
            table: 'tour',
            relation: 'oneChild',
            fields: ['name', 'seatsmax'],
            key: 'tour_id',
            listmini: true,
            alias: 'regonlinetour_id',
          },
          type: 'ID',
          typeInput: 'selectAutocomplete',
          listSource: '',
        },
        person_id: {
          preload: true,
          invisible: true,
          tableRelatedAlias: {
            table: 'person',
            relation: 'oneChild',
            fields: ['name', 'email'],
            key: 'person_id',
            listmini: true,
            alias: 'regonlineperson_id',
          },
          table: 'person',
          fieldName: 'person_id',
          target: ['maincrud'],
          label: 'table.person',
          fieldSource: {
            type: 'ID',
            typeInput: 'selectAutocomplete',
            disabledUpdate: true,
            onChange: true,
            validators: ['required'],
            add: true,
            inViewLink: '',
            recView: true,
            recZoom: true,
            dbtype: 'Json',
            table: 'person',
            loadAsync: true,
            saveonly: 'id',
            label: 'table.person',
          },
          tableRelated: {
            table: 'person',
            relation: 'oneChild',
            fields: ['name', 'email'],
            key: 'person_id',
            listmini: true,
            alias: 'regonlineperson_id',
          },
          type: 'ID',
          typeInput: 'selectAutocomplete',
          listSource: '',
        },
      },
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      customer_id: {
        preComponents: {
          components: {
            RegistrationGroupping: {
              condition: {
                compareTo: 'formState.actionsave',
                compareValue: 'add',
              },
              position: 'insideBottom',
            },
          },
        },
        type: 'ID',
        typeInput: 'selectAutocomplete',
        disabledUpdate: true,
        onChange: true,
        validators: ['required'],
        add: true,
        inViewLink: '',
        recView: true,
        recZoom: true,
        dbtype: 'Json',
        table: 'customer',
        loadAsync: true,
        saveonly: 'id',
        label: 'table.customer',
      },
      registrationgroupping_customers: {
        type: 'String',
        typeInput: 'hidden',
        virtual: true,
        disabledPreventExit: true,
        dbtype: 'String',
        label: 'form.registrationgroupping_customers',
      },
      tour_id: {
        type: 'ID',
        typeInput: 'selectAutocomplete',
        disabledUpdate: true,
        onChange: true,
        validators: ['required'],
        inViewLink: '',
        dbtype: 'ID',
        table: 'tour',
        label: 'table.tour',
      },
      registrationstatus_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        validators: ['required'],
        onChange: true,
        dbtype: 'String',
        table: 'registrationstatus',
        label: 'table.registrationstatus',
      },
      dateregistration: {
        type: 'String',
        dbtype: 'Date',
        typeInput: 'text',
        validators: ['date'],
        label: 'form.dateregistration',
      },
      confirmationstatus_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        dbtype: 'String',
        table: 'confirmationstatus',
        label: 'table.confirmationstatus',
      },
      inactive: {
        type: 'Boolean',
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.inactive',
      },
      customerstatus_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        formNewSection: true,
        onChange: true,
        dbtype: 'String',
        table: 'customerstatus',
        label: 'table.customerstatus',
      },
      customersubstatus_id: {
        type: 'String',
        label: 'form.substatus',
        typeInput: 'selectAutocomplete',
        filterBy: ['customerstatus_id'],
        subfield: 'substatus',
        table: 'customerstatus',
        dbtype: 'String',
      },
      tourroom_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        formNewSection: true,
        onDisabled: true,
        filterBy: ['tour_id'],
        onChange: true,
        toolBar: [
          {
            icon: 'bed',
            onClick: 'TourroomBooking',
            urlType: 'fn',
          },
        ],
        dbtype: 'String',
        table: 'tourroom',
        label: 'table.tourroom',
      },
      assignedroom: {
        type: 'Boolean',
        disabledMutation: true,
        view: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.assignedroom',
      },
      tollfree: {
        type: 'Boolean',
        typeInput: 'checkbox',
        onChange: true,
        formNewSection: true,
        dbtype: 'Boolean',
        label: 'form.tollfree',
      },
      price: {
        type: 'Float',
        typeValue: 'Price',
        disabledUpdate: true,
        disabledAdd: true,
        dbtype: 'Float',
        typeInput: 'text',
        normalize: 'Price',
        label: 'form.price',
      },
      paid: {
        type: 'Float',
        typeValue: 'Price',
        disabledUpdate: true,
        disabledAdd: true,
        defaultValue: 0,
        dbtype: 'Float',
        typeInput: 'text',
        normalize: 'Price',
        label: 'form.paid',
      },
      balance: {
        type: 'Float',
        typeValue: 'Price',
        disabledUpdate: true,
        disabledAdd: true,
        preComponents: {
          components: {
            PriceFormula: {
              position: 'outsideAfterCloseFieldsContainer',
            },
          },
        },
        dbtype: 'Float',
        typeInput: 'text',
        normalize: 'Price',
        label: 'form.balance',
      },
      _priceformula: {
        type: 'String',
        typeInput: 'hidden',
        virtual: true,
        dbtype: 'String',
        label: 'form._priceformula',
      },
      transportation: {
        type: 'String',
        dbtype: 'Json',
        label: 'table.transportations',
        labelChildsShow: true,
        placeHolderShowChildsShow: false,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          transportation_id: {
            type: 'String',
            typeInput: 'selectAutocomplete',
            filterBy: ['tour_id'],
            label: 'form.routeplanner',
            table: 'transportation',
            onChange: true,
            validators: ['required'],
            conditionalRequired: ['*isStopsPointFree', false],
          },
          stopspoint_id: {
            type: 'String',
            typeInput: 'selectAutocomplete',
            filterBy: ['transportation[*].transportation_id', 'tour_id'],
            conditionalfilterBy: ['*isStopsPointFree', false],
            table: 'stopspoint',
            conditionalTable: ['*isStopsPointFree', true],
            ql: 'getTourStopsPoints',
            validators: ['required'],
            label: 'table.stopspoint',
          },
        },
        formNewSection: true,
        typeInput: 'text',
        mode: {},
      },
      objects: {
        type: 'String',
        dbtype: 'Json',
        typeInput: 'hidden',
        inputTypeObject: true,
        label: 'form.objects',
      },
      notes: {
        type: 'String',
        typeInput: 'textarea',
        formNewSection: true,
        dbtype: 'String',
        label: 'form.notes',
      },
      counterhistory: {
        type: 'Int',
        disabledMutation: true,
        excludedInForm: true,
        label: 'form.crm',
        dbtype: 'Int',
        typeInput: 'text',
      },
      person_groupid: {
        disabledSystemUpdate: true,
        type: 'ID',
        dbtype: 'ID',
        excludedInForm: true,
        typeInput: 'text',
        label: 'form.person_groupid',
      },
      person_id: {
        disabledSystemUpdate: true,
        type: 'ID',
        dbtype: 'ID',
        excludedInForm: true,
        typeInput: 'text',
        label: 'form.person_groupid',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    toolbar: {
      menus: {},
    },
    listview: {
      fields: {
        tour_id: true,
        customer_id: true,
        registrationstatus_id: true,
        confirmationstatus_id: {
          label: 'form.confirmation_short',
          onlycolor: true,
          hiddenPhone: true,
        },
        price: true,
        balance: true,
        assignedroom: true,
        // "customerstatus_id": true
      },
    },
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    listOrder: '"registration"."updatedAt":DESC',
    norelated: {
      tourprice: {
        norelated: true,
        table: 'tourprice',
        fields: ['customerstatus_id', 'tour_id', 'price'],
      },
    },
    related: {
      customer_id: {
        table: 'customer',
        relation: 'oneChild',
        fields: ['name', 'email'],
        key: 'customer_id',
        listmini: true,
        alias: 'registrationcustomer_id',
      },
      tour_id: {
        table: 'tour',
        relation: 'oneChild',
        fields: ['name', 'seatsmax'],
        key: 'tour_id',
        listmini: true,
        alias: 'registrationtour_id',
      },
      tourroom_id: {
        table: 'tourroom',
        relation: 'oneChild',
        fields: ['room_type', 'hotel_id'],
        subfields: {
          room_type: true,
          hotel_id: true,
        },
        key: 'tourroom_id',
        related: {
          hotel_id: {
            table: 'hotel',
            relation: 'oneChild',
            fields: ['name'],
            key: 'hotel_id',
          },
        },
        alias: 'registrationtourroom_id',
      },
      registrationstatus_id: {
        table: 'registrationstatus',
        relation: 'oneChild',
        fields: ['name', 'color', 'statusbase'],
        key: 'registrationstatus_id',
        alias: 'registrationregistrationstatus_id',
      },
      confirmationstatus_id: {
        table: 'confirmationstatus',
        relation: 'oneChild',
        fields: ['name', 'color'],
        key: 'confirmationstatus_id',
        alias: 'registrationconfirmationstatus_id',
      },
      customerstatus_id: {
        table: 'customerstatus',
        relation: 'oneChild',
        fields: ['name'],
        key: 'customerstatus_id',
        alias: 'registrationcustomerstatus_id',
      },
    },
    name: {
      singular: 'registration',
      plural: 'registrations',
      singularCase: 'Registration',
      pluralCase: 'Registrations',
    },
    mainfield: 'customer_id',
    miniFields: ['id'],
    componentName: 'RegistrationView',
    path: '/registration-view/:id',
  },
  regonline: {
    triggers: true,
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.f1i2xryuseac',
    },
    formToolBarBottom: true,
    icon: 'registration',
    // "triggers": true, < --- important, pelevoyage logic id organizer, not addapted to triggers when use getRecord, give conflict , %% study for the future
    disableRecreateTemplateList: true,
    enabledResolverAdd: true, // important en voyages ; not for frontend, but for peleserver
    enabledResolverUpdate: true, // for voyage, let update fields like notes, before process the regonline
    disabledAdd: true, // for the frontend (toolbar), not for peleserver
    disabledDelete: true, // for the frontend (toolbar), not for peleserver
    listOrder: '"regonline"."updatedAt":DESC',
    afterAddGoView: true,
    preloadHeight: '500px',
    detail: {
      person_id: true,
      tour_id: true,
    },
    logFields: {
      person_id: true,
      tour_id: true,
    },
    uniquefields: {
      1: ['person_id', 'tour_id'],
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      person_id: {
        type: 'ID',
        // need be object to show well { id, name}, but simple id:
        // now , when peleser get a object in a type string, use value.id
        typeInput: 'selectAutocomplete',
        // "typeInput": "selectBox",  // dont work, because need {id , name } to shoh well
        disabledUpdate: true,
        validators: ['required'],
        inViewLink: '',
        dbtype: 'String', // Importante it's string, not json
        table: 'person',
        loadAsync: true,
        saveonly: 'id',
        label: 'table.person',
      },
      tour_id: {
        type: 'ID',
        typeInput: 'selectAutocomplete',
        disabledUpdate: true,
        onChange: true,
        validators: ['required'],
        inViewLink: '',
        dbtype: 'ID',
        table: 'tour',
        label: 'table.tour',
      },
      customerstatus_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        formNewSection: true,
        onChange: true,
        dbtype: 'String',
        ql: 'getTourCustomerStatusAndServices',
        label: 'table.customerstatus',
      },
      customersubstatus_id: {
        label: 'form.substatus',
        type: 'String',
        typeInput: 'selectAutocomplete',
        stateListOptions: true,
        formNewSection: true,
        onChange: true,
        dbtype: 'String',
      },
      dateregistration: {
        type: 'String',
        dbtype: 'Date',
        disabled: true,
        typeInput: 'text',
        validators: ['date'],
        label: 'form.dateregistration',
      },
      tourroom_id: {
        type: 'String',
        typeInput: 'selectBox',
        disabledUpdate: true,
        fieldDisplayedOptions: {
          subfields: {
            room_type: true,
            hotel_id: true,
            name: true,
          },
          separatorsubfield: ' / ',
          joinsubfields: false,
        },
        dbtype: 'String',
        table: 'tourroom',
        label: 'table.tourroom',
      },
      room_type: {
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'selectBox',
        listSource: 'roomBase',
        listmini: true,
        onChange: true,
        translateDisabledSource: true,
        label: 'form.room_type',
      },
      room_shared: {
        type: 'String',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.room_shared',
      },
      stopspoint_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        // "table": "stopspoint",
        ql: 'getTourStopsPoints',
        sublabel: '',
        sublabeldown: true,
        dbtype: 'String',
        label: 'table.stopspoint',
      },
      items: {
        type: 'String',
        dbtype: 'Json',
        typeInput: 'hidden',
        label: 'form.items',
      },
      objects: {
        type: 'String',
        dbtype: 'Json',
        typeInput: 'hidden',
        inputTypeObject: true,
        label: 'form.objects',
      },
      price: {
        type: 'Float',
        typeValue: 'Price',
        disabledUpdate: true,
        disabledAdd: true,
        dbtype: 'Float',
        typeInput: 'text',
        normalize: 'Price',
        label: 'form.price',
      },
      partialpayment: {
        type: 'Float',
        typeValue: 'Price',
        dbtype: 'Float',
        normalize: 'Price',
        label: 'form.partialpayment',
        sublabel: 'info.partialpayment',
        onChange: true,
        sublabeldown: true,
        validators: [], // important (same vide) to show errors, setted in crudCode (newStates.errors)
      },
      paid: {
        type: 'Float',
        typeValue: 'Price',
        disabledMutation: true,
        defaultValue: 0,
        dbtype: 'Float',
        typeInput: 'text',
        normalize: 'Price',
        label: 'form.paid',
      },

      signature: {
        type: 'String',
        typeInput: 'hidden',
        disabledSystemUpdate: true,
        excludedInForm: true,
        dbtype: 'String',
        label: 'form.signature',
      },
      payinput: {
        type: 'String',
        dbtype: 'Json',
        typeInput: 'hidden',
        label: 'form.payinput',
      },
      modetest: {
        type: 'Boolean',
        typeInput: 'hidden',
      },
      // voyages dont need payoutput,  (but peleadmin yes)
      state: {
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'selectBox',
        listSource: 'processRegonline',
        listmini: true,
        disabledOnAdd: true,
        defaultValue: 1,
        label: 'form.state',
      },

      notes: {
        type: 'String',
        typeInput: 'textarea',
        formNewSection: true,
        dbtype: 'String',
        label: 'form.notes',
      },
      person_groupid: {
        type: 'ID',
        dbtype: 'ID',
        typeInput: 'text',
        excludedInForm: true,
        label: 'form.person_groupid',
        disabledSystemUpdate: true,
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
      updated_user_id: {
        type: 'String',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.updated_user_id',
      },
      created_user_id: {
        type: 'String',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.created_user_id',
      },
      deleted_user_id: {
        type: 'String',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.deleted_user_id',
      },
    },
    toolbar: {
      menus: {},
    },
    listFilters: {
      fields: {
        person_id: {
          invisible: true, // temporally %%% until to fix the filter on voyages, but needed on server
          preload: true,
          tableRelatedAlias: {
            table: 'person',
            relation: 'oneChild',
            fields: ['name', 'email'],
            key: 'person_id',
            listmini: true,
            alias: 'regonlineperson_id',
          },
          table: 'person',
          fieldName: 'person_id',
          target: ['maincrud'],
          label: 'table.person',
          fieldSource: {
            type: 'ID',
            typeInput: 'selectAutocomplete',
            disabledUpdate: true,
            onChange: true,
            validators: ['required'],
            add: true,
            inViewLink: '',
            recView: true,
            recZoom: true,
            dbtype: 'Json',
            table: 'person',
            loadAsync: true,
            saveonly: 'id',
            label: 'table.person',
          },
          tableRelated: {
            table: 'person',
            relation: 'oneChild',
            fields: ['name', 'email'],
            key: 'person_id',
            listmini: true,
            alias: 'regonlineperson_id',
          },
          type: 'ID',
          typeInput: 'selectAutocomplete',
          listSource: '',
        },
        tour_id: {
          invisible: true, // temporally %%% until to fix the filter on voyages, but needed on server
          preload: true,
          tableRelatedAlias: {
            table: 'tour',
            relation: 'oneChild',
            fields: ['name', 'seatsmax'],
            key: 'tour_id',
            listmini: true,
            alias: 'regonlinetour_id',
          },
          table: 'tour',
          fieldName: 'tour_id',
          target: ['maincrud'],
          label: 'table.tour',
          fieldSource: {
            type: 'ID',
            typeInput: 'selectAutocomplete',
            disabledUpdate: true,
            onChange: true,
            validators: ['required'],
            inViewLink: '',
            dbtype: 'ID',
            table: 'tour',
            label: 'table.tour',
          },
          tableRelated: {
            table: 'tour',
            relation: 'oneChild',
            fields: ['name', 'seatsmax'],
            key: 'tour_id',
            listmini: true,
            alias: 'regonlinetour_id',
          },
          type: 'ID',
          typeInput: 'selectAutocomplete',
          listSource: '',
        },
        state: {
          invisible: true,
          tableRelatedAlias: '',
          table: '',
          fieldName: 'state',
          target: ['maincrud', 'listmini'],
          label: 'form.state',
          fieldSource: {
            type: 'Int',
            dbtype: 'Int',
            typeInput: 'selectBox',
            listSource: 'processRegonline',
            listmini: true,
            disabledUpdate: true,
            disabledOnAdd: true,
            disabledWhen: {
              init_statusbase: {
                $equal: 5,
              },
            },
            defaultValue: 3,
            label: 'form.state',
          },
          tableRelated: '',
          type: 'Int',
          typeInput: 'selectBox',
          listSource: 'processRegonline',
        },
      },
    },
    listview: {
      fields: {
        tour_id: 'longname',
        person_id: true,
        dateregistration: true,
        price: true,
        partialpayment: true,
        paid: true,
        state: true,
      },
    },
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    norelated: {
      tourprice: {
        norelated: true,
        table: 'tourprice',
        fields: ['customerstatus_id', 'tour_id', 'price'],
      },
    },
    related: {
      person_id: {
        table: 'person',
        relation: 'oneChild',
        fields: ['name', 'email'],
        key: 'person_id',
        listmini: true,
        alias: 'regonlineperson_id',
      },
      customer_id: {
        table: 'customer',
        relation: 'oneChild',
        fields: ['name', 'email'],
        key: 'customer_id',
        listmini: true,
        alias: 'regonlinecustomer_id',
      },
      tour_id: {
        table: 'tour',
        relation: 'oneChild',
        fields: ['name', 'seatsmax', 'longname'],
        key: 'tour_id',
        listmini: true,
        alias: 'regonlinetour_id',
      },
      tourroom_id: {
        table: 'tourroom',
        relation: 'oneChild',
        fields: ['room_type', 'hotel_id', 'id'],
        subfields: {
          room_type: true,
          hotel_id: true,
        },
        key: 'tourroom_id',
        subrelated: true,
        related: {
          hotel_id: {
            table: 'hotel',
            relation: 'oneChild',
            fields: ['name'],
            key: 'hotel_id',
          },
        },
        alias: 'regonlinetourroom_id',
      },
      stopspoint_id: {
        table: 'stopspoint',
        relation: 'oneChild',
        fields: ['name'],
        key: 'stopspoint_id',
        alias: 'regonlinestopspoint_id',
      },
      customerstatus_id: {
        table: 'customerstatus',
        relation: 'oneChild',
        fields: ['name', 'id'],
        key: 'customerstatus_id',
        alias: 'regonlinecustomerstatus_id',
      },
    },
    name: {
      singular: 'regonline',
      plural: 'regonlines',
      singularCase: 'Regonline',
      pluralCase: 'Regonlines',
    },
    mainfield: 'person_id',
    miniFields: ['id', 'state'],
    componentName: 'RegonlineView',
    path: '/regonline-view/:id',
  },
  payment: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#bookmark=id.6q6jc9i1en12',
      templates: {
        ajournal:
          'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#bookmark=id.auunr41vt05',
      },
    },
    formToolBarBottom: true,
    triggers: true,
    preloadHeight: '600px',
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    listFilters: {
      fields: {
        application_customer_id: {
          fieldContainer: 'application',
          subfield: 'customer_id',
          templates: ['.'],
          tableRelatedAlias: 'customer',
          table: 'customer',
          fieldName: 'application.customer_id',
          target: ['maincrud'],
          label: 'table.customer',
          fieldSource: {
            type: 'String',
            typeInput: 'selectAutocomplete',
            table: 'customer',
            add: true,
            onDebounce: 1500,
            recView: true,
            recZoom: true,
            loadAsync: true,
            saveonly: 'id',
            dbtype: 'Json',
            label: 'table.customer',
          },
          tableRelated: 'customer',
          type: 'String',
          typeInput: 'selectAutocomplete',
          listSource: '',
        },
        application_tour_id: {
          fieldContainer: 'application',
          subfield: 'tour_id',
          templates: ['.'],
          tableRelatedAlias: 'tour',
          table: 'tour',
          fieldName: 'application.tour_id',
          target: ['maincrud'],
          label: 'table.tour',
          fieldSource: {
            type: 'String',
            typeInput: 'selectAutocomplete',
            table: 'tour',
            validators: ['required'],
            onDebounce: 1500,
            label: 'table.tour',
          },
          tableRelated: 'tour',
          type: 'String',
          typeInput: 'selectAutocomplete',
          listSource: '',
        },
      },
    },
    orders: {
      noOptionBlank: true,
      label: 'form.order',
      inLine: true,
      defaultValue: 'updatedAt',
      defaultajournal: 'dateaccounting',
      fields: {
        updatedAt: {
          label: 'form.updateAtDesc',
          order: 'DESC',
        },
        datereception: {
          templates: ['.'],
        },
        dateaccounting: {},
        createdAt: {},
      },
    },
    icon: 'monetization',
    iconColor: '#af925c',
    afterAddGoView: true,
    disableRecreateTemplateList: true,
    toolbar: {
      menus: {},
    },
    detail: {
      name: true,
      amount: true,
    },
    logFields: {
      name: true,
      amount: true,
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String',
        label: 'form.issuer',
        validators: ['required'],
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
      },
      paymentmethod_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        validators: ['required'],
        onChange: true,
        dbtype: 'String',
        table: 'paymentmethod',
        label: 'table.paymentmethod',
      },
      amount: {
        type: 'Float',
        typeValue: 'Price',
        normalize: 'PriceNegative',
        onChange: true,
        validators: ['required'],
        listmini: true,
        dbtype: 'Float',
        typeInput: 'text',
        label: 'form.amount',
      },
      datereception: {
        type: 'String',
        dbtype: 'Date',
        listmini: true,
        defaultValue: 'f:today',
        onChange: true,
        validators: ['daysLimitOld:90', 'daysLimitOldToday', 'date'],
        typeInput: 'text',
        label: 'form.datereception',
      },
      datedeferred: {
        type: 'String',
        dbtype: 'Date',
        listmini: true,
        onChange: true,
        onDisabled: true,
        validators: ['daysLimitOld:60', 'daysLimitFuture:367', 'date'],
        typeInput: 'text',
        label: 'form.datedeferred',
      },
      dateaccounting: {
        type: 'String',
        dbtype: 'Date',
        listmini: true,
        onChange: true,
        defaultValue: 'f:today',
        validators: [
          'required',
          'daysLimitOld:90',
          'daysLimitFuture:367',
          'date',
        ],
        typeInput: 'text',
        label: 'form.dateaccounting',
      },
      bank_id: {
        formNewSection: true,
        type: 'String',
        dbtype: 'Json',
        typeInput: 'selectAutocomplete',
        add: true,
        listmini: true,
        table: 'bank',
        loadAsync: true,
        saveonly: 'id',
        label: 'table.bank',
      },
      bank_agence: {
        type: 'String',
        nameGroup: 'info',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.bank_agence',
      },
      paymentdocstatus_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        dbtype: 'String',
        table: 'paymentdocstatus',
        label: 'table.paymentdocstatus',
      },
      check_number: {
        type: 'String',
        nameGroup: 'info',
        listmini: true,
        validators: ['maxLength:25'],
        onDebounce: 1500,
        qlFilter: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.check_number',
      },
      application: {
        type: 'String',
        dbtype: 'Json',
        label: 'form.payment_application',
        sublabel: 'info.payment_application',
        labelChildsShow: true,
        placeHolderShowChildsShow: false,
        mode: {
          insertRowOnAdd: 1,
        },
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          customer_id: {
            type: 'String',
            typeInput: 'selectAutocomplete',
            table: 'customer',
            add: true,
            onDebounce: 1500,
            recView: true,
            recZoom: true,
            loadAsync: true,
            saveonly: 'id',
            dbtype: 'Json',
            label: 'table.customer',
          },
          tour_id: {
            type: 'String',
            typeInput: 'selectAutocomplete',
            table: 'tour',
            validators: ['required'],
            onDebounce: 1500,
            label: 'table.tour',
          },
          amount: {
            type: 'Float',
            typeValue: 'Price',
            normalize: 'PriceNegative',
            validators: ['required'],
            onChange: true,
            typeInput: 'text',
            label: 'form.amount',
          },
        },
        formNewSection: true,
        typeInput: 'text',
      },
      aentry: {
        module: 'ACCO',
        type: 'String',
        dbtype: 'Json',
        label: 'form.aentry',
        labelChildsShow: true,
        placeHolderShowChildsShow: false,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          atitle: {
            type: 'String',
            validators: ['maxLength:50'],
            typeInput: 'text',
            label: 'form.atitle',
          },
          amount: {
            type: 'Float',
            typeValue: 'Price',
            normalize: 'PriceNegative',
            validators: ['required'],
            onChange: true,
            typeInput: 'text',
            label: 'form.amount',
          },
          reference: {
            type: 'String',
            validators: ['maxLength:7'],
            typeInput: 'text',
            label: 'form.reference',
          },
        },
        formNewSection: true,
        typeInput: 'text',
        mode: {},
      },
      balance: {
        disabledMutation: true,
        label: 'form.applicationBalance',
        type: 'Float',
        typeValue: 'Price',
        formNewSection: true,
        dbtype: 'Float',
        typeInput: 'text',
        normalize: 'Price',
      },
      notes: {
        type: 'String',
        typeInput: 'textarea',
        nameGroup: 'info',
        formNewSection: true,
        dbtype: 'String',
        label: 'form.notes',
      },
      info: {
        type: 'String',
        dbtype: 'Json',
        fatherGroup: true,
        listmini: true,
        typeInput: 'text',
        label: 'form.info',
      },
      checkdeposit_id: {
        disabledMutation: true,
        type: 'String',
        excludedInForm: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.checkdeposit_id',
      },
      counterhistory: {
        type: 'Int',
        disabledMutation: true,
        excludedInForm: true,
        label: 'form.crm',
        dbtype: 'Int',
        typeInput: 'text',
      },
      person_groupid: {
        disabledSystemUpdate: true,
        type: 'ID',
        dbtype: 'ID',
        typeInput: 'text',
        label: 'form.person_groupid',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    listview: {
      fields: {
        name: true,
        paymentmethod_id: true,
        adebit: {
          templates: ['ajournal'],
          virtual: true,
          type: 'String',
          dbtype: 'Json',
          label: 'form.debit',
          style: {
            color: '#ed2939',
            fontWeight: 'bold',
            textAlign: 'right',
          },
          subfields: {
            amount: {
              type: 'Float',
              typeValue: 'Price',
            },
          },
        },
        acredit: {
          templates: ['ajournal'],
          virtual: true,
          type: 'String',
          dbtype: 'Json',
          label: 'form.credit',
          style: {
            color: '#3551de',
            fontWeight: 'bold',
            textAlign: 'right',
          },
          subfields: {
            amount: {
              type: 'Float',
              typeValue: 'Price',
            },
          },
        },
        abalance: {
          templates: ['ajournal'],
          virtual: true,
          type: 'String',
          dbtype: 'Json',
          label: 'form.balance',
          style: {
            color: '#000000',
            fontWeight: 'bold',
            textAlign: 'right',
          },
          subfields: {
            amount: {
              type: 'Float',
              typeValue: 'Price',
            },
          },
        },
        amount: true,
        application: {
          subfields: {
            amount: true,
            customer_id: true,
            tour_id: true,
            acode_id: {
              templates: ['ajournal'],
            },
          },
          separatorsubfield: ' / ',
          joinsubfields: false,
        },
        balance: {
          hiddenZero: true,
        },
        dateaccounting: {},
        datedeferred: {
          hiddenPhone: true,
          templates: ['.'],
        },
        datereception: {
          templates: ['.'],
        },
        check_number: {
          hiddenPhone: true,
        },
        paymentdocstatus_id: true,
        checkdeposit_id: {
          label: 'form.checkdeposit_id',
        },
      },
    },
    norelated: {
      customer: {
        norelated: true,
        table: 'customer',
        fields: ['name'],
      },
    },
    related: {
      bank_id: {
        table: 'bank',
        relation: 'oneChild',
        fields: ['name'],
        key: 'bank_id',
        alias: 'paymentbank_id',
      },
      paymentmethod_id: {
        table: 'paymentmethod',
        relation: 'oneChild',
        fields: ['name', 'color', 'statusbase', 'usedatedeferred'],
        key: 'paymentmethod_id',
        alias: 'paymentpaymentmethod_id',
      },
      checkdeposit_id: {
        table: 'checkdeposithead',
        relation: 'oneChild',
        fields: ['name'],
        key: 'checkdeposit_id',
        alias: 'paymentcheckdeposit_id',
      },
      paymentdocstatus_id: {
        table: 'paymentdocstatus',
        relation: 'oneChild',
        fields: ['name', 'color', 'statusbase'],
        key: 'paymentdocstatus_id',
        alias: 'paymentpaymentdocstatus_id',
      },
    },
    name: {
      singular: 'payment',
      plural: 'payments',
      singularCase: 'Payment',
      pluralCase: 'Payments',
    },
    mainfield: 'name',
    miniFields: [
      'id',
      'name',
      'amount',
      'datereception',
      'datedeferred',
      'dateaccounting',
      'bank_id',
      'info',
    ],
    componentName: 'PaymentView',
    path: '/payment-view/:id',
  },
  checkdeposithead: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.ljmqmn7j538g',
    },
    formToolBarBottom: true,
    triggers: true,
    preloadHeight: '600px',
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
    ],
    listOrder: '"checkdeposithead"."createdAt":DESC',
    icon: 'checkdepositlist',
    iconColor: '#af925c',
    disabledDeleteWhen: {
      statusbase: {
        $equal: 5,
      },
    },
    afterAddGoView: true,
    disabledOverWrite: {
      view: true,
    },
    listFilters: {
      fields: {
        name: {
          target: ['maincrud', 'listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.number',
          fieldSource: {
            type: 'String',
            label: 'form.number',
            validators: ['required'],
            disabledWhen: {
              init_statusbase: {
                $equal: 5,
              },
            },
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'String',
          typeInput: 'text',
          listSource: '',
        },
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    toolbar: {
      menus: {
        checkdepositdet: {
          icon: 'checkbank',
          label: 'table.checkdepositdet',
          component: 'checkdepositdet',
          urlType: 'child',
          inList: true,
          inView: true,
        },
        payment: {
          icon: 'monetization',
          label: 'table.payments',
          urlType: 'link',
          url: '/payment',
          inListHeader: true,
        },
        download: {
          icon: 'download',
          label: 'table.checkdepositdet',
          urlType: 'filter',
          inList: true,
          inView: true,
        },
      },
    },
    detail: {
      name: true,
      amount: true,
    },
    logFields: {
      name: true,
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String',
        label: 'form.number',
        validators: ['required'],
        disabledWhen: {
          init_statusbase: {
            $equal: 5,
          },
        },
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
      },
      statusbase: {
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'selectBox',
        listSource: 'processTransaction',
        listmini: true,
        disabledOnAdd: true,
        disabledWhen: {
          init_statusbase: {
            $equal: 5,
          },
        },
        defaultValue: 3,
        validators: ['required'],
        label: 'form.statusbase',
      },
      date: {
        type: 'String',
        dbtype: 'Date',
        defaultValue: 'f:today',
        validators: ['daysLimitOld:30', 'date'],
        disabledWhen: {
          init_statusbase: {
            $equal: 5,
          },
        },
        formNewSection: true,
        typeInput: 'text',
        label: 'form.date',
      },
      dateaccounting: {
        type: 'String',
        dbtype: 'Date',
        validators: [
          'daysLimitFuture:30',
          {
            validator: 'cantBeLowerThan',
            field: 'date',
          },
          'date',
        ],
        typeInput: 'text',
        label: 'form.dateaccounting',
      },
      notes: {
        type: 'String',
        typeInput: 'textarea',
        nameGroup: 'info',
        formNewSection: true,
        dbtype: 'String',
        label: 'form.notes',
      },
      info: {
        type: 'String',
        dbtype: 'Json',
        fatherGroup: true,
        typeInput: 'text',
        label: 'form.info',
      },
      lastcheckdepositopen: {
        type: 'ID',
        excludedInForm: true,
        disabledMutation: true,
        virtual: true,
        dbtype: 'ID',
        typeInput: 'text',
        label: 'form.lastcheckdepositopen',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    listview: {
      fields: {
        name: true,
        statusbase: true,
        date: true,
        dateaccounting: true,
      },
    },
    name: {
      singular: 'checkdeposithead',
      plural: 'checkdepositheads',
      singularCase: 'Checkdeposithead',
      pluralCase: 'Checkdepositheads',
    },
    mainfield: 'name',
    miniFields: ['id', 'name', 'statusbase'],
    componentName: 'CheckdepositheadView',
    path: '/checkdeposithead-view/:id',
  },
  checkdepositdet: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.ljmqmn7j538g',
    },
    parentTable: 'checkdeposithead',
    parentRelationField: 'checkdeposithead_id',
    disableRecreateTemplateList: true,
    disabledView: true,
    disabledUpdate: true,
    disabledPermissionOption: true,
    permissionTable: 'checkdeposithead',
    disabledAdd: true,
    toolbarTable: 'payment',
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      payment_id: {
        type: 'String',
        typeInput: 'hidden',
        isId: true,
        label: 'form.checkbank',
        fieldDisplayedOptions: {
          subfields: {
            name: true,
            bank_id: true,
            bank_agence: true,
            check_number: true,
            datedeferred: true,
            amount: true,
            dateaccounting: true,
            datereception: true,
          },
          separatorsubfield: 'col',
        },
        dbtype: 'String',
      },
      checkdeposithead_id: {
        type: 'String',
        typeInput: 'hidden',
        isId: true,
        dbtype: 'String',
        label: 'form.checkdeposithead_id',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    listview: {
      fields: {
        payment_id: {},
      },
    },
    icon: 'checkbank',
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
    ],
    orders: {
      noOptionBlank: true,
      defaultValue: 'datedeferredDesc',
      label: 'form.order',
      inLine: true,
      fields: {
        datedeferredDesc: {
          label: 'form.datedeferred',
          mainRelated: 'checkdepositdetpayment_id',
          order: 'ASC',
          fieldName: 'datedeferred',
        },
        datereception: {
          label: 'form.datereception',
          mainRelated: 'checkdepositdetpayment_id',
          order: 'ASC',
          fieldName: 'datereception',
        },
        amountAsc: {
          label: 'form.amount',
          mainRelated: 'checkdepositdetpayment_id',
          order: 'ASC',
          fieldName: 'amount',
        },
      },
    },
    related: {
      payment_id: {
        table: 'payment',
        relation: 'oneChild',
        fields: [
          'name',
          'datereception',
          'datedeferred',
          'dateaccounting',
          'bank_id',
          'bank_agence',
          'check_number',
          'amount',
        ],
        key: 'payment_id',
        listmini: true,
        subrelated: true,
        alias: 'checkdepositdetpayment_id',
      },
      checkdeposithead_id: {
        table: 'checkdeposithead',
        isParent: true,
        relation: 'oneChild',
        fields: ['name'],
        key: 'checkdeposithead_id',
        alias: 'checkdepositdetcheckdeposithead_id',
      },
    },
    name: {
      singular: 'checkdepositdet',
      plural: 'checkdepositdets',
      singularCase: 'Checkdepositdet',
      pluralCase: 'Checkdepositdets',
    },
    mainfield: 'payment_id',
    miniFields: ['id'],
    listFilters: {
      fields: {
        checkdeposithead_id: {
          tableRelatedAlias: {
            table: 'checkdeposithead',
            isParent: true,
            relation: 'oneChild',
            fields: ['name'],
            key: 'checkdeposithead_id',
            alias: 'checkdepositdetcheckdeposithead_id',
          },
          table: 'checkdeposithead',
          fieldName: 'checkdeposithead_id',
          target: ['maincrud'],
          label: 'form.checkdeposithead_id',
          fieldSource: {
            type: 'String',
            typeInput: 'hidden',
            isId: true,
            dbtype: 'String',
            label: 'form.checkdeposithead_id',
          },
          tableRelated: {
            table: 'checkdeposithead',
            isParent: true,
            relation: 'oneChild',
            fields: ['name'],
            key: 'checkdeposithead_id',
            alias: 'checkdepositdetcheckdeposithead_id',
          },
          type: 'String',
          typeInput: 'hidden',
          listSource: '',
        },
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'CheckdepositdetList',
    path: '/checkdeposithead-child/:parentid/checkdepositdet',
  },
  export: {
    pollInterval: 5,
    listOrder: '"export"."updatedAt":DESC',
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#bookmark=id.h1zyok6hg9u2',
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String!',
        validators: ['required', 'minLength:3', 'maxLength:30'],
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      state: {
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'selectBox',
        listSource: 'processBaseStatus',
        listmini: true,
        disabledOnAdd: true,
        sublabel: 'info.exportstate',
        defaultValue: 1,
        icon: true,
        label: 'form.state',
      },
      exportdate: {
        disabled: true,
        type: 'String',
        dbtype: 'Datetime',
        hiddenOnAdd: true,
        disabledSystemUpdate: true,
        typeInput: 'text',
        label: 'form.exportdate',
      },
      files: {
        type: 'String',
        dbtype: 'Json',
        placeHolderShowChildsShow: true,
        mode: {
          disabledRowAddOnUpdate: true,
          disabledRowInputsOnUpdate: true,
          disabledRowDeleteOnUpdate: true,
        },
        sublabel: 'info.exportfiles',
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          tour_id: {
            type: 'String',
            typeInput: 'selectAutocomplete',
            table: 'tour',
            validators: ['required'],
            validateIfEmpty: 'sinfo',
            hiddenOnUpdate: true,
            label: 'table.tour',
          },
          badges: {
            type: 'Boolean',
            typeInput: 'checkbox',
            disabledOnUpdate: true,
            label: 'form.badges',
          },
          labels: {
            type: 'Boolean',
            typeInput: 'checkbox',
            disabledOnUpdate: true,
            label: 'form.labels',
          },
          filters: {
            type: 'Int',
            dbtype: 'Int',
            typeInput: 'selectBox',
            listOptions: [
              {
                id: '',
                name: 'form.no',
              },
              {
                id: 10,
                name: 'table.registrations',
              },
              {
                id: 20,
                name: 'table.transportations',
              },
              {
                id: 30,
                name: 'table.hotel',
              },
              {
                id: 40,
                name: 'table.services',
              },
              {
                id: 50,
                name: 'table.payments',
              },
            ],
            disabledOnUpdate: true,
            label: 'form.filters',
          },
          sinfo: {
            label: 'table.tour',
            type: 'String',
            hiddenOnAdd: true,
            disabled: true,
            typeInput: 'text',
          },
          file: {
            type: 'String',
            disabled: true,
            hiddenOnAdd: true,
            url: '{DOWN_URI}{link}',
            typeInput: 'urlLink',
            noLabel: true,
            label: 'form.file',
          },
        },
        typeInput: 'text',
        label: 'form.files',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    listview: {
      fields: {
        name: true,
        state: true,
        exportdate: true,
        files: {
          subfields: {
            file: true,
            sinfo: true,
          },
          joinsubfields: true,
        },
      },
    },
    icon: 'export',
    formlayout: ['1Row1Field'],
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    name: {
      singular: 'export',
      plural: 'exports',
      singularCase: 'Export',
      pluralCase: 'Exports',
    },
    mainfield: 'name',
    miniFields: ['id', 'name', 'state'],
    listFilters: {
      fields: {
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
        name: {
          target: ['listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String!',
            validators: ['required', 'minLength:3', 'maxLength:30'],
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'ExportView',
    path: '/export-view/:id',
  },
  hotel: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.v9cg88egwi1d',
    },
    uniquefields: {
      1: ['name'],
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String',
        validators: ['required', 'minLength:3', 'maxLength:22'],
        sublabel: 'info.hotelshortname',
        sublabeldown: true,
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      longname: {
        type: 'String',
        validators: ['required', 'minLength:3'],
        sublabel: 'info.longname',
        sublabeldown: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.longname',
      },
      email: {
        type: 'String',
        validators: ['email'],
        normalize: 'LowerCase',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.email',
      },
      phone: {
        type: 'String',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.phone',
      },
      tour_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        dbtype: 'String',
        table: 'tour',
        label: 'table.tour',
      },
      info: {
        type: 'String',
        dbtype: 'Json',
        fatherGroup: true,
        typeInput: 'text',
        label: 'form.info',
      },
      addressline1: {
        type: 'String',
        placeHolderShow: true,
        nameGroup: 'info',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.addressline1',
      },
      addressline2: {
        type: 'String',
        placeHolderShow: true,
        nameGroup: 'info',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.addressline2',
      },
      cp: {
        type: 'String',
        placeHolderShow: true,
        inputSize: 7,
        nameGroup: 'info',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.cp',
      },
      city: {
        type: 'String',
        placeHolderShow: true,
        nameGroup: 'info',
        sublabel: 'info.citywillnotbeadd',
        sublabeldown: true,
        validators: ['required'],
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.city',
      },
      cityid: {
        type: 'ID',
        placeHolderShow: true,
        typeInput: 'hidden',
        nameGroup: 'info',
        dbtype: 'ID',
        label: 'form.cityid',
      },
      country: {
        type: 'String',
        validators: ['required'],
        typeInput: 'selectAutocomplete',
        listSource: 'countries',
        saveIdOnly: true,
        nameGroup: 'info',
        dbtype: 'String',
        translateDisabledSource: true,
        label: 'form.country',
      },
      contacts: {
        type: 'String',
        dbtype: 'Json',
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          name: {
            type: 'String',
            placeHolderShow: true,
            validators: ['required'],
            typeInput: 'text',
            label: 'form.name',
          },
          phone: {
            type: 'String',
            icon: 'phoneblack',
            typeInput: 'text',
            label: 'form.phone',
          },
          email: {
            type: 'String',
            validators: ['email'],
            typeInput: 'text',
            label: 'form.email',
          },
        },
        typeInput: 'text',
        label: 'form.contacts',
        mode: {},
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    listview: {
      fields: {
        name: true,
        phone: true,
        email: true,
        city: true,
        country: true,
        contacts: {
          subfields: {
            name: true,
            phone: true,
            email: true,
          },
          joinsubfields: true,
        },
      },
    },
    related: {
      tour_id: {
        table: 'tour',
        relation: 'oneChild',
        fields: ['name'],
        key: 'tour_id',
        alias: 'hoteltour_id',
      },
    },
    icon: 'hotel',
    formToolBarBottom: true,
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    name: {
      singular: 'hotel',
      plural: 'hotels',
      singularCase: 'Hotel',
      pluralCase: 'Hotels',
    },
    mainfield: 'name',
    miniFields: ['id', 'name'],
    listFilters: {
      fields: {
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
        name: {
          target: ['listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String',
            validators: ['required', 'minLength:3', 'maxLength:22'],
            sublabel: 'info.hotelshortname',
            sublabeldown: true,
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'HotelView',
    path: '/hotel-view/:id',
  },
  stopspoint: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.jydy3evs3108',
    },
    icon: 'stopspoint',
    uniquefields: {
      1: ['name'],
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String',
        sublabel: 'info.stopspointname',
        sublabeldown: true,
        validators: ['required', 'minLength:3', 'maxLength:40'],
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      sinfo: {
        type: 'String',
        typeInput: 'textarea',
        formNewSection: true,
        sublabel: 'info.stopspoint_sinfo',
        sublabeldown: true,
        dbtype: 'String',
        label: 'form.sinfo',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
      organizer_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        validators: ['required'],
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'String',
        table: 'organizer',
        label: 'table.organizer',
      },
    },
    listview: {
      fields: {
        name: true,
        sinfo: true,
      },
    },
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    name: {
      singular: 'stopspoint',
      plural: 'stopspoints',
      singularCase: 'Stopspoint',
      pluralCase: 'Stopspoints',
    },
    mainfield: 'name',
    miniFields: ['id', 'name'],
    listFilters: {
      fields: {
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
        name: {
          target: ['listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String',
            sublabel: 'info.stopspointname',
            sublabeldown: true,
            validators: ['required', 'minLength:3', 'maxLength:40'],
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'StopspointView',
    path: '/stopspoint-view/:id',
  },
  service: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.9taif8hta3qn',
    },
    triggers: true,
    listOrder: 'statusbase:ASC',
    uniquefields: {
      1: ['name'],
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String!',
        validators: ['required', 'minLength:3', 'maxLength:28'],
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      statusbase: {
        type: 'Int',
        dbtype: 'Int',
        label: 'form.servicebase',
        typeInput: 'selectBox',
        validators: ['required'],
        listSource: 'servicetBaseStatus',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    listview: {
      fields: {
        name: true,
        statusbase: true,
      },
    },
    icon: 'service',
    formlayout: ['1Row1Field'],
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],

    name: {
      singular: 'service',
      plural: 'services',
      singularCase: 'Service',
      pluralCase: 'Services',
    },
    mainfield: 'name',
    miniFields: ['id', 'name'],
    listFilters: {
      fields: {
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
        name: {
          target: ['listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String!',
            validators: ['required', 'minLength:3', 'maxLength:28'],
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'ServiceView',
    path: '/service-view/:id',
  },
  city: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.w5n5bayg1e6m',
    },
    loadAsync: true,
    icon: 'city',
    afterAddGoView: true,
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    orders: {
      noOptionBlank: true,
      defaultValue: 'name',
      label: 'form.order',
      inLine: true,
      fields: {
        name: {},
        createdAt: {},
        updatedAt: {
          label: 'form.updateAtDesc',
          order: 'DESC',
        },
      },
    },
    listview: {
      fields: {
        name: true,
        pcs: {
          subfields: {
            pc: true,
          },
          joinsubfields: true,
        },
        createdAt: true,
        updatedAt: true,
      },
    },
    listFilters: {
      fields: {
        name: {
          target: ['maincrud', 'listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String',
            validators: ['required'],
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String',
          typeInput: 'text',
          listSource: '',
        },
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    uniquefields: {
      1: ['name', 'country'],
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String',
        validators: ['required'],
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      pcs: {
        type: 'String',
        dbtype: 'Json',
        mode: {
          insertRowOnAdd: 1,
          minimalRows: 1,
        },
        listmini: true,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          pc: {
            type: 'String',
            placeHolderShow: true,
            validators: ['required', 'minLength:5', 'maxLength:5'],
            typeInput: 'text',
            label: 'form.pc',
          },
        },
        typeInput: 'text',
        label: 'form.pcs',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    name: {
      singular: 'city',
      plural: 'citys',
      singularCase: 'City',
      pluralCase: 'Citys',
    },
    mainfield: 'name',
    miniFields: ['id', 'name', 'pcs'],
    componentName: 'CityView',
    path: '/city-view/:id',
  },
  registrationstatus: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.34nu25nhigx0',
    },
    listOrder: 'statusbase:ASC',
    uniquefields: {
      1: ['name'],
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String!',
        validators: ['required', 'minLength:3', 'maxLength:25'],
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      statusbase: {
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'selectAutocomplete',
        label: 'form.registration_statusbase',
        sublabel: 'form.registration_statusbase_sublabel',
        listSource: 'registrationBaseStatus',
        validators: ['required'],
        listmini: true,
        onChange: true,
      },
      color: {
        type: 'String',
        typeInput: 'color',
        validators: ['minLength:7', 'maxLength:7'],
        sublabel: 'form.colorIsDefaultYouCanChange',
        listmini: true,
        dbtype: 'String',
        label: 'form.color',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    listview: {
      fields: {
        name: true,
        statusbase: true,
        color: true,
      },
    },
    icon: 'permcontactcalendar',
    formlayout: ['1Row1Field'],
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    name: {
      singular: 'registrationstatus',
      plural: 'registrationstatuss',
      singularCase: 'Registrationstatus',
      pluralCase: 'Registrationstatuss',
    },
    mainfield: 'name',
    miniFields: ['id', 'name', 'statusbase', 'color'],
    listFilters: {
      fields: {
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
        name: {
          target: ['listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String!',
            validators: ['required', 'minLength:3', 'maxLength:25'],
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'RegistrationstatusView',
    path: '/registrationstatus-view/:id',
  },
  confirmationstatus: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.uk840qoaxn54',
    },
    listOrder: 'statusbase:ASC',
    uniquefields: {
      1: ['name'],
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String!',
        validators: ['required', 'minLength:3', 'maxLength:25'],
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      statusbase: {
        type: 'Int',
        dbtype: 'Int',
        validators: ['required'],
        typeInput: 'selectAutocomplete',
        listSource: 'confirmationBaseStatus',
        label: 'form.confirmation_statusbase',
        sublabel: 'form.confirmation_statusbase_sublabel',
        onChange: true,
        listmini: true,
      },
      color: {
        type: 'String',
        typeInput: 'color',
        validators: ['minLength:7', 'maxLength:7'],
        sublabel: 'form.colorIsDefaultYouCanChange',
        listmini: true,
        dbtype: 'String',
        label: 'form.color',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    listview: {
      fields: {
        name: true,
        statusbase: true,
        color: true,
      },
    },
    icon: 'check',
    formlayout: ['1Row1Field'],
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    name: {
      singular: 'confirmationstatus',
      plural: 'confirmationstatuss',
      singularCase: 'Confirmationstatus',
      pluralCase: 'Confirmationstatuss',
    },
    mainfield: 'name',
    miniFields: ['id', 'name', 'statusbase', 'color'],
    listFilters: {
      fields: {
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
        name: {
          target: ['listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String!',
            validators: ['required', 'minLength:3', 'maxLength:25'],
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'ConfirmationstatusView',
    path: '/confirmationstatus-view/:id',
  },
  bank: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.tbws6cah3vw',
    },
    afterAddGoView: true,
    loadAsync: true,
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String!',
        validators: ['required', 'minLength:3'],
        ilikefull: true,
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    icon: 'bank',
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    listview: {
      fields: {
        name: true,
      },
    },
    name: {
      singular: 'bank',
      plural: 'banks',
      singularCase: 'Bank',
      pluralCase: 'Banks',
    },
    mainfield: 'name',
    miniFields: ['id', 'name'],
    listFilters: {
      fields: {
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
        name: {
          target: ['listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String!',
            validators: ['required', 'minLength:3'],
            ilikefull: true,
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'BankView',
    path: '/bank-view/:id',
  },
  paymentmethod: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.cnrq085ryfil',
    },
    uniquefields: {
      1: ['name'],
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String!',
        formNewSection: true,
        validators: ['required', 'minLength:3', 'maxLength:32'],
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      statusbase: {
        formNewSection: true,
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'selectBox',
        listSource: 'paymentMethodBaseStatus',
        validators: ['required'],
        listmini: true,
        onChange: true,
        label: 'form.statusbase',
      },
      color: {
        type: 'String',
        typeInput: 'color',
        validators: ['minLength:7', 'maxLength:7'],
        dbtype: 'String',
        label: 'form.color',
      },
      usedatedeferred: {
        type: 'Boolean',
        typeInput: 'checkbox',
        sublabel: 'info.usedatedeferred',
        listmini: true,
        formNewSection: true,
        dbtype: 'Boolean',
        label: 'form.usedatedeferred',
      },
      anature: {
        formNewSection: true,
        listmini: true,
        sublabel: 'info.anature',
        type: 'String',
        typeInput: 'selectBox',
        validators: ['required'],
        listOptions: [
          {
            id: 'C',
            name: 'form.credit',
          },
          {
            id: 'D',
            name: 'form.debit',
          },
        ],
        dbtype: 'String',
        label: 'form.anature',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    listview: {
      fields: {
        name: true,
        statusbase: true,
        usedatedeferred: true,
        color: true,
        anature: {},
      },
    },
    icon: 'localatm',
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    name: {
      singular: 'paymentmethod',
      plural: 'paymentmethods',
      singularCase: 'Paymentmethod',
      pluralCase: 'Paymentmethods',
    },
    mainfield: 'name',
    miniFields: ['id', 'name', 'statusbase', 'usedatedeferred', 'anature'],
    listFilters: {
      fields: {
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
        name: {
          target: ['listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String!',
            formNewSection: true,
            validators: ['required', 'minLength:3', 'maxLength:32'],
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'PaymentmethodView',
    path: '/paymentmethod-view/:id',
  },
  paymentdocstatus: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.xj6s8vc0k0xb',
    },
    listOrder: 'statusbase:ASC',
    uniquefields: {
      1: ['name'],
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String!',
        validators: ['required', 'minLength:3', 'maxLength:25'],
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      statusbase: {
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'selectBox',
        listSource: 'paymentDocBaseStatus',
        validators: ['required'],
        onChange: true,
        listmini: true,
        label: 'form.statusbase',
      },
      color: {
        type: 'String',
        typeInput: 'color',
        sublabel: 'form.colorIsDefaultYouCanChange',
        validators: ['minLength:7', 'maxLength:7'],
        listmini: true,
        dbtype: 'String',
        label: 'form.color',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    listview: {
      fields: {
        name: true,
        statusbase: true,
        color: true,
      },
    },
    icon: 'paymentdocstatus',
    formlayout: ['1Row1Field'],
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    name: {
      singular: 'paymentdocstatus',
      plural: 'paymentdocstatuss',
      singularCase: 'Paymentdocstatus',
      pluralCase: 'Paymentdocstatuss',
    },
    mainfield: 'name',
    miniFields: ['id', 'name', 'statusbase', 'color'],
    listFilters: {
      fields: {
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
        name: {
          target: ['listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String!',
            validators: ['required', 'minLength:3', 'maxLength:25'],
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'PaymentdocstatusView',
    path: '/paymentdocstatus-view/:id',
  },
  customerstatus: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.ocfrv7wxqy0c',
    },
    uniquefields: {
      1: ['name'],
    },
    orders: {
      noOptionBlank: true,
      defaultValue: 'name',
      label: 'form.order',
      inLine: true,
      fields: {
        name: {},
        createdAt: {},
      },
    },
    listview: {
      fields: {
        name: true,
        createdAt: true,
      },
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String!',
        validators: ['required', 'minLength:3', 'maxLength:25'],
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      substatus: {
        type: 'String',
        dbtype: 'Json',
        listmini: true,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          option: {
            type: 'String',
            validators: ['required', 'minLength:3'],
            typeInput: 'text',
            label: 'form.option',
          },
        },
        typeInput: 'text',
        label: 'form.substatus',
        mode: {},
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    icon: 'recordvoice',
    formlayout: ['1Row1Field'],
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    name: {
      singular: 'customerstatus',
      plural: 'customerstatuss',
      singularCase: 'Customerstatus',
      pluralCase: 'Customerstatuss',
    },
    mainfield: 'name',
    miniFields: ['id', 'name', 'substatus'],
    listFilters: {
      fields: {
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
        name: {
          target: ['listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String!',
            validators: ['required', 'minLength:3', 'maxLength:25'],
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String!',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'CustomerstatusView',
    path: '/customerstatus-view/:id',
  },
  gendertype: {
    help: {
      'fr-PELE':
        'https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.lbh1p9lh3l54',
    },
    uniquefields: {
      1: ['name'],
    },
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      name: {
        type: 'String',
        validators: ['required'],
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.name',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
    },
    icon: 'heart',
    formlayout: ['1Row1Field'],
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
    ],
    listview: {
      fields: {
        name: true,
      },
    },
    name: {
      singular: 'gendertype',
      plural: 'gendertypes',
      singularCase: 'Gendertype',
      pluralCase: 'Gendertypes',
    },
    mainfield: 'name',
    miniFields: ['id', 'name'],
    listFilters: {
      fields: {
        id: {
          target: 'listmini',
          tableRelatedAlias: '',
          table: '',
          fieldName: 'id',
          fieldSource: {
            type: 'ID!',
            listmini: true,
            dbtype: 'ID',
            typeInput: 'text',
          },
          tableRelated: '',
          type: 'ID!',
          typeInput: 'text',
          listSource: '',
        },
        name: {
          target: ['listmini'],
          tableRelatedAlias: '',
          table: '',
          fieldName: 'name',
          label: 'form.name',
          fieldSource: {
            type: 'String',
            validators: ['required'],
            listmini: true,
            dbtype: 'String',
            typeInput: 'text',
            label: 'form.name',
          },
          tableRelated: '',
          type: 'String',
          typeInput: 'text',
          listSource: '',
        },
      },
    },
    componentName: 'GendertypeView',
    path: '/gendertype-view/:id',
  },
  person: {
    triggers: true,
    enabledResolverUpdate: true,
    enabledResolverDelete: true,
    disabledDelete: true,
    enabledResolverAdd: true, // important en voyages ; not for frontend, but for peleserver
    orders: {
      noOptionBlank: true,
      defaultValue: 'updatedAt',
      label: 'form.order',
      inLine: true,
      fields: {
        name: {},
        updatedAt: {
          label: 'form.updateAtDesc',
          order: 'DESC',
        },
        createdAt: {},
      },
    },
    listOrder: '"person"."updatedAt":DESC',
    afterAddGoView: true,
    icon: 'tagfaces',
    preloadHeight: '700px',
    loadAsync: true,
    formToolBarBottom: true,
    destination: [
      'serverSchema',
      'serverResolver',
      'ormModels',
      'components',
      'app',
      'menus',
    ],
    fields: {
      id: {
        type: 'ID!',
        listmini: true,
        dbtype: 'ID',
        typeInput: 'text',
      },
      gendertype_id: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        listmini: false,
        dbtype: 'String',
        table: 'gendertype',
        label: 'table.gendertype',
      },
      name: {
        type: 'String',
        disabledMutation: true,
        excludedInForm: true,
        listmini: true,
        label: 'form.fullname',
        dbtype: 'String',
        typeInput: 'text',
      },
      lastname: {
        type: 'String',
        validators: ['required', 'alphai18n', 'minLength:3'],
        normalize: 'UpperCase',
        onDebounce: 1500,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.lastname',
      },
      firstname: {
        type: 'String',
        validators: ['required', 'alphai18n', 'minLength:3'],
        normalize: 'StartCase',
        onDebounce: 1500,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.firstname',
      },
      email: {
        type: 'String',
        validators: ['email'],
        normalize: 'LowerCase',
        listmini: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.email',
        disabledSystemUpdate: true,
      },
      nick: {
        type: 'String',
        /*
          // has no validator because is never included in form  
          "validators": [
            "required",
            "minLength:6",
            "maxLength:15"
          ],*/
        disabledUpdate: true,
        normalize: 'LowerCaseTrim',
        sublabel: 'info.uniquenick',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.nick',
        excludedInForm: true,
        disabledSystemUpdate: true,
      },
      occupation: {
        type: 'String',
        nameGroup: 'info',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.occupation',
      },
      imageright: {
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'selectBox',
        listSource: 'yesNoEmpty',
        validators: ['required'],
        label: 'form.imageright',
      },
      deceased: {
        type: 'Boolean',
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deceased',
      },
      birthdate: {
        type: 'String',
        dbtype: 'Date',
        formNewSection: true,
        typeInput: 'text',
        validators: ['required', 'date'],
        label: 'form.birthdate',
      },
      grouped_customer_id: {
        type: 'String',
        label: 'form.grouped_customer_id',
        typeInput: 'selectBox',
        formNewSection: true,
        dbtype: 'String', // importa for person
        table: 'person',
        loadAsync: true,
        saveonly: 'id',
      },
      grouped_filiation: {
        type: 'Int',
        dbtype: 'Int',
        typeInput: 'selectBox',
        label: 'form.grouped_filiation',
        listSource: 'filiation',
      },
      info: {
        type: 'String',
        dbtype: 'Json',
        fatherGroup: true,
        typeInput: 'text',
        label: 'form.info',
      },
      birthplace: {
        type: 'String',
        nameGroup: 'info',
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.birthplace',
      },
      birthcountry: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        listSource: 'countries',
        nameGroup: 'info',
        dbtype: 'String',
        translateDisabledSource: true,
        label: 'form.birthcountry',
      },
      nationality: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        listSource: 'countries',
        dbtype: 'String',
        translateDisabledSource: true,
        label: 'form.nationality',
        validators: ['required'],
      },
      residence: {
        type: 'String',
        typeInput: 'selectAutocomplete',
        listSource: 'countries',
        dbtype: 'String',
        translateDisabledSource: true,
        label: 'form.residence',
        validators: ['required'],
      },
      nameunmarried: {
        type: 'String',
        nameGroup: 'info',
        sublabel: 'info.nameunmarried',
        sublabeldown: true,
        dbtype: 'String',
        typeInput: 'text',
        label: 'form.nameunmarried',
      },
      contacts: {
        type: 'String',
        dbtype: 'Json',
        placeHolderShowChildsShow: true,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            noExport: true,
            label: 'form.id',
          },
          type: {
            type: 'String',
            typeInput: 'selectAutocomplete',
            listSource: 'contactType',
            validators: ['required'],
            label: 'form.type',
          },
          name: {
            type: 'String',
            validators: ['required'],
            typeInput: 'text',
            label: 'form.name',
          },
          phone: {
            type: 'String',
            validators: ['required'],
            icon: 'phoneblack',
            typeInput: 'text',
            label: 'form.phone',
          },
          otherinfo: {
            type: 'String',
            typeInput: 'text',
            label: 'form.otherinfo',
          },
        },
        typeInput: 'text',
        label: 'form.contacts',
        mode: {},
      },
      phones: {
        type: 'String',
        dbtype: 'Json',
        placeHolderShowChildsShow: true,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          type: {
            type: 'String',
            typeInput: 'selectAutocomplete',
            listSource: 'phonesType',
            validators: ['required'],
            label: 'form.type',
          },
          phone: {
            type: 'String',
            validators: ['required', 'valPhone'],
            icon: 'phoneblack',
            typeInput: 'text',
            label: 'form.phone',
          },
          otherinfo: {
            type: 'String',
            typeInput: 'text',
            label: 'form.otherinfo',
          },
        },
        typeInput: 'text',
        label: 'form.phones',
        mode: {},
      },
      customeraddress_id: {
        type: 'String',
        typeInput: 'hidden',
        label: 'form.customeraddress_id',
        dbtype: 'String',
      },
      customeraddress_customers: {
        type: 'String',
        typeInput: 'hidden',
        virtual: true,
        preventExit: true,
        dbtype: 'String',
        label: 'form.customeraddress_customers',
      },
      groupedaddress_customer_id: {
        type: 'String',
        label: 'form.grouped_address',
        placeHolder: 'form.grouped_without_address',
        typeInput: 'selectBox',
        onChange: true,
        listmini: true, // for voyages change here and add manually field to .miniFields[]
        formNewSection: true,
        dbtype: 'String', // importa for person
        table: 'person',
        loadAsync: true,
        saveonly: 'id',
      },
      addresses: {
        type: 'String',
        dbtype: 'Json',
        labelChildsShow: true,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            label: 'form.id',
          },
          type: {
            type: 'String',
            placeHolderShow: true,
            label: 'form.addresses_type',
            typeInput: 'selectAutocomplete',
            listSource: 'addressesType',
            validators: ['required'],
          },
          addressline1: {
            type: 'String',
            validators: ['required'],
            placeHolderShow: true,
            validateRequiredIfEmpty: 'addressline2',
            sublabel: 'info.addressline1',
            sublabeldown: true,
            typeInput: 'text',
            label: 'form.addressline1',
          },
          addressline2: {
            type: 'String',
            placeHolderShow: true,
            validateRequiredIfEmpty: 'addressline1',
            sublabel: 'info.addressline2',
            sublabeldown: true,
            typeInput: 'text',
            label: 'form.addressline2',
          },
          addressline3: {
            type: 'String',
            placeHolderShow: true,
            typeInput: 'text',
            label: 'form.addressline3',
          },
          city_id: {
            type: 'String',
            typeInput: 'text',
            validators: ['required'],
            label: 'table.city',
          },
          cp: {
            type: 'String',
            typeInput: 'text',
            validators: ['required'],
            label: 'form.cp',
          },
          country: {
            type: 'String',
            validators: ['required'],
            typeInput: 'selectAutocomplete',
            listSource: 'countries',
            saveIdOnly: true,
            translateDisabledSource: true,
            label: 'form.country',
          },
        },
        typeInput: 'text',
        label: 'form.addresses',
        mode: {},
      },
      identitydocs: {
        type: 'String',
        dbtype: 'Json',
        labelChildsShow: true,
        placeHolderShowChildsShow: false,
        subfields: {
          id: {
            type: 'ID',
            typeInput: 'hidden',
            noExport: true,
            label: 'form.id',
          },
          type: {
            type: 'String',
            label: 'form.id_doctype',
            typeInput: 'selectAutocomplete',
            listSource: 'identitydocsType',
            validators: ['required'],
          },
          country: {
            type: 'String',
            validators: ['required'],
            typeInput: 'selectAutocomplete',
            listSource: 'countries',
            saveIdOnly: true,
            translateDisabledSource: true,
            label: 'form.country',
          },
          number: {
            type: 'String',
            label: 'form.id_docnumber',
            validators: ['required'],
            typeInput: 'text',
          },
          idexpiration: {
            type: 'String',
            dbtype: 'Date',
            typeInput: 'text',
            validators: ['required', 'daysLimitOld:1'],
            label: 'form.idexpiration',
          },
          idiauthority: {
            type: 'String',
            typeInput: 'text',
            validators: ['required'],
            label: 'form.idiauthority',
          },
          ididate: {
            type: 'String',
            dbtype: 'Date',
            typeInput: 'text',
            validators: ['required', 'daysLimitFuture:1'],
            label: 'form.ididate',
          },
          idnameisother: {
            type: 'Boolean',
            typeInput: 'checkbox',
            inLine: true,
            noContainerInLine: true,
            onChange: true,
            formNewSection: true,
            noExport: true,
            width: '700px',
            label: 'form.idnameisother',
          },
          lastname: {
            type: 'String',
            validators: [
              // "minLength:3", commented until to clean this value with uncheckbox dif
              'alphai18n',
            ],
            normalize: 'UpperCase',
            onDebounce: 1500,
            onHidden: true,
            formNewSection: true,
            typeInput: 'text',
            label: 'form.lastname',
          },
          firstname: {
            type: 'String',
            validators: [
              // "minLength:3", commented until to clean this value with uncheckbox dif
              'alphai18n',
            ],
            onHidden: true,
            onDebounce: 1500,
            typeInput: 'text',
            label: 'form.firstname',
          },
          idaddressisother: {
            type: 'Boolean',
            typeInput: 'checkbox',
            inLine: true,
            onChange: true,
            formNewSection: true,
            noContainerInLine: true,
            noExport: true,
            width: '700px',
            label: 'form.idaddressisother',
          },
          addressline1: {
            type: 'String',
            onHidden: true,
            formNewSection: true,
            typeInput: 'text',
            label: 'form.addressline1',
          },
          addressline2: {
            type: 'String',
            onHidden: true,
            typeInput: 'text',
            label: 'form.addressline2',
          },
          addressline3: {
            type: 'String',
            onHidden: true,
            typeInput: 'text',
            label: 'form.addressline3',
          },
          cp: {
            type: 'String',
            inputSize: 7,
            onHidden: true,
            typeInput: 'text',
            label: 'form.cp',
          },
          city: {
            type: 'String',
            placeHolderShow: true,
            sublabel: 'info.citywillnotbeadd',
            sublabeldown: true,
            onHidden: true,
            typeInput: 'text',
            label: 'form.city',
          },
          cityid: {
            type: 'ID',
            typeInput: 'hidden',
            noExport: true,
            label: 'form.cityid',
          },
        },
        typeInput: 'text',
        label: 'form.identitydocs',
        mode: {},
      },
      notes: {
        type: 'String',
        typeInput: 'textarea',
        nameGroup: 'info',
        formNewSection: true,
        dbtype: 'String',
        label: 'form.notes',
      },
      counterhistory: {
        type: 'Int',
        disabledMutation: true,
        excludedInForm: true,
        label: 'form.crm',
        dbtype: 'Int',
        typeInput: 'text',
      },
      person_groupid: {
        disabledSystemUpdate: true,
        type: 'ID',
        dbtype: 'ID',
        typeInput: 'text',
        label: 'form.person_groupid',
      },
      updatedAt: {
        type: 'String',
        dbtype: 'Datetime',
        dbAutoUpdate: true,
        excludedInForm: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.updatedAt',
      },
      createdAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        dbAutoUpdate: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.createdAt',
      },
      deletedAt: {
        type: 'String',
        dbtype: 'Datetime',
        excludedInForm: true,
        disabledMutation: true,
        formatValue: 'dddd D MMM ⏱h:mm a',
        typeInput: 'text',
        formatListValue: 'dddd D MMM ⏱h:mm a',
        formatViewValue: 'dddd D MMM ⏱h:mm a',
        label: 'form.deletedAt',
      },
      deleted: {
        type: 'Boolean',
        excludedInForm: true,
        disabledMutation: true,
        dbtype: 'Boolean',
        typeInput: 'checkbox',
        label: 'form.deleted',
      },
      pending_tourid: {
        type: 'String',
        dbtype: 'String',
        excludedInForm: true,
        disabledMutation: true,
      },
      member: {
        type: 'Int',
        dbtype: 'Int',
        excludedInForm: true,
        disabledSystemUpdate: true,
      },
    },
    listview: {
      fields: {
        name: true,
        email: true,
        addresses: {
          subfields: {
            addressline1: true,
            addressline2: true,
            addressline3: true,
          },
          limit: 1,
          joinsubfields: true,
        },
        addresses2: {
          field: 'addresses',
          label: 'form.city',
          subfields: {
            city_id: true,
          },
          limit: 1,
          joinsubfields: true,
        },
        phones: {
          type: 'String',
          dbtype: 'Json',
          subfields: {
            phone: true,
          },
          limit: 2,
          joinsubfields: true,
          separator: '',
        },
      },
    },
    norelated: {
      city: {
        norelated: true,
        table: 'city',
        fields: ['name'],
      },
    },
    listFilters: {
      fields: {},
    },
    related: {
      grouped_customer_id: {
        table: 'person',
        label: 'form.grouped_customer_id',
        relation: 'oneChild',
        fields: ['name'],
        key: 'grouped_customer_id',
        listmini: true,
        alias: 'persongrouped_customer_id',
      },
      groupedaddress_customer_id: {
        table: 'person',
        label: 'form.grouped_customer_id',
        relation: 'oneChild',
        fields: ['name'],
        key: 'grouped_customer_id',
        listmini: true,
        alias: 'persongroupedaddress_customer_id',
      },
      gendertype_id: {
        table: 'gendertype',
        relation: 'oneChild',
        fields: ['name'],
        key: 'gendertype_id',
        listmini: true,
        alias: 'persongendertype_id',
      },
      pending_tourid: {
        table: 'tour',
        relation: 'oneChild',
        fields: ['name'],
        key: 'pending_tourid',
        listmini: true,
        alias: 'personpending_tourid',
      },
    },
    name: {
      singular: 'person',
      plural: 'persons',
      singularCase: 'Person',
      pluralCase: 'Persons',
    },
    mainfield: 'name',
    miniFields: ['id', 'name', 'email', 'groupedaddress_customer_id'],
    componentName: 'PersonView',
    path: '/person-view/:id',
  },
};
module.exports.Tables = Tables;
