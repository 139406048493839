import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import gql from 'graphql-tag';
import i18countries from 'i18n-iso-countries';
import { graphql, compose } from 'react-apollo';
import debounce from 'lodash/debounce';

import {
  parse as parseDate,
  format,
  differenceInCalendarDays as differenceInDays,
} from 'date-fns';

import { submit, change, touch, initialize } from 'redux-form';
import { Link } from 'react-router-dom';
// import { checkAuth } from '../../utils/auth';
import { signOut } from '../../actions/authActions';

import { gqlActions } from '../../gqls/gqlactions';

import { Tables } from '../../defTables';
import { fetchQl } from '../../apolloClient';
import defQls from '../../defQls';
import Form from './PreRegistrationForm';
import {
  processError,
  errorTranslate,
  checkLoadCrud,
  cleanFilters,
  getFilters,
  preSubmitToolBar,
  preSubmitValidation,
  submitValues,
  deleteRecord,
  uuid,
  getRecordFromOptionsIndirectOnFilter,
  resolveLink,
} from '../../utils/helper';
import ShowIcon from '../icons/ShowIcon';

import {
  appSubmitStart,
  appSubmitStop,
  appSettingsLoad,
} from '../../actions/appActions';
import { showConfirm, hideConfirm } from '../../actions/confirmActions';
// import { getSession } from '../../utils/auth';

import PageHeader from '../PageHeader';
import TourHeader from './TourHeader';
import CookiesAlert from '../CookiesAlert';
import Cgupelemobil from '../Cgupelemobil';

import { Button } from '../../layouts/cssstyled';

import CrudToolBar from '../CrudToolBar';
import IconLoading from '../icons/IconLoading';
import { MsgError } from '../ListData';
import {
  getInputValue,
  tt,
  getObjFromListById,
  dateClean,
  sourcesOptions,
  resolvePathObj,
  ISOtoDate,
  tryParseJSON,
  getNameHotelAndRoom,
} from '../../utils/commonutils';

import SignInPage from '../session/SignInPage';

import RecordInfo from '../RecordInfo';
import RecordHeadInfo from '../RecordHeadInfo';

import crudCode from './crudCodePreRegistration';

// TWIN 8190A
let querystring = window.location.search.substring(1);
let params = new URLSearchParams(querystring);
let mode = params.get('mode');

const templates = [];

const tableCrud = 'regonline'; // 'tour';
const action = params.get('trypay') ? 'update' : 'add';
const nameForm = tableCrud + 'Form';

const aFieldsRegistration = [
  'regonline_stopspoint',
  'regonline_hotel', // let it, because old version, recorded this field, and this not must enter like person required list field
  'regonline_room_type', // is reakky tourroom_id
  'regonline_room_share',
];

const getPersonId = (props) => {
  const search = props.history.location.search;
  const params = new URLSearchParams(search);
  const person_id = params.get('p') || props.session.iu;
  return person_id;
};

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionsave: action,
      actionsave_origen: action,
      crudAction: 'Add',
      crudTable: tableCrud,
      mainForm: nameForm,
      id: this.props.match.params.id || '',
      dialogOpened: {},
      parentid:
        this.props.match.params && this.props.match.params.parentid
          ? this.props.match.params.parentid
          : '',
      disabledFields: [],
      page: 1,
      hiddenFields: [],
      _resTourCustomerStatusAndServices: [],
      warningFields: {},
      clicked: false,
    };
  }

  changePage = (increment = 1) => {
    this.setState({ page: this.state.page + increment });
  };

  handleSubmit = async (values) => {
    try {
      if (
        this.state.warningFields &&
        this.state.warningFields._submit &&
        this.state.warningFields._submit.length
      ) {
        const validation = await preSubmitValidation(
          tableCrud,
          this.state.actionsave,
          this.props,
          this.syncCode
        );
        if (!validation) return false;
        this.props.showConfirm({
          id: uuid(),
          type: 'confirmation',
          text: [
            ...this.state.warningFields._submit,
            <div key="usure">{this.props.t('form.uSureSave')}</div>,
          ],
          buttons: [
            {
              label: this.props.t('form.yes'),
              onClick: async () => await this.handleSubmitOk(values),
            },
            {
              label: this.props.t('form.no'),
              onClick: () => null,
            },
          ],
        });
      } else {
        return await this.handleSubmitOk(values);
      }
    } catch (err) {
      throw err;
    }
  };

  handleSubmitOk = async (values) => {
    try {
      const res = await submitValues(
        tableCrud,
        this.state.actionsave,
        this.props,
        true,
        this.syncCode
      );
      if (res) {
        if (res !== 'dontreloaddata') {
          const newState = {
            actionsave: 'update',
            actionsave_origen: 'update',
          };
          if (res && res.id) newState.id = res.id;
          this.setState(newState, async () => {
            await this.executeCode('onChangeInput', {
              action: 'initForm',
              nameForm,

              formProps: { ...this.props, containerPropsForm: this.props },
              formState: this.state,
              event: null,
              newValue: null,
            });
          });
        }
      } else {
        this.props.dispatch(change(nameForm, '_fakefield', Math.random()));
      }
      return res;
    } catch (err) {}
  };

  handleClickSubmit = async (e) => {
    if (this.props.myState.app.submitting) {
      //ctrl submiting global , really neded, new block ctrl can reemplace this ?
      e.preventDefault();
      ////console.error('submit twice');
      return;
    }

    if (!this.state.clicked) {
      // TWIN CTR231 ctrl is not saving this
      this.setState({ clicked: true }, async () => {
        // just to disable double ctrl clic
        this.setState({ clicked: false });
      });
      // important preSubmit, because we need to show alerts popups with the errors
      //  otherwise redux form, show only the errors on the fields, and not popups
      //  only problem is that validation do twice .... %%% see if can be done only one time
      const resOk = await preSubmitToolBar(
        tableCrud,
        this.state.actionsave,
        { containerPropsForm: this.props },
        null
      );
      if (resOk) {
        this.props.appSubmitStart();
        const result = await this.props.dispatch(submit(nameForm)); // modern way to submit
        // commented use because , logic dont detect 'this.props.myState.app.submitting'
        //   and then loading (pending saving ) is not showed, to do stop, on the validations
        // this.props.appSubmitStop();
      }
    }
  };

  toolbarFunctions = (toolbar, params = {}) => {};

  hocdebounced = debounce((methodCode, params) => {
    this.executeCode(methodCode, params);
  }, 1500);

  // REAL SUBMIT !! Regonline dont use handleSubmit
  addRegOnline = async (values) => {
    const validation = await preSubmitValidation(
      tableCrud,
      this.state.actionsave,
      this.props,
      this.syncCode
    );
    if (!validation) return false;

    // execution direct, no control or process values, just execute ql and treat the errors
    // console.log("values", values);
    const objects = {};
    for (const [fieldKey, fieldValue] of Object.entries(values)) {
      // import than this.props (have .myState),

      if (fieldKey.substr(0, 8) === 'service_') {
        // must join in only one object
        /// search real id service
        if (fieldValue) {
          // service checked
          const idService = fieldKey.substr(8);
          const matchService = getObjFromListById(
            this.props.getTourServices.getTourServices,
            idService,
            null
          );
          if (matchService) {
            objects[idService] = {
              // id: service.idOnly,
              value: fieldValue.value ?? fieldValue,
              q: 1,
            };
          }
        }
        delete values[fieldKey];
      }
    }
    // there is no processevalues(), so need stringify manually
    //  because voyages send complex fields,
    values.objects = JSON.stringify(objects);

    /*if (values.hotel) {
      const matchService = getObjFromListById(
        this.props.getTourServices.getTourServices,
        values.hotel,
        null
      );
    }*/
    delete values['hotel'];
    // need to treat manually the values,
    // dont call processValues(), but must do it in the future
    values.price = parseFloat(values.price) || 0;
    values.partialpayment = parseFloat(values.partialpayment) || 0;

    const vads_order_id = params.get('trypay') ? values.id : uuid();

    // get boutique id
    const aTour = this.props.crud_view_tour.tour;
    const organizerModules = tryParseJSON(aTour.tourorganizer_id.modules, {});
    let aPayDataToSend = {};

    if (organizerModules.PAY_SG || organizerModules.PAY_SYSP) {
      let vads_site_id;
      if (organizerModules.PAY_SG) {
        vads_site_id = getObjFromListById(
          organizerModules.PAY_SG.values,
          { code: 'vads_site_id' },
          false
        );
      } else if (organizerModules.PAY_SYSP) {
        vads_site_id = getObjFromListById(
          organizerModules.PAY_SYSP.values,
          { code: 'vads_site_id' },
          false
        );
      }

      if (!vads_site_id.value) {
        alert('error vads_site_id not defined');
        return false;
      }
      const aPerson = resolvePathObj(this.props, 'crud_view_person.person');
      const phones = tryParseJSON(aPerson.phones, []);
      // max 32 chars allowed on phone
      // https://sogecommerce.societegenerale.eu/doc/fr-FR/error-code/error-23.html

      const vads_cust_phone = (
        phones.length === 0 ? '' : phones[0].phone
      ).substring(0, 10);

      const addresses = tryParseJSON(aPerson.addresses, []);
      let vads_cust_address = '';
      let vads_cust_address2 = '';
      let vads_cust_city = '';
      let vads_cust_country = '';

      addresses.map((address) => {
        vads_cust_address = address.addressline1;
        vads_cust_address2 =
          (address.addressline2 || '') + ' ' + (address.addressline3 || '');
        if (address.city_id && address.city_id.name) {
          vads_cust_city = address.city_id.name;
        }
        // console.log('address.country ', address.country );
        if (address.country) {
          const countryDet = getObjFromListById(
            sourcesOptions.countries,
            address.country.id || address.country
          );
          // console.log('countryDet ', countryDet);
          if (countryDet)
            vads_cust_country = i18countries.alpha3ToAlpha2(countryDet.id);
          // console.log('vads_cust_country ', vads_cust_country);
        }
      });

      let aPrePayDataToSend = {
        vads_action_mode: 'INTERACTIVE', // type is rentry by form
        // send partialpayment if is filled , otherwise just price
        vads_amount:
          (values.partialpayment ? values.partialpayment : values.price) * 100,
        vads_currency: 978,
        // vads_cust_id: aPerson.id,
        // without email will be complete with the email from the main person
        vads_cust_email: aPerson.email.trim(),
        vads_cust_first_name: aPerson.firstname,
        vads_cust_last_name: aPerson.lastname,
        vads_order_id,
        vads_order_info: aTour.name,
        vads_page_action: 'PAYMENT',
        vads_payment_cards: values.typecard,
        vads_payment_config: 'SINGLE',
        vads_site_id: vads_site_id.value,

        vads_version: 'V2',
      };
      if (vads_cust_address)
        aPrePayDataToSend.vads_cust_address = vads_cust_address;
      if (vads_cust_address2)
        aPrePayDataToSend.vads_cust_address2 = vads_cust_address2;
      if (vads_cust_city) aPrePayDataToSend.vads_cust_city = vads_cust_city;
      if (vads_cust_country)
        aPrePayDataToSend.vads_cust_country = vads_cust_country;
      if (vads_cust_phone) aPrePayDataToSend.vads_cust_phone = vads_cust_phone;

      // console.log('aPayDataToSend', aPrePayDataToSend);
      aPayDataToSend = aPrePayDataToSend;
    }
    try {
      const aValues = {
        ...values,
        payinput: JSON.stringify(aPayDataToSend),
        id: vads_order_id,
        // person_id: getSession().iu,
        tour_id: this.props.match.params.id,
        stopspoint_id:
          values.stopspoint_id && values.stopspoint_id.id
            ? values.stopspoint_id.id
            : null,
      };
      const action = params.get('trypay') ? 'Update' : 'Add';
      let wasError = false;
      const response = await gqlActions(
        'regonline',
        action,
        this.props,
        aValues,
        {
          redirect: 'dontredirect',
          action,
        }
      );
      if (wasError || !response) {
        return 'dontreloaddata';
      }
      if (!aPayDataToSend.vads_amount) {
        // when is zero to pay, or there are payment online
        this.props.history.push('/regonline');
      } else {
        // when is not zero , the amount payonline, make post
        // aPayDataToSend.signature = response.data.regonlineAdd.signature;
        const payinput = params.get('trypay')
          ? response.payinput
          : response.data.regonlineAdd.payinput;
        if (!payinput) {
          throw 'error payinput response not found';
        }
        aPayDataToSend = JSON.parse(payinput);
        // console.log(' response.data.regonlineAdd.payinput',  aPayDataToSend);

        /* const formData  = new FormData();
        for(const name in aPayDataToSend) {
          formData.append(name, aPayDataToSend[name]);
        }
          let responseApi = await fetch("https://sogecommerce.societegenerale.eu/vads-payment/", {
            headers: {
              'Access-Control-Allow-Origin': '*'
            },
            mode: "cors",
            method: 'POST',
            body: formData,
          });
          console.log('responseApi', responseApi); 
          */
        var form = document.createElement('form');

        form.method = 'POST';
        if (organizerModules.PAY_SG) {
          form.action = 'https://sogecommerce.societegenerale.eu/vads-payment/';
        } else if (organizerModules.PAY_SYSP) {
          form.action = 'https://paiement.systempay.fr/vads-payment/';
        }
        // console.log("zzzz aPayDataToSend", aPayDataToSend);

        for (const name in aPayDataToSend) {
          var element1 = document.createElement('input');
          /* element1.value=aPayDataToSend[name];
          element1.name=name; */
          element1.setAttribute('type', 'hidden');
          element1.setAttribute('name', name);
          element1.setAttribute('value', aPayDataToSend[name]);
          form.appendChild(element1);
        }

        document.body.appendChild(form);
        form.submit();
      }
    } catch (err) {
      processError(err, this.props, true);
    }
    // response has nothing, it seems execution is not really with await ?? %%%%%;, see the default case , call function gqlActions
    // console.log('response', response);
  };

  executeCode = async (methodCode = '', params = {}) => {
    params.tableCrud = tableCrud;
    params.formState = this.state;

    if (!crudCode[methodCode]) {
      return;
    }

    const result = await crudCode[methodCode](params);

    if (result && result.changeFieldsLater) {
      Object.keys(result.changeFieldsLater).map((keyName, keyIndex) => {
        let valueField = result.changeFieldsLater[keyName];
        params.formProps.change(keyName, valueField);
      });
    }

    if (result && result.newStates) {
      let formStateChanged = false;
      let currentState = this.state;
      Object.keys(result.newStates).map((keyName, keyIndex) => {
        if (
          typeof this.state[keyName] === 'undefined' ||
          JSON.stringify(this.state[keyName]) !==
            JSON.stringify(result.newStates[keyName])
        ) {
          let keyNewState = result.newStates[keyName];

          if (keyName === 'warningFields') {
            keyNewState = {
              ...this.state[keyName],
              ...result.newStates[keyName],
            };
          }
          this.setState({ [keyName]: keyNewState });
          currentState[keyName] = result.newStates[keyName];
          formStateChanged = true;
        }
      });
      if (formStateChanged) {
        this.props.dispatch(
          change(nameForm, '_formstate', JSON.stringify(currentState))
        );
      }
    }

    if (result && typeof result.valueToReturn !== 'undefined') {
      return result.valueToReturn;
    }
  };

  render() {
    const { t, auth, ...otherProps } = this.props;
    const aPersonId = this.props.session.iu;

    let aElements = [];
    let aQlFiltered;
    if (auth) {
      aQlFiltered = {
        getTourServices: { tokenInvalid: true },
        getTourStopsPoints: { tokenInvalid: true },
        // customer for regonline only  come from getTourCustomerStatusAndServices
        // customerstatus global is not used, come always because tourcategory checkbox customerstatus
        list_registration: { table: 'registration', tokenInvalid: true },
        list_regonline: { table: 'regonline', tokenInvalid: true },
        crud_view_tour: { table: 'tour' },
        crud_view_person: { table: 'person', tokenInvalid: true },
        list_stopspoint: { table: 'stopspoint', tokenInvalid: true },
        list_tourroom: { table: 'tourroom', tokenInvalid: true },
      };
    } else {
      aQlFiltered = { crud_view_tour: { table: 'tour' } };
    }
    const resultCheck = checkLoadCrud(aQlFiltered, this.props, false, false);
    if (
      auth &&
      (resultCheck.detailedError.list_stopspoint === 'login.tokenInvalid' ||
        resultCheck.detailedError.crud_view_person === 'login.tokenInvalid')
    ) {
      // contradiction, auth is ok, but server is not valid, so reset  and clean login
      signOut(); // special for pelevoyages;, when user delete account
      setTimeout(() => window.location.reload(), 100);
    }
    if (resultCheck.messageError) {
      return <MsgError msg={resultCheck.messageError} t={this.props.t} />;
    }
    if (resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(
        <div key="titleandloading">
          <div className="appBodyTitleSeparator" />
          <div
            className="animated-background"
            style={{ width: '100%', height: '300px' }}
          >
            <IconLoading key="icon" />
          </div>
        </div>
      );
    }

    if (!resultCheck.globalLoading && !resultCheck.messageError) {
      const aTour = this.props.crud_view_tour.tour;
      let textgcu;
      let urlgcu;

      if (!aTour.gcu) {
        if (aTour.tourorganizer_id.settingsform) {
          const settingsform = tryParseJSON(
            aTour.tourorganizer_id.settingsform,
            {}
          );
          if (settingsform.gcu === '1') {
            textgcu = aTour.tourorganizer_id.textgcu;
          } else if (settingsform.gcu === '2') {
            urlgcu = aTour.tourorganizer_id.settingsform.urlgcu;
          }
        }
      } else if (aTour.gcu === 1 && aTour.textgcu) {
        textgcu = aTour.textgcu;
      } else if (aTour.gcu === 2 && aTour.urlgcu) {
        urlgcu = aTour.urlgcu;
      }

      if (textgcu) {
        textgcu = JSON.parse(textgcu);
      }

      // add objects payment intermadiaries
      let paymentOnline = {};
      const organizerModules = tryParseJSON(aTour.tourorganizer_id.modules, {});
      if (organizerModules.PAY_SG) {
        paymentOnline['PAY_SG'] = {}; // really useful ?
      }
      if (organizerModules.PAY_SYSP) {
        paymentOnline['PAY_SYSP'] = {}; // really useful ?
      }

      const aStopsPoints =
        this.props.getTourStopsPoints &&
        this.props.getTourStopsPoints.getTourStopsPoints
          ? this.props.getTourStopsPoints.getTourStopsPoints
          : [];
      // console.log('aStopsPoints', aStopsPoints);
      let disabledTour = false;
      if (!aTour.tourtourcategory_id) {
        disabledTour = true;
      }

      aElements.push(<TourHeader key="th" {...aTour} t={t} />);

      const aPerson = resolvePathObj(this.props, 'crud_view_person.person');
      {
        aPerson &&
          aElements.push(
            <div
              key="pname"
              style={{
                display: 'flex',
                paddingBottom: '10px',
                fontSize: '18px',
                paddingBottom: '10px',
              }}
            >
              <div style={{ color: '#777777', width: '200px' }}>
                {t('form.name')}
              </div>
              <div style={{ color: '#07438E' }}>{aPerson.name}</div>
            </div>
          );
      }

      let disabledPreRegistration = false;
      let retryPayment = params.get('trypay');

      // control registration
      const aRegistrations = resolvePathObj(
        this.props,
        'list_registration.registrations',
        { notFound: null }
      );
      if (aRegistrations) {
        // is alread registrated
        // control personid is not already registrated
        const aReg = getObjFromListById(
          aRegistrations,
          { tour_id: aTour.id, person_id: aPersonId },
          true
        );
        if (aReg) {
          disabledPreRegistration = true;
          aElements.push(
            <div key="dup" style={{ color: 'darkred', fontSize: '18px' }}>
              {t('info.disabledPreRegistration')}
            </div>
          );
          aElements.push(
            <div key="dup" style={{ fontSize: '18px' }}>
              <Link to={`/registration-view/${aReg.id}`}>
                {t('info.vView')}&nbsp;{t('table.registration')} -&gt;
              </Link>
            </div>
          );
        }
      }

      // control pre-registation
      if (!disabledPreRegistration) {
        const aRegonlines = resolvePathObj(
          this.props,
          'list_regonline.regonlines',
          { notFound: null }
        );
        const aGoListe = (
          <div key="dupR" style={{ fontSize: '18px' }}>
            Pour reesayer un paiement, ou regarder vous inscriptions ou inscrire
            une autre personne&nbsp;
            <span style={{ color: 'darkred', fontSize: '18px' }}>
              <Link to="/regonline">cliquez ici.</Link>
            </span>
          </div>
        );

        if (aRegonlines) {
          // if retry payment , get the id and data from regonline, to update
          if (retryPayment) {
            // search de previous regonline by id, if exists..
            const aReg = getObjFromListById(
              aRegonlines,
              { id: retryPayment },
              true
            );
            if (!aReg || aReg.state !== 1) {
              // record not found or already rejected...
              disabledPreRegistration = true;
              aElements.push(
                <div key="dup" style={{ color: 'red', fontSize: '18px' }}>
                  La inscription n'est pas disponible.
                </div>
              );
              aElements.push(aGoListe);
            } else {
              retryPayment = aReg;
            }
          } else {
            // control personid is not already registrated
            const aReg = getObjFromListById(
              aRegonlines,
              { tour_id: aTour.id, person_id: aPersonId },
              true
            );
            if (aReg) {
              // allow repayment  if etry payment
              disabledPreRegistration = true;
              aElements.push(
                <div key="dup" style={{ color: 'red', fontSize: '18px' }}>
                  {t('info.alreadyPreRegistrated')}
                </div>
              );
              aElements.push(aGoListe);
            }
          }
        }
      }

      if (!disabledPreRegistration) {
        const startDate = dateClean(aTour.registrationstart);
        const differenceStart = differenceInDays(startDate, new Date());

        const endDate = dateClean(aTour.registrationend);
        const differenceEnd = differenceInDays(endDate, new Date());

        let disableReason = null;
        if (differenceStart > 0) {
          disabledTour = true;
          disableReason = (
            <div>
              {t('info.regStartError')}: {ISOtoDate(startDate)}
            </div>
          );
        }

        if (differenceEnd < 0) {
          disabledTour = true;
          disableReason = (
            <div>
              {t('info.regEndError')}: {ISOtoDate(endDate)}
            </div>
          );
        }

        if (disabledTour) {
          // aElements.push(<div key="tour1" style = { {color:'darkred'}}>{t('info.preregistration')}</div>);
          aElements.push(
            <div key="tour1" style={{ color: 'darkred' }}>
              {t('info.tourdisabled')}
            </div>
          );
          aElements.push(<div key="dis">{disableReason}</div>);
        } else {
          let totalIncompletFields = 0;

          if (!auth || !this.props.crud_view_person.person) {
            aElements.push(
              <SignInPage key="spl" {...this.props} tourId={aTour.id} />
            );
          } else {
            if (mode === 'test') {
              aElements.push(
                <div
                  key="test"
                  style={{
                    display: 'flex',
                    margin: '30px 50px 30px 0px',
                    fontSize: '22px',
                    color: 'red',
                  }}
                >
                  TEST MODE{' '}
                </div>
              );
            }
            aElements.push(
              <div
                key="info"
                style={{
                  display: 'flex',
                  margin: '30px 50px 30px 0px',
                  fontSize: '19px',
                  color: 'darkred',
                }}
              >
                Après finir votre inscription, pour inscrire une nouvelle
                personne, ajoutez la personne. depuis l'option 'Mon Groupe' (à
                gauche) et ensuite sur l'option 'Accueil' en utilisant le code
                ou le lien du pèlerinage
              </div>
            );

            aElements.push(
              <div
                key="requ1"
                style={{
                  display: 'flex',
                  paddingBottom: '10px',
                  fontSize: '21px',
                  color: '#555555',
                }}
              >
                1.&nbsp;{t('form.personaldata')}
              </div>
            );

            // check fields required for table person
            let settings = {};
            let paramsIncomplet = '';
            if (aTour.tourtourcategory_id.settings) {
              settings = JSON.parse(aTour.tourtourcategory_id.settings);
              const aFieldsPerson = [];
              const info = aPerson.info ? JSON.parse(aPerson.info) : {};
              //if (aPerson.info) aPerson.info = JSON.parse(aPerson.info);

              for (const [fieldKey, askData] of Object.entries(settings)) {
                let incompletField = 0;
                if (
                  fieldKey.substr(0, 10) === 'regonline_' &&
                  !aFieldsRegistration.includes(fieldKey) && // ony fields for table person
                  askData //
                ) {
                  //aFieldsPerson.push(<div key={fieldKey}>{t(Tables.tourcategory.fields[fieldKey].label)}</div>)
                  let resultField = [];
                  if (fieldKey === 'regonline_occupation') {
                    if (!info.occupation) {
                      incompletField = 1;
                      paramsIncomplet += '&required_occupation=1';
                    } else {
                      resultField.push(<div key="ocu">{info.occupation}</div>);
                    }
                  }
                  if (fieldKey === 'regonline_birthdata') {
                    let aSubResult = [];
                    if (!aPerson.birthdate) {
                      incompletField = 1;
                      paramsIncomplet += '&required_birthdate=1';
                      aSubResult.push(
                        <div key="1" style={{ color: 'darkred' }}>
                          {t('validator.required')}
                        </div>
                      );
                    } else {
                      aSubResult.push(
                        <div key="2" style={{ color: '#07438E' }}>
                          {aPerson.birthdate}
                        </div>
                      );
                    }
                    resultField.push(
                      <div key="birthdate">
                        <div>
                          <div style={{ color: '#111111' }}>
                            &nbsp;{t('form.date')}
                          </div>
                          <div style={{ display: 'flex' }}>{aSubResult}</div>
                        </div>
                      </div>
                    );

                    aSubResult = [];
                    if (!info.birthplace) {
                      incompletField = 1;
                      paramsIncomplet += '&required_birthplace=1';
                      aSubResult.push(
                        <div key="2" style={{ color: 'darkred' }}>
                          {t('validator.required')}
                        </div>
                      );
                    } else {
                      aSubResult.push(
                        <div key="1" style={{ color: '#07438E' }}>
                          {info.birthplace}
                        </div>
                      );
                    }
                    resultField.push(
                      <div key="birthplace" style={{ marginLeft: '10px' }}>
                        <div>
                          <div style={{ color: '#111111' }}>
                            {t('form.place')}:&nbsp;
                          </div>
                          <div style={{ display: 'flex' }}>{aSubResult}</div>
                        </div>
                      </div>
                    );

                    aSubResult = [];
                    if (!info.birthcountry) {
                      incompletField = 1;
                      paramsIncomplet += '&required_birthcountry=1';
                      aSubResult.push(
                        <div key="1" style={{ color: 'darkred' }}>
                          {t('validator.required')}
                        </div>
                      );
                    } else {
                      resultField.push(
                        <div key="bb2">{aPerson.info.birthcountry}</div>
                      );
                      aSubResult.push(
                        <div key="1" style={{ color: '#07438E' }}>
                          {info.birthcountry}
                        </div>
                      );
                    }
                    resultField.push(
                      <div key="birthcountry" style={{ marginLeft: '10px' }}>
                        <div>
                          <div style={{ color: '#111111' }}>
                            {t('form.country')}:&nbsp;
                          </div>
                          <div style={{ display: 'flex' }}>{aSubResult}</div>
                        </div>
                      </div>
                    );
                  }

                  if (fieldKey === 'regonline_contactEmergency') {
                    if (!aPerson.contacts) {
                      incompletField = 1;
                      paramsIncomplet += '&required_contacts=2'; // contact emergency is type 2
                    } else {
                      let contEmergency = false;
                      JSON.parse(aPerson.contacts).map((contact, index) => {
                        if (contact && contact.type && contact.type.id === 2) {
                          // cumulates, cause it can be more one emergency contact
                          resultField.push(
                            <div key={'contact' + index}>
                              {contact.name} {contact.phone}
                            </div>
                          );
                          contEmergency = true;
                        }
                      });

                      if (!contEmergency) {
                        incompletField = 1;
                        paramsIncomplet += '&required_contacts=2'; // contact emergency is type 2
                      }
                    }
                  }
                  if (fieldKey === 'regonline_nationality') {
                    if (!aPerson.nationality) {
                      incompletField = 1;
                      paramsIncomplet += '&required_nationality=1';
                    } else {
                      resultField.push(
                        <div key="nat">{aPerson.nationality}</div>
                      );
                    }
                  }
                  if (fieldKey === 'regonline_nameunmarried') {
                    if (!info.nameunmarried) {
                      incompletField = 2;
                      // paramsIncomplet +='&required_nameunmarried=1'  // dont know when is femenin
                    } else {
                      resultField.push(
                        <div key="unm">{aPerson.info.nameunmarried}</div>
                      );
                    }
                  }
                  if (fieldKey === 'regonline_phones') {
                    if (
                      !aPerson.phones ||
                      JSON.parse(aPerson.phones).length === 0
                    ) {
                      incompletField = 1;
                      paramsIncomplet += '&required_phones=1';
                    } else {
                      JSON.parse(aPerson.phones).map((phone, index) => {
                        resultField.push(
                          <div key={'ph' + index}>
                            {phone.phone}&nbsp;&nbsp;
                          </div>
                        );
                      });
                    }
                  }
                  if (fieldKey === 'regonline_address') {
                    if (
                      !aPerson.addresses ||
                      JSON.parse(aPerson.addresses).length === 0
                    ) {
                      incompletField = 1;
                      paramsIncomplet += '&required_addresses=1';
                    } else {
                      JSON.parse(aPerson.addresses).map((address, key) => {
                        resultField.push(
                          <div key={'add1' + key}>
                            {address.addressline1}&nbsp;{address.addressline2}
                            &nbsp;{address.addressline3}
                          </div>
                        );
                        resultField.push(
                          <div key={'add2' + key}>
                            {address.cp}&nbsp;{address.city_id}&nbsp;
                          </div>
                        );
                      });
                    }
                  }
                  if (fieldKey === 'regonline_NationalIdentity') {
                    if (!aPerson.identitydocs) {
                      incompletField = 1;
                      paramsIncomplet += '&required_identitydocs=1';
                    } else {
                      const identitydocs = JSON.parse(aPerson.identitydocs);
                      const doc = identitydocs.find((thedoc) => {
                        if (thedoc && thedoc.type && thedoc.type.id === 1)
                          return true;
                      });
                      if (doc) {
                        // resultField.push(<div>{contEmergency.name} {contEmergency .phone}</div>);
                        resultField.push(
                          <div key="doci">
                            {doc.number}&nbsp;&nbsp;{doc.idexpiration}
                          </div>
                        );
                      } else {
                        incompletField = 1;
                        paramsIncomplet += '&required_identitydocs=1';
                      }
                    }
                  }
                  if (fieldKey === 'regonline_passport') {
                    if (!aPerson.identitydocs) {
                      incompletField = 1;
                      paramsIncomplet += '&required_identitydocs=5';
                    } else {
                      const identitydocs = JSON.parse(aPerson.identitydocs);
                      const doc = identitydocs.find((thedoc) => {
                        if (thedoc && thedoc.type && thedoc.type.id === 5)
                          return true;
                      });
                      if (doc) {
                        resultField.push(
                          <div key="pass">
                            {doc.number}&nbsp;&nbsp;{doc.idexpiration}&nbsp;
                          </div>
                        );
                      } else {
                        incompletField = 1;
                        paramsIncomplet += '&required_identitydocs=5';
                      }
                    }
                  }
                  if (fieldKey === 'regonline_imageright') {
                    if (!aPerson.imageright) {
                      incompletField = 1;
                      paramsIncomplet += '&required_imageright=1';
                    } else {
                      resultField.push(
                        <div key="pass">
                          {t(
                            'yesNoEmpty.' +
                              getObjFromListById(
                                sourcesOptions.yesNoEmpty,
                                aPerson.imageright
                              ).name
                          )}
                          &nbsp;
                        </div>
                      );
                    }
                  }
                  if (incompletField) {
                    if (incompletField === 1) {
                      // 2 is not really error incomplete, is variable like 'unmarrriedname'
                      totalIncompletFields++;
                      resultField.push(
                        <ShowIcon
                          key={fieldKey + 'c'}
                          size="22"
                          color="red"
                          icon="close"
                        />
                      );
                    }
                  } else {
                    resultField.push(
                      <ShowIcon
                        key={fieldKey + 'o'}
                        size="22"
                        color="#00e699"
                        icon="check"
                      />
                    );
                  }
                  if (!Tables.tourcategory.fields[fieldKey]) {
                    throw Object.assign(
                      new Error(
                        `Error 901: undefined fieldkey ${fieldKey} on Tables.tourcategory.fields`
                      ),
                      { code: 901 }
                    );
                  }

                  aFieldsPerson.push(
                    <div
                      key={'result' + fieldKey}
                      style={{
                        display: 'flex',
                        paddingBottom: '10px',
                        fontSize: '18px',
                        paddingBottom: '10px',
                      }}
                    >
                      <div style={{ color: '#777777', width: '200px' }}>
                        {t(Tables.tourcategory.fields[fieldKey].label)}
                      </div>
                      <div style={{ color: '#07438E', display: 'flex' }}>
                        {resultField}
                      </div>
                    </div>
                  );
                }
              }

              if (aFieldsPerson.length > 0) {
                aElements.push(<div key="requ33">{aFieldsPerson}</div>);
              }
            }

            if (totalIncompletFields > 0) {
              aElements.push(
                <div
                  key="requ5"
                  style={{
                    display: 'flex',
                    paddingBottom: '10px',
                    fontSize: '18px',
                    color: 'darkred',
                  }}
                >
                  &nbsp;{t('info.regIncompletProfile')}
                </div>
              );
              aElements.push(
                <div
                  key="requ10"
                  style={{
                    fontSize: '23px',
                    color: '#999999',
                    backgroundColor: '#FEEFB3',
                    padding: '10px',
                  }}
                >
                  <Link
                    to={`/person-view/${getPersonId(
                      this.props
                    )}?redirect=/tour/${
                      this.props.match.params.id
                    }@@p=${getPersonId(this.props)}${paramsIncomplet}`}
                  >
                    <div style={{ display: 'flex' }}>
                      <div>
                        <ShowIcon icon="navright" />
                      </div>
                      <div>{t('info.completProfile')}&nbsp;</div>
                      <div style={{ color: '#9F6000' }}>
                        {aPerson && aPerson.name}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            } else {
              aElements.push(
                <div
                  key="otherf"
                  style={{
                    display: 'flex',
                    paddingTop: '20px',
                    fontSize: '21px',
                    color: '#555555',
                    width: '500px',
                  }}
                >
                  2.&nbsp;{t('form.tourdata')}
                </div>
              );

              // because skip, after login , aServices has no ql loaded, so charge
              // TWIN REO022
              let aServices =
                this.props.getTourServices &&
                this.props.getTourServices.getTourServices
                  ? this.props.getTourServices.getTourServices
                  : [];

              let aHotelsBrute = this.props.list_tourroom.tourrooms;

              if (settings.regonline_status) {
                // && page > 1) {
                const customerstatus_id = getInputValue(
                  this.props,
                  'customerstatus_id',
                  {
                    nameForm,
                  }
                );
                let customerSubStatus;
                let customersubstatus_id = getInputValue(
                  this.props,
                  'customersubstatus_id',
                  {
                    nameForm,
                  }
                );
                // TWIN VLL122
                const customerStatus =
                  this.state._resTourCustomerStatusAndServices.find(
                    (customerstatus) => customerstatus.id === customerstatus_id
                  );

                if (customerStatus && customersubstatus_id) {
                  customerSubStatus = customerStatus.substatuss.find(
                    (st) => st.id === customersubstatus_id
                  );
                }
                if (
                  customerSubStatus &&
                  customerSubStatus.availableservices.length > 0
                ) {
                  aServices = customerSubStatus.availableservices;
                } else if (
                  customerStatus &&
                  customerStatus.availableservices.length > 0
                ) {
                  aServices = customerStatus.availableservices;
                }

                if (
                  customerSubStatus &&
                  customerSubStatus.availablehotels.length > 0
                ) {
                  aHotelsBrute = customerSubStatus.availablehotels;
                } else if (
                  customerStatus &&
                  customerStatus.availablehotels.length > 0
                ) {
                  aHotelsBrute = customerStatus.availablehotels;
                }
              }
              let aHotels = [];
              aHotelsBrute.map((tourroom) => {
                aHotels.push({
                  id: tourroom.id,
                  name: tourroom.priceroom
                    ? `${tourroom.name} € ${tourroom.priceroom} `
                    : tourroom.name,
                  disabled: tourroom.people_available < 1,
                });
              });

              aHotels.sort((a, b) =>
                a.name > b.name ? 1 : b.name > a.name ? -1 : 0
              );

              const allCustomerStatus =
                this.state._resTourCustomerStatusAndServices;

              aElements.push(
                <Form
                  executeCode={this.executeCode}
                  syncCode={this.syncCode}
                  textgcu={textgcu}
                  retryPayment={retryPayment}
                  page={this.state.page}
                  urlgcu={urlgcu}
                  toursettings={settings}
                  allCustomerStatus={allCustomerStatus}
                  aTour={aTour}
                  aHotels={aHotels}
                  paymentOnline={paymentOnline}
                  aServices={aServices}
                  aStopsPoints={aStopsPoints}
                  toolbarFunctions={this.toolbarFunctions}
                  crudAction="View"
                  key="mainform"
                  id={this.props.match.params.id}
                  person_id={getPersonId(this.props)}
                  data={this.props['crud_view_tour'].tour}
                  onSubmit={this.addRegOnline}
                  containerPropsForm={this.props}
                  formState={this.state}
                  t={this.props.t}
                  handleClickSubmit={this.handleClickSubmit}
                  submitting={this.state.submitting}
                  changePage={this.changePage}
                />
              );

              /*aElements.push (
                <Button key="bs" type="submit" onClick={ ()=> this.addRegOnline()}>
                  {t('info.vCancel')}
                </Button>
              );*/
            }
          }
        }
      }

      return (
        <div style={{ textAlign: auth ? 'left' : '' }}>
          {aElements}
          <CookiesAlert t={t} />
          {!(this.props.session && this.props.session.iu) && (
            <Cgupelemobil t={t} />
          )}
        </div>
      );
    }

    return null;
  }
}

const defQls_getTourStopsPoints = gql`
  query getTourStopsPoints($tour_id: ID) {
    getTourStopsPoints(tour_id: $tour_id) {
      id
      realstopid
      name
      tour_id
      transportation_id
      price
    }
  }
`;
const withGraphqlandRouter = compose(
  graphql(defQls.registration.ListMini, {
    name: 'list_registration',
    options: (props) => {
      const search = props.history.location.search;
      const params = new URLSearchParams(search);
      // removed:  _qlType: 'ListMini' , because we need all the fields from regonine
      const optionsValues = {
        variables: {
          _process: 'checkifexst',
          tour_id: props.match.params.id,
          person_id: getPersonId(props),
          //_qlType: 'ListMini'
        },
      };

      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      return optionsValues;
    },
    skip: (props) => {
      return !props.auth;
    },
  }),
  graphql(defQls.regonline.ListMini, {
    name: 'list_regonline',
    options: (props) => {
      // removed:  _qlType: 'ListMini' , because we need all the fields from regonine
      const optionsValues = {
        variables: {
          _process: 'checkifexst',
          tour_id: props.match.params.id,
          person_id: getPersonId(props),
          // _qlType: 'ListMini'
        },
      };

      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      return optionsValues;
    },
    skip: (props) => {
      return !props.auth;
    },
  }),
  graphql(defQls.tour.View, {
    name: 'crud_view_tour',
    options: (props) => {
      const optionsValues = {
        variables: { id: props.match.params.id, _qlType: 'View' },
      };
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      return optionsValues;
    },
  }),
  graphql(defQls.getTourServices, {
    name: 'getTourServices',
    options: (props) => {
      const optionsValues = {
        variables: {
          tour_id: props.match.params.id,
          onlineregtype: true,
          _qlType: 'View',
        },
      };

      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
    skip: (props) => {
      return !props.auth;
    },
  }),

  graphql(defQls.regonline.Add, {
    name: 'crud_add_regonline',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = 'no-cache';
      optionsValues.variables = { _qlType: 'Add' };
      return optionsValues;
    },
  }),
  graphql(defQls.regonline.Update, {
    name: 'crud_update_regonline',
    options: (props) => {
      const optionsValues = {
        variables: { id: params.get('trypay'), _qlType: 'Update' },
      };
      optionsValues.fetchPolicy = 'no-cache';
      return optionsValues;
    },
  }),
  graphql(defQls.person.View, {
    name: 'crud_view_person',

    options: (props) => {
      const optionsValues = {
        variables: { id: getPersonId(props), _qlType: 'View' },
      };
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      return optionsValues;
    },
    skip: (props) => {
      return !props.auth;
    },
  }),
  graphql(defQls.stopspoint.ListMini, {
    name: 'list_stopspoint',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = {
        _qlType: 'ListMini',
        organizer_id: props.match.params.id,
      };
      return optionsValues;
    },
    skip: (props) => {
      return !props.auth;
    },
  }),
  graphql(defQls_getTourStopsPoints, {
    name: 'getTourStopsPoints',
    options: (props) => {
      let filters = {};
      const optionsValues = {};
      optionsValues.variables = { tour_id: props.match.params.id };

      optionsValues.fetchPolicy = 'network-only';
      return optionsValues;
    },
    skip: (props) => {
      return !props.auth;
    },
  }),
  /* graphql(defQls.customerstatus.ListMini, {
    name: "list_customerstatus",
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : "network-only";
      optionsValues.variables = {
        _qlType: "ListMini",
        organizer_id: props.match.params.id,
      };
      return optionsValues;
    },
  }),*/
  graphql(defQls.tourroom.ListMini, {
    name: 'list_tourroom',
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : 'network-only';
      optionsValues.variables = {
        tour_id: props.match.params.id,
        _qlType: 'ListMini',
      };
      return optionsValues;
    },
  })
)(withRouter(FormContainer));

const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      appSubmitStart,
      appSubmitStop,
      showConfirm,
      initialize,
      change,
      touch,
      dispatch,
    },
    dispatch
  );
};

const withState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

const ComponentFull = withState;

export default ComponentFull;
