import React, { useState } from 'react';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import { getToday } from '../utils/commonutils';

const today = getToday();

const cookieSave = (setShow) => {
  setShow(false);
  localStorage.setItem('cookiesAlertDate', today);
};
const CookiesAlert = (props) => {
  const { t } = props;
  const [show, setShow] = useState(true);

  //console.log('today', today);

  let cookiesAlertDate = localStorage.getItem('cookiesAlertDate');
  //console.log('cookiesAlertDate', cookiesAlertDate);
  if (cookiesAlertDate !== null) {
    const cookieExpire = format(addDays(cookiesAlertDate, 365), 'YYYY-DD-MM');
    //console.log('cookieExpire', cookieExpire);

    if (cookieExpire > today) {
      // 'its  ok , not yet expired
      return null;
    }
  }

  if (!show) {
    return false;
  }
  return (
    <div
      style={{
        backgroundColor: '#9a3415dd',
        minHeight: '26px',
        fontSize: '20px',
        color: '#ccc',
        lineHeight: '26px',
        padding: '10px 10px 10px 10px',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <div>{t('info.cookiesAlert')}</div>
      <div
        style={{
          marginLeft: '10px',
        }}
      >
        <a
          onClick={() => cookieSave(setShow)}
          style={{
            backgroundColor: '#000000',
            color: '#fff',
            display: 'inline-block',
            padding: '10px',
            cursor: 'pointer',
          }}
        >
          {t('info.vAccept')}
        </a>
      </div>
    </div>
  );
};

export default CookiesAlert;
