import React, { Component } from 'react';

import { Tables } from '../../defTables';
import { ListDataRercord, ListDataHeader } from '../ListData';

import crudCode from './crudCode';

const tableCrud = 'payment';
const action = 'list';
const qlName = 'crud_list_' + tableCrud;

//const formFilterName="Filterpayment"; // give error, a different name for every form
const formFilterName = 'listFilterpayment';

export default class ApplicationPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listMode: 'rows',
      counter: 0,
      date: Date.now,
    };
  }

  render() {
    //console.log(this.props);
    const { t, ...otherProps } = this.props;
    let aElements = [];

    // hiddenFirstCol = true, because has no toolbar icons, no eye ico
    aElements.push(
      <ListDataHeader
        key="list"
        t={t}
        tableCrud="payment"
        settings={this.state}
        hiddenFirstCol={true}
      />
    );
    if (this.props[qlName] && this.props[qlName].payments) {
      // && this.props[qlName] <- not needed, no error not loading then ok
      this.props[qlName].payments.map((record) => {
        aElements.push(
          <ListDataRercord
            key={record.id}
            t={t}
            tableCrud={'payment'}
            record={record}
            settings={this.state}
            disabledView={true}
          />
        );
      });

      aElements.push(
        <div className="row" key="rfound">
          <div className="cell"></div>
          <div
            className="cell"
            style={{ color: '#999999', paddingTop: '20px' }}
          >
            {this.props[qlName].payments.length} {t('info.recordsFound')}
          </div>
        </div>
      );
    }

    return (
      <div>
        <div style={{ fontSize: '20px', color: '#999999' }}>
          {t('table.payments')}
        </div>
        {aElements}
      </div>
    );
  }
}
