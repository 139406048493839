import React, { Component } from "react";
import { withRouter } from "react-router";
import { MenuItem } from "./menu";
import { ISOtoDate, resolvePathObj } from "../utils/commonutils";
import LogoPeleAdmin from "../components/icons/logopele-bgdark";
import Notif from "../components/Notif";
import { getSession } from "../utils/auth";
import ShowIcon from "../components/icons/ShowIcon";
/*

<MenuItem
theme={props.theme}
label={t('table.payments')}
menuMini={menuMini}
icon="monetization"
goUrl={props.goUrl}
path="/payment"
currentPath={props.location.pathname}
/>
*/
const headerbg = require("../headerbg.jpg");
class LayoutComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPC: true,
      menuMini: false,
      type: "",
    };
  }

  toMenuMini = (mini) => {
    this.setState((prevState) => {
      return { menuMini: mini };
    });
  };

  handleResize = () => {
    this.setState((prevState) => {
      let newMini;
      newMini = prevState.mini;
      if (window.matchMedia("screen and (max-width: 840px)").matches) {
        console.log("is phone");
      } else {
        newMini = prevState.mini;
        console.log("is pc");
      }
      return { menuMini: prevState.mini };
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    const checkMediaQuery = () => {
      const type = window.matchMedia("(max-width: 840px)").matches
        ? "mobile"
        : "desktop";
      if (type !== this.state.type) {
        this.setState({ type });
      }
    };

    window.addEventListener("resize", checkMediaQuery);
    checkMediaQuery();
  }

  render() {
    let {
      component: DisplayComponent,
      t,
      changeLanguage,
      i18n,
      ...props
    } = this.props;
    const localPreHeaderStyle = {
      // background: props.theme.PrimaryDark,
      backgroundImage: `url(${headerbg})`,
      backgroundSize: "cover",
      opacity: 0.9,
      height: "29px",
      color: "white",
      fontSize: "11px",
      textAlign: "right",
      paddingRight: "10px",
      display: "flex",
      justifyContent: "space-between",
      letterSpacing: "2px",
    };

    const localHeaderStyle = {
      opacity: 0.7,
      backgroundImage: `url(${headerbg})`,
      backgroundSize: "cover",
      color: props.theme.SecondaryText,
      textAlign: "center",
      width: "100%",
      fontSize: "20px",
    };
    const localFooterStyle = {
      background: props.theme.Background,
      color: props.theme.TextOnBackgroundLight,
      padding: "10px",
      textAlign: "right",
      height: "30px",
    };

    const localTitleStyle = {
      fontSize: "18px",
      color: "#FFFFFF",
    };
    let appHeaderNotif;
    let localMenuStyle;
    let localContentStyle;
    let localAppContentStyle;
    appHeaderNotif = {
      margin: "auto",
      position: "absolute",
      padding: "10px",
      bottom: "100px;",
      left: "190px;",
      minHeight: "60px",
      zIndex: "2000",
    };

    localAppContentStyle = {
      display: "block",
      flexGrow: "1",
      flexShrink: "1",
      overFlow: "auto",
      paddingTop: "20px",
      paddingLeft: "15px",
      width: "100%",
    };

    localMenuStyle = {
      minHeight: "300px",
      minWidth: "195px",
      background: props.theme.Background,
    };
    let menuMini = false;
    let screenMini = window.matchMedia("screen and (max-width: 840px)").matches;
    if (screenMini) {
      localAppContentStyle.width = "90%";
    }
    if (this.state.menuMini || screenMini) {
      menuMini = true;
      appHeaderNotif.bottom = "200px";
      appHeaderNotif.left = "33px";

      localMenuStyle = Object.assign(localMenuStyle, {
        minWidth: "37px",
        width: "37px",
        overflow: "hidden",
        position: "absolute",
        left: "0",
        top: "27px",
      });

      localAppContentStyle.paddingLeft = "45px";
      localAppContentStyle.flexGrow = "0";
    } else {
    }
    const pagePrivate = !props.public;

    if (pagePrivate) {
      localAppContentStyle.textAlign = "left";
      localAppContentStyle.paddingTop = "20px";
    } else {
      localAppContentStyle.textAlign = "center";
      localAppContentStyle.paddingTop = "50px";
    }

    let session = getSession();
    let nickuser = session.nick;

    let styleLgSelected = {
      border: "none",
      backgroundColor: props.theme.PrimaryDark,
      opacity: 0.9,
      color: "yellow",
    };
    let styleLg = {
      border: "none",
      backgroundColor: props.theme.PrimaryDark,
      opacity: 0.5,
      color: "#FFFFFF",
    };

    return (
      <div
        ref={(elem) => (this.nv = elem)}
        className="appContainerColumnDirection"
      >
        <div
          key="topbar"
          className="preHeaderStyle"
          style={localPreHeaderStyle}
        >
          <div style={{ alignItems: "center", display: "flex" }}>
            <div style={{ alignItems: "center", display: "flex" }}>
              {nickuser && (
                <div>
                  <a
                    className="aNotHover"
                    href="/signout"
                    style={{
                      verticalAlign: "middle",
                      display: "inline",
                      marginLeft: "9px",
                    }}
                  >
                    <ShowIcon icon="powersettings" color="#FFFFFF" />
                  </a>
                </div>
              )}

              <div style={{ margin: "5px" }}>
                {nickuser} &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              {nickuser === "suspended" && (
                <div>
                  <a
                    className="aNotHover"
                    href="/myaccount"
                    style={{
                      verticalAlign: "middle",
                      display: "inline",
                      marginLeft: "7px",
                    }}
                  >
                    <ShowIcon icon="person" color="#FFFFFF" />
                  </a>
                </div>
              )}
            </div>
            <button
              style={i18n.language === "fr" ? styleLgSelected : styleLg}
              onClick={() => changeLanguage("fr")}
            >
              Franç.
            </button>
            <button
              style={i18n.language === "es" ? styleLgSelected : styleLg}
              onClick={() => changeLanguage("es")}
            >
              Esp
            </button>
            <button
              style={i18n.language === "it" ? styleLgSelected : styleLg}
              onClick={() => changeLanguage("it")}
            >
              Ita
            </button>
            <button
              style={i18n.language === "de" ? styleLgSelected : styleLg}
              onClick={() => changeLanguage("de")}
            >
              Deu
            </button>
            <button
              style={i18n.language === "en" ? styleLgSelected : styleLg}
              onClick={() => changeLanguage("en")}
            >
              Eng
            </button>
          </div>
          <div style={{ marginTop: "5px" }}>{props.nameApp}</div>
        </div>
        {!menuMini && !props.invisibleHeader && (
          <div key="barinfo" style={localHeaderStyle}>
            <div style={{ height: "35px", padding: "5px" }}>
              <div style={{ right: 0, top: "28px", position: "absolute" }}>
                <LogoPeleAdmin fill="#EDEDED" />
              </div>
              <div className="appContentTitle" style={localTitleStyle}>
                {getSession("company")}
              </div>
            </div>
          </div>
        )}
        <div key="container" className="appContainerRowDirection">
          {(pagePrivate || this.props.auth) &&
            !window.location.href.includes("/signin") && (
              <div key="menu" style={localMenuStyle}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {!screenMini && menuMini && (
                    <MenuItem
                      theme={props.theme}
                      label={t("menu.maximize")}
                      menuMini={menuMini}
                      icon="navright"
                      action={this.toMenuMini}
                      actionValue={false}
                      currentPath="{props.location.pathname}"
                    />
                  )}
                  {!screenMini && !menuMini && (
                    <MenuItem
                      theme={props.theme}
                      label={t("menu.minimize")}
                      menuMini={menuMini}
                      icon="navleft"
                      action={this.toMenuMini}
                      actionValue={true}
                      currentPath="{props.location.pathname}"
                    />
                  )}
                  <MenuItem
                    theme={props.theme}
                    label={t("menu.home")}
                    menuMini={menuMini}
                    icon="home"
                    goUrl={props.goUrl}
                    path="/dashboard"
                    currentPath={props.location.pathname}
                  />
                  <MenuItem
                    theme={props.theme}
                    label={t("form.myprofile")}
                    menuMini={menuMini}
                    icon="person"
                    goUrl={props.goUrl}
                    myProfile="1"
                    userid={session.iu}
                    path={`/person-view/${session.iu}`}
                    currentPath={props.location.pathname}
                  />
                  {(session.ai ||
                    !session.gu ||
                    (session.pe.person && session.pe.person.view)) && (
                    <MenuItem
                      theme={props.theme}
                      label={t("form.mygroup")}
                      menuMini={menuMini}
                      userid={session.iu}
                      icon="people"
                      goUrl={props.goUrl}
                      path="/person"
                      currentPath={props.location.pathname}
                    />
                  )}
                  <MenuItem
                    theme={props.theme}
                    label={t("table.regonlines")}
                    menuMini={menuMini}
                    icon="tablet"
                    goUrl={props.goUrl}
                    path="/regonline"
                    currentPath={props.location.pathname}
                  />
                  <MenuItem
                    theme={props.theme}
                    label={t("table.registrations")}
                    menuMini={menuMini}
                    icon="registration"
                    goUrl={props.goUrl}
                    path="/registration"
                    currentPath={props.location.pathname}
                  />
                  <MenuItem
                    theme={props.theme}
                    label={t("login.signout")}
                    menuMini={menuMini}
                    icon="powersettings"
                    goUrl={props.goUrl}
                    path="/signout"
                    currentPath={props.location.pathname}
                  />
                </div>
              </div>
            )}
          <div key="appcnt" id="test" style={localAppContentStyle}>
            <DisplayComponent t={t} {...props} />
          </div>
        </div>
        {props.notifications.length > 0 && (
          <div key="notif" className="appHeaderNotif">
            {props.notifications.map((notification) => {
              return (
                <Notif
                  hideNotification={props.hideNotification}
                  key={notification.id}
                  notification={notification}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default LayoutComponent;
