import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { fetchQl } from '../../apolloClient';
import { Button } from '../../layouts/cssstyled';
import FormField from '../FormField';
import { fieldsValidator } from '../../utils/helper';
import { aValidator } from '../../utils/validators';
import ShowIcon from '../icons/ShowIcon';
import { normalizes } from '../../utils/normalizes';
import { getInputValue, resolvePathObj } from '../../utils/commonutils';
const fields = {
  nick: {
    validators: ['required', 'alphaUSNumeric', 'minLength:3'],
  },
  password: {
    validators: ['required', 'password'],
  },
  email: {
    validators: ['email'],
  },
};
const nameForm = 'signin';
const validate = (values, props) =>
  fieldsValidator(fields, values, props, nameForm);
/*
icon is not ok position left: style={{  position: 'relative', paddingLeft: '35px' }}
 */

const handleForget = async (values, props) => {
  if (!values.email) {
    props.containerPropsForm.showNotificationWithTimeout(
      'Email: ' + props.t('validator.required'),
      2,
      5
    );
    return false;
  }
  // whe input tourId exists, his value overwrittes tourId (that comes from state, that comes from url)

  const variables = { email: values.email };

  // check if tour exist
  const res = await fetchQl(
    `
    mutation forgetLogin ($email: String!) {
      forgetLogin  (email: $email) {
        answer
        errors {  
          key
          value 
        }       
      }
    }`,
    [{ name: 'email', type: 'String!', value: values.email }],
    {
      props,
    }
  );
  // console.log('res', res);
  const answer = resolvePathObj(res, 'data.forgetLogin.answer', {
    notFound: false,
  });
  if (!answer) {
    props.containerPropsForm.showNotificationWithTimeout(
      props.t('login.errorNick'),
      2,
      7
    );
    return false;
  } else {
    props.containerPropsForm.showNotificationWithTimeout(
      props.t('login.forgetSentEmail'),
      false,
      9
    );

    return true;
  }
};

const handleChangePass = async (values, props) => {
  // console.log('handleChangePass', values);

  if (!values.emailvcode) {
    props.containerPropsForm.showNotificationWithTimeout(
      props.t('form.codeverify') + ': ' + props.t('validator.required'),
      2,
      5
    );
    return false;
  }

  if (!values.password) {
    props.containerPropsForm.showNotificationWithTimeout(
      props.t('form.password') + ': ' + props.t('validator.required'),
      2,
      5
    );
    return false;
  }

  const valPassword = aValidator['password'](values.password);
  if (valPassword) {
    props.containerPropsForm.showNotificationWithTimeout(
      props.t('form.password') + ': ' + props.t(valPassword),
      2,
      7
    );
    return false;
  }

  // whe input tourId exists, his value overwrittes tourId (that comes from state, that comes from url)

  // check if tour exist
  const res = await fetchQl(
    `
    mutation recoveryPass($email: String!, $emailvcode: String!,  $password: String!)  {
      recoveryPass  (email: $email, emailvcode: $emailvcode, password: $password) {
        answer
        errors {  
          key
          value 
        }       
      }
    }`,
    [
      { name: 'email', type: 'String!', value: values.email },
      { name: 'emailvcode', type: 'String!', value: values.emailvcode },
      { name: 'password', type: 'String!', value: values.password },
    ],
    {
      props,
    }
  );
  // console.log('res', res);
  const answer = resolvePathObj(res, 'data.recoveryPass.answer', {
    notFound: false,
  });
  if (!answer) {
    return false;
  } else {
    if (answer === 'ok') {
      props.containerPropsForm.showNotificationWithTimeout(
        props.t('info.recoverydone'),
        false,
        10
      );
      return true;
    } else {
      props.containerPropsForm.showNotificationWithTimeout(
        props.t(answer),
        2,
        7
      );
      return false;
    }
  }
};

const Form = (props) => {
  const [show, setShow] = useState(0);
  const [email, setEmail] = useState('');
  // //console.log('sign in form props');
  // //console.log(props);

  const {
    error,
    handleSubmit,
    pristine,
    invalid,
    reset,
    submitting,
    t,
    changeLanguage,
    i18n,
    browserCompatible,
    browserVersionCompatible,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      {show === 0 && (
        <div style={{ width: '300px', margin: 'auto' }}>
          <Field
            name="nick"
            icon="account"
            iconcolor="orange"
            animatePath="*"
            type="text"
            component={FormField}
            label="login.usercode"
            formRowExpand="1"
            autocomplete="on"
            normalize={normalizes.LowerCase}
            showError="submitFailed"
          />
          <Field
            name="password"
            icon="vpnkey"
            iconcolor="orange"
            animatePath="*"
            typeInput="password"
            component={FormField}
            label="login.password"
            autocomplete="on"
            formRowExpand="1"
            showError="submitFailed"
          />
          {browserCompatible && browserVersionCompatible && (
            <div style={{ position: 'relative', margin: 'auto' }}>
              <Button type="submit" disabled={submitting}>
                {t('login.signin')}
              </Button>
            </div>
          )}
          <div style={{ marginTop: '20px', marginBottom: '24px' }}>
            <a onClick={() => setShow(1)}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#777777',
                }}
              >
                <div>{t('login.forgetlogin')}</div>
                <ShowIcon icon="navright" size="19" color="#37ad9a" />
              </div>
            </a>
          </div>
        </div>
      )}

      {show === 1 && (
        <div style={{ width: '300px', margin: 'auto' }}>
          <Field
            inputName="email"
            name="email"
            formProps={props}
            label="form.email"
            nameForm="singup1"
            component={FormField}
            type="text"
            normalize={normalizes.LowerCase}
            typeInput="text"
            formRowExpand="1"
          />

          <div
            style={{ position: 'relative', paddingTop: '15px', margin: 'auto' }}
          >
            <Button
              type="button"
              disabled={submitting}
              onClick={async () => {
                const email = getInputValue(props, 'email', { nameForm });
                setEmail(email);
                const values = { email };
                const res = await handleForget(values, props);
                if (res) setShow(2);
              }}
            >
              {t('login.rememberlogin')}
            </Button>
          </div>
        </div>
      )}

      {show === 2 && (
        <div style={{ width: '300px', margin: 'auto' }}>
          <Field
            inputName="emailvcode"
            name="emailvcode"
            formProps={props}
            label="form.codeverify"
            nameForm="singup2"
            component={FormField}
            type="text"
            normalize={normalizes.LowerCase}
            typeInput="text"
            formRowExpand="1"
          />
          <div className="formError" style={{ color: '#be0000' }}>
            {t('validator.required')}
          </div>

          <Field
            name="password"
            type="text"
            component={FormField}
            label="login.newpassword"
            validatorRequired="1"
            width="300px"
          />

          <div
            style={{ position: 'relative', paddingTop: '15px', margin: 'auto' }}
          >
            <Button
              type="button"
              disabled={submitting}
              onClick={async () => {
                const emailvcode = getInputValue(props, 'emailvcode', {
                  nameForm,
                });
                const password = getInputValue(props, 'password', { nameForm });
                const values = { emailvcode, password, email };
                const res = await handleChangePass(values, props);
                if (res) setShow(0);
              }}
            >
              {t('info.vUpdate')}
            </Button>
          </div>
        </div>
      )}
    </form>
  );
};

export default reduxForm({
  form: nameForm,
  validate,
})(Form);
