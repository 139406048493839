import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../FormField';
import FieldReadOnly from '../FieldReadOnly';
import FormArrayField from '../FormArrayField';

import {
  fieldsValidator,
  getOptions,
  processNewValues,
} from '../../utils/helper';
import {
  resolvePathObj,
  processValues,
  realTypeOf,
  tryParseJSON,
  getNameHotelAndRoom,
  getRelatedTourService,
  toPrice,
} from '../../utils/commonutils';

import { Tables } from '../../defTables';
import { normalizes } from '../../utils/normalizes';

const tableCrud = 'regonline';
let nameForm = `${tableCrud}Form`;
const validate = (values, props) => {
  const result = {}; // view or update mode, is disabled the fields, so , no validation
  return result;
  //fieldsValidator('regonline', values, props, nameForm);
};
class Form extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.timerHandle = setTimeout(() => {
      this.props.dispatch(change(nameForm, '_fakefield', Math.random()));
      this.props.executeCode('onChangeInput', {
        nameForm,
        action: 'initForm',
        props: this.props,
        formProps: this.props,
        formState: this.props.formState,
      });
    }, 1000);
    window.addEventListener('message', this.readMessage, true);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
    window.removeEventListener('message', this.readMessage, true);
  }

  readMessage = (event) => {
    if (!event.origin.includes(window.location.hostname)) return;
    if (event && event.data) {
      const params = tryParseJSON(event.data, {});
      if (params.popup) {
        params.inputFullName = params.popup;
        if (!params.formProps) params.formProps = this.props;
        this.props.executeCode('onChangeInput', params);
      }
    }
  };

  hocdebounced = debounce((methodCode, params) => {
    this.props.executeCode(methodCode, params);
  }, 1500);

  render() {
    const {
      error,
      handleSubmit,
      invalid,
      pristine,
      change,
      submitting,
      submitFailed,
      t,
      aHotels,
      aServices,
      customerStatus,
      customerSubStatus,
      nameRoom,
      data,
    } = this.props;

    let tourDescription = '';
    if (data.regonlinetour_id) {
      tourDescription = data.regonlinetour_id.longname
        ? data.regonlinetour_id.longname
        : '';
    }

    // take name from related tables, it's more sure than getTourCustomerStatusAndServices
    // because status for tourcategory could had changed
    let nameCustomerStatus = '';
    if (customerStatus) {
      nameCustomerStatus = customerStatus.name;
      if (customerSubStatus) {
        nameCustomerStatus =
          nameCustomerStatus + ' - ' + customerSubStatus.name;
      }
    }

    let aServicesSelects = [];
    const objects = tryParseJSON(data.objects, {});

    for (let [key, object] of Object.entries(objects)) {
      let servicetour = getRelatedTourService(aServices, key, object, {
        name: 'fullNameWithoutPrice',
      });
      if (servicetour) {
        aServicesSelects.push(
          <div className="formSection" key={key}>
            {servicetour.nameOnly} € {servicetour.amount}
          </div>
        );
      }
    }

    // if items has data, reeplace values to display
    const items = tryParseJSON(data.items, []);
    if (items.length > 0) {
      const nameCustomerStatusItem = items.find(
        (i) => i.code === 'customerstatus'
      );
      if (nameCustomerStatusItem) {
        nameCustomerStatus =
          nameCustomerStatusItem.name +
          ' ' +
          toPrice(nameCustomerStatusItem.price);
      }
    }

    return (
      <form onSubmit={handleSubmit}>
        <div className="appBodyTitleSeparator" />

        <div>
          <span className="formLabel">{t('table.tour')}:</span>{' '}
          {tourDescription}
        </div>
        <div style={{ paddingBottom: '15px' }}> Code: {data.tour_id}</div>

        <div className="formSection">
          <FieldReadOnly
            value={
              data.regonlineperson_id && data.regonlineperson_id.name
                ? data.regonlineperson_id.name
                : ''
            }
            label={t('table.person')}
          />
          <Field
            inputName="state"
            name="state"
            formProps={this.props}
            pathInTables="regonline.fields.state"
            formState={this.props.formState}
            nameForm="regonlineForm"
            component={FormField}
            disabled={true}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectBox"
            typeInput="selectBox"
            listSource="processRegonline"
          />
          <Field
            inputName="dateregistration"
            name="dateregistration"
            formProps={this.props}
            pathInTables="regonline.fields.dateregistration"
            formState={this.props.formState}
            nameForm="regonlineForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
        </div>

        <FieldReadOnly
          value={nameCustomerStatus}
          label={t('table.customerstatus')}
        />

        <div className="formSection">
          <FieldReadOnly value={nameRoom} label={t('table.tourroom')} />
          <Field
            inputName="room_shared"
            name="room_shared"
            formProps={this.props}
            pathInTables="regonline.fields.room_shared"
            formState={this.props.formState}
            nameForm="regonlineForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
            disabled={true}
            disableValidatorRequired={true}
          />
        </div>
        <div className="formSection">
          <FieldReadOnly
            value={
              data.regonlinestopspoint_id && data.regonlinestopspoint_id.name
                ? data.regonlinestopspoint_id.name
                : ''
            }
            label={t('table.stopspoint')}
          />
        </div>
        {aServicesSelects}
        <div className="formSection">
          <Field
            inputName="price"
            name="price"
            formProps={this.props}
            pathInTables="regonline.fields.price"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.Price}
            typeInput="text"
            disabled={true}
          />
          <Field
            inputName="partialpayment"
            name="partialpayment"
            formProps={this.props}
            pathInTables="regonline.fields.partialpayment"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.Price}
            typeInput="text"
            disabled={true}
          />
        </div>

        <Field
          inputName="notes"
          name="notes"
          formProps={this.props}
          pathInTables="registration.fields.notes"
          formState={this.props.formState}
          nameForm={nameForm}
          component={FormField}
          executeCode={this.props.executeCode}
          syncCode={this.props.syncCode}
          type="textarea"
          typeInput="textarea"
          width="500px"
          disabled={data.state === 1 ? false : true}
        />
        <Field
          inputName="_formstate"
          name="_formstate"
          typeInput="hidden"
          component={FormField}
          label=""
        />

        <div>
          {error && <strong>{error}</strong>}

          <div className="formError">{invalid && t('form.haserrors')}</div>
        </div>
      </form>
    );
  }
}

const ComponentWithData = reduxForm({
  form: nameForm,
  touchOnChange: true,

  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  const log = false;
  const statesReturn = { myState: state };
  let initialValues;

  if (
    ownProps.formState.actionsave_origen === 'update' ||
    ownProps.formState.actionsave_origen === 'duplicate'
  ) {
    if (ownProps.data) {
      initialValues = processValues(
        ownProps,
        tableCrud,
        ownProps.data,
        'toClient',
        'view'
      );

      if (
        ownProps.formState.defaultValues &&
        ownProps.formState.defaultValues.id
      ) {
        initialValues.id = ownProps.formState.defaultValues.id;
      }
    }
  } else {
    initialValues = processNewValues(
      ownProps,
      tableCrud,
      ownProps.data,
      'toClient',
      'new'
    );
    if (
      ownProps.containerPropsForm.history &&
      Object.keys(Tables[tableCrud].listFilters)
    ) {
      const search = ownProps.containerPropsForm.history.location.search;
      const params = new URLSearchParams(search);

      Object.keys(Tables[tableCrud].listFilters.fields).map((fieldKey) => {
        if (log) console.log('fieldKey', fieldKey);
        let fieldFilter = Tables[tableCrud].listFilters.fields[fieldKey];
        let defaultValue, defaultValueName;
        if (
          ownProps.formState.defaultValues &&
          ownProps.formState.defaultValues[fieldKey]
        ) {
          defaultValue = ownProps.formState.defaultValues[fieldKey];
        } else {
          defaultValue = params.get(fieldKey);
          defaultValueName = params.get(fieldKey.replace('_id', '_name'));
        }
        if (defaultValue) {
          let initialVal;
          const isFieldArray =
            fieldFilter.fieldContainer &&
            Tables[tableCrud].fields[fieldFilter.fieldContainer].subfields;
          if (
            fieldFilter.fieldSource &&
            fieldFilter.fieldSource.typeInput === 'selectAutocomplete' &&
            fieldFilter.fieldSource.saveonly &&
            !defaultValue.id
          ) {
            initialVal = { id: defaultValue, name: defaultValueName };
          } else {
            initialVal = defaultValue;
          }
          if (isFieldArray) {
            fieldKey = fieldFilter.fieldContainer;
            if (!initialValues[fieldKey])
              initialValues[fieldKey] = [{ id: '' }];
            initialValues[fieldKey][0][fieldFilter.subfield] = initialVal;
          } else {
            initialValues[fieldKey] = initialVal;
          }
          if (log)
            console.log(
              'initialValues[' + fieldKey + '] ',
              initialValues[fieldKey]
            );
        }
      });
    }
  }
  statesReturn.initialValues = initialValues;

  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null
)(ComponentWithData);

export default ComponentWithDataAndState;
