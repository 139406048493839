import React from 'react';
import { Field, reduxForm, change } from 'redux-form';

import { Button } from '../../layouts/cssstyled';
import FormField from '../FormField';
import { fieldsValidator } from '../../utils/helper';
import ShowIcon from '../icons/ShowIcon';
import { normalizes } from '../../utils/normalizes';
import { validUUID } from '../../utils/validators';

const fieldsWithTour = {
  person_id: {
    validators: ['required'],
  },
  tourId: {
    validators: ['required'],
  },
};

const tableCrud = 'regonline';
let nameForm = `addPreregistration`;
const validate = (values, props) => {
  let result;

  result = fieldsValidator(fieldsWithTour, values, props, nameForm);
  if (!result.tourId) {
    const urlSplit = values.tourId.trim().split('/');
    const tour = urlSplit[urlSplit.length - 1];

    // have value, no errors required, then valid uuid
    result.tourId = validUUID(tour);
  }

  // 'validUUID'
  return result;
};

const Form = (props) => {
  const {
    error,
    handleSubmit,
    pristine,
    invalid,
    reset,
    submitting,
    t,
    changeLanguage,
    i18n,
    browserCompatible,
    browserVersionCompatible,
    tourid,
    lastTours,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ backgroundColor: '#efefef', padding: '15px' }}>
        <div style={{ color: 'red' }}>Nouvelle pre-inscription?</div>
        <div style={{ margin: 'auto', display: 'flex' }}>
          {!tourid && (
            <div style={{ marginRight: '30px' }}>
              <Field
                inputName="tourId"
                name="tourId"
                formProps={props}
                label="form.codetour"
                nameForm={nameForm}
                component={FormField}
                type="text"
                typeInput="text"
                formRowExpand="1"
                showError="submitFailed"
              />
            </div>
          )}
          <div>
            {' '}
            <Field
              inputName="person_id"
              name="person_id"
              label="table.person"
              formProps={props}
              listOptions={props.persons}
              nameForm={nameForm}
              component={FormField}
              type="selectBox"
              typeInput="selectBox"
              showError="submitFailed"
            />
          </div>

          <div style={{}}>
            <Button type="submit" disabled={submitting}>
              {t('login.signup')}
            </Button>
          </div>
        </div>
        {!tourid && lastTours && lastTours.length > 0 && (
          <div>
            Vous ne connaissez pas le code? Cliquez sur le nom d'un des derniers
            pèlerinages utilisés ci-dessous:
            {lastTours.map((tour) => (
              <div key={tour.id}>
                <a
                  style={{ color: 'darkblue' }}
                  onClick={() =>
                    props.dispatch(
                      change('addPreregistration', 'tourId', tour.id)
                    )
                  }
                >
                  {tour.longname}
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </form>
  );
};

export default reduxForm({
  form: nameForm,
  validate,
})(Form);
